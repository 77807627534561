import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceConfigService {

  public server;
  public httpWithoutInterceptor: HttpClient;
  public addGtagToHead(): void {
    const gtagId = this.getServerConfig().ga4Tag;
    //Insert gtag script in head <head>
    if (gtagId) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtagId}`;
      document.head.appendChild(script);

      //Insert GTag Initialization script
      const gtagScript = document.createElement('script');
      gtagScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer',${gtagId});`;
      document.head.appendChild(gtagScript)
    }
  }

  constructor(handler: HttpBackend) { 
     this.httpWithoutInterceptor = new HttpClient(handler);
  }
  loadAppConfig() {
    return this.httpWithoutInterceptor.get('/assets/data/appConfig.json')
      .toPromise()
      .then(data => {
        this.server = data;
      });
  }

  getServerConfig() {
    return this.server;
  }
}
