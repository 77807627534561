import { Component, Input } from '@angular/core';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { ApiService } from "@app/gateway/services/api.service";
import { CreateAccountService } from '@app/gateway/services/createAccount.service';
import { StateService } from '@app/services/state/state.service';
import { ApplicationService } from '@app/services/application/application.service';
import { StorageService } from '@app/services/storage/storage.service';

@Component({
  templateUrl: './confirm-appeal.component.html',
  styleUrls: ['./confirm-appeal.component.scss']
})
export class ConfirmAppealModalComponent extends BaseModalDirective {
  @Input() pageId;
  constructor(protected modalRef: BsModalRef, protected builder: FormBuilder, private router: Router, public apiService: ApiService, private createaAccountService: CreateAccountService, private stateService: StateService, private application: ApplicationService, protected storageService: StorageService) {
    super(modalRef, builder);
  }

  applicationNum;

  hideStepper() {
    this.createaAccountService.setIsStepperOn(false);
  };


  onAppealConfirm(from: string) {
    this.modalRef.hide();
    if(sessionStorage.getItem('appType') === 'BXT'){
      if (from === 'backToPrevious') {
        this.hideStepper();
        this.router.navigateByUrl('api/prov/member-search?type=BXT');
        return;
      } else {
        this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
        return;
      }
      
    } if(sessionStorage.getItem('appType') === 'IME'){
      this.hideStepper();
      if (from === 'backToPrevious') {
        this.router.navigateByUrl('api/prov/member-search?type=IME');
        return;
      } else {
        this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
        return;
      }
      
    } else if (from === 'readOnlyAPL') {
      this.startApp('APL');
    } else {
      if(from === 'TNIcon') {
        this.router.navigateByUrl('/user-profile/benefit-dashboard')
      } else {
        this.router.navigateByUrl("user-profile/my-appeals");
      }
    }
  }

  startApp(appType) {
    this.application.getHeadOfHouseholdInfo(0, 'APL').subscribe((res: any) => {
      this.applicationNum = res?.applicationNumber;
      if (sessionStorage.getItem('removedAppealRecord')) {
        sessionStorage.removeItem('removedAppealRecord')
      }
      if (this.applicationNum && this.storageService.getAppNumber() !== this.applicationNum) {
        sessionStorage.setItem('appNum', this.applicationNum);
        this.storageService.setAppNumber(this.applicationNum);
      }
      if (this.applicationNum && this.applicationNum !== '0' && this.applicationNum !== 0) {
        this.stateService.resetStates();
        this.application.deleteApp(this.applicationNum, this.pageId).subscribe(() => {
          sessionStorage.setItem('appNum', null);
          this.storageService.setApplicationType(appType);
          this.router.navigateByUrl('/application/' + 'NEW');
        })
      }
      else {
        this.stateService.resetStates();
        sessionStorage.setItem('appNum', null);
        this.storageService.setApplicationType(appType);
        this.router.navigateByUrl('/application/' + 'NEW');
      }
    });
  };
}
