import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/gateway/services/api.service';
import { StorageService } from "@app/services/storage/storage.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AppealsService } from '../appeals.service';
import Vs from '@app/services/validators/validators.service';
import { HttpHeaders  } from '@angular/common/http';

@Component({
  selector: 'nh-appeal-withdrawal',
  templateUrl: './appeal-withdrawal.component.html',
  styleUrls: ['./appeal-withdrawal.component.scss']
})
export class AppealWithdrawalComponent implements OnInit {
    translatedLang;
    memberInfo;
    appealWithdrawalItems;
    selectedItem;
    continueBenefits;
    response;

    yes = { "code": "Y", "value": "Yes"};
    no = { "code": "N", "value": "No" };

    yesSp = { "code": "Y", "value": "Si"};
    noSp = { "code": "N", "value": "No" };

    appealWithdrawalItemsEn: any = [ this.yes, this.no];
    appealWithdrawalItemsSp: any = [ this.yesSp, this.noSp];

    appealWithdrawalForm = new FormGroup({
        reasonForWithdrawal: new FormControl('', [Vs.required, Vs.submittedBy]),
        relationshipWithAppellant: new FormControl('', [Vs.required, Vs.submittedBy]),
        phoneNumber: new FormControl('', [Vs.phoneNumber]),
        appellantSignSW: new FormControl('', Vs.required),
        appellantSign: new FormControl('', Vs.required),
        submittedBy: new FormControl('', Vs.required)
    });

    get form() { return this.appealWithdrawalForm['controls']; }


    constructor(public translateService: TranslateService, public app: ApiService,
        protected storageService: StorageService, private router: Router, public appealsService: AppealsService,public activatedRoute: ActivatedRoute) { }


    ngOnInit() {
        const defaultLanguage = this.translateService.getDefaultLang();
        this.translatedLang = defaultLanguage;
        this.languageCheck(this.translatedLang);
        this.translateService.onDefaultLangChange.subscribe((res) => {
            this.translatedLang = res.lang
            this.languageCheck(this.translatedLang);

          if(this.memberInfo){
            if(this.translatedLang === 'en'){
              this.memberInfo['appealReasonDescription'] = this.response?.appealWithdrawalResponse.appealReason?.value;
              this.memberInfo['appealStatusDesc'] = this.response?.appealWithdrawalResponse.appealStatus;
              this.memberInfo['continueBenefitsDescription'] = this.response?.appealWithdrawalResponse.continueBenefits?.value;
            }else{
              this.memberInfo['appealReasonDescription'] = this.response?.appealWithdrawalResponse?.appealReason?.spValue;
              this.memberInfo['appealStatusDesc'] = this.response.appealWithdrawalResponse.appealStatusEs;
              this.memberInfo['continueBenefitsDescription'] = this.response?.appealWithdrawalResponse.continueBenefits?.spValue;
            }
          }
        });

        this.activatedRoute.queryParams.subscribe(res => this.onLoad(res));

    }

    onValueChange(formControlName): void {
        this.selectedItem = this.appealWithdrawalForm.controls[formControlName].value.code;
    };
    
    goToAppealsDashboard(){
        this.router.navigateByUrl("user-profile/my-appeals");
    }

    onLoad(response){
        const { appealNumber, indvid } = response;
        const url = `appeals/withdrawal/indv/${indvid}/apl/${appealNumber}`;
        this.app.get(url, true, {headers: new HttpHeaders({'pageId': 'TCAPW'})}).subscribe((resp) => {  
          if (resp) {
            console.log(resp);
            this.response = resp;
            this.memberInfo = resp ? resp['appealWithdrawalResponse'] : null;
          };

          if(this.translatedLang === 'en'){
            this.memberInfo['appealReasonDescription'] = resp['appealWithdrawalResponse'].appealReason?.value;
            this.memberInfo['appealStatusDesc'] = resp['appealWithdrawalResponse'].appealStatus;
            this.memberInfo['continueBenefitsDescription'] = resp['appealWithdrawalResponse'].continueBenefits?.value;
          }else{
            this.memberInfo['appealReasonDescription'] = resp['appealWithdrawalResponse'].appealReason?.spValue;
            this.memberInfo['appealStatusDesc'] = resp['appealWithdrawalResponse'].appealStatusEs;
            this.memberInfo['continueBenefitsDescription'] = resp['appealWithdrawalResponse'].continueBenefits?.spValue;
          }
    
          this.appealWithdrawalForm.controls["reasonForWithdrawal"].patchValue(this.memberInfo.reasonForWithdrawal);
          this.appealWithdrawalForm.controls["relationshipWithAppellant"].patchValue(this.memberInfo.relationshipWithAppellant);
          this.appealWithdrawalForm.controls["submittedBy"].patchValue(this.memberInfo.submittedBy);      
        })
    };

    languageCheck(lang) {
        if (lang === 'en') {
          this.appealWithdrawalItems = this.appealWithdrawalItemsEn;
        } else {
          this.appealWithdrawalItems = this.appealWithdrawalItemsSp;
        }
      }

    isFormValid(): boolean {
        return ((this.appealWithdrawalForm.status === "VALID"));
    }

    onSubmit(info, controls, currentLanguage): void {
      this.appealsService.saveWithdrawal(info, controls, currentLanguage);
    };

    deselect(val){
      if(val.target.checked){
          this.appealWithdrawalForm.controls["appellantSignSW"].patchValue('Y');
      }else{
          this.appealWithdrawalForm.controls["appellantSignSW"].patchValue(null);
      }
    }

    submittedValidator(val) {
      if(val.target.value.length>0){
        const exp = /^[^#%*{}<>|[\]^]*$/;
        !exp.test(this.appealWithdrawalForm.controls["submittedBy"].value) ? this.appealWithdrawalForm.controls["submittedBy"].setErrors({INVALID_SUBMITTEDBY: true}): this.appealWithdrawalForm.controls["submittedBy"].setErrors(null)
      }
    }

    signatureValidator(val) {
      if(val.target.value.length>0){
        const exp = /^[^#%*{}<>|[\]^]*$/;
        !exp.test(this.appealWithdrawalForm.controls["appellantSign"].value) ? this.appealWithdrawalForm.controls["appellantSign"].setErrors({INVALID_SUBMITTEDBY: true}): this.appealWithdrawalForm.controls["appellantSign"].setErrors(null)
      }
    }

}
 