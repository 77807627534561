<div class="mb-3">
  <div class="bg-custom">
   <div class="container" *ngIf="displayDetails">
     <div *ngIf="memberInfo">
       <h5 class="section-header">{{'HOUSEHOLD_MEMBER' | translate}}</h5><hr>
         <div>
           <small><strong>{{'NAME' | translate}}</strong></small>
           <p *ngIf="isHeadOfHouseHold">{{ memberInfo.firstName }} {{memberInfo.hoHMiddleInitial}} {{ memberInfo.lastName }} {{memberInfo.hoHSuffixName}}</p>
           <p *ngIf="!isHeadOfHouseHold">{{ memberInfo.firstName }} {{memberInfo.middleInitial}} {{ memberInfo.lastName }} {{memberInfo.suffixName}}</p>
         </div>
         <div>
           <small><strong>{{'PERSON_ID' | translate}}</strong></small>
           <p>{{ memberInfo.indvId }}</p>
         </div>
         <div>
         </div>
     </div><br>

     <div *ngIf="coverageInfo">
       <div><h5 class="section-header">{{'COVERAGE_INFO' | translate}}</h5></div><hr>

       <div *ngIf="coverageInfo.edgStatus === 'AP' && coverageInfo.cobSw !== 'Y'">
         <p>{{'APPROVAL_MSG' | translate}}<strong>{{'APPROVAL_MSG_1' | translate}}</strong></p><br>
       </div>
       <div *ngIf="coverageInfo.edgStatus === 'TN' && coverageInfo.cobSw !== 'Y'">
         <p>{{'END_MSG' | translate}}<strong>{{'END_MSG_1' | translate}}</strong></p><br>
       </div>
       <div *ngIf="coverageInfo.edgStatus === 'DN' && coverageInfo.cobSw !== 'Y'">
         <p>{{'DENIAL_MSG' | translate}}<strong>{{'DENIAL_MSG_1' | translate}}</strong></p><br>
       </div>
       <div *ngIf="coverageInfo.cobSw === 'Y'">
        <p>{{'OPEN_ON_APPEAL' | translate}}<strong>{{'OPEN_ON_APPEAL_1' | translate}}</strong></p><br>
      </div>

        <div>
           <small><strong>{{'COVERAGE_TYPE' | translate}}</strong></small>
           <p>{{ coverageInfo.highLevelProgram }}</p>
         </div>
         <div>
           <small><strong>{{'COVERAGE_CASE_NUMBER' | translate}}</strong></small>
           <nh-popover class="pl-1" [content]="caseNumPop | translate"></nh-popover>
           <p>{{ memberInfo.caseNum }}</p>
         </div>
         <div>
           <small><strong>{{'COVERAGE_STATUS' | translate}}</strong></small>
           <nh-popover *ngIf="coverageInfo.cobSw === 'Y'" class="pl-1" [content]="coverageStatustooltip | translate"></nh-popover>
           <div *ngIf="this.translateService.getDefaultLang() === 'en'">
              <p *ngIf="coverageInfo.edgStatus === 'TN' && coverageInfo.cobSw !== 'Y'">Terminated</p>
              <p *ngIf="coverageInfo.edgStatus === 'AP' && coverageInfo.cobSw !== 'Y'">Approved</p>
              <p *ngIf="coverageInfo.edgStatus === 'DN' && coverageInfo.cobSw !== 'Y'">Denied</p>
              <p *ngIf="coverageInfo.cobSw === 'Y'">Open on Appeal</p>
            </div>
            <div *ngIf="this.translateService.getDefaultLang() === 'sp'">
              <p *ngIf="coverageInfo.edgStatus === 'TN' && coverageInfo.cobSw !== 'Y'">Cesado</p>
              <p *ngIf="coverageInfo.edgStatus === 'AP' && coverageInfo.cobSw !== 'Y'">Aprobado</p>
              <p *ngIf="coverageInfo.edgStatus === 'DN' && coverageInfo.cobSw !== 'Y'">Denegado</p>
              <p *ngIf="coverageInfo.cobSw === 'Y'">Abierto en apelación</p>
            </div>

         </div>
         <div *ngIf="coverageInfo.edgStatus === 'AP' || coverageInfo.edgStatus === 'TN' || coverageInfo.cobSw === 'Y'">
           <small><strong>{{'COVERAGE_BEGIN_DATE' | translate}}</strong></small>
           <p>{{ covBeginDate }}</p>
         </div>
         <div *ngIf="coverageInfo.edgStatus === 'DN' && applicationDate && coverageInfo.cobSw !== 'Y'">
           <small><strong>{{'APPLICATION_BEGIN_DATE' | translate}}</strong></small>
           <p>{{ applicationDate }}</p>
         </div>
         <div *ngIf="coverageInfo.coverageEndDate && coverageInfo.edgStatus === 'TN' || coverageInfo.coverageEndDate && coverageInfo.cobSw === 'Y'">
           <small><strong>{{'COVERAGE_END_DATE' | translate}}</strong></small>
           <p>{{ covEndDate }}</p>
         </div>

      <div *ngIf="coverageInfo?.kbPremiumResponse?.length">
       <div><h5 class="section-header">{{'KB' | translate}}</h5></div><hr>
       <div class="row">
         <table class="table">
           <thead>
             <tr>
               <th>{{'PREMIUM_BEGIN_DATE' | translate}}</th>
               <th>{{'PREMIUM_END_DATE' | translate}}</th>
               <th>{{'INDIVIDUAL_PREMIUM_AMOUNT' | translate}}</th>
             </tr>
           </thead>
           <tbody>
             <tr *ngFor="let item of coverageInfo.kbPremiumResponse">
               <td>{{ item.premiumBeginDate }}</td>
               <td>{{ item.premiumEndDate ? item.premiumEndDate : 'Ongoing' }}</td>
               <td>{{ item.premiumAmt }}</td>
             </tr>
           </tbody>
         </table>
       </div>
      </div><br>

       <div *ngIf="coverageInfo.denialReasonResponse && coverageInfo.edgStatus === 'DN' && coverageInfo.cobSw !== 'Y'">
         <small><strong>{{'DENIAL_RSN' | translate}}</strong></small><br>
         <div *ngFor="let item of coverageInfo.denialReasonResponse">
           <div *ngIf="this.translateService.getDefaultLang() === 'en'">
             <ol class="pl-0"><p>{{ item.denialReasonText }}</p></ol>
           </div>
           <div *ngIf="this.translateService.getDefaultLang() === 'sp'">
             <ol class="pl-0"><p>{{ item.denialReasonTextES }}</p></ol>
           </div>
         </div>
       </div>

       <div *ngIf="coverageInfo.terminationReasonResponse && coverageInfo.edgStatus === 'TN' && coverageInfo.cobSw !== 'Y'">
         <small><strong>{{'REASON_COVERAGE_END' | translate}}</strong></small><br>
         <div *ngFor="let item of coverageInfo.terminationReasonResponse">
          <div *ngIf="this.translateService.getDefaultLang() === 'en'">
           <ol class="pl-0"><p>{{ item.terminationReasonText }}</p></ol>
          </div>
          <div *ngIf="this.translateService.getDefaultLang() === 'sp'">
            <ol class="pl-0"><p>{{ item.terminationReasonTextES }}</p></ol>
          </div>
         </div>
       </div>
     </div>

     <div *ngIf="appealSummary && appealSummary.length && appealSummary[0].appealTypeResponse && appealSummary[0].appealTypeResponse.length">
       <h5 class="section-header">{{'APPEAL_INFORMATION' | translate}}</h5><hr>
         <div *ngFor="let item of appealSummary[0].appealTypeResponse">
         <div class="pt-3">
           <small><strong>{{'APPEAL_NUMBER' | translate}}</strong></small>
           <div>
             {{item.appealNumber}}
           </div>
         </div>
         <div class="pt-3">
           <small><strong>{{'APPEAL_STATUS' | translate}}</strong></small>
           <div>
             {{item.appealStatusDescription}}
           </div>
         </div>
         <div class="pt-3" *ngIf="item.formatedHearingDate">
           <small><strong>{{'HEARING_INFO' | translate}}</strong></small>
           <div>
             <div>{{ item.formatedHearingTime }}</div>
             <div>{{ item.formatedHearingDate | date: 'MM/dd/yyyy' }}</div>
             <div>{{ item.hearingTypeDescription }}</div>
           </div>
         </div>
         </div>
     </div>

     <br>

     <div *ngIf="tplResponse && tplResponse.length">
       <h5 class="section-header">{{'OTHER_COVERAGE' | translate}}</h5><hr>
         <div>
           <small><strong>{{'HEALTH_COVERAGE_TYPE' | translate}}</strong></small>
           <div *ngFor="let item of tplResponse">
             {{item.tplType}}
           </div>
         </div>
         <div class="pt-3">
           <small><strong>{{'HEALTH_INSURANCE_COMPANY_NAME' | translate}}</strong></small>
           <div *ngFor="let item of tplResponse">
             {{item.tplName}}
           </div>
         </div>
         <div class="pt-3" *ngFor="let item of tplResponse">
           <div>
             <small><strong>{{'DATE_STARTED' | translate}}</strong></small>
             <div *ngIf="item.tplCoverageBeginDate">
               {{item.tplCoverageBeginDate}}
             </div>
           </div>
         </div>
         <div class="pt-3" *ngFor="let item of tplResponse">
           <div *ngIf="item.tplCoverageEndDate">
             <small><strong>{{'DATE_ENDED' | translate}}</strong></small>
             <div>
               {{item.tplCoverageEndDate}}
             </div>
           </div>
         </div>
     </div>

     <div *ngIf="healthPlanResponse && healthPlanResponse.length">
       <h5 class="section-header">{{'HEALTH_PLAN_SECTION' | translate}}</h5><hr>
       <div>
         {{'HEALTH_PLAN_INFOTEXT' | translate}}
       </div>
       <br> 
       <div *ngFor="let item of healthPlanResponse">
         <div>
           <small><strong>{{'HEALTH_PLAN_NAME' | translate}}</strong></small>
           <div>
             {{item.providerName}}
           </div>
         </div>
         <div class="pt-3">
           <small><strong>{{'HEALTH_PLAN_START_DATE' | translate}}</strong></small>
           <div>
             {{item.startDate}}
           </div>
         </div>
         <div class="pt-3">
           <small><strong>{{'HEALTH_PLAN_END_DATE' | translate}}</strong></small>
           <div>
             {{item.endDate}}
           </div>
         </div>
         <br>
         </div>
         <div>
           <br>
             {{'HEALTH_PLAN_MORE_INFO' | translate}} <a target="_blank" href="https://www.tn.gov/tenncare/members-applicants/managed-care-organizations.html">TennCare website</a>
         </div>
     </div>


   </div>
 </div>
</div>