import { Component, AfterContentInit, ChangeDetectionStrategy, AfterContentChecked } from '@angular/core';
import { filter, isNil, equals } from 'ramda';
import { Individual } from '@app/models/individual.model';
import { isYes } from '@app/helpers/tayn.helpers';
import { swapControls } from '@app/helpers/forms.helpers';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { ViewEncapsulation } from '@angular/core';
import { setValid } from '@app/helpers/form-control.helpers';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { distinctUntilChanged } from "rxjs/operators";
import Vs from "@app/services/validators/validators.service";
import { Title } from '@angular/platform-browser';
import { disableReset, disableResetControls } from './../../../../helpers/reset-able.helpers';
import { disableControls, enableControls, enableResetControls, resetEnableControls } from '@app/helpers/reset-able.helpers';
import { maskZip } from './../../../../helpers/function.helpers';
import { isExported, isExportStatusNotEmpty, isRmbRmc } from '@app/helpers/mode.helpers';

@Component({
  templateUrl: './kt-referral.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./kt-referral.component.scss']
})
export class KtReferralComponent extends SectionFormDirective implements AfterContentInit, AfterContentChecked {
  isDonotWantToContinueSelected = false;
  individuals18Under: Individual;
  selectedChild: any;
  isOtherPersonInformationEnabled = false;
  primaryIndividual: any;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'value',
    itemsShowLimit: 3,
    enableCheckAll: false
  };
  headSelected = false;
  assistantSelected = false;
  otherSelected = false;
  translatedLang;
  defaultLanguage;
  physicalHomeNumber;
  assistingHomeNumber;
  isReasonSelected = false;
  isPastYearLivArrangementSelected = false;
  isRmbRmc = false;
  appType = sessionStorage.getItem('appType');
  showKbQuestion;
  houseMember;
  address;
  kbAddressFormat;
  isHideSchoolSw = false;
  hohZip;
  assisZip;
  exported: boolean = false;

  constructor(
    builder: FormBuilder,
    public translateService: TranslateService,
    private titleService: Title
  ) {
    super(builder);
    this.titleService.setTitle('Katie Beckett');
  }

  ngAfterContentInit(): void {
    this.exported = isExportStatusNotEmpty(this.record.exportingStatus) && isExported(this.record.exportingStatus) && isRmbRmc(this.mode);
    this.isRmbRmc = this.appType === 'RB' || this.appType === 'RC';
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
    });
    if (sessionStorage.getItem('changeKBReferralSW') !== 'Y') {
      this.showKbQuestion = false;
    } else {
      this.showKbQuestion = true;
    }
    const data = this.getControl('data');
    const waitingList = this.getControl('data.kbRmcWlSw');
    const disEnroll = this.getControl('data.kbRmcDsnrSw')
    this.tables.MPKBCHNG = waitingList.value !== 'Y' ? this.removeValuesFromDropdown('WLR') : this.tables.MPKBCHNG;
    this.tables.MPKBCHNG = disEnroll.value !== 'Y' ? this.removeValuesFromDropdown('DIS') : this.tables.MPKBCHNG;
    const kbOtherPhysicalAddressSwitch = data.get('livingArrangement.kbOtherPhysicalAddressSwitch');
    const kbContactInterpreterSwitch = data.get('kbContactInterpreterSwitch');
    const kbContactIndicator = data.get('contactInformation.kbContactIndicator');
    const kbContactLanguage = data.get('kbContactLanguage');
    this.kbAddressFormat = data.get('livingArrangement.kbAddressFormat');
    this.address = data.get('livingArrangement.address');    

    if (this.kbAddressFormat?.value) {
      const tableVar = this.tables.ADDRESSFORMATTYPES.filter((item) => item.code === this.kbAddressFormat.value.code);
      tableVar[0].value = this.kbAddressFormat.value.value;
      this.kbAddressFormat.patchValue(tableVar[0]);
      if (this.kbAddressFormat.value && this.kbAddressFormat.value.code === 'US') {
        this.getControl('data.livingArrangement.address.addrApoFpo').clearValidators();
        this.getControl('data.livingArrangement.address.addrApoFpo').updateValueAndValidity();
        this.getControl('data.livingArrangement.address.addrAaAeAp').clearValidators();
        this.getControl('data.livingArrangement.address.addrAaAeAp').updateValueAndValidity();

      } else if (this.kbAddressFormat.value && this.kbAddressFormat.value.code !== 'US') {
        this.languageCheck(this.translatedLang);
      }
    } else {
      disableResetControls([this.kbAddressFormat, this.address]);
    }

    if (this.appType === 'RC' && this.showKbQuestion === true) {
      this.getControl('data.kbChange').setValidators(Vs.required);
      this.getControl('data.kbChange').updateValueAndValidity();
    } else {
      this.getControl('data.kbChange').clearValidators();
      this.getControl('data.kbChange').updateValueAndValidity();
    }
    const kbSchoolSwitch = data.get('livingArrangement.kbSchoolSwitch');
    const reasonsForApplyingToKb = data.get('reasonsForApplyingToKb');
    this.kbAddressFormat.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((res) => {
      if (res && res.code === 'US') {
        this.getControl('data.livingArrangement.address.addrApoFpo').clearValidators();
        this.getControl('data.livingArrangement.address.addrApoFpo').updateValueAndValidity();
        this.getControl('data.livingArrangement.address.addrAaAeAp').clearValidators();
        this.getControl('data.livingArrangement.address.addrAaAeAp').updateValueAndValidity();

      } else if (res && res.code !== 'US') {
        // this.getControl('data.livingArrangement.address.city').clearValidators();
        // this.getControl('data.livingArrangement.address.city').updateValueAndValidity();
        this.getControl('data.livingArrangement.address.state').clearValidators();
        this.getControl('data.livingArrangement.address.state').updateValueAndValidity();
        this.getControl('data.livingArrangement.address.county').clearValidators();
        this.getControl('data.livingArrangement.address.county').updateValueAndValidity();
        this.getControl('data.livingArrangement.address.addrApoFpo').setValidators(Vs.required);
        this.getControl('data.livingArrangement.address.addrApoFpo').updateValueAndValidity();
        this.getControl('data.livingArrangement.address.addrAaAeAp').setValidators(Vs.required);
        this.getControl('data.livingArrangement.address.addrAaAeAp').updateValueAndValidity();
        this.languageCheck(this.translatedLang);
      }
    })
    const livingAddressState = this.getControl('data.livingArrangement.address.state');
    if (livingAddressState?.value) {
      const tableVar = this.tables.STATE.filter((item) => item.code === livingAddressState.value.code);
      tableVar[0].value = livingAddressState.value.value;
      livingAddressState.patchValue(tableVar[0]);
    }

    const livingAddressCounty = this.getControl('data.livingArrangement.address.county');
    if (livingAddressCounty?.value) {
      const tableVar = this.tables.COUNTY.filter((item) => item.code === livingAddressCounty.value.code);
      tableVar[0].value = livingAddressCounty.value.value;
      livingAddressCounty.patchValue(tableVar[0]);
    }

    const addrApoFpo = this.getControl('data.livingArrangement.address.addrApoFpo');
    if (addrApoFpo?.value) {
      const tableVar = this.tables.MILITARYPOCODES.filter((item) => item.code === addrApoFpo.value.code);
      tableVar[0].value = addrApoFpo.value.value;
      addrApoFpo.patchValue(tableVar[0]);
    };

    const addrAaAeAp = this.getControl('data.livingArrangement.address.addrAaAeAp');
    if (addrAaAeAp?.value) {
      const tableVar = this.tables.STATEMILITARY.filter((item) => item.code === addrAaAeAp.value.code);
      tableVar[0].value = addrAaAeAp.value.value;
      addrAaAeAp.patchValue(tableVar[0]);
    };

    livingAddressState.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((res) => {
      if (res && res.code !== 'TN') {
        this.getControl('data.livingArrangement.address.county').clearValidators();
        this.getControl('data.livingArrangement.address.county').updateValueAndValidity();
      }
    })

    const houseMember = data.get('houseMember');
    this.houseMember = houseMember;
    const livingArrangmentHelper = this.getControl('extras.helpers.pastYearLivingArrangement');
    const reasonshelper = this.getControl('extras.helpers.reasonsForApplyingToKb');
    const pastYearLivingArrangement = this.getControl('data.livingArrangement.pastYearLivingArrangement');
    const selectedLiving = data.get('livingArrangement.pastYearLivingArrangement');
    this.registerToggle(livingArrangmentHelper, setValid(selectedLiving));
    this.registerToggle(reasonshelper, setValid(reasonsForApplyingToKb));

    if (this.selectedIndividual) {
      const currentEnrollment  = this.selectedIndividual?.studentStatus;
      if (currentEnrollment?.code === 'NA') {
        this.isHideSchoolSw = true;
      }
    };

    this.isOtherPersonInformationEnabled = (kbContactIndicator.value === 'OTH');
    this.registerToggle(kbContactInterpreterSwitch, swapControls([kbContactLanguage], isYes));
    this.registerToggle(houseMember, () => {
      this.houseMember.updateValueAndValidity();
      this.selectedChild = filter(n => n.identifier == houseMember.value, this.individuals)[0];
      this.getControl('identifier.individualId')?.patchValue(this.selectedChild?.identifier);
      if (this.selectedChild) {
        const currentEnrollment  = this.selectedChild?.studentStatus;
        if (equals(currentEnrollment?.code, 'NA')) {
          this.isHideSchoolSw = true;
          kbSchoolSwitch.enable();
          kbSchoolSwitch.patchValue({ code: 'N', value: 'No', spValue: "No" });
        } else {
          this.isHideSchoolSw = false;
          kbSchoolSwitch.enable();
        }
      }
    });

    this.primaryIndividual = filter(n => isNil(n.applicantRelationship), this.individuals)[0];
    if (this.primaryIndividual?.physicalAddress[0]?.contactPhones?.home) {
      const phoneNumber = this.primaryIndividual?.physicalAddress[0]?.contactPhones?.home;
      const firstHalf = phoneNumber.substring(0, 3);
      const secondHalf = phoneNumber.substring(3, 6);
      const thirdHalf = phoneNumber.substring(6, 10);
      this.physicalHomeNumber = "(" + firstHalf + ")" + " " + secondHalf + "-" + thirdHalf;
    }

    if (this.primaryIndividual?.physicalAddress[0].address?.zip && this.primaryIndividual?.physicalAddress[0].address?.zip.length > 5) {
      this.hohZip = maskZip(this.primaryIndividual?.physicalAddress[0].address?.zip);
    } else {
      this.hohZip = this.primaryIndividual?.physicalAddress[0].address?.zip;
    }

    if (this.primaryIndividual?.assistingPerson?.contactPhones?.home) {
      const phoneNumber = this.primaryIndividual?.assistingPerson?.contactPhones?.home;
      const firstHalf = phoneNumber.substring(0, 3);
      const secondHalf = phoneNumber.substring(3, 6);
      const thirdHalf = phoneNumber.substring(6, 10);
      this.assistingHomeNumber = "(" + firstHalf + ")" + " " + secondHalf + "-" + thirdHalf;
    }

    if (this.primaryIndividual?.assistingPerson?.address?.zip && this.primaryIndividual?.assistingPerson?.address?.zip.length > 5) {
      this.assisZip = maskZip(this.primaryIndividual?.assistingPerson?.address?.zip);
    } else {
      this.assisZip = this.primaryIndividual?.assistingPerson?.address?.zip;
    }

    if (reasonsForApplyingToKb.value) {
      const continueOption = filter(n => n.code == 'Q', reasonsForApplyingToKb.value)
      if (continueOption.length == 1) {
        this.onReasonSelected();
      }
    }
    this.isReasonSelected = !this.isRmbRmc ? reasonsForApplyingToKb.value.length ? true : false : true;
    this.isPastYearLivArrangementSelected = !this.isRmbRmc ? pastYearLivingArrangement.value.length ? true : false : true;
    const reasonSelectedvalues = this.tables.MPAPLKBRSN.filter(item => item.code === this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][item.code].value?.code);
    reasonSelectedvalues.forEach(value => this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][value.code].value = value);
    const pastLivingArrValues = this.tables.MPKBLIVING.filter(item => item.code === this.getControl('extras.helpers.pastYearLivingArrangement')['controls'][item.code].value?.code);
    pastLivingArrValues.forEach(value => this.getControl('extras.helpers.pastYearLivingArrangement')['controls'][value.code].value = value);

    if (kbContactIndicator.value) {
      this.clearValidators();
      kbContactIndicator.value === 'OTH' ? (this.otherSelected = true, disableReset(kbOtherPhysicalAddressSwitch)) : kbContactIndicator.value === 'ATH' ? this.assistantSelected = true : this.headSelected = true;
    }

    if ((kbOtherPhysicalAddressSwitch['value'] && kbOtherPhysicalAddressSwitch['value']['code'] == 'N') && kbContactIndicator.value === 'HOH')  {
      this.disableControls(this.address);
      this.kbAddressFormat.disable();
    }
    if (kbContactInterpreterSwitch['value'] && kbContactInterpreterSwitch['value']['code'] == 'N') {
      kbContactLanguage.disable();
    }

    const selectedReason = this.getControl('data.reasonsForApplyingToKb')?.value;
    if(selectedReason !=undefined && selectedReason != null && selectedReason.length > 0){
      if (selectedReason.findIndex(s => s.code === 'Q') > -1) {
        var controls = Object.keys(this.getControl('extras.helpers.reasonsForApplyingToKb')['controls']);
        for (let index = 0; index < controls.length; index++) {
          if (controls[index] != 'Q')
            this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].disable();
        }
      }
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  clearValidators() {
    const hiddenField = this.getControl('data.hiddenField');
    hiddenField.clearValidators();
    hiddenField.updateValueAndValidity();
  };

  setUsAddress = () => {
    const { addressLine1, addressLine2, city, zip } = this.primaryIndividual?.assistingPerson?.address;
    this.address.controls.addressLine1.setValue(addressLine1);
    this.address.controls.addressLine2.setValue(addressLine2);
    this.address.controls.city.setValue(city);
    this.address.controls.zip.setValue(zip);
    this.address.controls.state.patchValue({ code: "TN", description: "Tennessee", spDescription: "Tennessee", value: "Tennessee"});
  };

  setMiAddress = () => {
    const { addressLine1, addrApoFpo, addrAaAeAp, zip } = this.primaryIndividual?.assistingPerson?.address;

    this.address.controls.addressLine1.setValue(addressLine1);
    if (addrApoFpo.code === 'APO') {
      this.address.controls.addrApoFpo.patchValue({code: "APO", description: "APO - Air/Army Post Office", spDescription: "APO - Air/Army Post Office", value: "APO - Air/Army Post Office"});
    } else {
      this.address.controls.addrApoFpo.patchValue({code: "FPO", description: "FPO - Fleet Post Office", spDescription: "FPO - Fleet Post Office", value: "FPO - Fleet Post Office"});
    }
    if (addrAaAeAp.code === 'AA') {
      this.address.controls.addrAaAeAp.patchValue({code: "AA", description: "AA - Armed Forces America", spDescription: "AA - Armed Forces America", value: "AA - Armed Forces America"});
    } else if (addrAaAeAp.code === 'AE') {
      this.address.controls.addrAaAeAp.patchValue({code: "AE", description: "AE - Armed Forces Africa, Canada, Europe, Middle East", spDescription: "AE - Armed Forces Africa, Canada, Europe, Middle East", value: "AE - Armed Forces Africa, Canada, Europe, Middle East"});
    } else {
      this.address.controls.addrAaAeAp.patchValue({code: "AP", description: "AP - Armed Forces Pacific", spDescription: "AP - Armed Forces Pacific", value: "AP - Armed Forces Pacific"});
    }

    this.address.controls.zip.setValue(zip);
  };

  addressSwitch(formCtrl?) {
    const data = this.getControl('data');
    const kbContactIndicator = data.get('contactInformation.kbContactIndicator');
    const kbAddressFormat = data.get('livingArrangement.kbAddressFormat');
    if (formCtrl?.value?.code === 'Y') {
      enableResetControls([this.address, this.kbAddressFormat]);
    } else if (kbContactIndicator?.value === 'ATH') {
      if (formCtrl?.value.code === 'N') {
        this.kbAddressFormat.enable();
        if (this.primaryIndividual?.assistingPerson.addressFormat.code === "US") {
          kbAddressFormat.reset();
          enableResetControls([this.address]);
          kbAddressFormat.setValue({code: "US", description: "US", spDescription: "EEUU", value: "US"});
          this.setUsAddress();
        } else {
          enableResetControls([this.address]);
          kbAddressFormat.patchValue({code: "MI", description: "Military", spDescription: "Militario", value: "Military"});
          this.setMiAddress();
        }
      } else {
        kbAddressFormat.reset();
      }
    } else if ((kbContactIndicator.value === 'HOH' || kbContactIndicator.value === 'OTH') && formCtrl.value.code === 'N') {
      disableResetControls([this.kbAddressFormat, this.address]);
    }else if (formCtrl.value.code === 'N') {
      disableResetControls([this.kbAddressFormat, this.address]);
    }
  }

  ngAfterContentChecked() {
    const houseMember = this.getControl('data.houseMember');
    if(this.exported){
      this.individuals18Under = filter(n => (n.age < 18), this.individuals);
    }else{
      this.individuals18Under = filter(n => (n.age < 18 && n.applyForCoverage?.code === 'Y'), this.individuals);
    }
    this.selectedChild = filter(n => n.identifier == houseMember.value, this.individuals)[0];
  }

  removeValuesFromDropdown(itemCode) {
    const modifiedTable = []
    for (let i = 0; this.tables.MPKBCHNG.length > i; i++) {
      if (this.tables.MPKBCHNG[i].code !== itemCode) {
        modifiedTable.push(this.tables.MPKBCHNG[i]);
      }
    }
    this.tables.MPKBCHNG = modifiedTable;
    return this.tables.MPKBCHNG;
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  contactAddressDetails(par: string): void {
    this.clearValidators();
    const data = this.getControl('data');
    const kbOtherPhysicalAddressSwitch = data.get('livingArrangement.kbOtherPhysicalAddressSwitch');
    const kbContactIndicator = data.get('contactInformation.kbContactIndicator');
    const contactInformation = data.get('contactInformation');
    kbContactIndicator.patchValue(par);
    this.resetSelectedflags();
    if (par === 'OTH') {
      this.isOtherPersonInformationEnabled = true;
      this.enableControls(contactInformation);
      this.otherSelected = true;
      // kbOtherPhysicalAddressSwitch.enable();
      disableReset(kbOtherPhysicalAddressSwitch);
    } else {
      this.isOtherPersonInformationEnabled = false;
      this.disableControls(contactInformation);
      this.getControl('data.contactInformation.kbContactIndicator').enable();
      kbOtherPhysicalAddressSwitch.enable();
      (par === 'HOH') ? (this.headSelected = true) : (this.assistantSelected = true);
    }

    if (par === 'ATH') {
      resetEnableControls([this.kbAddressFormat, this.address]);
      kbOtherPhysicalAddressSwitch.reset();
    } else if (par === 'OTH') {
      resetEnableControls([this.kbAddressFormat, this.address]);
      enableResetControls([this.address]);
    } else if (par === 'HOH' && isNil(kbContactIndicator)) {
      disableResetControls([this.kbAddressFormat, this.address]);
    } else if (par === 'HOH' && !isNil(kbOtherPhysicalAddressSwitch)) {
      kbOtherPhysicalAddressSwitch.reset();
      disableResetControls([this.kbAddressFormat, this.address]);
    } else {
      disableResetControls([this.kbAddressFormat, this.address]);
    }
  }

  onReasonSelected() {
    const livingArrangement = this.getControl('data.livingArrangement');
    const contactInformation = this.getControl('data.contactInformation');
    const kbContactRelationship = this.getControl('data.kbContactRelationship');
    const kbContactInterpreterSwitch = this.getControl('data.kbContactInterpreterSwitch');
    const hiddenField = this.getControl('data.hiddenField');
    const reasonsForApplyingToKb = this.getControl('data.reasonsForApplyingToKb');
    const isOrContinueSelected = filter(item => item.code == 'Q', reasonsForApplyingToKb.value);
    const kbSchoolSwitch = this.getControl('data.livingArrangement.kbSchoolSwitch');
    const kbContactEmail = this.getControl('data.contactInformation.kbContactEmail');
    const kbAddressFormat = this.getControl('data.livingArrangement.kbAddressFormat');
    const address = this.getControl('data.livingArrangement.address');
    const ktControls = [kbContactRelationship, kbContactInterpreterSwitch, livingArrangement, contactInformation];

    if (isOrContinueSelected.length > 0) {
      disableControls(ktControls);
      this.houseMember.updateValueAndValidity();
      hiddenField.clearValidators();
      hiddenField.updateValueAndValidity();
      this.isDonotWantToContinueSelected = true;
    } else {
      this.isDonotWantToContinueSelected = false;
      enableControls(ktControls);
      kbContactEmail?.disable();
      if(this.selectedChild.studentStatus.currentEnrollment === "NA"){
        kbSchoolSwitch?.disable();
      }
      if(this.getControl('data.livingArrangement.kbOtherPhysicalAddressSwitch')?.value.code != 'Y'){
        kbAddressFormat?.disable();
        address?.disable();
      }
    };

  }

  onReasonSelect(event: any): void {
    const MPAPLKBRSNcodes = ['reasonsC', 'reasonsI', 'reasonsB', 'reasonsQ', 'reasonsN'];
    const reasonsForApplyingToKb = this.getControl('data.reasonsForApplyingToKb');
    var controls = Object.keys(this.getControl('extras.helpers.reasonsForApplyingToKb')['controls']);
    let selectedIndex = reasonsForApplyingToKb.value.findIndex(s => s.code === 'Q');
    let selectedNone = reasonsForApplyingToKb.value.findIndex(s => s.code === 'N');
    if (selectedIndex > -1) {
      for (let index = 0; index < controls.length; index++) {
        if (controls[index] != 'Q'){
          this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].setValue(null);
          this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].disable();
        }
      }
    } else if(selectedNone > -1){
      for (let index = 0; index < controls.length; index++) {
        if (controls[index] != 'N'){
          this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].setValue(null);
          this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].disable();
        }
      }
    }
    else {
      for (let index = 0; index < controls.length; index++) {
        if (controls[index] != 'Q') {
          this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].enable();
        } else if (controls[index] != 'N'){
          this.getControl('extras.helpers.reasonsForApplyingToKb')['controls'][controls[index]].enable();
        }
      }
    }
    const isOrContinueSelected = filter(item => item.code == 'Q', reasonsForApplyingToKb.value);
    const isNonSelected = filter(n => n.code == 'N', reasonsForApplyingToKb.value);
    const livingArrangement = this.getControl('data.livingArrangement');
    const contactInformation = this.getControl('data.contactInformation');
    const kbContactRelationship = this.getControl('data.kbContactRelationship');
    const kbContactInterpreterSwitch = this.getControl('data.kbContactInterpreterSwitch');
    const kbContactEmail = this.getControl('data.contactInformation.kbContactEmail');
    const hiddenField = this.getControl('data.hiddenField');
    const kbAddressFormat = this.getControl('data.livingArrangement.kbAddressFormat');
    const address = this.getControl('data.livingArrangement.address');
    const kbSchoolSwitch = this.getControl('data.livingArrangement.kbSchoolSwitch');
    const data = this.getControl('data');
    const kbContactIndicator = data.get('contactInformation.kbContactIndicator');
    this.isReasonSelected = reasonsForApplyingToKb.value.length === 0 ? false : true;
    const ktControls = [kbContactRelationship, kbContactInterpreterSwitch, livingArrangement, contactInformation];

    if (isOrContinueSelected.length > 0) {
      disableControls(ktControls);
      this.houseMember.updateValueAndValidity();
      hiddenField.clearValidators();
      hiddenField.updateValueAndValidity();
      this.isDonotWantToContinueSelected = true;
    } else {
      this.isDonotWantToContinueSelected = false;
      enableControls(ktControls);
      if(kbContactIndicator?.value != 'OTH'){
        kbContactEmail?.disable();
      }
      if(this.selectedChild?.studentStatus?.currentEnrollment === "NA"){
        kbSchoolSwitch?.disable();
      }
      if(this.getControl('data.livingArrangement.kbOtherPhysicalAddressSwitch')?.value?.code != 'Y'){
        kbAddressFormat?.disable();
        address?.disable();
      }
      kbContactIndicator?.enable();
      kbContactIndicator?.setValidators(Vs.required);
    };

    if (event.target.id == 'reasonsN' && event.target.checked == true) {
      reasonsForApplyingToKb.patchValue(isNonSelected);
      MPAPLKBRSNcodes.forEach(id => {
        if (id !== 'reasonsN')
          (document.getElementById(id) as HTMLInputElement).checked = false
      });
    } else if ((document.getElementById("reasonsN") as HTMLInputElement).checked && document.getElementById("reasonsN").id !== event.target.id) {
      (document.getElementById("reasonsN") as HTMLInputElement).checked = false;
    }

    if (event.target.id == 'reasonsQ' && event.target.checked == true) {
      reasonsForApplyingToKb.patchValue(isOrContinueSelected);
      MPAPLKBRSNcodes.forEach(id => {
        if (id !== 'reasonsQ')
          (document.getElementById(id) as HTMLInputElement).checked = false
      });
    } else {
      if ((document.getElementById("reasonsQ") as HTMLInputElement).checked && document.getElementById("reasonsQ").id !== event.target.id) {
        (document.getElementById("reasonsQ") as HTMLInputElement).checked = false;
      }
    }

    // MPAPLKBRSNcodes.forEach(id => {
    //   const val = (filter(n => n?.code == id.substring(7), reasonsForApplyingToKb.value))[0];
    //   const index = reasonsForApplyingToKb.value.findIndex(value => value == val);
    //   if ((document.getElementById(id) as HTMLInputElement).checked == false && index >= 0) {
    //     reasonsForApplyingToKb.value.splice(index, 1);
    //   }
    // });
  }

  onPastYearLivArrangementSelect(event: any): void {
    const MPKBLIVINGCodes = ['livingL', 'livingM', 'livingP', 'livingGI', 'livingGP', 'livingSS', 'livingN'];
    const pastYearLivingArrangement = this.getControl('data.livingArrangement.pastYearLivingArrangement');
    const isNonSelected = filter(n => n.code === 'N', pastYearLivingArrangement.value);
    var controls = Object.keys(this.getControl('extras.helpers.pastYearLivingArrangement')['controls']);
    let selectedNone = pastYearLivingArrangement.value.findIndex(s => s.code === 'N');
    if(pastYearLivingArrangement.value.length){
      this.isPastYearLivArrangementSelected = true;
    } else {
      this.isPastYearLivArrangementSelected = false;
    }
    if(selectedNone > -1){
      for (let index = 0; index < controls.length; index++) {
        if (controls[index] != 'N'){
          this.getControl('extras.helpers.pastYearLivingArrangement')['controls'][controls[index]].setValue(null);
          this.getControl('extras.helpers.pastYearLivingArrangement')['controls'][controls[index]].disable();
        }
      }
    } 
    else {
      for (let index = 0; index < controls.length; index++) {
        if (controls[index] != 'N'){
          this.getControl('extras.helpers.pastYearLivingArrangement')['controls'][controls[index]].enable();
        }
      }
    }
    if (event.target.id == 'livingN' && event.target.checked == true) {
      pastYearLivingArrangement.patchValue(isNonSelected);
      this.isPastYearLivArrangementSelected = true;
      MPKBLIVINGCodes.forEach(id => {
        if (id !== 'livingN')
          (document.getElementById(id) as HTMLInputElement).checked = false
      });
    } else if ((document.getElementById("livingN") as HTMLInputElement).checked && document.getElementById("livingN").id !== event.target.id) {
      (document.getElementById("livingN") as HTMLInputElement).checked = false;
    }
  }

  onPreLivingSelect(event: any): void {
    const pastYearLivingArrangement = this.getControl('data.livingArrangement.pastYearLivingArrangement');
    const nonSelection = filter(n => n.code === 'N', pastYearLivingArrangement.value);
    if (event.code === 'N') {
      pastYearLivingArrangement.patchValue([event]);
    } else if (nonSelection.length >= 1) {
      pastYearLivingArrangement.patchValue([event]);
    }
  }

  disableControls(formGroup: any): void {
    Object.keys(formGroup['controls']).forEach(key => {
      formGroup['controls'][key].disable();
    });
  }

  enableControls(formGroup: any): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup['controls'][key].enable();
    });
  }

  resetSelectedflags() {
    this.otherSelected = false;
    this.headSelected = false;
    this.assistantSelected = false;
  }

}
