import { ProcessService } from './../services/process.service';
import { Inject, Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { identity, contains, isNil, isEmpty, gte, head, flatten, concat, prop, and, or, equals, append} from 'ramda';

import { isNotNil, isNonEmptyArray } from 'ramda-adjunct';

import { StateService } from '@app/services/state/state.service';
import { StorageService } from '@app/services/storage/storage.service';
import { ContainerDirective } from '@app/directives/container/container.directive';
import { NotificationsService, MessagesToken, ErrorsToken } from '@app/services/notification/notification.service';

import { isSkipNo } from '@app/helpers/top-level.helpers';
import { activate } from '@app/helpers/observable.helpers';
import { assign, trudy } from '@app/helpers/function.helpers';
import { isYES, isComplete } from '@app/helpers/types.helpers';
import { findNotifications } from '@app/helpers/notification.helpers';
import { eligibleSections, emptyFormStatus, emptyRecord, excludeComments, excludeSections, hideSaveExit } from '@app/helpers/models.helpers';
import { isCreate, formIdentifier, formData, recordIndividual } from '@app/helpers/record.helpers';

import { Tables } from '@app/models/tables.model';
import { Notifications } from '@app/models/partials.model';
import { getDataValue } from '@app/helpers/object.helpers';
import { homeCareChildren } from '@app/helpers/programs.helpers';
import { Individuals, Individual } from '@app/models/individual.model';
import { Record, Block, Identifier, Records, BlockId } from '@app/models/record.model';
import { ApplicationNumber, Programs, Mode, ModuleId, SectionStatuses } from '@app/models/app.model';
import { Columns, FormValidation, PostObject, IsPartial, IndividualsFilter } from '@app/models/section.model';
import { FormStatus, TopLevelAnswer, SectionId, BaseFormComponent, ContinueLabels, ExitLabels, CancelLabels } from '@app/models/section.model';
import { isChangeReport, isExported, isRedeMode, isRmbRmc } from '@app/helpers/mode.helpers';
import { codeEqual } from '@app/helpers/code.helpers';
import { ModalService } from '@app/services/modal/modal.service';
import { ApiService } from '@app/gateway/services/api.service';
import { CreateAccountService } from '@app/gateway/services/createAccount.service';
import { isNo } from '@app/helpers/tayn.helpers';
import { isPresumptiveApp } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
import { isApplyForBenefits } from '@app/helpers/mode.helpers';
import { toISO } from '@app/helpers/date.helpers';
import { HttpHeaders } from '@angular/common/http';

@Component({
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent implements OnInit, CanDeactivate<SectionComponent> ,OnDestroy{
  @ViewChild(ContainerDirective, { static: true })
  container: ContainerDirective;

  sectionLinks$ = this.state.sectionLinks$;
  blockLinks$ = this.state.blockLinks$;

  notifications: Observable<Notifications>;
  form: FormStatus = emptyFormStatus();
  inSummary = true;
  isCreate = false;
  isPartial: IsPartial = 0;
  isExit = false;
  isAFB = false;

  applicationNumber: ApplicationNumber;
  applyingForHcbc: boolean;
  applicationType: string;
  applyingForNursingFacilities: boolean;
  individuals: Individuals = [];
  comments = null;
  mode: Mode;
  programs: Programs = [];
  tables: Tables = {};
  // @ts-ignore
  private originalFormData: any;

  sectionId: SectionId;
  topLevelAnswer: TopLevelAnswer;
  records: Records = [];
  earnedIncome: boolean;
  showInternet: boolean;
  showInternetInclude: boolean;
  showNewActivities: boolean;

  isNextdisableSessionVal = false;
  isBtnEnable = false;
  enabledNext = true;

  columns: Columns = [];
  labelData: any;
  component: BaseFormComponent;
  formValidation: FormValidation = identity;
  postObject: PostObject = identity;
  individualsFilter: IndividualsFilter;
  hasSummary = false;

  title: string;
  moduleId: ModuleId;
  status: SectionStatuses;
  previousSection: SectionId;

  activePerson: Record;
  blockId: BlockId;

  hasRecords = false;
  showLeave = true;
  showSubmit = false;
  showComments = true;
  showSaveAndExit = false;
  labelContinue = ContinueLabels.NEXT;
  labelExit = ExitLabels.EXIT;
  labelCancel = CancelLabels.CANCEL;
  tooltipAdd;
  buttonAdd;
  validateSubmit;
  hasUsFormat = false;
  filteredIndividuals;
  peopleSelection;
  saveAndExitClicked: boolean;
  isRenewMyBenefit: boolean;
  saveAndExitFirstTimeClicked: boolean;
  backToPreviousPage;
  isPresumptiveApp: boolean;
  isAppTypeRB: boolean;
  isChangeMode = false;
  isRedeModeOnly = false;
  appKeyWord;
  isFinReassessment = false;
  isPreterm = false;
  isSummaryTitle = false;
  addAssistingPerson = false;
  addKbMedicalBill = true;
  recordExists = false;
  isNBN: boolean = (sessionStorage.getItem('appType') === 'NBN');
  summaryArray = ['ICEMI', 'ICSEM', 'ICOTH', 'XPUTL', 'XPDEP', 'XPSUP', 'INMED', 'ASLIQ', 'ASVEH', 'ASPRP', 'ASBUR', 'ASLIF', 'OTHRS', 'ASCLD', 'HHHLC']
  isKepro: any; 
  allIndividuals;
  appealSummaryAddrep = false;
  imeDetailsSummaryStatic = false;
  isAppeal;
  isIME = sessionStorage.getItem('appType') === 'IME';
  hideAddRepresentative = false;
  user = sessionStorage.getItem('userType');
  appType = sessionStorage.getItem('appType');
  hidecontent: boolean= false;
  noDuplicateTaxSub: Subscription;
  noDuplicateJFSub: Subscription;
  noDuplicateTaxJFPresmSub: Subscription;
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
  hideBackToPrev;

  
  imeDetails = false;
  apiContext = `easy-app/api`;
  constructor(
    protected state: StateService,
    public router: Router,
    public storageService: StorageService,
    public processService: ProcessService,
    private modal: ModalService,
    public apiService: ApiService,
    private titleService: Title,
    private createaAccountService: CreateAccountService,
    public cd: ChangeDetectorRef,
    @Inject(MessagesToken) public messages: NotificationsService,
    @Inject(ErrorsToken) public errors: NotificationsService
  ) {
    this.notifications = this.messages.list;
    this.state.updates(u => this.update(u));
    this.noDuplicateTaxSub = this.processService.isNoDuplicateTaxOutsideInsideValidate.subscribe((result) => {
      const taxDependentData = result[1]['data'];
      const hohindvId = result[1]['identifier']['individualId'];
      if(result[0] && this.processService.noDuplicateTaxInOutAfterModal && this.blockId === "taxdependoutside" && this.form.value?.identifier?.individualId == hohindvId){
        this.form.value.data = taxDependentData;
        this.onSave();
      }
    });

    this.noDuplicateJFSub = this.processService.isNoDuplicateJFOutsideInsideValidate.subscribe((data) => {
      if(data[0] && this.processService.noDuplicateJFInOutAfterModal && this.blockId === "jointfileroutside"){
        this.form.value.data = data[1];
        this.onSave();
      }
    });

    this.noDuplicateTaxJFPresmSub = this.processService.isNoDuplicateTaxDepJFPresmInOutValidate.subscribe((data) => {
      if(data[0] && this.processService.noDuplicateTaxDepJFInOutAfterModal && this.sectionId === "PRRLS" && this.blockId !== 'demographics' && this.blockId !== "additional" && this.blockId !== "immigration"){
        this.form.value.data = data[1];
        this.form.value.identifier.sectionId = 'PRRLS';
        this.onSave();
      }
    });
  }

  ////////////////////////////////////// LIFECYCLE //////////////////////////////////////

  ngOnInit() {
        if (this.inSummary) {
      this.titleService.setTitle('Section Summary page');
    };
    sessionStorage.removeItem("isnext");
    const bankName = sessionStorage.bankName;
    
    if (bankName != undefined && bankName != '' && bankName != null) {
      if (this.records.length > 0 && this.records[0]?.data?.accountInformation)
        this.records[0].data.accountInformation.bankSearch.bankName = bankName;
    }

    this.backToPreviousPage = this.sectionId === 'SURCH' ? 'BACK_TO_DASHBOARD' : 'BACK_TO_PREVIOUS_PAGE';
    this.isChangeMode = isChangeReport(this.mode);
    this.isRedeModeOnly = isRedeMode(this.mode);
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
    this.isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    this.isAppeal = sessionStorage.getItem('appType') === 'APL';
    this.isIME = sessionStorage.getItem('appType') === 'IME';
    this.imeDetails = sessionStorage.getItem('appType') === 'IME' && this.sectionId === 'IMEDT'
    this.appKeyWord = this.isChangeMode ? 'CHANGE' : (this.isRedeModeOnly && !this.isFinReassessment) ? 'RENEWAL' : (this.isFinReassessment && this.isRedeModeOnly) ? 'reassessment' : this.isAppeal ? 'Appeal' : 'APPLICATION';
    if (sessionStorage.getItem('userType') === "RP" && this.sectionId === 'PRHAS') {
      this.backToPreviousPage = 'BACK_TO_WELCOME_PAGE';
    }
    if (sessionStorage.getItem('appType') === "APL" && this.sectionId === 'APREQ') {
      this.backToPreviousPage = 'BACK_TO_APPEAL_DASHBOARD';
    }

    if (sessionStorage.getItem('appType') === "APL" && this.sectionId === 'APREQ' && this.isKepro) {
      this.backToPreviousPage = null;
      this.hidecontent = true;
    }

    if (equals(1, this.programs.length) && homeCareChildren(this.programs)) {
      this.messages.addMultiple(findNotifications(this.moduleId, this.programs));
    }
    this.showLeave = isNotNil(this.previousSection);
    if (this.sectionId === 'ICSEM') {
      for (var i = 0; i < Array.length; i++) {
        const arrVals = this.records.filter(arr => arr.data.businessDescription !== null)
        if (arrVals.length === 0) {
        this.columns = this.columns.filter(val => val.name !== 'Business Description')
        }
      }
    } else if (this.sectionId === "ASBUR") {
      const index = this.columns.findIndex(i => i.prop == "data.bankSearch.bankName");
      if (index == -1)
        this.columns.push({ name: "Bank or Company Name", prop: "data.bankSearch.bankName" })
    }

    if (this.sectionId === 'ASLIQ') {
      for (var i = 0; i < Array.length; i++) {
        const arrVals = this.records.filter(arr => arr.data.accountInformation ? arr.data.accountInformation.bankSearch.bankName !== null : null)
        if (arrVals.length === 0) {
          this.columns = this.columns.filter(val => val.name !== 'Bank or <br/> Company Name')
        }
      }
    }

    if (this.sectionId === 'INMED') {
      this.isAFB = isApplyForBenefits(this.applicationType);
      this.records.map(s => {
        let index = this.columns.findIndex(i => i.prop == 'data.medicalBillOweAmt');
        let ind = this.columns.findIndex(i => i.prop == 'data.medicalBillUnpaidAmt');
        if (s.data.medicalBillOweAmt == null) {
          s.data.medicalBillOweAmt = 'N/A';          
          if (index != -1)
            delete this.columns[index].pipe;         
        } else
          this.columns[index].pipe = 'currency';
        if ( this.isAFB && s.data.medicalBillUnpaidAmt === null) {
          s.data.medicalBillUnpaidAmt = 'N/A';
          if (ind != -1)
            delete this.columns[ind].pipe;
        } else
          this.columns[ind].pipe = 'currency';
        if ( this.isAFB && s.data.kbMedicalBillsSw?.code === 'N') {
          this.addKbMedicalBill = false;
        }
      });
    }

    if (!this.showRecords()) {
      this.onCreate(head(this.records));
    }
    if (this.mode) {
      sessionStorage.setItem('mode', this.mode);
    }
    if (this.sectionId !== 'SACHS' && this.sectionId !== 'HACHS') {
      this.showSaveAndExit = !isChangeReport(this.mode);
    }
    if (this.applicationType === 'ADMIT_DISCHARGE' || contains(this.sectionId, hideSaveExit)) {
      this.showSaveAndExit = false;
    }

    this.state.sectionLinks$.subscribe(res => {
      const appType:any = sessionStorage.getItem('appType');
      if (res && res[0]?.title === 'Application Summary' && appType === 'RB' &&  this.isFinReassessment) {
        res[0].title = 'Reassessment Summary'
      } else if (res && res[0]?.title === 'Application Summary' && appType === 'RC' ) {
        res[0].title = 'Change Summary'
      } else if (res && res[0]?.title === 'Application Summary' && appType === 'RB'&& !this.isFinReassessment && !this.isPreterm ) {
        res[0].title = 'Renewal Summary'
      } else if (res && (res[0]?.title === 'Application Summary' || res[0]?.title === 'Renewal Summary') && appType === 'RB' && this.isPreterm ) {
        res[0].title = 'Questions for Review Summary'
      } else {
        return res ? res[0]?.title : null;
      }

      if (res && res[3]?.title === 'Sign Your Application' && (appType === 'RC' || (appType === 'CHANGE_REPORT' && this.isKepro))) {        
        res[3].title = 'Sign Your Change';
      } else if (res && res[3]?.title === 'Sign Your Application' && (appType === 'RB' || (appType === 'RENEW_BENEFITS' && this.isKepro)) && !this.isFinReassessment && !this.isPreterm) {
        res[3].title = 'Sign Your Renewal';
      } else if (res && res[3]?.title === 'Sign Your Application' && appType === 'RB' && this.isFinReassessment) {
        res[3].title = 'Sign & Submit';
      } else if (res && res[3]?.title === 'Sign Your Application' && appType === 'RB' && this.isPreterm) {
        res[3].title = 'Sign Your Questions for Review';
      }
    });

    if (this.summaryArray.includes(this.sectionId)) {
      this.isSummaryTitle = true;
    }
    if (this.sectionId === 'ARPDT' && this.records) {
      let authElig;
      let authELgSwitch;
      authElig = this.records?.[0]['data']?.eligibleRepresentative?.eligAuthSelected === true;
      authELgSwitch = this.records?.[0]['data']?.eligAuthResprentativeSw === "Y";
      this.appealSummaryAddrep = this.records?.length && this.records[0].data?.addReprentative?.code === 'N' && !authElig ? true : false;
      const appealRelationCodes = ['NF', 'PA', 'F', 'AR', 'A', 'ER']
      let appealRelationArray = this.records.filter(value => (appealRelationCodes.includes(value?.data?.appeallantRelationship?.code)));
      if(authELgSwitch) {
        if (appealRelationArray?.length >= 5 && authElig) {
          this.hideAddRepresentative = true;
        } else {
          this.hideAddRepresentative = false;
        }
      } else {
        if (appealRelationArray?.length >= 5) {
          this.hideAddRepresentative = true;
        } else {
          this.hideAddRepresentative = false;
        }
      }

    } else if(this.sectionId === 'IMEDT') {
      if(this.records.length === 0 && JSON.parse(sessionStorage.getItem('removedIMERecord'))) {
        this.imeDetailsSummaryStatic = true;
      } else {
        this.imeDetailsSummaryStatic = false;
      }
      
    }
    this.hideBackToPrev = (this.mobileUser && this.sectionId === 'HACHS') || (this.isKepro && (this.sectionId === 'HACHS' || this.sectionId === 'SACHS')) ? true : false
  }

  addAssisting() {
    if (this.sectionId === 'HHAPL' && this.records.length > 0) {
      this.onEdit(this.records[0])
    }
    if (this.sectionId === 'INMED' && this.isAFB) {
      this.addKbMedicalBill
    }
  }

  presumptiveCondition() {
    return this.isPresumptiveApp && this.sectionId === 'PRHAS';
  }

  continueDisable () {
    if(this.sectionId === 'IMEDT' && this.inSummary && this.records.length === 0) {
      sessionStorage.setItem('disableImeSubmit', 'true')
      return true
    } else {
      const bool = this.records.some(rec => rec.exportingStatus === 'EXPORTED');
      if(this.sectionId === 'IMEDT' && sessionStorage.getItem('disableImeSubmit')) {
        sessionStorage.removeItem('disableImeSubmit')
      }
      return isRedeMode(this.mode) ? bool : false;
    }
  }


  canDeactivate(): Observable<boolean> {
    const logout = JSON.parse(sessionStorage.getItem('isLogout'));
    const  isBCCEXT = sessionStorage.getItem('appType') === 'BXT';
    const  isIME = sessionStorage.getItem('appType') === 'IME';
    if (logout || this.saveAndExitClicked || contains(this.sectionId, excludeSections) || isBCCEXT || isIME) {
      return activate();
    } else {
      return this.canUnload() ? activate() : this.state.canDeactivate();
    }
  }

  ////////////////////////////////////// LIFECYCLE //////////////////////////////////////

  ////////////////////////////////////// FACADE //////////////////////////////////////

  createRecord() {
    if (this.sectionId == 'XPDEP') {
      let index = this.records.findIndex(s => {
        return (s.identifier.individualId == this.form.value.identifier.individualId &&
          ((s.data.dependantCareInformation.dependantIndivNumber !== '1' &&
            (s.data.dependantCareInformation.dependantIndivNumber == this.form.value.data.dependantCareInformation.dependantIndivNumber)) ||
            (s.data.dependantCareInformation.dependantIndivNumber === '1' && (s.data.dependantCareInformation.personOutOfHouseholdName == this.form.value.data.dependantCareInformation.personOutOfHouseholdName))))
      });
      if (index > -1) {
        this.recordExists = true;
        return null;
      }
    }

    if (this.sectionId === 'NBINF') {
      const formRecord = this.formRecord();
      formRecord.identifier?.recordId ? (formRecord.identifier.recordId = '') : null;
      this.state
      .createRecord(this.applicationNumber, formRecord, this.isPartial, this.topLevelAnswer)
      .subscribe(record => this.onSaved(record));
    }else if(this.sectionId === 'PRRLS') {
      
      let outsideHousehold = this.form.value?.data?.outsideHousehold[0]?.dependentBirthDate;
      if(this.form.value?.data?.outsideHousehold?.length === 1 && outsideHousehold === null){
        this.state
        .createRecord(this.applicationNumber, this.formRecord(), this.isPartial, this.topLevelAnswer)
        .subscribe(record => this.onSaved(record));
      }else if(this.processService.noDuplicateTaxDepJFInOutAfterModal){
        this.processService.isDuplicateTaxDepJFOutsideInside = false;
        this.processService.noDuplicateTaxDepJFInOutAfterModal = true;
        this.state
          .createRecord(this.applicationNumber, this.formRecord(), this.isPartial, this.topLevelAnswer)
          .subscribe(record => this.onSaved(record));
      }else{
        if (this.processService.isDuplicateTaxDepJFOutsideInside) {
          this.processService.isDuplicateTaxDepJFOutsideInside = false;
          this.state
            .createRecord(this.applicationNumber, this.formRecord(), this.isPartial, this.topLevelAnswer)
            .subscribe(record => this.onSaved(record));
        } else {
          this.processService.duplicateTaxDepJFPresmInOutValidate.next('fromSave');
        }
      }
    }else{
      const formRecord = this.formRecord();
      this.state
      .createRecord(this.applicationNumber, formRecord, this.isPartial, this.topLevelAnswer)
      .subscribe(record => this.onSaved(record));
    }

  }

  updateRecord(this) {
    
    const hhData = this.form.value.data;
    const physicalFormat = getDataValue('physicalAddress.addressFormat', hhData);
    const mailingFormat = getDataValue('mailingAddress.addressFormat', hhData);
    const assistingFormat = getDataValue('assistingPerson.addressFormat', hhData);
    const nursingFacilityFormat = getDataValue('nursingFacilityAddress.addressFormat', hhData);
    const dischargeFormat = getDataValue('dischargeAddress.addressFormat', hhData);
    const appealsFormat = getDataValue('appealAddress.addressFormat', hhData);
    const appealRepAddressFormat = getDataValue('representativeAddress.addressFormat', hhData);
    this.hasUsFormat = codeEqual('US', nursingFacilityFormat) || codeEqual('US', dischargeFormat) || codeEqual('US', physicalFormat) || codeEqual('US', mailingFormat) || codeEqual('US', assistingFormat) || codeEqual('US', appealsFormat) || codeEqual('US', appealRepAddressFormat);
    if (this.sectionId === 'ADNFI') {
      this.hasUsFormat = true;
    }
    const addressValidatteSections = ['ADNFI', 'HHAPL', 'PRAPL', 'APDEM', 'ARPDT']
    if (addressValidatteSections.includes(this.sectionId) && this.hasUsFormat) {
      if (this.processService.addressValidated) {
        const sectionIdForCreateRecords = ['ADNFI', 'APDEM', 'ARPDT']
        if (sectionIdForCreateRecords.includes(this.sectionId) && this.status === 'IN_PROGRESS') {
          this.createRecord();
        } else if (this.sectionId === 'ARPDT' && (sessionStorage.getItem("labelForAddAnother") == "false") ) {
          this.createRecord();
        } else {
        this.state
          .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
          .subscribe(record => this.onSaved(record));
      }
     } else {
        this.processService.processAddressValidate.next('fromSave');
      }
    } else if (this.sectionId === 'NBINF' || this.sectionId === 'NBIMM') {
      const recordsent = this.formRecord();
      if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') != "null" && sessionStorage.getItem('recordId') != '-1') {
        const resultRecord = this['records'].filter((item => (item.identifier.recordId == sessionStorage.getItem('recordId'))));
        recordsent.identifier.recordId = resultRecord[0].identifier.recordId;
      } else if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') == '-1') {
        const recordId = Math.max.apply(Math, this['records'].map(function(o) { return o.identifier.recordId; }))
        const previousRecord = this['records'].filter((item => (item.identifier.recordId == recordId)));
        recordsent.identifier.recordId = previousRecord[0].identifier.recordId;
      } else if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') == 'null') {
        const recordId = Math.max.apply(Math, this['records'].map(function(o) { return o.identifier.recordId; }))
        const previousRecord = this['records'].filter((item => (item.identifier.recordId == recordId)));
        recordsent.identifier.recordId = previousRecord[0].identifier.recordId;
      } 
      return this.state
      .updateRecord(this.applicationNumber, recordsent, this.isPartial)
      .subscribe(record => this.onSaved(record));

    } else if (this.sectionId === 'SURCH') {
      const newRecord = this.formRecord();
      const modules = [];
      Object.keys(newRecord.data).forEach(key => {
        // @ts-ignore
        modules.push(newRecord.data[key]);
      });

      if (this.isKepro) {
        const { appSource } = newRecord?.data;
        modules.pop();
        newRecord.data = { modules, appSource };
      } else {
        newRecord.data = { modules };
      };
      return this.state.updateRecord(this.applicationNumber, newRecord, this.isPartial).subscribe(record => {
        this.state.loadApp(this.applicationNumber).subscribe();
        this.onSaved(record);
      });
    } else if (this.sectionId === 'ARPDT') {
      const elgAuthSelected = this.form?.value?.data?.eligibleRepresentative?.eligAuthSelected === true;
      if (!this.records?.length || (this.records?.length && this.records[0]['data']['addReprentative'] === null) || (this.form?.value?.data?.addReprentative && sessionStorage.getItem("labelForAddAnother") == "false")) {
        if(elgAuthSelected || this.form?.value?.data?.addReprentative.code === 'Y') {
          this.hasSummary = true;
        } else {
          this.hasSummary = false;
        }
        
        this.createRecord();
      } else if(this.records?.length === 5 && !this.form?.value?.data?.addReprentative && sessionStorage.getItem("labelForAddAnother") == "false") {
        this.hasSummary = true;
        this.createRecord();
      } else {
        return this.state
          .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
          .subscribe(record => this.onSaved(record));
      }
    } else if(this.sectionId === 'APDEM' && !this.records[0].data?.appellantDemographics?.futureAddressSw) {
      this.createRecord();
    } else if(this.sectionId === 'HHTJF' && this.blockId === 'taxdependoutside') {

      if(this.form.value.data.hasTaxDependentOutside?.code === 'N'){
        this.processService.isDuplicateTaxOutsideInside = false;
        this.state
        .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
        .subscribe(record => this.onSaved(record));
      }else if(this.processService.noDuplicateTaxInOutAfterModal){
        this.onValidate(this.form);
        this.processService.isDuplicateTaxOutsideInside = false;
        this.processService.noDuplicateTaxInOutAfterModal = true;
        this.state
          .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
          .subscribe(record => this.onSaved(record));
      }else{
        if (this.processService.isDuplicateTaxOutsideInside) {
          this.processService.isDuplicateTaxOutsideInside = false;
          this.state
            .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
            .subscribe(record => this.onSaved(record));
        } else {
          this.processService.duplicateTaxOutsideInsideValidate.next('fromSave');
        }
      }

    }else if(this.sectionId === 'HHTJF' && this.blockId === 'jointfileroutside') {

      if(this.form.value.data.jointFilerOutside?.code === 'N' || this.form.value.data.birthDate === undefined || this.form.value.data.birthDate === null){
        this.processService.isDuplicateJFOutsideInside = false;
        this.state
        .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
        .subscribe(record => this.onSaved(record));
      }else if(this.processService.noDuplicateJFInOutAfterModal){
        this.onValidate(this.form);
        this.processService.isDuplicateJFOutsideInside = false;
        this.processService.noDuplicateJFInOutAfterModal = true;
        this.state
          .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
          .subscribe(record => this.onSaved(record));
      }else{
        if (this.processService.isDuplicateJFOutsideInside) {
          this.processService.isDuplicateJFOutsideInside = false;
          this.state
            .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
            .subscribe(record => this.onSaved(record));
        } else {
          this.processService.duplicateJFOutsideInsideValidate.next('fromSave');
        }
      }
    
    }else  {
      return this.state
        .updateRecord(this.applicationNumber, this.formRecord(), this.isPartial)
        .subscribe(record => this.onSaved(record));
    }
  }

  updateApp() {
    this.state.updateApp(this.applicationNumber).subscribe(() => this.update({ isPartial: 0 }));
  }

  nextSection() {
    return this.state.nextSection(this.applicationNumber).subscribe();
  }

  updateSection() {
    this.state.sectionUpdate(this.applicationNumber, this.sectionId, this.topLevelAnswer).subscribe(() => {
      if (this.isExit) this.state.exitForm(true);
    })
  }

  applicationUpdate() {
    this.state.applicationUpdate(this.applicationNumber, this.sectionId, this.topLevelAnswer).subscribe();
  }

  ////////////////////////////////////// FACADE //////////////////////////////////////

  ////////////////////////////////////// STATE UPDATE //////////////////////////////////////

  update(that) {
    return assign(this, that);
  }

  ////////////////////////////////////// STATE UPDATE //////////////////////////////////////

  ////////////////////////////////////// TEMPLATE ACTION HANDLERS //////////////////////////////////////

  // FORM CHANGE
  onValidate(form) {
    this.update({ form });
  }
  //

  // NAV TABSET
  onNavigate(tab) {
    this.navigate(tab.sectionId);
    this.createaAccountService.setIsSummary(this.inSummary && and(this.hasSummary, this.hasRecords));
  }
  //

  // SUMMARY TABLE
  onEdit(record: Record | Block) {
    this.inSummary = false;
    this.createaAccountService.setIsSummary(this.inSummary && and(this.hasSummary, this.hasRecords));
    this.isCreate = isCreate(record);
    this.createForm(record);
    sessionStorage.removeItem('labelForAddAnother'); 
  }

  onDelete(data) {
    const record = data.record.identifier.recordId
      ? this.records.find(r => r.identifier.recordId === data.record.identifier.recordId)
      : this.records.find(r => r.identifier.individualId === data.record.identifier.individualId);
    if (this.sectionId === 'HHAPL') {
      record.exportingStatus = data.record.exportingStatus;
    }

    if (contains(data.record.identifier.sectionId, eligibleSections) && isExported(record.exportingStatus)) {
      const indvName = this.sectionId === 'HHAPL' ? record.data.casehead.fullName : this.filterRemovedIndividualId(data);
      const pregnancy = data.isPregnant;
      const recordData = { indvName, record, pregnancy };
      data.record.identifier['isExported'] = isExported(getDataValue('exportingStatus', record));
      this.state.onDelete(this.applicationNumber, data.record.identifier, recordData).subscribe(resp => {
        if (resp) {
          this.continue();
        }
      });
    } else if (this.sectionId === 'HHAPL' && !isExported(record.exportingStatus)) {
      const indvName = this.sectionId === 'HHAPL' ? record.data.casehead.fullName : this.individuals.filter(indv => indv.identifier == data.record.identifier.individualId)[0].name.fullName;
      const recordData = { indvName, record};
      this.state.onDelete(this.applicationNumber, data.record.identifier, recordData, true).subscribe((resp) => { if (resp) this.continue()});
    } else if (this.sectionId === 'ARPDT') {
      const recordData = data?.record?.data;
      this.state.onAppealDelete(this.applicationNumber, data.record.identifier, recordData).subscribe(() => this.continue());
    } else if (this.sectionId === 'IMEDT') {
      const recordData = data?.record?.data;
      this.state.onIMEDelete(this.applicationNumber, data.record.identifier, recordData).subscribe(() => this.continue());
    } else {
      this.state.onDelete(this.applicationNumber, data.record.identifier, null).subscribe(() => this.continue());
    }
  }

  filterRemovedIndividualId(data){
    const recordIndvId = data?.record?.identifier?.individualId;
    if(recordIndvId !== '0'){
      return this.individuals.filter(indv => indv.identifier == data.record.identifier.individualId)[0].name.fullName;
    }else{
      const outOfHouseholdName = data.record?.nameAge;
      return outOfHouseholdName;
    }
  }

  onUndo (record) {
    this.state.onUndo(this.applicationNumber, record.identifier, record).subscribe((resp) => { if (resp) { this.continue()}});
  }
  //

  // CREATE BUTTON
  onCreate(record: Record | Block = this.emptyRecord()) {
    this.onEdit(record);
    sessionStorage.setItem('labelForAddAnother', "false"); 
  }
  //

  saveAndExit() {
    this.saveAndExitClicked = true;

    if (this.sectionId === 'HHAPL' || this.sectionId === 'PRAPL' || this.sectionId === 'PRRLS') {
      const hhData = this.form?.value?.data;
      const physicalFormat = getDataValue('physicalAddress.addressFormat', hhData);
      const mailingFormat = getDataValue('mailingAddress.addressFormat', hhData);
      const assistingFormat = getDataValue('assistingPerson.addressFormat', hhData);

      this.hasUsFormat = codeEqual('US', physicalFormat) || codeEqual('US', mailingFormat) || codeEqual('US', assistingFormat);

      if (this.hasUsFormat) {
        if (!this.processService.addressValidated) {
          this.processService.processAddressValidate.next('fromSaveExit');
          return;
        }
      }

      if (this.sectionId === 'PRRLS') {
        if (!this.processService.isDuplicateTaxDepJFOutsideInside) {
          this.processService.duplicateTaxDepJFPresmInOutValidate.next('fromSaveExit');
          return;
        }
      }
    }

    if (this.sectionId === 'HHTJF' && this.blockId === 'taxdependoutside') {
      if (!this.processService.isDuplicateTaxOutsideInside) {
        this.processService.duplicateTaxOutsideInsideValidate.next('fromSaveExit');
        return;
      }
    }

    if (this.sectionId === 'HHTJF' && this.blockId === 'jointfileroutside') {
      if (!this.processService.isDuplicateJFOutsideInside) {
        this.processService.duplicateJFOutsideInsideValidate.next('fromSaveExit');
        return;
      }
    }

    if (this.sectionId === 'HHIND'
         && !this.inSummary
         && this.activePerson
          && this.activePerson.data.blocks[0].status !== 'COMPLETED'
           && this.activePerson.data.headOfHousehold
            && this.form.value.data.ssnInformation?.ssn
             && !this.saveAndExitFirstTimeClicked) {
      this.saveAndExitFirstTimeClicked = true;
      const { firstName, lastName } = this.form.value.data.name;
      const fullName = firstName + " " + lastName;
      sessionStorage.setItem('hohName', fullName);
       /* Commenting this code to open Save and Exit modal on HHIND screen, when we come to the screen first time and enter SSN */
    //  this.onSave();
    //   return;
    }

    this.modal.openSaveExit(this.sectionId, this.applicationType).subscribe((modalData) => {
      switch (modalData.appType) {
        case 'atglance':
          this.isExit = true;
          if (this.sectionId === 'HHTJF' && this.blockId === 'taxfiling' && this.form.value?.identifier?.individualId === "" && this.form.value?.data?.filing?.code === 'N') {
            const headIndvId = this.individuals[0]?.identifier;
            this.form.value.identifier.individualId = headIndvId;
            }else if (this.sectionId === 'HHTJF'  && this.blockId === 'taxfiling' && this.form.value?.identifier.individualId === "" && this.form.value?.data?.filing?.code === 'Y') {
              this.form.value.identifier.individualId = this.form.value.data.taxFilingIndividualId;
              }
          if (!this.inSummary) {
            this.onSave();
          } else {
            this.onNext();
          }
          /* Swapna - TEDS 1034992 Commenting this.state.exitForm(true) this line to prevent from the call happening twice; 
          since this was being called in  this.onSave() function */
          // this.state.exitForm(true);
          break;
        case 'continue':
          break;
        case 'submit':
          const headers =  new HttpHeaders({
            action: 'update',
            'pageId':this.sectionId
          })
          if (this.inSummary) {
          this.apiService.post(`applications/${this.applicationNumber}`, {}, { headers }, true).subscribe( () => {
            this.updateApp();
          })
          } else if (this.moduleId === 'ICPPG') {
            if (this.topLevelAnswer !== 'YES') {
              this.updateSection();
            }
            this.partialRecord();
          } else {
            if (this.sectionId === 'HHTJF' && this.form.value.identifier.individualId === "") {
            this.form.value.identifier.individualId = "1234567890";
            }
            this.partialRecord();
          }
          break;
      }
    });
  }

  // BUTTON BAR (NOT IN SUMMARY)
  onSave() {
    if (this.sectionId === 'SUSIG') {
      sessionStorage.removeItem('isNewApp')
    };
    const storeItem = sessionStorage.getItem('typesOfCoverage');
    if (this.sectionId === 'SACHS' && !storeItem) {
      this.createaAccountService.setIsStartPage(false);
      sessionStorage.setItem('typesOfCoverage', 'yes');
      this.navigate(this.sectionId)
    } else if (this.blockId === 'taxfiling') {
      this.topLevelAnswer = TopLevelAnswer.EMPTY;
      if (this.isCreate === true || this.activePerson === null || (!isNil(this.activePerson.data.blocks) && this.activePerson.data.blocks[0]?.status === 'UNCOMPLETED' && this.activePerson.exportingStatus !== 'EXPORTED')) {
        this.createRecord();
      } else if (isNil(this.activePerson.data.blocks) || this.activePerson.data.blocks[0]?.status === 'COMPLETED') {
        this.updateRecord();
      }
    } else if (this.sectionId === 'PRRLS') {
      this.createRecord();
    } else if (this.sectionId === 'PRIND') {
      const data = this.form.value.data
      const income = !!data?.income?.find(element => element.indvId);
      const selfEmployment = !!data?.selfEmployment?.find(element => element.indvId);
      const taxDeductionsData = !!data?.taxDeductionsData?.find(element => element.indvId);
      const otherIncome = !!data?.otherIncome?.find(element => element.indvId);
      if (!income && !selfEmployment && !taxDeductionsData && !otherIncome) {
        this.modal.openWarningIncome('resp').subscribe(response => {
          this.enabledNext = response
          this.form.value.data = { ...this.form.value.data, incDedTaxConsentSw: response ? 'Y' : 'N' };
          this.status === 'IN_PROGRESS' ? this.createRecord() : this.updateRecord();
        });
      } else {
        this.enabledNext = true
        this.form.value.data = { ...this.form.value.data, incDedTaxConsentSw: 'N' };
        this.status === 'IN_PROGRESS' ? this.createRecord() : this.updateRecord();
      }
    } else if (this.sectionId === 'NBINF') {
       if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') !== 'null') {
        this.updateRecord();
       } else {
        this.createRecord()
       }
    } else if (this.sectionId === 'NBSUM') {
      this.updateSection();
    } else if (this.sectionId === 'HHRLS') {
      this.status === 'IN_PROGRESS' && this.records.length === 0 ? this.createRecord() : this.updateRecord();
    } else if (this.sectionId === 'PRASM' || this.sectionId === 'ADPIN' || this.sectionId === 'ADNFI') {
      this.sectionId === 'ADNFI' ? this.updateRecord() : this.updateSection();
    } else if (this.sectionId === 'SUCOV') {
      if (gte(this.form.value.data.selectedList.length, 1)) {
        this.modal.openEndCoverageTypes(this.form.value.data.selectedList, this.applicationNumber).subscribe((result) => {
          if (result !== true && !isEmpty(result)) {
            this.form.value.data = result;
            this.createRecord();
          }
        })
      } else {
        this.form.value.data = [];
        this.createRecord();
      }
    } else if (this.sectionId === 'APREQ') {
      this.status === 'IN_PROGRESS' && this.records[0].data.filingCaseFor === null ? this.createRecord() : this.updateRecord();
    } else if (this.sectionId === 'APDET') {
      this.status === 'IN_PROGRESS' && this.records[0].data.appealReasonTxt === null ? this.createRecord() : this.updateRecord();
    } else if (this.sectionId === 'APSUM') {
      this.createRecord();
  } else {
      if(this.sectionId === 'SUCOM' && this.form?.value?.data?.comments.length > 0){
        this.form.value.data.comments = "Comment added through members TennCare Connect Account: " + this.form.value.data.comments;
      }
      else if (this.sectionId === 'INMED') {
       const medBillData = this.records?.find(item => item?.identifier?.individualId === this.form?.value?.identifier?.individualId && item?.identifier?.recordId === this.form?.value?.identifier?.recordId);
          this.form.value.data = { ...this.form?.value?.data, medBillCarryFwSw: medBillData?.data?.medBillCarryFwSw === 'Y' && !this.isCreate && medBillData?.exportingStatus !== "ADDED" ? 'Y' : 'N' }  
      } 
      sessionStorage.removeItem('typesOfCoverage');
      this.update({ isPartial: 0 });
      this.cleanForm();
      this.isSection() ? this.onNext() : this.saveRecord();
    }
  }

  onClose() {
    if (this.sectionId === 'NBIMM' && (!sessionStorage.getItem('recordId') || sessionStorage.getItem('recordId') === 'null')) {
        sessionStorage.setItem('recordId', '-1');
    }
    if (this.sectionId === 'PRRLS' || this.sectionId === 'ADSUB' || this.sectionId === 'ADNFI') {
      this.state.canDeactivate().subscribe(res => res === true ? this.navigate(this.previousSection) : null);
    } else if (this.sectionId === 'SURCH') {
      this.router.navigateByUrl('user-profile/benefit-dashboard');
    } else {
      return and(this.showRecords(), !this.inSummary) ? this.toSummary() : this.onPrevious();
    }
  }
  //

  // BUTTON BARS (IN SUMMARY)
  onNext() {
    return this.updateSection();
  }

  onSubmit() {
    this.createaAccountService.setIsSummary(this.inSummary);
    this.state.onSubmit().subscribe(trudy(() => this.isPartialSection()));
  }

  hideStepper() {
    this.createaAccountService.setIsStepperOn(false);
  };

  onPrevious() {
    this.isPresumptiveApp = isPresumptiveApp(this.applicationType);
    const fromPartnerPortal = sessionStorage.getItem('userType') === "RP" && !sessionStorage.getItem('isKepro') && sessionStorage.getItem('ViewTCC') !== "true";
    if (this.sectionId === 'SACHS' && sessionStorage.getItem('typesOfCoverage') === 'yes') {
      sessionStorage.removeItem('typesOfCoverage');
      this.navigate(this.sectionId);
      this.createaAccountService.setIsStartPage(true);
    } else if (this.sectionId === 'SACHS' && !sessionStorage.getItem('typesOfCoverage') && !this.isPresumptiveApp && !sessionStorage.getItem('isKepro')) {
      this.hideStepper();
      const isNewApp = JSON.parse(sessionStorage.getItem('isNewApp'));
      if (isNewApp) {
        this.router.navigateByUrl("user-profile/benefit-dashboard");
      } else if (sessionStorage.getItem('userType') !== "RP") {
        this.router.navigateByUrl("start/welcomeback");
      } else if (sessionStorage.getItem('userType') === "RP") {
        this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      }
    } else if (this.sectionId === 'HACHS' && this.isFinReassessment) {
      this.hideStepper();
      this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
    } else if (this.sectionId === 'HACHS' && (sessionStorage.getItem('appType') === 'RB') && !sessionStorage.getItem('isKepro') && !this.isPreterm) {
      this.hideStepper();
      this.router.navigateByUrl('/renewMyBenefit?caseNumber=' + sessionStorage.getItem('caseNum'))
    } else if (this.sectionId === 'HACHS' && (sessionStorage.getItem('appType') === 'RB') && !fromPartnerPortal && !sessionStorage.getItem('isKepro') && this.isPreterm) {
      this.hideStepper();
      this.router.navigateByUrl('/renewMyBenefit?caseNumber=' + sessionStorage.getItem('caseNum'))
    } else if (this.sectionId === 'HACHS' && (sessionStorage.getItem('appType') === 'RB') && fromPartnerPortal && this.isPreterm) {
      this.hideStepper();
      this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
    } else if (this.sectionId === 'PRHAS') {
      this.hideStepper();
      this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
    } else if (this.sectionId === 'ADPIN') {
      this.hideStepper();
      if (this.form.pristine) {
        sessionStorage.setItem("admitDischarge",  undefined);
        this.router.navigateByUrl('api/prov/search?type=ADF');
        return;
      }
      this.modal.confirmDeactivate().subscribe(resp => {
        if (resp) {
          sessionStorage.setItem("admitDischarge",  undefined);
          this.router.navigateByUrl('api/prov/search?type=ADF');
        }
      });
    } else if (this.sectionId === 'NBINF') {
      this.hideStepper();
      this.router.navigateByUrl('api/prov/search?type=NBN');
    }  else if (this.sectionId === 'BCEXS') {
      this.state.canDeactivateBCCExt('backToPrevious');
    } else if (this.sectionId === 'SACHS' || this.sectionId === 'HACHS' && sessionStorage.getItem('isKepro') === 'true') {
      this.hideStepper();
      this.router.navigateByUrl('api/prov/kepro');
    } else if (this.isAppeal && this.sectionId === 'APREQ') {
      this.state.canDeactivateAppeal('APREQ', this.sectionId)
    } else if (this.isIME && this.sectionId === 'IMEST') {
      this.state.canDeactivateAppeal('backToPrevious', this.sectionId)
    } else {
      this.navigate(this.previousSection);
    }
  }


  onComment() {
    return this.state.onComment(this.applicationNumber);
  }
  //

  ////////////////////////////////////// TEMPLATE ACTION HANDLERS //////////////////////////////////////

  ////////////////////////////////////// CONTINUITY  //////////////////////////////////////

  navigate(sectionId) {
    this.state.navigate(this.applicationNumber, sectionId);
  }

  reload() {
    //this.errors.clear();
    this.navigate(this.sectionId);
  }

  partialRecord() {
    this.update({ isPartial: 1 });
    this.cleanForm();
    this.saveRecord();
  }

  isPartialSection() {
    return or(this.inSummary, !this.isYes()) ? this.applicationUpdate() : this.partialRecord();
  }

  isPartialSubmit() {
    return this.isPartial ? this.updateApp() : this.continue();
  }

  continue() {
    if (this.blockId === 'taxfileroutside') {
      const { filing } = this.activePerson.data;
      const { filerOutsideHoh } = this.form.value.data;
      if (isNo(filing) && isNo(filerOutsideHoh)) {
        this.nextSection();
      } else {
        this.hasSummary ? this.reload() : this.nextSection();
      }
    } else {
      this.hasSummary ? this.reload() : this.nextSection();
    }
  }

  saveRecord() {
    if (this.sectionId !== 'ARPDT') {
      this.isCreate ? this.createRecord() : this.updateRecord();
    } else {
      this.updateRecord();
    }
    
  }
  onSaved(_) {
    if (this.isExit) this.state.exitForm(true);
    else if (this.enabledNext) {
      return this.isPartialSubmit();
    }
  }

  toSummary() {
    this.canDeactivate().subscribe(trudy(() => this.reload()));
  }

  ////////////////////////////////////// CONTINUITY  //////////////////////////////////////

  ////////////////////////////////////// CONTAINER  //////////////////////////////////////

  createForm(record) {
    this.isPresumptiveApp = isPresumptiveApp(this.applicationType);
    if (contains(this.sectionId, excludeComments)) {
      this.showComments = false
    } else {
      this.showComments = true
    }
    const jointFilerFilter = individual => this.individuals.filter(indv => indv.identifier != individual);

    if (this.blockId === 'taxfiling' && this.records.length > 0) {
      const { jointFilerSw } = this.records[0].data;
      if (jointFilerSw && jointFilerSw.code === 'Y') {
        const { jointTaxFilingIndividualId } = this.records[0].data;
        const listWithoutJf = jointFilerFilter(jointTaxFilingIndividualId);
        if (this.activePerson === null) {
          this.filteredIndividuals = this.individualsFilter(listWithoutJf, this.records);
        } else {
          this.filteredIndividuals = listWithoutJf;
        }
      } else if (this.activePerson) {
        this.filteredIndividuals = this.individuals;
      } else if ((this.isCreate === true) && isNil(this.activePerson)) {
        this.filteredIndividuals = this.individualsFilter(this.individuals, this.records);
      } else if (isNil(this.activePerson)) {
        this.filteredIndividuals = this.individuals;
      } else {
        this.filteredIndividuals = this.individualsFilter(this.individuals, this.records);
      }
    } else if (this.blockId === 'taxfileroutside' && this.records.length > 0) {
      const filerIds = this.records.filter(rec => rec.data.taxFilingStatus === 'Filer').map(rec => rec.identifier.individualId);
      const jointFilers = this.records.filter(rec =>rec.data.jointFilerSw && rec.data.jointFilerSw.code==='Y').map(rec => rec.identifier.individualId).toString();
      const taxDependents = this.records.filter(rec => rec.data.taxDependents).map(rec => rec.data.taxDependents);
      const dependentsList = flatten(taxDependents).map(indv => indv.individualId);
      const dependentsString = dependentsList.map(String);

      if (taxDependents?.length > 0 || !isEmpty(jointFilers)) {
        let dependentList;

        if (taxDependents?.length > 0 ) {
          dependentList = concat(filerIds, dependentsString)
        } else {
          dependentList = filerIds;
        }

        if (!isEmpty(jointFilers)) {
          const listWithJf = append(jointFilers, dependentList);
          this.allIndividuals = this.individuals.filter(indv => !listWithJf.includes(indv.identifier));
          this.filteredIndividuals = this.allIndividuals.filter(indv => indv.applyForCoverage?.code === 'Y');
        } else {
          this.allIndividuals = this.individuals.filter(indv => !dependentList.includes(indv.identifier));
          this.filteredIndividuals = this.allIndividuals.filter(indv => indv.applyForCoverage?.code === 'Y');
        }
      } else {
        this.allIndividuals = this.individuals.filter(indv => !filerIds.includes(indv.identifier));
        this.filteredIndividuals = this.allIndividuals.filter(indv => indv.applyForCoverage?.code === 'Y');
      }
    } else {
      if (this.form.value === null) {
        const  sectionIdArray = ['HHRLS', 'PRRLS', 'ASLIQ', 'ASVEH', 'ASPRP', 'ASBUR', 'ASLIF', 'OTHRS', 'ASCLD', 'XPUTL', 'XPDEP', 'XPSUP', 'XPOTH', 'ICEMI', 'ICSEM', 'ICOTH', 'HHHLC', 'INMED', 'OTKBR', 'PRIND', 'PRSEM','SCAEM','PRASM'];
        if (sectionIdArray.includes(this.sectionId)) {
          this.filteredIndividuals = this.individuals;
        }else{
          this.filteredIndividuals = this.individuals.filter(indv => indv.applyForCoverage?.code === 'Y');
        }
      } else {
        this.filteredIndividuals = this.individualsFilter(this.individuals, this.records);
      }
    }

    const selectedIndividual = this.selectedIndividual(record);
    this.originalFormData = JSON.parse(JSON.stringify(getDataValue('data', record)));

    this.container.createInstance(this.component, {
      records: this.records,
      record,
      applicationNumber: this.applicationNumber,
      applicationType: this.applicationType,
      moduleId: this.moduleId,
      topLevelAnswer: this.topLevelAnswer,
      formValidation: this.formValidation,
      mode: this.mode,
      programs: this.programs,
      individuals: this.filteredIndividuals,
      tables: this.tables,
      selectedIndividual: selectedIndividual,
      applyingForHcbc: this.applyingForHcbc,
      applyingForNursingFacilities: this.applyingForNursingFacilities,
      members: this.individuals,
      earnedIncome: this.earnedIncome,
      showInternet: this.showInternet,
      showInternetInclude: this.showInternetInclude,
      showNewActivities: this.showNewActivities,
      status: this.status,
      onValidate: this.onValidate.bind(this),
    });
  }

  clearForm() {
    this.container.clear();
  }

  cleanForm() {
    assign(this.form, { pristine: true });
  }

  ////////////////////////////////////// CONTAINER  //////////////////////////////////////

  ////////////////////////////////////// FORM TYPE / STATUS  //////////////////////////////////////

  // INDIVIDUALS
  selectedIndividual(record: Record): Individual {
    return recordIndividual(record, this.individuals);
  }

  showCreate(): boolean {
    if (this.sectionId === 'HHAPL' && this.records) {
      if(!this.isFinReassessment){
        if (this.records[0].data.assistingOnBehalf?.code === 'S' && (!this.records[0].data.assistingPerson || (!this.records[0].data.assistingPerson?.name.firstName || this.records[0].data.assistingPerson?.exportedStatus === 'REMOVED'))) {
          this.addAssistingPerson = true;
        }
      }else{
        if (this.records[0].data.ppAssistingOnBehalf?.code === 'N' && (!this.records[0].data.assistingPerson || (!this.records[0].data.assistingPerson?.name.firstName || this.records[0].data.assistingPerson?.exportedStatus === 'REMOVED'))) {
          this.addAssistingPerson = true;
        }
      }
    }

    if (this.sectionId === 'HHAPL' && this.records && isRmbRmc(this.mode)) {
      if (this.records[0].data.assistingOnBehalf?.code === 'S' && (this.records[0].data.assistingPerson && (this.records[0].data.assistingPerson?.name.firstName || this.records[0].data.assistingPerson?.exportedStatus === 'REMOVED'))) {
        this.addAssistingPerson = false;
      } else if (this.records[0].data.assistingOnBehalf?.code === 'A' && (this.records[0].data.assistingPerson && (this.records[0].data.assistingPerson?.name.firstName || this.records[0].data.assistingPerson?.exportedStatus === 'EXPORTED'))) {
        this.addAssistingPerson = true;
      } else if (this.records[0].data.assistingOnBehalf?.code === 'S' && (!this.records[0].data.assistingPerson && (!this.records[0].data.assistingPerson?.name.firstName || this.records[0].data.assistingPerson?.exportedStatus === 'ADDED'))) {
        this.addAssistingPerson = true;
      }
    }
    return isNonEmptyArray(this.individualsFilter(this.individuals, this.records));
  }
  //

  // RECORD
  showRecords(): boolean {
    this.createaAccountService.setIsSummary(this.inSummary && and(this.hasSummary, this.hasRecords));
    if (this.sectionId === 'HHAPL' && isRmbRmc(this.mode)) {
      this.hasSummary = true;
      return and(this.hasSummary, this.hasRecords);
    } else {
      if (this.sectionId === 'ARPDT') {
        const removed = JSON.parse(sessionStorage.getItem('removedAppealRecord'));
        if (!this.records || (this.records?.length && this.records[0]['data']['addReprentative'] === null) && !removed) {
          this.hasRecords = false;
        } else  if (!this.records || (this.records?.length && this.records[0]['data']['addReprentative'] === null) && removed) {
          this.appealSummaryAddrep = removed ? true : false;
        }
      } else if(this.sectionId === 'IMEDT') {
        const removed = JSON.parse(sessionStorage.getItem('removedIMERecord'));
        if (!this.records.length && !removed) {
          this.hasRecords = false;
        } else if (!this.records.length && removed) {
          this.hasRecords = true;
          this.imeDetailsSummaryStatic = removed ? true : false;
        }      
      }
      return and(this.hasSummary, this.hasRecords);
    }
  }

  formRecord(): Record {
    if(this.blockId === 'taxdependoutside' && this.processService.noDuplicateTaxInOutAfterModal && this.form.value.data?.hasTaxDependentOutside.code !== 'N'){
      this.processService.noDuplicateTaxInOutAfterModal = false;
      return {
        identifier: formIdentifier(this.form),
        data: this.noDuplicateTaxOrJFInOutAfterModal(this.form),
        exportingStatus: '',
      };
    }else if(this.blockId === 'jointfileroutside' && this.processService.noDuplicateJFInOutAfterModal && this.form.value.data?.jointFilerOutside?.code !== 'N'){
      this.processService.noDuplicateJFInOutAfterModal = false;
      return {
        identifier: formIdentifier(this.form),
        data: this.noDuplicateTaxOrJFInOutAfterModal(this.form),
        exportingStatus: '',
      };
    }else if(this.sectionId === 'PRRLS' && this.processService.noDuplicateTaxDepJFInOutAfterModal){
      this.processService.noDuplicateTaxDepJFInOutAfterModal = false;
      return {
        identifier: formIdentifier(this.form),
        data: this.noDuplicateTaxOrJFInOutAfterModal(this.form),
        exportingStatus: '',
      };
    }else{
      return {
        identifier: formIdentifier(this.form),
        data: this.postObject(formData(this.form)),
        exportingStatus: '',
      };
    }
  }

  // formRecord(): Record {
  //   return {
  //     identifier: formIdentifier(this.form),
  //     data: this.postObject(formData(this.form)),
  //     exportingStatus: '',
  //   };
  // }

  emptyRecord(identifier: Identifier = { sectionId: this.sectionId }) {
    return emptyRecord(identifier);
  }

  isSingleRecord(): boolean {
    return and(!this.hasSummary, !this.hasRecords);
  }
  //

  isSection(): boolean {
    return or(this.isSkipNo(), this.isSingleRecord());
  }

  isComplete() {
    return isComplete(this.status);
  }

  // TOP LEVEL ANSWER
  isSkipNo() {
    return isSkipNo(this.topLevelAnswer);
  }

  isYes() {
    return isYES(this.topLevelAnswer);
  }
  //

  canUnload(): boolean {
    return or(this.inSummary, this.canDiscardForm());
  }

  // FORM
  validForm(): boolean {
    let isValid = prop('valid', this.form);
    if (this.sectionId === 'SURCH' && sessionStorage.getItem('appType') === 'RC') {
      const initialSelections = [];
      if (this.form.value) {
        Object.keys(this.form.value.data).forEach(key => {
          const initialSelection = this.form.value.data[key]?.sections?.find(section => section.changeRequested);
          if (initialSelection) {
            initialSelections.push(initialSelection);
          }
        });
      }
      isValid = !!initialSelections.length;
    }
    return isValid;
  }

  disableContinue(): boolean {
    if (this.sectionId === 'PRHAS' || this.sectionId === 'SACHS') {
      return false;
    } else if(sessionStorage.getItem('appType') === 'IME' && this.sectionId === "SUSIG" && JSON.parse(sessionStorage.getItem('disableImeSubmit'))) {
      return true
    } else {
      return !this.validForm();
    }
  }

  canDiscardForm(): boolean {
    return or(!this.isYes(), prop('pristine', this.form));
  }

  disableSubmit(): boolean {
    return and(this.validateSubmit, !this.validForm());
  }


  noDuplicateTaxOrJFInOutAfterModal(form){
    if(this.blockId === 'taxdependoutside'){
      const taxDependentsOutsideIndvs = form.value.data.taxDependentsOutside;
        Object.keys(taxDependentsOutsideIndvs).forEach((item) => {
          taxDependentsOutsideIndvs[item]['birthDate'] = toISO(taxDependentsOutsideIndvs[item]['birthDate']);
        })
      return form.value.data;
    }
    if(this.blockId === 'jointfileroutside'){
      let jfOutsideIndvs = form.value.data.birthDate;
      form.value.data['birthDate'] = toISO(jfOutsideIndvs);
      return form.value.data;
    }
    if(this.sectionId === 'PRRLS'){
      const taxDepJFInOutIndvs = form.value.data.outsideHousehold;
        Object.keys(taxDepJFInOutIndvs).forEach((item) => {
          taxDepJFInOutIndvs[item]['dependentBirthDate'] = toISO(taxDepJFInOutIndvs[item]['dependentBirthDate']);
        })
      return form.value.data;
    }
  }

  ngOnDestroy() {
    this.noDuplicateTaxSub.unsubscribe();
    this.noDuplicateJFSub.unsubscribe();
    this.noDuplicateTaxJFPresmSub.unsubscribe();
  }

  ////////////////////////////////////// FORM TYPE / STATUS  //////////////////////////////////////
}