import { Injectable } from '@angular/core';

import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { and, cond, prop, propOr, T, contains, merge } from 'ramda';

import { isNonEmptyArray } from 'ramda-adjunct';

import { SubscriptionsDirective } from '@app/classes/subscriptions.class';

import { StorageService } from '@app/services/storage/storage.service';
import { ModalService } from '@app/services/modal/modal.service';

import { ApplicationFacade } from '@app/state/app/app.facade';
import { SectionFacade } from '@app/state/section/section.facade';

import { PeopleFacade } from '@app/state/people/people.facade';
import { PeopleState, peopleState } from '@app/state/people/people.state';

import { viewIdentifier } from '@app/state/people/people.selectors';

import { trudy } from '@app/helpers/function.helpers';
import { ifNotEmpty } from '@app/helpers/null.helpers';
import { foodStamps } from '@app/helpers/programs.helpers';
import { eligibleSections, emptySection } from '@app/helpers/models.helpers';
import { recordSection } from '@app/helpers/record.helpers';
import { deniedCitizenship, individualName } from '@app/helpers/people.helpers';
import { viewApp, viewBlock, viewButtonadd, viewRecords } from '@app/helpers/lenses.helpers';
import {
  activate,
  deactivate,
  firstNun,
  orEmpty,
  partialOptions,
  skipNils,
  skipSwitch,
  zipMerge,
} from '@app/helpers/observable.helpers';
import {
  hasCitizenshipDiscrepancy,
  isInfoMessages,
  isPartialApp,
  isQuestionnaire,
  isVerifyInput,
  nonePayload,
} from '@app/helpers/payload.helpers';

import { TableCodes } from '@app/models/tables.model';
import { isOtherModes } from '@app/helpers/mode.helpers';
import { Messages, Payload } from '@app/models/payload.model';
import { ApplicationNumber, Mode } from '@app/models/app.model';
import { Activable, Questions } from '@app/models/partials.model';
import { ApplicationStateModel, PeopleSet } from '@app/models/state.model';
import { IsPartial, SectionId, TopLevelAnswer } from '@app/models/section.model';
import { Block, Identifier, Person, Record, Records } from '@app/models/record.model';
import { PeopleDeleteModalComponent } from '@app/components/modals/people-delete/people-delete.component';
import { RecordDeleteModalComponent } from './../../components/modals/record-delete/record-delete.component';
import { DatePipe } from '@angular/common';

@Injectable()
export class StateService extends SubscriptionsDirective {
  sectionLinks$ = this.section.sectionLinks$;
  blockLinks$ = this.people.blockLinks$;
  identifier$ = this.section.identifier$;
  title$ = this.section.title$;
  saveAndExit = new BehaviorSubject<boolean>(false);
  saveAndExit$ = this.saveAndExit.asObservable();
  continueApp = new BehaviorSubject<boolean>(false);
  continueApp$ = this.continueApp.asObservable();
  private closeRef$ = new Subject<any>();
  exitBool = false;

  constructor(
    private app: ApplicationFacade,
    private section: SectionFacade,
    private people: PeopleFacade,
    private modal: ModalService,
    private storage: StorageService,
    private datePipe: DatePipe
  ) {
    super();
  }

  /////////// UPDATES ///////////

  updates(update) {
    return this.merge([
      this.formInfo(update),
      this.records(update),
      this.individuals(update),
      this.tables(update),
      this.activePerson(update),
      this.topLevelAnswer(update),
      this.earnedIncome(update),
      this.showInternet(update),
      this.showInternetInclude(update),
      this.showNewActivities(update),
    ]);
  }

  getCloseRef(): Observable<any> {
    return this.closeRef$.asObservable();
  }

  setCloseRef(bool) {
    this.closeRef$.next(bool);
  }

  exitForm(bool) {
    this.exitBool = true;
    this.saveAndExit.next(bool);
  }

  continueApplication(bool) {
    this.continueApp.next(bool);
  };

  showSubmit({ showSubmit, programs, mode }) {
    return and(showSubmit, foodStamps(programs)) && !isOtherModes(mode);
  }

  formInfo(update) {
    return this.create(zipMerge(this.section.sectionConfig$, this.app.application$, this.section.config$), start => {
      const title = prop('title', start);
      update({
        /// APP
        applicationNumber: prop('applicationNumber', start),
        mode: prop('mode', start),
        applicationType: prop('applicationType', start),
        applyingForHcbc: prop('applyingForHcbc', start),
        applyingForNursingFacilities: prop('applyingForNursingFacilities', start),
        comments: prop('comments', start),
        labelData: prop('labelData', start),
        programs: propOr([], 'programs', start),
        title,
        columns: propOr([], 'columns', start),
        component: prop('component', start),
        formValidation: prop('formValidation', start),
        individualsFilter: prop('individualsFilter', start),
        postObject: prop('postObject', start),
        hasSummary: prop('hasSummary', start),
        labelContinue: prop('labelContinue', start),
        labelExit: prop('labelExit', start),
        labelCancel: prop('labelCancel', start),
        showComments: prop('showComments', start),
        showSaveAndExit: prop('showSaveAndExit', start),
        showSubmit: this.showSubmit(start),
        tooltipAdd: prop('tooltipAdd', start),
        buttonAdd: viewButtonadd(title, start),
        validateSubmit: prop('validateSubmit', start),
        moduleId: prop('moduleId', start),
        sectionId: prop('sectionId', start),
        previousSection: prop('previousSection', start),
        earnedIncome: prop('earnedIncome', start),
        showInternet: prop('showInternet', start),
        showInternetInclude: prop('showInternetInclude', start),
        showNewActivities: prop('showNewActivities', start),
        status: prop('status', start),
      });
    });
  }

  activePerson(update) {
    return this.create(this.people.activePerson$, activePerson => update({ activePerson }));
  }

  records(update) {
    return this.create(this.section.records$, records => update({ records, hasRecords: isNonEmptyArray(records) }));
  }

  individuals(update) {
    return this.create(this.app.individuals$, individuals => update({ individuals }));
  }

  tables(update) {
    return this.create(this.section.tables$, tables => update({ tables }));
  }

  topLevelAnswer(update) {
    return this.create(this.section.topLevelAnswer$, topLevelAnswer => update({ topLevelAnswer }));
  }

  earnedIncome(update) {
    return this.create(this.section.earnedIncome$, earnedIncome => update({ earnedIncome }));
  }

  showInternet(update) {
    return this.create(this.section.showInternet$, showInternet => update({ showInternet }));
  }

  showInternetInclude(update) {
    return this.create(this.section.showInternetInclude$, showInternetInclude => update({ showInternetInclude }));
  }

  showNewActivities(update) {
    return this.create(this.section.showNewActivities$, showNewActivities => update({ showNewActivities }));
  }

  /////////// APP ///////////

  guardApp(action) {
    return this.app.applicationNumber$.pipe(skipNils(() => action()));
  }

  tableCodes(applicationNumber: ApplicationNumber, sectionId: SectionId) {
    return this.app.tableCodes(sectionId).pipe(
      first(),
      switchMap(codes => this.loadSection(applicationNumber, sectionId, codes))
    );
  }

  loadApp(applicationNumber: ApplicationNumber) {
    return this.app.loadApp(applicationNumber).pipe(
      tap(() => this.storage.setAppNumber(applicationNumber)) //
    );
  }

  resetStates () {
    return this.app.resetAll();
  }

  startApp(mode: Mode) {
    return this.app.startApp(mode, this.storage.getCaseNumber());
  }

  getApp(applicationNumber: ApplicationNumber): Observable<ApplicationStateModel> {
    return this.app.getApp(applicationNumber).pipe(map(viewApp));
  }

  applicationUpdate(applicationNumber: ApplicationNumber, sectionId: SectionId, topLevelAnswer: TopLevelAnswer) {
    return this.sectionUpdate(applicationNumber, sectionId, topLevelAnswer).pipe(
      switchMap(() => this.updateApp(applicationNumber, null))
    );
  }

  updateApp(applicationNumber: ApplicationNumber, incompleteSection: SectionId | null = null) {
    return this.app.updateApp(applicationNumber, incompleteSection).pipe(
      switchMap(() => this.getApp(applicationNumber)),
      switchMap(() => this.inProgress()),
      switchMap(deactivate)
    );
  }

  setIndividuals(records: Records) {
    this.app.setIndividuals(records);
  }

  onComment(applicationNumber: ApplicationNumber) {
    return this.app.comments$
      .pipe(
        firstNun(() => this.app.loadComments(applicationNumber)),
        switchMap(comments => this.modal.openComments(comments)),
        tap(trudy(comments => this.app.updateComments(applicationNumber, comments)))
      )
      .subscribe();
  }

  loadCongrats(applicationNumber: ApplicationNumber) {
    return this.app.loadCongrats(applicationNumber);
  }

  /////////// SECTION ///////////

  guardSection(applicationNumber: ApplicationNumber, sectionId: SectionId) {
    return this.section.identifier$.pipe(skipSwitch(() => this.tableCodes(applicationNumber, sectionId)));
  }

  afterRecord(payload, applicationNumber, record, isPartial) {
    return this.getApp(applicationNumber).pipe(
      switchMap(() => this.handlePayload(applicationNumber, payload, record, isPartial)),
      tap(() => this.resetPayload())
    );
  }

  createRecord(applicationNumber: ApplicationNumber, record: Record, isPartial: IsPartial, topLevelAnswer: TopLevelAnswer) {
    return this.section.createRecord(applicationNumber, record, isPartial, topLevelAnswer).pipe(
      switchMap(({ payload, activeBlock }) => this.afterRecord(payload, applicationNumber, activeBlock, isPartial)) //
    );
  }

  updateRecord(applicationNumber: ApplicationNumber, record: Record, isPartial: IsPartial) {
    return this.section.updateRecord(applicationNumber, record, isPartial).pipe(
      switchMap(({ payload, activeBlock }) => this.afterRecord(payload, applicationNumber, activeBlock, isPartial)) //
    );
  }

  removeRecord(applicationNumber: ApplicationNumber, identifier: Identifier) {
    return this.section.removeRecord(applicationNumber, identifier);
  }

  removeAppealRecord(applicationNumber: ApplicationNumber, identifier: Identifier,  record: Record) {
    sessionStorage.setItem('removedAppealRecord', 'true');
    return this.section.removeDataRecord(applicationNumber, identifier, record, 'ADDED');
  }

  removeIMERecord(applicationNumber: ApplicationNumber, identifier: Identifier,  record: Record) {
    sessionStorage.setItem('removedIMERecord', 'true');
    return this.section.removeDataRecord(applicationNumber, identifier, record, 'ADDED');
  }

  removeDataRecord(applicationNumber: ApplicationNumber, identifier: Identifier, record: Record, exportingStatus?: String) {
    return this.section.removeDataRecord(applicationNumber, identifier, record, exportingStatus);
  }

  updateSection(applicationNumber: ApplicationNumber, sectionId: SectionId, topLevelAnswer: TopLevelAnswer) {
    return this.section.updateSection(applicationNumber, sectionId, topLevelAnswer);
  }

  updatePeopleSumSection(applicationNumber: ApplicationNumber, sectionId: SectionId, answer: any) {
    return this.section.updatePeopleSumSection(applicationNumber, sectionId, answer);
  }

  sectionUpdate(applicationNumber: ApplicationNumber, sectionId: SectionId, topLevelAnswer: TopLevelAnswer) {
    return this.updateSection(applicationNumber, sectionId, topLevelAnswer).pipe(
      switchMap(() => this.getApp(applicationNumber)),
      switchMap(() => this.app.nextSection())
    );
  }

  sectionPeopleSumUpdate(applicationNumber: ApplicationNumber, sectionId: SectionId, answer: any) {
    return this.updatePeopleSumSection(applicationNumber, sectionId, answer).pipe(
      switchMap(() => this.getApp(applicationNumber)),
      switchMap(() => this.app.nextSection())
    );
  }

  loadSection(applicationNumber: ApplicationNumber, sectionId: SectionId, tableCodes: TableCodes): Observable<Records> {
    return this.section.loadSection(applicationNumber, sectionId, tableCodes).pipe(map(viewRecords));
  }

  setSection(section = emptySection()): Observable<PeopleState> {
    return this.section.setSection(section);
  }

  /////////// PEOPLE ///////////

  setPeople(peopleSet: PeopleSet = peopleState): Observable<PeopleState> {
    return this.people.setPeople(peopleSet);
  }

  setPerson(activePerson: Person | null): Observable<Identifier> {
    return this.setPeople({ activePerson }).pipe(map(viewIdentifier));
  }

  setBlock(activeBlock: Block): Observable<Block> {
    return this.setPeople({ activeBlock }).pipe(map(viewBlock));
  }

  loadBlock(applicationNumber: ApplicationNumber, identifier): Observable<Block> {
    return this.people.loadBlock(applicationNumber, identifier).pipe(map(viewBlock));
  }

  getCongrats() {
    return this.app.congrats$.pipe(first());
  }

  /////////// PAYLOAD ///////////

  handlePayload(applicationNumber: ApplicationNumber, payload: Payload, record: Record, isPartial: IsPartial) {
    return cond([
      [isPartialApp, () => this.partialApp(applicationNumber, record, isPartial)],
      [isQuestionnaire, () => this.questionnaire(applicationNumber, <Questions>payload.content, record)],
      [isInfoMessages, () => this.infoMessages(applicationNumber, <Messages>payload.content, record)],
      [isVerifyInput, () => this.verifyInput(<Messages>payload.content, record)],
      [T, () => of(null)],
    ])(payload.type);
  }

  questionnaire(applicationNumber: ApplicationNumber, questions: Questions, record: Record) {
    return this.modal.questionnaire(questions, record, this.exitBool).pipe(
      switchMap((verification) => this.section.saveQuestionnaire(applicationNumber, verification)), //
      switchMap(({ payload }) => {
        this.setCloseRef('questionnaire');
        return this.handlePayload(applicationNumber, payload, record, 0)
      })
    );
  }

  partialApp(applicationNumber: ApplicationNumber, record: Record, isPartial: IsPartial) {
    return partialOptions(isPartial, () => this.confirmUpdate(), activate).pipe(
      switchMap(orEmpty(() => this.updateApp(applicationNumber, recordSection(record))))
    );
  }

  infoMessages(applicationNumber: ApplicationNumber, content: Messages, record: Record): Observable<Record> {
    return this.modal.infoMessages(content, individualName('this person', record.data)).pipe(
      tap(
        ifNotEmpty(data =>
          this.deathConfirmation(applicationNumber, { identifier: record.identifier, data, exportingStatus: '' })
        )
      ), //
      map(() => this.hasCitizenshipDiscrepancy(content, record)), //
      switchMap(updatedRecord => of(updatedRecord)) //
    );
  }

  verifyInput(content: Messages, record: Record): Observable<Record> {
    return this.modal.verifyInput(content).pipe(switchMap(() => of(record)));
  }

  hasCitizenshipDiscrepancy(messages: Messages, record: Record): Record {
    return hasCitizenshipDiscrepancy(messages) ? deniedCitizenship(record) : record;
  }

  deathConfirmation(applicationNumber: ApplicationNumber, record: Record) {
    return this.section.deathConfirmation(applicationNumber, record);
  }

  resetPayload() {
    return this.section.handlePayload(nonePayload());
  }

  /////////// NAVIGATION ///////////

  navigate(applicationNumber: ApplicationNumber, sectionId: SectionId) {
    return this.app.navigate(applicationNumber, sectionId);
  }

  nextSection(applicationNumber) {
    return this.getApp(applicationNumber).pipe(switchMap(() => this.app.nextSection()));
  }

  inProgress() {
    return this.app.inProgress();
  }

  /////////// MODALS ///////////

  onDelete(applicationNumber: ApplicationNumber, identifier: Identifier, recordData: any, flag = false): Observable<any> {

    if (flag) {
      const subject = new Subject();
      this.modal.confirmDelete().subscribe(resp => {
        if(resp) {
          recordData.record.data.hohIndividual = null;
          recordData.record.data.assistingPerson = null;
          recordData.record.data.assistingOnBehalf = { code: 'S', value: 'Self' };
          this.section.updateRecord(applicationNumber, recordData.record, 0).subscribe(res => {
            if (res) {
              subject.next(res);
            }
          })
        }
      })
      return subject;
    } else {

    if (contains(identifier.sectionId, eligibleSections) && identifier['isExported']) {
      const subject = new Subject();
      const commonItems = {
        indvName: recordData.indvName,
        assistingPersonName: recordData.record.data.assistingPerson?.name?.fullName,
        sectionId: identifier.sectionId,
        componentName: RecordDeleteModalComponent
      };
      const formData = recordData.record.data;
      const removePerson = { 
        deleteReason: formData.moveOutReason? {code: formData.moveOutReason?.code, value: formData.moveOutReason?.value } : formData.moveOutReason || null, 
        indvDeletedDate: formData.indvDeletedDate ? this.datePipe.transform(formData.indvDeletedDate, "MM/dd/yyyy"):formData.indvDeletedDate || null, 
        deathDate: formData.indvDeletedDate ? this.datePipe.transform(formData.indvDeletedDate, "MM/dd/yyyy"):formData.indvDeletedDate || null, 
        addressFormat: formData.addressFormat || null, 
        address: formData.address || null, 
        indvName: recordData.indvName,
        componentName: PeopleDeleteModalComponent };
      const removeForm = 
        identifier.sectionId === 'HHAPL' ? { assistingEndDate: null, commonItems } : 
        identifier.sectionId === 'HHHLC' ? { coverageEndDate: formData.coverageEndDate || null, commonItems } :
        identifier.sectionId === 'HHTJF' ? { taxFilingEndDate:  formData.taxFilingEndDate || null, commonItems } :
        identifier.sectionId === 'ICEMI' ? { employmentEndDate: formData.employmentEndDate || null , lastPaycheckDate: formData.lastPaycheckDate || null , lastPaycheckAmt: formData.lastPaycheckAmt || null, commonItems } :
        identifier.sectionId === 'ICSEM' ? { selfempEndDate: formData.selfempEndDate || null, commonItems } :
        identifier.sectionId === 'ICOTH' ? { otherIncomeEndDate: formData.otherIncomeEndDate || null, commonItems } :
        identifier.sectionId === 'OTKBR' ? { kbRemoveSw: formData.kbRemoveSw || null, kbRemovalDt: formData.kbRemovalDt || null, commonItems } :
        identifier.sectionId === 'XPUTL' ? { shelterEndDate: formData.shelterEndDate || null, commonItems } :
        identifier.sectionId === 'XPDEP' ? { careEndDate: formData.careEndDate || null, commonItems } :
        identifier.sectionId === 'XPSUP' ? { mandatoryEndDate: formData.mandatoryEndDate || null, commonItems } :
        identifier.sectionId === 'INMED' ? { medicalPaymentDate: formData.medicalPaymentDate || null, commonItems } :
        identifier.sectionId === 'XPOTH' ? { otherEndDate: formData.otherEndDate || null, commonItems } :
        identifier.sectionId === 'ASLIQ' ? { cashEndDate: formData.cashEndDate || null, commonItems } :
        identifier.sectionId === 'ASVEH' ? { vehicleEndDate: formData.vehicleEndDate || null, commonItems } :
        identifier.sectionId === 'ASPRP' ? { propertyEndDate: formData.propertyEndDate || null, commonItems } :
        identifier.sectionId === 'ASBUR' ? { burrialEndDate: formData.burrialEndDate || null, commonItems } :
        identifier.sectionId === 'ASLIF' ? { lifeIncEndDate: formData.lifeIncEndDate || null, commonItems } :
        identifier.sectionId === 'OTHRS' ? { othResourcesEndDate: formData.othResourcesEndDate || null, commonItems } :
        identifier.sectionId === 'ASCLD' ? { transferEndDate: formData.transferEndDate || null, agreeTerms: formData.agreeTerms || null, commonItems } :
        identifier.sectionId === 'HHIND' ? removePerson : '';

      this.modal.openDynamicModal(identifier.sectionId, removeForm).subscribe(result => {
        if (result) {
          if (identifier.sectionId === 'HHAPL') {
            recordData.record.data.hohIndividual = null;
            recordData.record.data.assistingPerson = null;
            recordData.record.data.assistingOnBehalf = { code: 'S', value: 'Self' };
            const assistingEndDate = prop('assistingEndDate', removeForm);
            recordData.record.data.assistingEndDate = assistingEndDate;
            this.section.updateRecord(applicationNumber, recordData.record, 0).subscribe(resp => {
              if (resp) {
                subject.next(true);
              }
            })
          } else {
            const finalRecord = merge(recordData.record.data, removeForm);
            this.removeDataRecord(applicationNumber, identifier, finalRecord).subscribe(resp => {
              if (resp) {              
                subject.next(true);
              }
            });
          }
        }
      });
      return subject;
    } else {
      return this.modal
        .confirmDelete()
        .pipe(switchMap(orEmpty(() => this.removeRecord(applicationNumber, identifier))));
      }
    }
  }

  onAppealDelete(applicationNumber: ApplicationNumber, identifier: Identifier, recordData: any): Observable<any> {
      return this.modal
        .confirmDelete()
        .pipe(switchMap(orEmpty(() => this.removeAppealRecord(applicationNumber, identifier, recordData))));

  }

  onIMEDelete(applicationNumber: ApplicationNumber, identifier: Identifier, recordData: any): Observable<any> {
    return this.modal
      .confirmDelete()
      .pipe(switchMap(orEmpty(() => this.removeIMERecord(applicationNumber, identifier, recordData))));

  }

  onUndo(applicationNumber: ApplicationNumber, identifier: Identifier, recordData: any) {
    if (identifier.sectionId === 'HHAPL') {
      recordData.data.assistingOnBehalf = { code: 'A', value: 'Assisting Person' };
    }
    return this.removeDataRecord(applicationNumber, identifier, recordData.data, recordData.exportingStatus)
  }

  onSubmit(): Activable {
    return this.modal.confirmSubmit();
  }

  canDeactivate(): Activable {
    return this.modal.confirmDeactivate();
  }

  canDeactivateAppeal(from,pageId?) : Activable {
    return this.modal.confirmDeactivateAppeal(from, pageId);
  }

  canDeactivateBCCExt(from) : Activable {
    return this.modal.confirmDeactivateBCCExt(from);
  }

  confirmUpdate(): Activable {
    return this.modal.confirmUpdate();
  }

  confirmRealName(realName: string): Activable {
    return this.modal.confirmName(realName);
  }

  confirmRemoveAllPrograms(): Activable {
    return this.modal.confirmRemoveAllPrograms();
  }

  // coverageDashboardFilter(): Activable {
  //   return this.modal.coverageDashboardFilters();
  // }
}
