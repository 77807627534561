import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/gateway/services/api.service';
import { StorageService } from "@app/services/storage/storage.service";
import { ActivatedRoute, Router } from '@angular/router';
import { AppealsService } from '../appeals.service';
import { FormControl, FormGroup } from '@angular/forms';
import Vs from '@app/services/validators/validators.service';
import { minus120Years } from '@app/helpers/date.helpers';
import { ModalService } from '@app/services/modal/modal.service';
import { HttpHeaders  } from '@angular/common/http';

@Component({
  selector: 'nh-appeal-continuance',
  templateUrl: './appeal-continuance.component.html',
  styleUrls: ['./appeal-continuance.component.scss']
})
export class AppealContinuanceComponent implements OnInit {
    translatedLang;
    memberInfo;
    minimumDate = minus120Years();
    proposedHearingDate;

    appealContinuanceForm = new FormGroup({
        // proposedHearingDate: new FormControl('', [Vs.validDate]),
        reasonForContinuance: new FormControl('', [Vs.required, Vs.submittedBy]),
        relationshipWithAppellant: new FormControl('', [Vs.required, Vs.submittedBy]),
        appellantSignSW: new FormControl('', Vs.required),
        appellantSign: new FormControl('', Vs.required),
        submittedBy: new FormControl('', Vs.required)

    });

    get form() { return this.appealContinuanceForm['controls']; }

    constructor(public translateService: TranslateService, public app: ApiService,
        protected storageService: StorageService, private router: Router, public activatedRoute: ActivatedRoute,
        public apiService: ApiService, public appealsService: AppealsService, private modal: ModalService) { }

    
    ngOnInit() {
        const defaultLanguage = this.translateService.getDefaultLang();
        this.translatedLang = defaultLanguage;
        this.translateService.onDefaultLangChange.subscribe((res) => {
            this.translatedLang = res.lang;     
        });
        this.activatedRoute.queryParams.subscribe(res => this.onLoad(res));
            // const proposedHearingDate =  this.datePipe.transform(this.memberInfo.proposedHearingDate , "MM/dd/yyyy");
            // this.appealContinuanceForm.controls["proposedHearingDate"].patchValue(proposedHearingDate);
    }

    goToAppealsDashboard(){
        this.router.navigateByUrl("user-profile/my-appeals");
    }

    onPrevious() {
        if (this.appealContinuanceForm.pristine) {
          this.router.navigateByUrl('/user-profile/my-appeals/');
          return;
        }
        this.modal.confirmDeactivate().subscribe(resp => {
          if (resp) {
            this.router.navigateByUrl('/user-profile/my-appeals/');
          }
        });
    }

    onLoad(response){
        const { appealNumber, indvid } = response;
        const url = `appeals/continuance/indv/${indvid}/apl/${appealNumber}`;
        this.app.get(url, true, {headers: new HttpHeaders({'pageId': 'TCAPC'})}).subscribe((resp) => {    
            if (resp) {
                console.log(resp);
                this.memberInfo = resp ? resp['appealContinuanceResponse'] : null;
            };

            this.proposedHearingDate =  this.memberInfo?.hearingDate;
            if(this.proposedHearingDate){
                this.proposedHearingDate = this.proposedHearingDate;
            }else{
                this.proposedHearingDate = 'N/A';
            };

            this.appealContinuanceForm.controls["reasonForContinuance"].patchValue(this.memberInfo.reasonForContinuance);
            this.appealContinuanceForm.controls["relationshipWithAppellant"].patchValue(this.memberInfo.relationshipWithAppellant);
            this.appealContinuanceForm.controls["submittedBy"].patchValue(this.memberInfo.submittedBy);
        })
    };

    onSubmit(info, controls, currentLanguage): void {
        this.appealsService.saveContinuance(info, controls, currentLanguage);
    };
    
    isFormValid(): boolean {
        return ((this.appealContinuanceForm.status === "VALID"));
    }

    deselect(val){
        if(val.target.checked){
            this.appealContinuanceForm.controls["appellantSignSW"].patchValue('Y');
        }else{
            this.appealContinuanceForm.controls["appellantSignSW"].patchValue(null);
        }
    }

    submittedValidator(val) {
        if(val.target.value.length>0){
            const exp = /^[^#%*{}<>|[\]^]*$/;
            !exp.test(this.appealContinuanceForm.controls["submittedBy"].value) ? this.appealContinuanceForm.controls["submittedBy"].setErrors({INVALID_SUBMITTEDBY: true}): this.appealContinuanceForm.controls["submittedBy"].setErrors(null)
        }
    }

    signatureValidator(val) {
        if(val.target.value.length>0){
            const exp = /^[^#%*{}<>|[\]^]*$/;
            !exp.test(this.appealContinuanceForm.controls["appellantSign"].value) ? this.appealContinuanceForm.controls["appellantSign"].setErrors({INVALID_SUBMITTEDBY: true}): this.appealContinuanceForm.controls["appellantSign"].setErrors(null)
        }
    }

}