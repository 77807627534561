<div class="p-2" *ngIf="mainPage === true && !fromMyAppeals && !fromRMCDocUpload">
    <nh-alert type="success" [isOpen]="success">
        <div class="row">
            <div class="icon">
                <img src="../../../../../assets/images/Icons/⚛️ icons-checkmark-outline.svg" alt="danger" />
            </div>
            <div class="message">
                <span>{{'DOCUMENT_UPLOADED' | translate }}</span>
            </div>
        </div>
    </nh-alert>

    <div *ngIf="response && ((rows && rows.length > 0) || (kbRows && kbRows.length > 0) || (appealRows && appealRows.length))">
        <div *ngIf="!success">
            <h5 class="section-header">{{'DOCUMENTS_WE_NEED' | translate }}</h5>
            <hr>
            <p [innerHTML]="'DOCUMENTS_WE_NEED_STATIC_TEXT21' | translate "></p>
            <div (click)="showProofTypes()" class="pb-3 popup">{{'Types of Proof' | translate }}
                <span><i class="fa fa-external-link"></i></span>
            </div>
        </div>

        <div *ngIf="success">
            <div *ngIf="translatedLang === 'en'">
              <p class="static-text">It might take us time to review the documents you've uploaded. If you think you need to upload more documents for the proof type we asked you for, select <strong>Add Another Document.</strong></p>
              <p class="static-text">You can upload other kinds of proof we need by clicking the <strong>Upload Proof</strong> button.</p>
              <p class="static-text">TennCare is reviewing your documents now.  We’ll let you know if we need anything else.</p>
              
              <h5 class="section-header">Case Documents We Need</h5>
            </div>
            <div *ngIf="translatedLang !== 'en'">
              <p  class="static-text">Es posible que nos lleve tiempo revisar los documentos que ha subido. Si cree que necesita cargar más documentos para el tipo de prueba que le solicitamos, seleccione Agregar otro documento.</p>
              <p class="static-text">Puede cargar otros tipos de prueba que necesitamos haciendo clic en el botón Cargar prueba.</p>
              <p class="static-text">TennCare está revisando sus documentos ahora. Le informaremos si necesitamos algo más.</p>
              
              <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT17' | translate }}</h5>
            </div>
            
        </div>
        <hr>
    <div *ngIf="rows && rows.length" class="d-none d-lg-block">
            <nh-alert type="warning" [isOpen]="vc136" class="preterm_banner">
                <div class="row">
                    <div class="icon">
                        <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                    </div>
                    <div class="message">
                        <span>{{'QFR_BANNER' | translate}} <strong><a *ngIf="!readTCC" [routerLink]="['/user-profile/pre-term']">{{'QFR' |
                                    translate}}.</a><a *ngIf="readTCC" [class.disabled]="readTCC">{{'QFR' |
                                        translate}}.</a></strong></span>
                    </div>
                </div>
            </nh-alert>
            <ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table [rows]='rows' [limit]="6" [columnMode]='"force"' [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of columns">
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'Status' | translate}}" [width]="150">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <span *ngIf="row.submittedStatus === 'N'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT3' | translate }}</span>
                        <span *ngIf="row.submittedStatus === 'Y'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT4' | translate }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{ 'Action' | translate }}" [width]="245">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <ng-container *ngIf="row.verfCd !== 'VC190'">
                            <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary" (click)="uploadProof(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                            <div class="p-0 hover-red">
                                <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="uploadProof(row)" class="btn p-0 hover-red text-left btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="row.verfCd == 'VC190'">
                            <span>N/A</span>
                        </ng-container>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <ng-container *ngIf="vc190">
                <p>{{'ECF_STATIC_TEXT_1' | translate }} <a href="https://tpaes.tenncare.tn.gov/tmtrack/ecf/index.htm">https://tpaes.tenncare.tn.gov/tmtrack/ecf/index.html</a>. 
                   {{'ECF_STATIC_TEXT_2' | translate }}
                </p>
            </ng-container>
        </div>

        <div class="nh-table-mobile d-lg-none">
            <div *ngIf="rows?.length">
                <nh-alert type="warning" [isOpen]="vc136" class="preterm_banner">
                    <div class="row">
                        <div class="icon">
                            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                        </div>
                        <div class="message">
                            <span>{{'QFR_BANNER' | translate}} <strong><a *ngIf="!readTCC" [routerLink]="['/user-profile/pre-term']">{{'QFR' |
                                        translate}}.</a><a *ngIf="readTCC" [class.disabled]="readTCC">{{'QFR' |translate}}.</a></strong></span>
                        </div>
                    </div>
                </nh-alert>
                <div class="table-row mb-3 pb-3" *ngFor="let row of rows">
                    <div class="mobile-table-header">{{columns[0].name | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.fullName}}</div>
                    <br>
                    <div class="mobile-table-header">{{columns[1].name | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.caseNum}}</div>
                    <br>
                    <div class="mobile-table-header">{{columns[2].name | translate}}</div>
                    <hr>
                    <div *ngIf="this.translatedLang == 'en'" class="mobile-table-data">{{row.proofType}}</div>
                    <div *ngIf="this.translatedLang !== 'en'" class="mobile-table-data">{{row.proofTypeEs}}</div>
                    <br>
                    <div class="mobile-table-header">{{columns[3].name | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.verfDue}}</div>
                    <br>
                    <div class="mobile-table-header">{{ 'Action' | translate }}</div>
                    <hr>
                    <ng-container *ngIf="row.verfCd !== 'VC190'">
                    <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary action-button"
                        (click)="uploadProof(row)">{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</button>
                    <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" class="p-0 text-left hover-red btn-link"
                        (click)="uploadProof(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                    </ng-container>
                    <ng-container *ngIf="row.verfCd == 'VC190'">
                        <span>N/A</span>
                    </ng-container>
                    <br>

                </div>
                <ng-container *ngIf="vc190">
                    <p>{{'ECF_STATIC_TEXT_1' | translate }} <a href="https://tpaes.tenncare.tn.gov/tmtrack/ecf/index.htm">https://tpaes.tenncare.tn.gov/tmtrack/ecf/index.html</a>. 
                       {{'ECF_STATIC_TEXT_2' | translate }}
                    </p>
                </ng-container>
            </div>
            <p *ngIf="!rows?.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
        </div>

				<!-- Appeal rows section start for Desktop & Mobile -->
        <div *ngIf="appealRows &&  appealRows.length > 0">
            <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT18' | translate }}</h5>
            <hr>
            <p [innerHTML]="'DOCUMENTS_WE_NEED_STATIC_TEXT24' | translate "></p>
            <div></div>
            <div class="d-none d-lg-block">
                <ngx-datatable class='bootstrap striped' #table [rows]='appealRows' [columnMode]='"standard"' [limit]="6"
                    [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                    <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" [width]="col.width" *ngFor="let col of appealColumns">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{'Status' | translate}}" [width]="125">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                            <span *ngIf="row.submittedStatus === 'N'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT3' | translate }}</span>
                            <span *ngIf="row.submittedStatus === 'Y'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT4' | translate }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{'Action' | translate}}" [width]="200">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                            <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary" (click)="uploadProof(row)"><span>
                                {{'DOCUMENTS_WE_NEED_STATIC_TEXT22' | translate }}</span></button>
                                    <div class="p-0 hover-red">
                                        <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'"  (click)="uploadProof(row)"
                                            class="btn p-0 hover-red text-left btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                                    </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
        <div class="nh-table-mobile d-lg-none">
            <ng-container *ngIf="appealRows && appealRows.length > 0">
                <div class="table-row mb-3 pb-3" *ngFor="let row of appealRows">
                    <div class="mobile-table-header">{{appealColumns[0].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.fullName}}</div>
                    <br>
                    <div class="mobile-table-header">{{'Appeal' | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.appealNum}}</div>
                    <br>
                    <div class="mobile-table-header">{{appealColumns[2].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.verfDesc}}</div>
                    <br>
                    <div class="mobile-table-header">{{appealColumns[3].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.appealFileDate}}</div>
                    <br>
                    <div class="mobile-table-header">{{appealColumns[4].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.lastUpdatedDateApl}}</div>
                    <br>
                    <div class="mobile-table-header">{{ 'Action' | translate }}</div>
                    <hr>
                    <span>
                        <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary action-button" (click)="uploadProof(row)"><span
                                >{{'DOCUMENTS_WE_NEED_STATIC_TEXT22' | translate }}</span></button>
                        <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'"  class="p-0 text-left hover-red btn-link" (click)="uploadProof(row)"><span>
                            {{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                    </span>
                    <br>
                    <hr>
                </div>
            </ng-container>
            <!-- <p *ngIf="this.appealRows" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p> -->
        </div>
				<!-- Appeal rows section end for Desktop & Mobile -->

				<!-- KB rows section end for Desktop & Mobile -->
        <div *ngIf="kbRows && kbRows.length > 0">
            <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT19' | translate }}</h5>
            <hr>
            <div class="d-none d-lg-block">
                <ngx-datatable class='bootstrap striped' #table [rows]='kbRows' [columnMode]='"force"' [limit]="5"
                    [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                    <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of kbColumns">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{'Information Needed' | translate }}">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                            <span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT20' | translate }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{'Action' | translate}}" [width]="200">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                            <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary"
                                (click)="uploadProof(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                            <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="uploadProof(row)"
                            class="p-0 text-left hover-red btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>

            <div class="nh-table-mobile d-lg-none">
                <div class="table-row mb-3 pb-3" *ngFor="let row of kbRows">
                    <div class="mobile-table-header">{{kbColumns[0].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.fullName}}</div>
                    <br>
                    <hr>
                    <div class="mobile-table-header">{{kbColumns[1].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.refId}}</div>
                    <br>
                    <div class="mobile-table-header">{{'Information Needed' | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{'DOCUMENTS_WE_NEED_STATIC_TEXT20' | translate }}</div>
                    <br>
                    <div class="mobile-table-header">{{'Action' | translate}}</div>
                    <hr>
                    <span>
                        <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary action-button" (click)="uploadProof(row)">
													<span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
													<a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" class="p-0 text-left hover-red btn-link" (click)="uploadProof(row)">
														<span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                    </span>
                    <br>
                    <hr>
                </div>
                <!-- <p *ngIf="!appealRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p> -->
            </div>
        </div>
				<!-- KB rows section end for Desktop & Mobile -->

        <div *ngIf="householdRows && householdRows.length > 0">
            <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT2' | translate }}</h5>
            <hr>
            <div class="d-none d-lg-block">
                <ngx-datatable class='bootstrap striped' #table [rows]='householdRows' [limit]="5" [columnMode]='"force"'
                    [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                    <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of houseHoldColumns">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{'Status' | translate}}"  [width]="160">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                            <span *ngIf="row.submittedStatus === 'N'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT3' | translate }}</span>
                            <span *ngIf="row.submittedStatus === 'Y'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT4' | translate }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{'Action' | translate}}" [width]="245">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                            <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary"
                                (click)="documentUploadB(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                            <div class="p-0 hover-red">
                                <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="documentUploadB(row)"
                                    class="btn hover-red p-0 text-left btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>

            <div class="nh-table-mobile d-lg-none">
                <ng-container *ngIf="householdRows.length">
                    <div class="table-row mb-3 pb-3" *ngFor="let row of householdRows">
                        <div class="mobile-table-header">{{houseHoldColumns[0].name | translate}}</div>
                        <hr>
                        <div class="mobile-table-data">{{row.hohName}}</div>
                        <br>
                        <div class="mobile-table-header">{{houseHoldColumns[1].name | translate}}</div>
                        <hr>
                        <div class="mobile-table-data">{{row.type}}</div>
                        <br>
                        <div class="mobile-table-header">{{houseHoldColumns[2].name | translate}}</div>
                        <hr>
                        <div class="mobile-table-data">{{row.submissionDate}}</div>
                        <br>
                        <div class="mobile-table-header">{{ 'Action' | translate }}</div>
                        <hr>
                        <span>
                            <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary action-button"
                                (click)="documentUploadB(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                            <div class="p-0 hover-red">
                                <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="documentUploadB(row)"
                                class="btn p-0 text-left hover-red btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                            </div>
                        </span>
                        <br>
                        <hr>
                    </div>
                </ng-container>
                <p *ngIf="!householdRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
            </div>
        </div>
    </div>

    <div *ngIf="response && response.caseScenario === 'B' && !rows"> 
        <div *ngIf="!success">
            <h5 class="section-header">{{'DOCUMENTS_WE_NEED' | translate }}</h5>
            <hr>
            <p *ngIf="translatedLang === 'en'" class="static-text">We're still reviewing your application so we haven't listed any documents yet.  If we need more information, we'll send you a letter that says what we need and the due date. But do you want to upload documents you think we'll need now?  Click <strong>Upload Proof</strong>.</p>
            <p  *ngIf="translatedLang !== 'en'" class="static-text">Todavía estamos revisando su solicitud, por lo que aún no hemos incluido ningún documento.  Si necesitamos más información, le enviaremos una carta que dice lo que necesitamos y la fecha de vencimiento.  Pero, ¿quiere cargar documentos que piensa que podríamos necesitar ahora?  Haga clic en Cargar comprobante.</p>
        </div>

        <div *ngIf="success">
            <p class="static-text">{{'DOCUMENTS_WE_NEED_STATIC_TEXT1' | translate }}</p>
        
            <p *ngIf="translatedLang === 'en'" class="static-text">But do you want to upload documents you think we'll need now?  Click <strong>Upload Proof</strong>. If you think you need to upload more documents for an application, change, or renewal, select <strong>Add Another Document </strong>.</p>
            <p *ngIf="translatedLang !== 'en'" class="static-text">Pero, ¿quiere cargar documentos que piensa que podríamos necesitar ahora?  Haga clic en Cargar comprobante. Si cree que necesita cargar más documentos para una solicitud, cambio o renovación, seleccione Agregar otro documento</p>
        </div>
        
        <p *ngIf="translatedLang === 'en'" class="static-text">Click <strong>Types of Proof </strong>to see examples of the kinds of documents (or proof) you can use. We may not need all of this information from you. But if you have some of these documents now and want to send them to us, you can.</p>
        <p *ngIf="translatedLang !== 'en'" class="static-text">Haga clic en Tipos de comprobantes para ver ejemplos de los tipos de documentos (o comprobantes) que puede utilizar. Es posible que no necesitemos toda esta información de usted. Pero si tiene algunos de estos documentos ahora y quiere enviárnoslos, puede hacerlo.</p>

        <div style="margin: 0px 0px 32px 0px" class="row pl-1 pt-1 hover-red">
            <a (click)="showProofTypes()">
                <span class="pr-2 popup">{{'Types of Proof' | translate }}</span>
                <i class="fa fa-external-link"></i>
            </a>
        </div>

        <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT2' | translate }}</h5>
        <hr>
        <div class="d-none d-lg-block">
            <ngx-datatable class='bootstrap striped' #table [rows]='householdRows' limit="5" [columnMode]='"force"'
                [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of houseHoldColumns">
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'Status' | translate}}" [width]="160">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <span *ngIf="row.submittedStatus === 'N'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT3' | translate }}</span>
                        <span *ngIf="row.submittedStatus === 'Y'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT4' | translate }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'Action' | translate}}" [width]="245">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary"
                            (click)="documentUploadB(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                        <div class="p-0 hover-red">
                            <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="documentUploadB(row)"
                                class="btn p-0 hover-red text-left btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

        <div class="nh-table-mobile d-lg-none">
            <ng-container *ngIf="householdRows.length">
                <div class="table-row mb-3 pb-3" *ngFor="let row of householdRows">
                    <div class="mobile-table-header">{{houseHoldColumns[0].name | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.hohName}}</div>
                    <br>
                    <div class="mobile-table-header">{{houseHoldColumns[1].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.type}}</div>
                    <br>
                    <div class="mobile-table-header">{{houseHoldColumns[2].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.submissionDate}}</div>
                    <br>
                    <div class="mobile-table-header">{{ 'Status' | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">
                        <span *ngIf="row.submittedStatus === 'N'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT3' | translate }}</span>
                        <span *ngIf="row.submittedStatus === 'Y'">{{'DOCUMENTS_WE_NEED_STATIC_TEXT4' | translate }}</span>
                    </div>
                    <br>
                    <div class="mobile-table-header">{{ 'Action' | translate }}</div>
                    <hr>
                    <span>
                        <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary w-100"
                            (click)="documentUploadB(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                        <div class="p-0 hover-red">
                            <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="documentUploadB(row)"
                                class="btn p-0 text-left hover-red btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                        </div>
                    </span>
                    <br>
                    <hr>
                </div>
            </ng-container>
            <p *ngIf="!householdRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
        </div>
    </div>

    <div *ngIf="bccRows && bccRows.length > 0">
        <h5 class="section-header">{{'DOCUMENTS_WE_NEED_BCC_STATIC1' | translate }}</h5>
        <hr>
        <p [innerHTML]="'DOCUMENTS_WE_NEED_BCC_STATIC3' | translate "></p>
        <div class="d-none d-lg-block">
            <ngx-datatable class='bootstrap striped' #table [rows]='bccRows' [columnMode]='"force"' [limit]="5"
                [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of bccColumns">
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'Information Needed' | translate }}">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <span>{{'DOCUMENTS_WE_NEED_BCC_STATIC2' | translate }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'Due Date' | translate }}">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <span *ngIf="row.verfDueDt">{{row.verfDueDt }}</span>
                        <span *ngIf="!row.verfDueDt">N/A</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'Action' | translate}}" [width]="200">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary"
                            (click)="documentUploadB(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate
                                }}</span></button>
                        <div class="p-0 hover-red">
                            <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="documentUploadB(row)"
                                class="btn p-0 hover-red text-left btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
        <div class="nh-table-mobile d-lg-none">
            <ng-container *ngIf="bccRows.length">
                <div class="table-row mb-3 pb-3" *ngFor="let row of bccRows">
                    <div class="mobile-table-header">{{bccColumns[0].name | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.fullName}}</div>
                    <br>
                    <div class="mobile-table-header">{{bccColumns[1].name | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{row.caseNum}}</div>
                    <br>
                    <div class="mobile-table-header">{{'Information Needed' | translate }}</div>
                    <hr>
                    <div class="mobile-table-data">{{'DOCUMENTS_WE_NEED_BCC_STATIC2' | translate }}</div>
                    <hr>
                    <div class="mobile-table-header">{{'Due Date' | translate }}</div>
                    <br>
                    <div class="mobile-table-data" *ngIf="row.verfDueDt">{{row.verfDueDt }}</div>
                    <div class="mobile-table-data" *ngIf="!row.verfDueDt">N/A</div>
                    <hr>
                    <div class="mobile-table-header">{{ 'Action' | translate }}</div>
                    <hr>
                    <span>
                        <button [disabled]="readTCC" *ngIf="row.submittedStatus === 'N'" class="btn btn-sm btn-secondary w-100"
                            (click)="documentUploadB(row)"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT5' | translate }}</span></button>
                        <div class="p-0 hover-red">
                            <a [class.disabled]="readTCC" *ngIf="row.submittedStatus === 'Y'" (click)="documentUploadB(row)"
                                class="btn p-0 hover-red text-left btn-link"><span>{{'DOCUMENTS_WE_NEED_STATIC_TEXT6' | translate }}</span></a>
                        </div>
                    </span>
                    <br>
                    <hr>
                </div>
            </ng-container>
            <p *ngIf="!bccRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
        </div>
    </div>

    <div *ngIf="response && response.caseScenario === 'C' && !response.bccTreatment && !appealRows">
        <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT15' | translate }}</h5>
        <hr>
        <p class="static-text">{{'DOCUMENTS_WE_NEED_STATIC_TEXT16' | translate }}
        </p>
    </div>

    <div *ngIf="linkMyCase">
        <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT15' | translate }}</h5>
        <hr>
        <p *ngIf="translatedLang === 'en'" class="static-text">Have you already applied and want to upload documents? Be sure you've linked your
            TennCare Connect account to
            your case. Go to <a *ngIf="!readTCC" [routerLink]="['/user-profile', 'case-link']">Link My Case</a><a *ngIf="readTCC" [class.disabled]="readTCC">Link My Case</a>.</p>
        <p *ngIf="translatedLang !== 'en'" class="static-text">¿Ya presentó la solicitud y quiere subir documentos?  Asegúrese de haber vinculado su cuenta de TennCare Connect a su caso.  Vaya a
            <a *ngIf="!readTCC" [routerLink]="['/user-profile', 'case-link']">Vincular mi caso</a><a *ngIf="readTCC" [class.disabled]="readTCC">Vincular mi caso</a>.</p>
    </div>
    <div *ngIf="response && ((response.caseScenario !== 'C' && indiv && response.caseScenario !== null) || response.bccTreatment === true)">
        <h5 class="section-header">{{'DOCUMENTS_WE_NEED_STATIC_TEXT7' | translate }}</h5>
        <hr>
        <p class="static-text">{{'DOCUMENTS_WE_NEED_STATIC_TEXT8' | translate }}
        </p>
        <p><strong>{{'DOCUMENTS_WE_NEED_STATIC_TEXT9' | translate }}</strong>{{'DOCUMENTS_WE_NEED_STATIC_TEXT10' | translate }}</p>
        <p class="static-text"><strong>Fax: </strong>{{'DOCUMENTS_WE_NEED_STATIC_TEXT11' | translate }}</p>
        <p>{{'DOCUMENTS_WE_NEED_STATIC_TEXT12' | translate }}</p>
        <div class="static-text"><strong>{{'Mail: '| translate }}</strong>{{'DOCUMENTS_WE_NEED_STATIC_TEXT13' | translate }}</div>
        <address>
            TennCare<br>
            P.O Box 305240<br>
            Nashville, TN 37230-5240<br>
        </address>
        <p class="static-text">{{'DOCUMENTS_WE_NEED_STATIC_TEXT14' | translate }}
        </p>
    </div>
</div>

<div *ngIf="caseA && clickedRow">
    <nh-doc-upload [row]="clickedRow" [selectData]="referenceData" (docUploadSuccess)="documentUploaded($event)">
    </nh-doc-upload>
</div>
<div *ngIf="caseB && appTrackingNum">
    <nh-proof-upload [appTrackingNum]="appTrackingNum" [caseNumber]="caseNumber" [bccCase]="bccEnable" [kbDataList]="kbList" (docUploadSuccess)="documentUploaded($event)"></nh-proof-upload>
</div>
<div *ngIf="bccEnable && !appTrackingNum">
    <nh-proof-upload [appTrackingNum]="appTrackingNum" [caseNumber]="caseNumber" [bccCase]="bccEnable" [kbDataList]="kbList" (docUploadSuccess)="documentUploaded($event)"></nh-proof-upload>
</div>