import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// import { Subscription } from 'rxjs';
// import { map } from 'rxjs/operators';

import { defaultTo } from 'ramda';

import { ApplicationService } from '@app/services/application/application.service';

// import { readToken } from '@app/helpers/services.helpers';
import { StorageService } from '../storage/storage.service';
import { SigninService } from '@app/gateway/signin/signin.service';
import { ApiService } from '@app/gateway/services/api.service';
import { AuthService } from '@app/gateway/services/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { ChatbotComponent } from '@app/components/chatbot/chatbot.component';
import { ModalService } from '../modal/modal.service';

/**
 * Manage security jwt token
 *
 */
@Injectable()
export class TokenService {
  /**
   * @constructor
   * @param {HttpClient} http Perform HTTP requests.
   *
   * @return {TokenService} TokenService instance
   */
   constructor(private application: ApplicationService, private storage: StorageService, private router: Router, private signinService: SigninService, private authService: AuthService,
    private apiService: ApiService, private modal: ModalService) {}

  /**
   * Get token value
   *
   * @param {void} No params
   *
   * @example
   *  constructor(private token: TokenService) {
   *    const token = this.token.get()
   *  }
   *
   * @returns {string | null} The stored value
   */
  get(): string {
    return defaultTo('', this.storage.getToken());
  }

  /**
   * Set token value
   *
   * @param {string | any} [token] New token value
   *
   * @example
   * constructor(private token: TokenService) {
   *    this.token.set("NEKOT")
   * }
   *
   * @returns {void}
   */
  set(token: string | any): void {
    this.storage.setToken(token);
  }

  /**
   * Refreshes the token from backend
   *
   * @param {void} No params
   *
   * @example
   * constructor(private token: TokenService) {
   *    this.token.refresh()
   * }
   *
   * @returns {Subscription}
   */
  // refresh(): Subscription {
  //   return this.application
  //     .refreshToken()
  //     .pipe(map(readToken))
  //     .subscribe(token => this.set(token));
  // }

  refresh() {
    return this.application.refreshToken().subscribe(res => {
      if (res) {
        const authToken = res.headers.get('x-auth-token');
        this.set(authToken);
        if (res && res?.body == false) {
          this.onSignout();
        }
      }
    })
  }

  onSignout() {
    let mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
    this.modal.closeModal();
    sessionStorage.clear();
    if (ChatbotComponent.chatbotInstance) {
      ChatbotComponent.chatbotInstance.destroy();
    };

    document.cookie = "TCAM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    sessionStorage.setItem('isLogout', 'true');
    const url = 'indi/signout';
    const cx = sessionStorage.getItem('tcam');
    document.cookie = "TCAM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    const headers = new HttpHeaders({ Authorization: this.authService.tokenId, pageId:'TCLGT'});
    this.apiService.post(url, {'tcam': cx }, { headers }).subscribe();
    this.signinService.signOut(false); 
    if(!mobileUser){
      this.router.navigateByUrl('/logout');
    }else{
      this.router.navigateByUrl('/mobile-logout');
    }

  }

  getCookie(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  /**
   * Navigate to Gateway logout route
   *
   * @example
   * constructor(private token: TokenService) {
   *    this.token.signOut()
   * }
   *
   * @returns {void}
   */
  signOut(): void {
    let mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
    if(!mobileUser){
      sessionStorage.clear();
      sessionStorage.setItem('isLogout', 'true');
      this.router.navigateByUrl('/logout');
    } else {
      sessionStorage.clear();
      sessionStorage.setItem('isLogout', 'true');
      this.router.navigateByUrl('/mobile-logout');
    }
    
  }

  /**
   * Navigate to Gateway resume route
   *
   * @example
   * constructor(private token: TokenService) {
   *    this.token.resume()
   * }
   *
   * @returns {void}
   */
  resume(C: 'Y' | null = null): void {
    location.assign(`${environment.gatewayURL}#/resume${C ? `?C=${C}` : ''}`);
  }
}
