import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/gateway/services/api.service";
import { ModalService } from "@app/services/modal/modal.service";
import { SigninService } from '@app/gateway/signin/signin.service';

declare let gtag: Function;
@Injectable({
    providedIn: 'root'
})
export class LinkCaseService {
  caseLinkfrom;
  isCaseLinked;
  selectedItem;
  isError: boolean;
  errorMessage = '';
  showError = false;
  showSuccess = false;
  ssnIndvId = null;
  confirmedSsnSw = 'N';
  confirmedSSN = null;

  constructor(private apiService: ApiService, private router: Router, private modal: ModalService, private signinService : SigninService) {}

  onValueChange(selItem): void {
    switch (selItem) {
      case 'ssn':
        this.caseLinkfrom.controls['individualId'].disable();
        this.caseLinkfrom.controls['wpAppNum'].disable();
        break;
      case 'individualId':
        this.caseLinkfrom.controls['ssn'].disable();
        this.caseLinkfrom.controls['wpAppNum'].disable();
        break;
      case 'wpAppNum':
        this.caseLinkfrom.controls['ssn'].disable();
        this.caseLinkfrom.controls['individualId'].disable();
        break;
    }
  }

  saveCaseLink() {
    this.selectedItem = this.caseLinkfrom.controls['and'].value.code;
    this.linkError();
  }

  linkError(){
    const dateOfBirthFormat = this.caseLinkfrom.controls['dateOfBirth'].value;
    const userdateOfBirth = dateOfBirthFormat.getFullYear()
      + '-' + String(dateOfBirthFormat.getMonth() + 1).padStart(2, '0')
      + '-' + String(dateOfBirthFormat.getDate()).padStart(2, '0');
    const url = "indi/linkmycase";
    const saveLinkCase = {}
    saveLinkCase['dateOfBirth'] = userdateOfBirth;
    if(sessionStorage.getItem("userType") === 'RP'){
      saveLinkCase['usernameOrEmail'] = this.caseLinkfrom.controls['usernameOrEmail'].value;
    }
    saveLinkCase[this.selectedItem] = this.caseLinkfrom.controls[this.selectedItem].value;
    if(this.selectedItem === 'ssn'){
      saveLinkCase['individualId'] = this.ssnIndvId;
      saveLinkCase['confirmedSsn_sw'] = this.confirmedSsnSw;
      saveLinkCase['confirmedSSN'] = this.confirmedSSN;
    }

      this.apiService.post(url, saveLinkCase, {headers: new HttpHeaders({'pageId': 'TCLMC'})}).subscribe((res) => {
        this.isCaseLinked = res;

        this.isError = (res['code'] !== '000');
        // INDV ALREADY LINKED
        if (res['code'] === '200' && res['message'] === 'REQUESTED INDV ALREADY LINKED') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'ERROR_EXISTING_INDIVIDUAL'
          // INDIVIDUAL NOT FOUND or Record Set Empty
        } else if ((res['code'] === '200' || res['code'] === '100') && (res['message'] === 'INVALID APP NUM DOB' || res['message'] === 'RECORD SET EMPTY')) {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'INDIV_NOT_FOUND'
          // APPLICATION PENDING
        } else if ((res['code'] === '200') && res['message'] === 'APPLICATION PENDING') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'APPLICATION_PENDING';
          // All other cases including WP down
        } else if ((res['code'] === '200') && res['message'] === 'Invalid Individual') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'ERROR_PERSON_ID_18';
          //Individual age is less than 18 and not HOH
        } else if ((res['code'] === '300') && res['message'] === 'DCS FOSTER RECORD FOUND') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'NO_LINKING_DCS_FOSTER';

        }  else if (res['code'] === '301') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = false;
            this.showSuccess = false;
          }
          this.isError = false;
          this.ssnIndvId = res['individualId'];
          this.modal.openConfirmSsn(this.caseLinkfrom.controls[this.selectedItem].value).subscribe((modalData) => {
            console.log(modalData);
            this.isError = false;
            if(modalData){
              this.confirmedSsnSw = 'Y';
              this.confirmedSSN = this.caseLinkfrom.controls[this.selectedItem].value;
              this.linkError();
            }else{
              this.ssnIndvId = null;
              this.confirmedSSN = null;
              this.confirmedSsnSw = 'N';
            }
          });

        } else if (sessionStorage.getItem("userType") === 'RP' && (res['code'] === '200') && res['message'] === 'Invalid userName or email') {
          this.showError = true;
          this.showSuccess = false;
          this.errorMessage = 'LINK_CASE_USERNAME_OR_EMAIL_ERROR';
          //Individual age is less than 18 and not HOH
        } else {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'GENERIC_LINKCASE_ERROR_MESSAGE';
          this.showSuccess = false;
        }

        if ((res['code'] === '000') && sessionStorage.getItem("userType") === 'RP') {
          this.showSuccess = true;
          this.showError = false;
          this.errorMessage = 'LINK_CASE_SUCCESS';
        }else if (res['code'] === '000') {
          (res['individualId']) ? sessionStorage.setItem('individualId', res['individualId']) : '';
          gtag('event', 'caseLinked', {
            'event_category': 'Case Linked successfully',
            'event_label': 'Case Linked successfully',
            'value': "success"
          });
          const userName = sessionStorage.getItem('userName');
          this.signinService.getAccountData(userName).subscribe(data => {
            if (data) {
              sessionStorage.setItem('currentUser', JSON.stringify(data));
              sessionStorage.setItem('appNum', data['appNum']);
              sessionStorage.setItem('individualId', data['indvId']);
              sessionStorage.setItem('hoh', data['headOfHousehold']);
              sessionStorage.setItem('firstTimeAccessSw', data['firstTimeAccessSw']);
              this.router.navigateByUrl('user-profile/benefit-dashboard');
            }
          })
        }
      });
  }

  calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  onCancel() {
    this.isError = false;
    return this.router.navigateByUrl('user-profile/manage-account');
  }
}