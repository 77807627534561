export enum ErrorCodes {
  required = 'required',
  minlength = 'minlength',
  minlength_count = 'minlength_count',
  minlength_count_character = 'minlength_count_character',
  maxlength = 'maxlength',
  maxlength_count = 'maxlength_count',
  max = 'max',
  min = 'min',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_APPLICATION_DATE = 'INVALID_APPLICATION_DATE',
  INVALID_DATE_OF_BIRTH_FOR_APPLICATION = 'INVALID_DATE_OF_BIRTH_FOR_APPLICATION',
  INVALID_MIN_DATE = 'INVALID_MIN_DATE',
  INVALID_DATE_OF_SERVICE_MIN_DATE = "INVALID_DATE_OF_SERVICE_MIN_DATE",
  INVALID_MAX_DATE = 'INVALID_MAX_DATE',
  INVALID_LANGUAGE = 'INVALID_LANGUAGE',
  INVALID_CREDITCARD = 'INVALID_CREDITCARD',
  INVALID_EMAIL_ADDRESS = 'INVALID_EMAIL_ADDRESS',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  UNMATCHED_FIELDS = 'UNMATCHED_FIELDS',
  INVALID_EXPENSE = 'INVALID_EXPENSE',
  INVALID_HOUSE_NUMBER = 'INVALID_HOUSE_NUMBER',
  INVALID_UNIT_NUMBER = 'INVALID_UNIT_NUMBER',
  INVALID_NAME = 'INVALID_NAME',
  INVALID_OTHER_EXPENSE = 'INVALID_OTHER_EXPENSE',
  INVALID_TAX_NAME = 'INVALID_TAX_NAME',
  INVALID_INSURANCE_COMPANY_NAME = 'INVALID_INSURANCE_COMPANY_NAME',
  INVALID_NURSING_NAME = 'INVALID_NURSING_NAME',
  INVALID_DESCRIPTION = 'INVALID_DESCRIPTION',
  INVALID_NUMBER = 'INVALID_NUMBER',
  INVALID_PERCENTAGE_DECIMAL = 'INVALID_PERCENTAGE_DECIMAL',
  INVALID_STREET_ADDRESS = 'INVALID_STREET_ADDRESS',
  INVALID_COMMENTS = 'INVALID_COMMENTS',
  DATE_DUE = 'DATE_DUE',
  DATE_NOT_AFTER_MONTH = 'DATE_NOT_AFTER_MONTH',
  DATE_START_AFTER_END = 'DATE_START_AFTER_END',
  DATE_NOT_BEFORE_30_DAYS = 'DATE_NOT_BEFORE_30_DAYS',
  DATE_NOT_BEFORE_90_DAYS = 'DATE_NOT_BEFORE_90_DAYS',
  DATE_NOT_BEFORE_120_YEARS = 'DATE_NOT_BEFORE_120_YEARS',
  BABY_DATE_NOT_GREATER_THAN_1_YEAR = 'BABY_DATE_NOT_GREATER_THAN_1_YEAR',
  PERIOD_BIWEEKLY = 'PERIOD_BIWEEKLY',
  PERIOD_MONTHLY = 'PERIOD_MONTHLY',
  PERIOD_SEMIMONTHLY = 'PERIOD_SEMIMONTHLY',
  PERIOD_WEEKLY = 'PERIOD_WEEKLY',
  INVALID_ZIP_CODE = 'INVALID_ZIP_CODE',
  INVALID_CITY = 'INVALID_CITY',
  INVALID_MEDICARE_NUMBER = 'INVALID_MEDICARE_NUMBER',
  INVALID_FIELD = 'INVALID_FIELD',
  INVALID_VEHICLE_YEAR = 'INVALID_VEHICLE_YEAR',
  LESS_THAN = 'LESS_THAN',
  INVALID_TOP_LEVEL = 'INVALID_TOP_LEVEL',
  INVALID_POLICY_NUMBER = 'INVALID_POLICY_NUMBER',
  ONE_PLAN_REQUIRED = 'ONE_PLAN_REQUIRED',
  INVALID_DOCUMENT_DESCRIPTION = 'INVALID_DOCUMENT_DESCRIPTION',
  EQUITY_VALUE = 'EQUITY_VALUE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  INVALID_I94 = 'INVALID_I94',
  INVALID_ALPHANUMERIC_CHARACTER = 'INVALID_ALPHANUMERIC_CHARACTER',
  INVALID_SAH_ALPHANUMERIC_CHARACTER = 'INVALID_SAH_ALPHANUMERIC_CHARACTER',
  INVALID_ORGANIZATION_NAME = 'INVALID_ORGANIZATION_NAME',
  INVALID_SEVIS_ID = 'INVALID_SEVIS_ID',
  INVALID_ALIEN_NUMBER = 'INVALID_ALIEN_NUMBER',
  INVALID_CARD_NUMBER = 'INVALID_CARD_NUMBER',
  INVALID_CITIZENSHIP_CERTIFICATE = 'INVALID_CITIZENSHIP_CERTIFICATE',
  INVALID_NATURALIZATION_NUMBER = 'INVALID_NATURALIZATION_NUMBER',
  INVALID_CURRENCY = 'INVALID_CURRENCY',
  INVALID_HOURS = 'INVALID_HOURS',
  INVALID_SSN = 'INVALID_SSN',
  INVALID_PASSPORT_NUMBER = 'INVALID_PASSPORT_NUMBER',
  INVALID_DOB = 'INVALID_DOB',
  INVALID_FUTURE_DATE = 'INVALID_FUTURE_DATE',
  INVALID_NUMERIC = 'INVALID_NUMERIC',
  INVALID_PRECENTAGE = 'INVALID_PRECENTAGE',
  DATE_NOT_BEFORE_60_DAYS = 'DATE_NOT_BEFORE_60_DAYS',
  FIVE_MONTHS_MAX = 'FIVE_MONTHS_MAX',
  TWELVE_MONTHS_MAX = 'TWELVE_MONTHS_MAX',
  PAST_DUE_DATE = 'PAST_DUE_DATE',
  INVALID_START_DATE = 'INVALID_START_DATE',
  DUPLICATE_SSN = 'DUPLICATE_SSN',
  MAX_DATE_DUE = 'MAX_DATE_DUE',
  INVALID_USER_NAME = 'INVALID_USER_NAME',
  INVALID_USER_NAME_EMAIL = 'INVALID_USER_NAME_EMAIL',
  INVALID_LENGTH = 'INVALID_LENGTH',
  NO_SPACE = 'NO_SPACE',
  INVALID_ANSWER = 'INVALID_ANSWER',
  PASSWORD_GUIDELINES = 'PASSWORD_GUIDELINES',
  PASSWORDS_DIFFERENT= 'PASSWORDS_DIFFERENT',
  ANSWER_DUPLICATE = 'ANSWER_DUPLICATE',
  EMAIL_MATCH = 'EMAIL_MATCH',
  PASSWORD_CONFIRM = 'PASSWORD_CONFIRM',
  ENTER_APP_NUM = 'ENTER_APP_NUM',
  INVALID_PERSON_ID = 'INVALID_PERSON_ID',
  INVALID_FIRST = 'INVALID_FIRST',
  INVALID_LAST = 'INVALID_LAST',
  INVALID_SSN_NUM = 'INVALID_SSN_NUM',
  INVALID_INDIV= 'INVALID_INDIV',
  DATE_PAST_THREEMONTHS = 'DATE_PAST_THREEMONTHS', 
  POLICY_HOLDER_DOB = 'POLICY_HOLDER_DOB',
  INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
  SSN_CONFIRM= 'SSN_CONFIRM',
  SSN_DIFFERENT= 'SSN_DIFFERENT',
  LINK_SSN_DIFFERENT= 'LINK_SSN_DIFFERENT',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_SUBMITTEDBY = 'INVALID_SUBMITTEDBY',
  INVALID_MEDBILL= 'INVALID_MEDBILL',
  INVALID_JOB_START_DATE = 'INVALID_JOB_START_DATE'
}

export type ErrorCode = keyof typeof ErrorCodes;