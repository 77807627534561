import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule, RecaptchaLoaderService } from 'ng-recaptcha';
import { AppRouting } from './app.routing';
import { AppResolve } from './app.resolve';
import { AppGuard } from './app.guard';

import { NotificationsService, MessagesToken, ErrorsToken } from './services/notification/notification.service';

import { ComponentsModule } from './components/components.module';
import { CongratsModule } from './congrats/congrats.module';
import { GatewayModule } from './gateway/gateway.module';
import { SectionModule } from './section/section.module';
import { StateModule } from './state/state.module';

import { AppComponent } from './app.component';

import { PopoverConfig } from 'ngx-bootstrap/popover';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServiceConfigService } from './services/service-config.service';
import {FileUploadModule} from 'ng2-file-upload';
import { ProvidersModule } from './providers/providers.module';
import { JoyrideModule } from 'ngx-joyride';

import { RecaptchaLanguageLoaderService } from './services/recaptcha-language-loader.service';
import { HeaderComponent } from './components/header/header.component';
import { KeproModule } from './kepro/kepro.module';
import { DatePipe } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function init(config: ServiceConfigService) {
  return () => config.loadAppConfig().then(() =>{
    config.addGtagToHead();
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRouting,
    ComponentsModule,
    SectionModule,
    StateModule,
    CongratsModule,
    FileUploadModule,
    ProvidersModule,
    KeproModule,
    GatewayModule,
    BrowserAnimationsModule,
    JoyrideModule.forRoot(),
    RecaptchaModule
  ],
  exports: [
    TranslateModule,
    RouterModule,
    FileUploadModule
  ],
  providers: [
    DatePipe,
    AppGuard,
    AppResolve,
    { provide: ErrorsToken, useClass: NotificationsService },
    { provide: MessagesToken, useClass: NotificationsService },
    PopoverConfig,
    ServiceConfigService,
    RecaptchaLanguageLoaderService,
    HeaderComponent,
    Title,
    {
      'provide': APP_INITIALIZER,
      'useFactory': init,
      'deps': [ServiceConfigService],
      'multi': true
    },
    {
      provide: RecaptchaLoaderService,
      useClass: RecaptchaLanguageLoaderService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
