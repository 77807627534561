import { equals } from "ramda";
import { Component, AfterContentInit, NgZone, Inject } from "@angular/core";
import { SigninService } from "@app/gateway/signin/signin.service";
import { AuthService } from "@app/gateway/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { ApiService } from "../services/api.service";
import Vs from "@app/services/validators/validators.service";
import { ServiceConfigService } from "@app/services/service-config.service";
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { RecaptchaLanguageLoaderService } from '@app/services/recaptcha-language-loader.service';
import { GoogleAnalyticsService } from "@app/services/analytics/analytics.service";
import { isEmpty } from 'ramda';
import { Title } from "@angular/platform-browser";
import { HttpHeaders } from '@angular/common/http';

enum STEPS {
  ID,
  PASSWORD,
  SECURITY,
}
@Component({
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements AfterContentInit {
  steps = STEPS;
  currentStep: STEPS;
  userInfo: any = {};
  fieldTextType: boolean;
  showError: string;
  siteKey: string;
  isRecaptchaEnabled: boolean;
  loaderReady = false;
  currentLang;
  ppUrl;
  documentsFlyer : boolean;
  renewalFlyer: boolean;
  flyer: boolean

  SignInform = new FormGroup({
    userName: new FormControl('', [Vs.userNameEmail]),
    password: new FormControl(""),
    recaptchaReactive: new FormControl(null, Vs.required),
  });

  constructor(
    private signinService: SigninService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serverConfig: ServiceConfigService,
    public translateService: TranslateService,
    public analytics: GoogleAnalyticsService,
    @Inject(RecaptchaLoaderService)private loader: RecaptchaLanguageLoaderService,
    private zone: NgZone,
    private titleService: Title
  ) {
    this.titleService.setTitle('Signin');
    this.currentStep = STEPS.ID;
    this.ppUrl = this.serverConfig.getServerConfig().partnerPortalUrl;
    this.siteKey = this.serverConfig.getServerConfig().SITE_KEY;
    this.isRecaptchaEnabled = this.serverConfig.getServerConfig().isRecaptchaEnabled;
    this.currentLang =  this.translateService.getDefaultLang();
    translateService.onDefaultLangChange.subscribe((res) => {
      this.currentLang = res.lang;
      res.lang === 'sp' ? this.changeRecaptchaLanguage('es') : this.changeRecaptchaLanguage('en')
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  ngAfterContentInit() {
    if (!this.isRecaptchaEnabled) {
      this.SignInform.removeControl('recaptchaReactive');
    }
    setTimeout(() => {
      const lang = this.translateService.getDefaultLang();
      lang === 'sp' ? this.changeRecaptchaLanguage('es') : this.changeRecaptchaLanguage('en')
    }, 1000);

    this.loader.ready.subscribe(v => {
      setTimeout(() => this.zone.run(() => this.loaderReady = !!v), 0);
    });
  }

  nextStep() {
    if (this.currentStep === STEPS.ID) {
      if (this.userInfo.user) {
        this.signinService
          .signIn(this.userInfo.user)
          .subscribe((userResponse) => {
            if (userResponse) {
              this.authService.user = userResponse;
              this.currentStep = STEPS.PASSWORD;
            }
          });
      }
    } else if (this.currentStep === STEPS.SECURITY) {
    } else {
    }
  }

  signin() {
    sessionStorage.clear();
    this.activatedRoute.queryParams.subscribe(res => {
      console.log(res)
      this.flyer = !isEmpty(res) ? true : false;
      this.documentsFlyer = this.flyer && res.utm_id.includes('qrProjectMydocuments') ? true : false;
      this.renewalFlyer = this.flyer && res.utm_id.includes('qrProjectRenewalscreen') ? true : false;
      console.log(this.documentsFlyer)
    });

    const user = {
      userName: this.SignInform["controls"]["userName"].value,
      password: this.SignInform["controls"]["password"].value,
      language: this.currentLang
    };
    this.signinService.validate(user).subscribe((res) => {
      if (equals(res, "error_login_incorrect")) {
        this.showError = "ERROR_LOGIN";
      } else if (equals(res, "Your account is disabled for a security reason. If you wish to use this account, please reach out to TennCare Support")) {
        this.showError = "ACCOUNT_INACTIVE";
      } else if (equals(res, "Your password has expired. Please click \"Forgot Password\" to reset your password.")) {
        this.showError = "ERROR_INVALID";
      }  else if (equals(res, "Your account is not verified, please check your inbox and click on Email validation link.")) {
        this.showError = "ACCOUNT_VERIFIED";
      } else if (equals(res, "error_account_locked")) {
        this.showError = "ACCOUNT_LOCKED";
      } else if (!this.flyer) {
        // this.idle.start();
        this.analytics.setCurrentUser(user?.userName);
        this.router.navigate(["/", "user-profile"]);
      } else if (this.flyer) {
        this.analytics.setCurrentUser(user?.userName);
        const indiv = sessionStorage.getItem('individualId');
        const hasIndiv = indiv && indiv != 'null' && indiv != '0' ? true : false;
        if (this.documentsFlyer && hasIndiv) {
        this.router.navigate(['user-profile/my-documents', 'documents-we-need'])
        } else if (this.renewalFlyer && hasIndiv) {
          this.router.navigateByUrl('/user-profile/renew-benefits');
        } else {
          this.router.navigate(["/", "user-profile"]);
        }
      }
    });
  }

  resolved(captchaResponse: string) {
    const recaptachRequest = {
      recaptchaResponseToken: captchaResponse,
      chkRecaptcha: "YES",
    };
    const contentHeader = new HttpHeaders({'pageId':'TCLGN'})
    this.apiService
      .post("publ/users/validateRecaptcha", recaptachRequest,{headers: contentHeader})
      .subscribe(() => {});
  }

  changeRecaptchaLanguage(lang) {
    this.loader.updateLanguage(lang);
  }
}