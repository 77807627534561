import { evolve } from 'ramda';
import { HealthCoverageComponent } from './health-coverage.component';
import Vs from '@app/services/validators/validators.service';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { aPath } from '@app/helpers/function.helpers';
import {controlWith} from '@app/helpers/form.helpers';
import { showIndividuals } from '@app/helpers/individual.helpers';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { insuranceInfo, policyHolderName } from '@app/helpers/models.helpers';
import { codeEqual } from '@app/helpers/code.helpers';
import healthCoverageLabels from './health-coverage-labels';

const formValidation = (builder, recordData, {individuals}) => {

  const healthInsurance = lookIn(recordData);
  const houseMember = healthInsurance(['policyHolder', 'houseMember']);
  const policyHolderFirstName = healthInsurance(['policyHolder', 'policyHolderFirstName']);
  const policyHolderLastName = healthInsurance(['policyHolder', 'policyHolderLastName']);
  const dob = toDate(healthInsurance(['policyHolder', 'dobDate']));
  const ssnNum = healthInsurance(['policyHolder', 'ssnNum']);
  const coverageType = healthInsurance(['coverageType']);
  const insuranceCompanyName = healthInsurance(['insuranceCompanyName']);
  const monthlyPremiumAmountForKB = healthInsurance(['monthlyPremiumAmountForKB']);
  const longTermCarePremiumAmount = healthInsurance(['longTermCarePremiumAmount']);
  const groupNumber = healthInsurance(['groupNumber']);
  const policyNumber = healthInsurance(['policyNumber']);
  const policyStartDate = toDate(healthInsurance(['policyStartDate']));
  const stateEmployeeBenfitPlanSwitch = healthInsurance(['stateEmployeeBenfitPlanSwitch']);
  const limitedBenefitPlanSwitch = healthInsurance(['limitedBenefitPlanSwitch']);
  const coverMaternityBenefitsSwitch = healthInsurance(['coverMaternityBenefitsSwitch']);
  const purchaseInsFromYourEmplyerSw = healthInsurance(['purchaseInsFromYourEmplyerSw']);
  const coveredIndividuals = aPath(['coveredIndividualDetails', 'converedIndiv'], recordData);
  const individualsFilter = individuals.some(i => i.age < 18);

  const isCoverageType = codeEqual('EI', coverageType)

  const data = builder.group({
    policyHolder: builder.group({
      houseMember: [houseMember, Vs.required],
      policyHolderFirstName: [policyHolderFirstName, [Vs.maxLength(45), Vs.firsName, Vs.required]],
      policyHolderLastName: [policyHolderLastName, [Vs.maxLength(45), Vs.lastName, Vs.required]],
      dobDate: [dob],
      ssnNum: [ssnNum, [Vs.ssn]]
    }),
    coverageType: [coverageType, Vs.required],
    insuranceCompanyName: [insuranceCompanyName, [Vs.invalidInsuranceCompanyName]],
    monthlyPremiumAmountForKB: [controlWith(monthlyPremiumAmountForKB, !individualsFilter), Vs.currency],
    longTermCarePremiumAmount: [longTermCarePremiumAmount, [Vs.currency, Vs.required]],
    groupNumber: [groupNumber, [Vs.maxLength(16), Vs.unit]],
    policyNumber: [policyNumber, [Vs.maxLength(16), Vs.unit]],
    policyStartDate: [policyStartDate],
    purchaseInsFromYourEmplyerSw: [controlWith(purchaseInsFromYourEmplyerSw, !individualsFilter), Vs.required],
    stateEmployeeBenfitPlanSwitch: [controlWith(stateEmployeeBenfitPlanSwitch, !isCoverageType)],
    limitedBenefitPlanSwitch: [limitedBenefitPlanSwitch],
    coverMaternityBenefitsSwitch: [coverMaternityBenefitsSwitch],
    coveredIndividualDetails: builder.group({
      converedIndiv: builder.array(coveredIndividuals, coveredIndividualValidator())
    })
  })
  return { data };
}

const postObject = evolve({
  policyHolder: {
    dobDate: toISO
  },
  policyStartDate: toISO
});

const  coveredIndividualValidator = ( minRequired = 1 ) => {
  return (formGroup) => {
    let checked = 0
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key]
      if (control.value) {
        checked++
      }
    })
    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true,
      }
    }
    return null
  }
}


export default sectionConfiguration(
  HealthCoverageComponent,
  formValidation,
  ['TAYN', 'TSTS', 'TDSA', 'TACY', 'TSTA', 'TATR','STATE', 'COUNTY', 'TPFT', 'MPPAYFREQ', 'YESNO', 'THIRDPARTYPOLICYTYP'],
  [{ name: 'Policy Holder', pipe: 'domain', args: policyHolderName },
  {
    name: 'Who is Covered',
    prop: 'data.coveredIndividualDetails.converedIndiv',
    pipe: 'domain',
    args: showIndividuals('coveredIndividualNumber'),
  },
  {
    name: 'Insurance Company/ <br> Policy Number',
    prop: 'data',
    pipe: 'domain',
    args: insuranceInfo,
  },
  { name: 'Monthly Premium', prop:'data.monthlyPremiumAmountForKB', pipe: 'currency' },],
  {...{}, ...healthCoverageLabels},
  {
    postObject
  }
);
