<p *ngIf="isRmbRmc && !isAddAnother">{{ 'OTHER_COVERAGE_STATICTEXT14' | translate }}</p> 
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label"> {{ selectedIndividual | fullname }}</h6>
</div>
<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  [topLevelQuestion]="conditionalTopLevelQuestion"
  individualQuestion="{{ this.individualQuestion }}"
  (validate)="onValidate($event)" [topLevelName] = "'emp'">

  <section [formGroup]="getControl('data')">
    <nh-field id = "emp_loseJobSw" [mandatory]="false" label="{{'EMPLOYMENT_STATIC_TEXT1' | translate }}{{selectedIndividual | fullname}} {{'EMPLOYMENT_STATIC_TEXT2' | translate }}"
      class="label-section" *ngIf="isEnabled('data.loseJobSw')">
      <select nh name="loseJobSw" aria-label="loseJobSw" [items]="tableCodeValue('YESNO', 'data.loseJobSw')" formControlName="loseJobSw"></select>
    </nh-field>
    <nh-field id = "emp_loseJobEndDate" label="{{'EMPLOYMENT_STATIC_TEXT3' | translate }} {{ selectedIndividual | fullname }} {{'EMPLOYMENT_STATIC_TEXT4' | translate }}" class="label-section"
      *ngIf="isEnabled('data.loseJobEndDate')">
      <nh-datepicker>
        <input type="text" class="form-control" (change)="isInvalidEndDate($event)" [minDate]="minimumloseJobEndDate" [maxDate]="today"
          name="loseJobEndDate" formControlName="loseJobEndDate" bsDatepicker nh maxlength="10">
      </nh-datepicker>
      <span class="form-text invalid-tooltip d-block" *ngIf="errorMessage">{{'INVALID_JOB_START_DATE' | translate}}</span>
    </nh-field>
  </section>

  <section>
    <fieldset [formGroup]="getControl('data.employer')">
      <h5 class="section-header">
        {{'EMPLOYMENT_STATIC_TEXT5' | translate}}
      </h5>
      <hr>
      <nh-field id = "emp_name" label="{{'EMPLOYMENT_STATIC_TEXT6' | translate }}" class="label-section">
        <input type="text" aria-label="employerName" name="employerName" maxlength="100" class="form-control"
          formControlName="name">
      </nh-field>
      <h5 class="section-header">
        {{'EMPLOYMENT_STATIC_TEXT7' | translate }}
      </h5>
      <hr>

      <nh-alert type="warning"
        [isOpen]="getControl('data.employer.address').touched && isEmpty('data.employer.address.addressLine1') && isEmpty('data.employer.address.city')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'ADDRESS_WARNING' | translate}}</span>
          </div>
        </div>
      </nh-alert>

      <nh-name-address [addressname] = "'emp'" [addressFieldMandatory]="false" [stateDefault]="false" [showPopup]="false" [address]="getControl('data.employer.address')"
        [tables]="tables" [hideCounty]="true"></nh-name-address><br>
      <nh-field id = "emp_phoneNumber" [mandatory]="false" label="{{'EMPLOYMENT_STATIC_TEXT8' | translate }}" class="label-section">
        <input type="text" name="employerphoneNumber" formControlName="phoneNumber" class="form-control"
          mask="000-000-0000" placeholder="XXX-XXX-XXXX">
      </nh-field>
    </fieldset>
    <br>
  </section>
  
  <section [formGroup]="getControl('data')">
    <nh-field id = "emp_startDate" [mandatory]="false" [option]="startDateOption"
      label="{{'WHEN_DID_1' | translate}} {{ selectedIndividual | fullname }} {{'EMPLOYMENT_STATIC_TEXT10' | translate }}" class="label-section">
      <nh-datepicker>
        <input type="text" class="form-control" [minDate]="minDate" [maxDate]="maxJobStartDate" name="startDate" (change)="isInvalidStartDate($event)"
          formControlName="startDate" bsDatepicker nh>
      </nh-datepicker>
    </nh-field>
  </section>
  <h5 class="section-header">
    {{'EMPLOYMENT_STATIC_TEXT11' | translate }}
  </h5>
  <hr>
  <section [formGroup]="getControl('data.wage')">
    <nh-field id = "emp_paymentOption" label="{{'EMPLOYMENT_STATIC_TEXT12' | translate }} {{ selectedIndividual | fullname }}{{'EMPLOYMENT_STATIC_TEXT13' | translate }} " class="label-section">
      <div class=hint>{{'EMPLOYMENT_STATIC_TEXT14' | translate}} {{ selectedIndividual | fullname }} {{'EMPLOYMENT_STATIC_TEXT15' | translate}}
      </div>
      <select nh name="paymentOption" aria-label="paymentOption" [items]="tableCodeValue('MPPAYFREQ', 'extras.helpers.paymentOption')"
        [formControl]="getControl('extras.helpers.paymentOption')" (change)="paymentOptionChange()"></select>
    </nh-field>
  </section>
  <section *ngIf="isEnabled('data.wage.hoursWorkedPeriod')" [formGroup]="getControl('data.wage')">
        <nh-field id = "emp_hoursWorkedPeriod" label="{{'EMPLOYMENT_STATIC_TEXT16' | translate }} {{ selectedIndividual | fullname }} {{'EMPLOYMENT_STATIC_TEXT17' | translate }}" class="label-section">
            
			<input nh type="decimal" maxlength="6" name="hoursWorkedPeriod" formControlName="hoursWorkedPeriod" class="form-control">
        </nh-field>
        <nh-field id = "emp_paymentAmtPerHr" label="{{'EMPLOYMENT_STATIC_TEXT18' | translate }} {{ selectedIndividual | fullname }} {{'EMPLOYMENT_STATIC_TEXT19' | translate }}" class="label-section">
            <nh-input-group prepend="$">
                
				<input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="paymentAmtPerHr" formControlName="paymentAmtPerHr" class="form-control">
            </nh-input-group>
        </nh-field>
    </section>
    <section *ngIf="isEnabled('data.wage.paymentPeriod')" [formGroup]="getControl('data.wage')">
        <nh-field id = "emp_paymentPeriod" label="{{'EMPLOYMENT_STATIC_TEXT20' | translate }} {{ selectedIndividual | fullname }}{{'EMPLOYMENT_STATIC_TEXT21' | translate }}" class="label-section">
      <select nh name="paymentPeriod" aria-label="paymentPeriod" [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.wage.paymentPeriod')"
        formControlName="paymentPeriod"></select>
    </nh-field>
    <nh-field id = "emp_paymentAmountPerPayCheck" label="{{'EMPLOYMENT_STATIC_TEXT22' | translate }} {{ selectedIndividual | fullname }} {{'EMPLOYMENT_STATIC_TEXT23' | translate }} " class="label-section">
      <nh-popover class="pl-1" [content]="payCheckText"></nh-popover>
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="paymentAmountPerPayCheck" formControlName="paymentAmountPerPayCheck" class="form-control">
      </nh-input-group>
        </nh-field>
    </section>
</nh-form>