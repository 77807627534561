import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarConfig, ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { SidebarModule } from 'ng-sidebar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// Layout
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { IdBarComponent } from './id-bar/id-bar.component';
import { PageTitleComponent } from './page-title/page-title.component';

// Modals
import { ConfirmModalComponent } from './modals/confirm/confirm.component';
import { ConfirmAppealModalComponent } from './modals/confirm-appeal/confirm-appeal.component';
import { PeopleDeleteModalComponent } from '@app/components/modals/people-delete/people-delete.component';
import { FeedbackSurveyModalComponent } from './modals/feedback-survey/feedback-survey.component';
import { ReportAbuseModalComponent } from './modals/report-abuse/report-abuse.component';
import { CommentsModalComponent } from './modals/comments/comments.component';
import { TimeoutModalComponent } from './modals/timeout/timeout.component';
import { IncomeDeductionModalComponent       } from './modals/income-deduction-modal/income-deduction-modal.component';
import { AnalyticsModalComponent } from './modals/analytics/analytics.component';
import { QuestionnaireModalComponent } from './modals/questionnaire/questionnaire.component';
import { InfoMessagesModalComponent } from './modals/info-messages/info-messages.component';
import { PrimaryCaregiversModalComponent } from './modals/primary-caregivers/primary-caregivers.component';
import { AssistingPersonTypesModalComponent } from './modals/assisting-person-types/assisting-person-types.component';
import { LiquidTypesModalComponent } from './modals/liquid-types/liquid-types.component';
import { BurialResourcesModalComponent } from './modals/burial-resources/burial-resources.component';
import { TypesOfProofsComponent } from './modals/types-of-proofs/types-of-proofs.component';
import { ConfirmSsnModalComponent } from './modals/confirm-ssn/confirm-ssn.component';

// Reusable
import { ButtonBarComponent } from './button-bar/button-bar.component';
import { ContactPhonesComponent } from './contact-phones/contact-phones.component';
import { CountComponent } from './count/count.component';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';
import { FieldComponent } from './field/field.component';
import { FormComponent } from './form/form.component';
import { InputGroupComponent } from './input-group/input-group.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { NameAddressComponent } from './name-address/name-address.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchComponent } from './search/search.component';
import { PopoverComponent } from './popover/popover.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StepperComponent } from './stepper/stepper.component';
import { TableComponent } from './table/table.component';
import { TabsetComponent } from './tabset/tabset.component';
import { TopLevelComponent } from './top-level/top-level.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { CheckboxDropdownListComponent } from '@app/components/checkbox-dropdown-list/checkbox-dropdown-list.component';
import { CheckboxListComponent } from './checkbox-list/checkbox-list.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CityStateComponent } from './city-state/city-state.component';
import { BlockTitleComponent } from './block-title/block-title.component';
import { SectionInfoComponent } from './section-info/section-info.component';
import { AlertComponent } from './alert/alert.component';
import { AddressValidationComponent } from './modals/address-validation/address-validation.component';

import { DirectivesModule } from '@app/directives/directives.module';
import { PipesModule } from '@app/pipes/pipes.module';
import { TranslateModule } from "@ngx-translate/core";
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SaveExitModalComponent } from './modals/save-exit/save-exit.component';
import { ReviewSummaryTableComponent } from './review-summary-table/review-summary-table.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { EndCoverageSummaryModalComponent } from './modals/end-coverage-summary-modal/end-coverage-summary-modal.component';
import { CoverageTutorialModalComponent } from './modals/coverage-tutorial-modal/coverage-tutorial-modal.component';
import { JoyrideModule } from 'ngx-joyride';
import { RecordDeleteModalComponent } from './modals/record-delete/record-delete.component';
import { RemoveSvgComponent } from './remove-svg/remove-svg.component';
import { CoOwnerTableComponent } from './co-owner-table/co-owner-table.component';
import { AddSvgComponent } from './add-svg/add-svg.component';
import { KatieBeckettModalComponent } from './modals/katie-beckett/katie-beckett.component';
import { ExitSvgComponent } from './exit-svg/exit-svg.component';
import { BrowserIncompatibleModalComponent } from './modals/browser-incompatible-modal/browser-incompatible-modal.component';
import { HealthPlansComponent } from '@app/components/modals/health-plans/health-plans.component';
import { HealthPlanConfirmModalComponent } from './modals/health-plan-confirm/health-plan-confirm.component';
import { AppealEligibilityModalComponent } from './modals/appeal-eligibility/appeal-eligibility.component';
import { ReportChangeModalComponent } from './modals/report-change-modal/report-change-modal.component';
import { DuplicateContinuanceAppealModalComponent } from './modals/duplicate-continuance-appeal/continuance-appeal.component';
import { DuplicateWithdrawlAppealModalComponent } from './modals/duplicate-appeal-withdrawl/duplicate-appeal-withdrawl.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { NudgeComponent } from './nudge/nudge.component';
import { DuplicateTaxDepOrJointFilerComponent } from './modals/duplicate-taxdep-or-jointfiler/duplicate-taxdep-or-jointfiler.component';
import { mobileFAQComponent } from './modals/mobileFAQ/mobileFAQ.component';
import { ConfirmHohComponent } from './modals/confirm-hoh/confirm-hoh.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        SidebarModule.forRoot(),
        PopoverModule.forRoot(),
        AlertModule.forRoot(),
        ProgressbarModule.forRoot(),
        ButtonsModule.forRoot(),
        NgxDatatableModule,
        DirectivesModule,
        PipesModule,
        TranslateModule,
        FileUploadModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatSortModule,
        JoyrideModule.forChild()
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        KatieBeckettModalComponent,
        FeedbackComponent,
        IdBarComponent,
        PageTitleComponent,
        ReportAbuseModalComponent,
        SaveExitModalComponent,
        FeedbackSurveyModalComponent,
        CommentsModalComponent,
        ConfirmModalComponent,
        ConfirmAppealModalComponent,
        PeopleDeleteModalComponent,
        IncomeDeductionModalComponent,
        ButtonBarComponent,
        ContactPhonesComponent,
        CountComponent,
        ErrorMessagesComponent,
        FieldComponent,
        FormComponent,
        InputGroupComponent,
        ContactInfoComponent,
        NameAddressComponent,
        NotFoundComponent,
        PopoverComponent,
        SearchComponent,
        SpinnerComponent,
        StepperComponent,
        TableComponent,
        TabsetComponent,
        TopLevelComponent,
        DatepickerComponent,
        CheckboxDropdownListComponent,
        CheckboxListComponent,
        CheckboxComponent,
        NotificationsComponent,
        CityStateComponent,
        BlockTitleComponent,
        TimeoutModalComponent,
        AnalyticsModalComponent,
        QuestionnaireModalComponent,
        InfoMessagesModalComponent,
        SectionInfoComponent,
        AlertComponent,
        PrimaryCaregiversModalComponent,
        AssistingPersonTypesModalComponent,
        LiquidTypesModalComponent,
        BurialResourcesModalComponent,
        AddressValidationComponent,
        HealthPlansComponent,
        TypesOfProofsComponent,
        FileUploadComponent,
        ReviewSummaryTableComponent,
        ChatbotComponent,
        EndCoverageSummaryModalComponent,
        CoverageTutorialModalComponent,
        ReportChangeModalComponent,
        RecordDeleteModalComponent,
        RemoveSvgComponent,
        CoOwnerTableComponent,
        AddSvgComponent,
        ExitSvgComponent,
        BrowserIncompatibleModalComponent,
        HealthPlanConfirmModalComponent,
        AppealEligibilityModalComponent,
        DuplicateContinuanceAppealModalComponent,
        DuplicateWithdrawlAppealModalComponent,
        NudgeComponent,
        DuplicateTaxDepOrJointFilerComponent,
        mobileFAQComponent,
        ConfirmHohComponent,
        ConfirmSsnModalComponent
    ],
    exports: [
        // Modules
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        ModalModule,
        SidebarModule,
        PopoverModule,
        AlertModule,
        ProgressbarModule,
        ButtonsModule,
        NgxDatatableModule,
        DirectivesModule,
        PipesModule,
        // Components
        HeaderComponent,
        FooterComponent,
        FeedbackComponent,
        IdBarComponent,
        PageTitleComponent,
        ReportAbuseModalComponent,
        SaveExitModalComponent,
        FeedbackSurveyModalComponent,
        CommentsModalComponent,
        ConfirmModalComponent,
        ConfirmAppealModalComponent,
        PeopleDeleteModalComponent,
        ButtonBarComponent,
        ContactPhonesComponent,
        CountComponent,
        ErrorMessagesComponent,
        KatieBeckettModalComponent,
        FieldComponent,
        FormComponent,
        InputGroupComponent,
        ContactInfoComponent,
        NameAddressComponent,
        NotFoundComponent,
        PopoverComponent,
        SearchComponent,
        SpinnerComponent,
        StepperComponent,
        TableComponent,
        TabsetComponent,
        TopLevelComponent,
        DatepickerComponent,
        CheckboxDropdownListComponent,
        CheckboxListComponent,
        CheckboxComponent,
        NotificationsComponent,
        CityStateComponent,
        BlockTitleComponent,
        TimeoutModalComponent,
        AnalyticsModalComponent,
        QuestionnaireModalComponent,
        InfoMessagesModalComponent,
        SectionInfoComponent,
        AlertComponent,
        AddressValidationComponent,
        HealthPlansComponent,
        FileUploadComponent,
        ReviewSummaryTableComponent,
        ChatbotComponent,
        RecordDeleteModalComponent,
        RemoveSvgComponent,
        IncomeDeductionModalComponent,
        CoOwnerTableComponent,
        AddSvgComponent,
        ExitSvgComponent,
        HealthPlanConfirmModalComponent,
        AppealEligibilityModalComponent,
        DuplicateContinuanceAppealModalComponent,
        DuplicateWithdrawlAppealModalComponent,
        NudgeComponent,
        DuplicateTaxDepOrJointFilerComponent,
        mobileFAQComponent,
        ConfirmSsnModalComponent
    ],
    providers: [BsDatepickerConfig, ProgressbarConfig]
})
export class ComponentsModule { }
