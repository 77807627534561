<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left" id="my-modal-title">{{'REPORT_CHANGE_POPUP_TITLE' | translate}}</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
            <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="'REPORT_CHANGE_POPUP_DESC' | translate"></p>
    </div>
    <div class="modal-footer d-block">
        <div class="action-container" >
            <button type="button" id="dialog" class="btn btn-block btn-secondary btn-start" (click)="updateMyInformation()" [disabled]="readTCC">{{'UPDATE_MY_INFORMATION' | translate}}</button>
        </div>
    </div>
</div>
