import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { minus120Years } from "@app/helpers/date.helpers";
import { FormControl, FormGroup } from "@angular/forms";
import Vs from "@app/services/validators/validators.service";
import { ApplicationService } from "@app/services/application/application.service";
import { Router } from "@angular/router";
import { StateService } from "@app/services/state/state.service";
import { StorageService } from "@app/services/storage/storage.service";
import { DatePipe } from "@angular/common";
import { ApiService } from "@app/gateway/services/api.service";
import { Title } from '@angular/platform-browser';
import { SSNPipe } from "@app/pipes/ssn/ssn.pipe";


@Component({
  selector: 'nh-coverage-search',
  templateUrl: './coverage-search.component.html',
  styleUrls: ['./coverage-search.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CoverageSearchComponent implements OnInit {
  coverageSearchForm = new FormGroup({
    firstName: new FormControl(null, [Vs.required, Vs.maxLength(15), Vs.invalidName, Vs.firsName]),
    lastName: new FormControl(null, [Vs.required, Vs.maxLength(15), Vs.invalidName, Vs.lastName]),
    ssn: new FormControl(null, [Vs.required, Vs.ssn]),
    dateOfBirth: new FormControl(null, Vs.required),
    applicantBehalf: new FormControl(null, Vs.required)
  });
  minimumDate = minus120Years();
  today: any = new Date();
  columns = [
    { name: "Name", prop: "Name" },
    { name: "SSN", prop: "ssn" },
    { name: "Date of Birth", prop: "dob" },
    { name: "Status", prop: "status" }
  ];
  rows;
  heading1;
  heading2;
  heading3;
  heading4;
  isSearchCompleted = false;
  constructor(
    private router: Router,
    private stateService: StateService,
    protected storageService: StorageService,
    protected application: ApplicationService,
    private datePipe: DatePipe,
    public apiService: ApiService,
    private titleService: Title,
    private ssnPipe: SSNPipe
  ) {
    this.titleService.setTitle('Provider Search');
  }

  getCookie(name) {
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  ngOnInit() {
    this.heading1 = 'Apply For Full Coverage';
    this.heading2 = 'Search Criteria';
    this.heading3 = `Search for the Head of Household below to see if a call center worker has already started an application.`;
    this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household. I understand and have explained to the patient that any updates made may also impact coverage for other people in their household.`
  }

  onSearch() {
    const dob = this.datePipe.transform(this.coverageSearchForm.controls["dateOfBirth"].value, "MM/dd/yyyy");
    const ssn = this.coverageSearchForm.controls["ssn"].value;
    const firstName = this.coverageSearchForm.controls["firstName"].value;
    const lastName = this.coverageSearchForm.controls["lastName"].value;
    const postObj = {
      firstName: firstName,
      lastName: lastName,
      ssn: ssn,
      dob: dob,
      applicationType: "APPLY_BENEFITS",
    };
    sessionStorage.setItem('AfbAHSSearch', JSON.stringify(postObj));
    this.application.getPendingApplications(null, null,'TCPCS', postObj).subscribe((res: any) => {
      if (res?.AFB?.length > 0) {
        const hohDetails = res?.AFB[0]?.headOfHousehold;
        if (hohDetails) {
          this.isSearchCompleted = true;
          this.rows = [{
            Name: hohDetails.headOfHousehold,
            ssn: this.ssnPipe.ssnTransform(hohDetails.ssn),
            dob: this.datePipe.transform(hohDetails.dob, "MM/dd/yyyy"),
            status: 'In Progress'
          }];
        }
      } else {
        this.rows = [];
      }
    });
  }

  onPrevious() {
    this.router.navigateByUrl('api/prov/kepro?mpToken=' + this.apiService.getAhsMpToken());
  }

  resumeAFB() {
    this.router.navigateByUrl("start/welcomeback");
  }

  startApp(appType) {
    this.stateService.resetStates();
    this.storageService.setApplicationType(appType);
    this.router.navigateByUrl('/application/' + 'NEW');
    if (sessionStorage.getItem('AfbAHSSearch')) {
      sessionStorage.removeItem('AfbAHSSearch');
    }
  };
}
