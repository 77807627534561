import { Component, Input, OnInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { proofData } from './types-of-proof-data';

@Component({
  templateUrl: './types-of-proofs.component.html',
  styleUrls: ['./types-of-proofs.component.scss']
})
export class TypesOfProofsComponent extends BaseModalDirective implements OnInit {
  @Input() public cmbCaseIndvData: any;
  data: any = [];
  incomeProofColA = [];
  incomeProofColB = [];
  generalHeader: '';
  generalProof: [];
  otherProof: '';
  listAHeader: '';
  listAProof: [];
  listBHeader: '';
  listBProof: [];
  staticTextDisplay: boolean = false;
  listARes = false;
  listBRes = false;
  listAFin = false;
  listBFin = false;
  listCFin = false;

  ngOnInit() {
    if (this.cmbCaseIndvData.length > 0) {
      this.staticTextDisplay = false;
      const popUpData = [...proofData];
      this.listARes = this.cmbCaseIndvData.includes(['RDPR', 'NHPR', 'MFPR']);
      this.listBRes = this.cmbCaseIndvData.includes(['DCSR']);
      this.listAFin = this.cmbCaseIndvData.includes(['KA']);
      this.listBFin = this.cmbCaseIndvData.includes(['RSA']);
      this.listCFin = this.cmbCaseIndvData.includes(['SMF']);
      this.cmbCaseIndvData.forEach((item) => {
        let popupArray = popUpData.filter(tableDisplayed => (tableDisplayed.code.includes(item)));
        let popupUniqueArray = popupArray.filter((c, index) => {
          return popupArray.indexOf(c) === index;
        });
        if (popupUniqueArray.length && !this.data.includes(popupUniqueArray[0])) {
          this.data.push(popupUniqueArray[0]);
        }
      });
    } else {
      this.staticTextDisplay = true;
      this.data = [...proofData];
      this.listARes = true;
      this.listBRes = true;
      this.listAFin = true;
      this.listBFin = true;
      this.listCFin = true;
      this.data = this.data .filter(tableDisplayed => (!tableDisplayed.code.includes("QKPR")));
      this.data = this.data .filter(tableDisplayed => (!tableDisplayed.code.includes("BCPR")));
    }
    this.incomeProofData();
    this.citizenshipProofData();
  }

  incomeProofData() {
    this.data.forEach(incmPrf => {
      if (incmPrf.key == 'TYPES_OF_PROOF_STATIC6') {
        this.incomeProofColA = incmPrf.value.slice(0, 5);
        this.incomeProofColB = incmPrf.value.slice(5,11);
      }
    })
  }

  citizenshipProofData() {
    this.data.forEach(citizenshipProof => {
      if (citizenshipProof.key == 'TYPES_OF_PROOF_STATIC7') {
        this.generalHeader = citizenshipProof.value.general.generalHeader;
        this.generalProof = citizenshipProof.value.general.generalProof;
        this.otherProof = citizenshipProof.value.otherProof;
        this.listAHeader = citizenshipProof.value.listA.listAHeader;
        this.listAProof = citizenshipProof.value.listA.listAProof;
        this.listBHeader = citizenshipProof.value.listB.listBHeader;
        this.listBProof = citizenshipProof.value.listB.listBProof;
      }
    })
  }
}
