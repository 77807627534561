import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationService } from "@app/services/application/application.service";
import { toISO, toMiddleEndian } from "@app/helpers/date.helpers";
import { minus120Years } from "@app/helpers/date.helpers";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import Vs from "@app/services/validators/validators.service";
import { map, switchMap } from "rxjs/operators";
import { StorageService } from '@app/services/storage/storage.service';
import { ModalService } from '@app/services/modal/modal.service';
import { DatePipe } from '@angular/common';
import { equals, isNil, contains } from 'ramda';
import { StateService } from '@app/services/state/state.service';
import { ApiService } from '@app/gateway/services/api.service';
import { addDays } from 'date-fns';
import { of } from 'rxjs';
import { isNotNil } from 'ramda-adjunct';
import { HttpHeaders  } from '@angular/common/http';
@Component({
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SearchComponent implements OnInit {
  constructor( 
    private application: ApplicationService,
    private apiService: ApiService,
    private router: Router,
    protected storageService: StorageService,
    private modal: ModalService,
    private datePipe: DatePipe,
    private stateService: StateService,
    public activatedRoute: ActivatedRoute,
  ) {}

  options;
  suffixValue;
  search = false;
  isMedicaidEligible;
  applicationNum;
  nextDisaled = true;
  heading1;
  heading2;
  heading3;
  heading4;

  searchForm = new FormGroup({
    firstName: new FormControl(null, [Vs.required, Vs.maxLength(15), Vs.invalidName, Vs.firsName]),
    middleInitial: new FormControl(null),
    lastName: new FormControl(null, [Vs.required, Vs.maxLength(15), Vs.invalidName, Vs.lastName]),
    suffix: new FormControl(null),
    dateOfBirth: new FormControl(null, Vs.required),
    ssn: new FormControl(null, [ Vs.required, Vs.ssn]),
    personID: new FormControl(null, [Vs.required, Vs.personId]),
    select: new FormControl(null)
  });

  minimumDate = minus120Years();
  today: any = new Date();
  columns = [
    { name: "Name", prop: "Name" },
    { name: "Person ID", prop: "personId" },
    { name: "Date of Birth", prop: "dob" },
    { name: "Address", prop: "address" },
  ];
  rows: any;
  limit = 10;
  selected = [];
  result: any = [];
  isNewborn = false;
  showSearch = false;
  newBornOrAdmitDischarge = false;
  get showSearchByNameMessage() {
    const { firstName, lastName, dateOfBirth} = this.searchForm.controls;
    return (firstName.value || lastName.value || dateOfBirth.value) &&
        !(firstName.value && lastName.value && dateOfBirth.value)
  }

  get isFormValid() {
    const { firstName, lastName, dateOfBirth, ssn, personID } = this.searchForm.controls;
    return (
      (firstName.valid && lastName.valid && dateOfBirth.valid)
      ||
      ssn.valid
      ||
      personID.valid
    )
  }

  onPrevious() {
    if (this.searchForm.pristine) {
      this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      return;
    }
    this.modal.confirmDeactivate().subscribe(resp => {
      if (resp) {
        this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      }
    });
  }

  startAppUrl = () => this.router.navigateByUrl('/application/' + 'NEW');

  selectedOption(event) {
    const firstChar = event.target.value[0];    
    const xc = this.options.find(item => item.code === firstChar);
    this.suffixValue = ({ code: xc.code, value: xc.enDescription });
  }

  startApp(appType) {
    this.stateService.resetStates();
    this.storageService.setApplicationType(appType);
    this.router.navigateByUrl('/application/' + 'NEW');  
  }

  startApplication(row?) {
    this.stateService.resetStates();
    if (this.isNewborn && isNotNil(row)) {
      const { personId } = row;
      console.log(row);
      const appType = row?.flagStatus === 'NEWBORN' ? 'NBN' : row?.flagStatus;
      this.storageService.setApplicationType(appType);
      sessionStorage.setItem('individualId', personId);
    } else {
      row = this.selected[0];
      this.storageService.setApplicationType('ADF');
    }

    const { caseNumber, renewalSw, ssiSw, fosterCareSw } = row;
    this.newBornOrAdmitDischarge = (row.flagStatus === "ADF" || row.flagStatus === "NEWBORN") ? true : false;
    sessionStorage.setItem('isNewApp', 'true');
    sessionStorage.setItem('caseNumber', caseNumber);

    if (this.isNewborn && this.newBornOrAdmitDischarge) {
      sessionStorage.setItem('renewalSw', renewalSw);
      sessionStorage.setItem('ssiSw', ssiSw);
      sessionStorage.setItem('fosterCareSw ', fosterCareSw );
      
      this.application.getHeadOfHouseholdInfo(caseNumber, 'NEW').subscribe((res: any) => {
        if (res && res?.applicationNumber) {
          this.application.deleteApp(this.applicationNum, 'TCPSM').subscribe(() => {
            this.startAppUrl();
          })
        } else {
          this.startAppUrl();
        }
      })
    } else {
      this.startAppUrl();
    }
  }

  onSearch() {
    const { firstName, middleInitial, lastName, dateOfBirth, ssn, personID } = this.searchForm.controls;
    const postObj = {
      firstName: firstName.value,
      middleInitial: middleInitial.value,
      lastName: lastName.value,
      suffix: this.suffixValue,
      dateOfBirth: toISO(dateOfBirth.value),
      ssn: ssn.value,
      individualId: personID.value,
    };

    this.result = [];

    const keyWord = this.isNewborn ? 'newbornsearch' : 'admitdischargesearch';
    this.rows = this.application.search(keyWord, postObj).pipe(
      switchMap((res) => {
        return of([res])
      })
    ).pipe(map((resultRes: any) => {
      const res = resultRes[0];

      if (this.isNewborn) {
        this.result = res.map((searchData) => {
          const birthDate = this.datePipe.transform(searchData.dateOfBirth, "MM/dd/yyyy");
          const flagStatus = searchData.flagStatus;
          // sessionStorage.setItem('individualId', searchData.individualId);
          const appTypeText = 
              equals(flagStatus, 'HPE' ) ? 'Apply for Hospital Presumptive Eligibility' :
                  equals(flagStatus, 'PRG' ) ? 'Apply for Presumptive Pregnancy Application' :
                      equals(flagStatus, 'BCC' ) ? 'Apply for Presumptive BCC Application' :
                          equals(flagStatus, 'AFB' ) ? 'Apply for Full Coverage' :
                              equals(flagStatus, 'NEWBORN' ) ? 'Add a Newborn' :
                                  equals(flagStatus, 'ADF' ) ? 'Apply for Admit/Discharge Form' : '';
          return {
            Name: searchData.firstName + ' ' + searchData.lastName,
            personId: searchData.individualId,
            dob: birthDate,
            address: searchData.address,
            select: true,
            gender: searchData.genderCd,
            ssn: searchData.ssn,
            caseNum: searchData.appDetailsType[0].caseNum,
            appDetailsType : searchData.appDetailsType,
            flagStatus: flagStatus,
            appTypeText: appTypeText,
            ssiSw: searchData.ssiSw,
            fosterCareSw: searchData.fosterCareSw,
            renewalSw: searchData.renewalSw,
            caseNumber: (flagStatus === "ADF" || flagStatus === "NEWBORN") ? searchData.caseNumber : 0,
          };
        });
  
        this.showSearch = true;
        return this.result;
      } else {
        this.result = res.map((searchData) => {
          const flagStatus = searchData.flagStatus;
          const birthDate = this.datePipe.transform(searchData.dateOfBirth, "MM/dd/yyyy");
          return {
              Name: searchData.firstName + ' ' + searchData.lastName,
              personId: searchData.individualId,
              dob: birthDate,
              address: searchData.address,
              select: true,
              middleInitial: searchData.midName,
              suffix: searchData.sufxName,
              gender: searchData.genderCd,
              ssn: searchData.ssn,
              caseNum: searchData.appDetailsType[0].caseNum,
              appDetailsType : searchData.appDetailsType,
              flagStatus: flagStatus,
              renewalSw: searchData.renewalSw,
              caseNumber: searchData.caseNumber,
              isMedicaidEligible: this.checkMedicaidEligiblility(searchData, res)
          };
        });

      this.showSearch = true;
      this.isMedicaidEligible = false;
      if (this.result === undefined || this.result.length === 0) {
        this.search = true;
      } else if (this.result !== undefined || this.result.length > 0) {
        this.search = false;
      }
      return this.result;
      }
    }));
  }

  checkMedicaidEligiblility(item, searchIndv) {
    let medicaidEligible = false;
    let tempItem = [];
    if (item && item.appDetailsType !== undefined) {
      tempItem = item;
    } else if (item.appDetailsType === undefined) {
      tempItem = item[0];
    }
        for (let i = 0; tempItem['appDetailsType'].length > i; i ++) {
          const appDetailsType = tempItem['appDetailsType'][i];
          if (appDetailsType.coeCodes && appDetailsType.coeCodes.length > 0) {
            for (let j = 0;  appDetailsType.coeCodes.length > j; j ++) {
              const coeCodes = appDetailsType.coeCodes[j]
              const acceptedList = ['L01', 'L02', 'W01', 'SSI'];
              if (contains(coeCodes.coeCode, acceptedList)) {
                medicaidEligible = true;
              }
            }
          }
        }
        if (searchIndv.length > 0 && searchIndv[0]?.programStatus?.length && searchIndv[0]?.programStatus[0]?.edgStatus != 'AP') {
          medicaidEligible = false;
        }
    return medicaidEligible;
  }

  onLoad(response) {
    if (response?.firstName) {
      const birthDate = addDays(new Date(response.dob), 1);
      const { firstName, lastName, dateOfBirth} = this.searchForm.controls;
      firstName.setValue(response.firstName);
      lastName.setValue(response.lastName);
      dateOfBirth.setValue(birthDate);
      this.onSearch();
    }
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search)
    if (!sessionStorage.getItem('tokenId') && !urlParams.get('mpToken')) {
      this.router.navigateByUrl('/logout');
      return;
    }
    this.isNewborn = this.router.routerState.snapshot.url.endsWith('NBN');

    if (this.isNewborn) {
      this.heading1 = 'Add a Newborn Member Search';
      this.heading2 = 'Search for a Mother';
      this.heading3 = `Please use the fields below to tell us about this newborn. If the mother gave birth to more than one newborn, complete this page first.`;
      this.heading4 = `If you need to tell us about a Safe Haven Baby, you must use the Birth Reporting Form and fax or mail the form to TennCare Connect. `
    } else {
      this.activatedRoute.queryParams.subscribe(res => this.onLoad(res));
      this.heading1 = 'Admit/Discharge Form Member Search';
      this.heading2 = 'Search Criteria';
      this.heading3 = `Please use the criteria below to search for a member who is currently receiving TennCare Institutional Medicaid coverage or both TennCare Medicaid and SSI Cash to see if you can submit an admit/discharge Form 2350 for this person.`;
    }

    const url = 'publ/reference-tables/retrieve?table=NAMESUFFIX'; 
    this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCPSM'})}).subscribe((res) => this.options = [...res['columnDetails']]);
  };

  clearCtrls = ctrl => {
    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
  };

  validateName(event) {
    const { firstName, lastName, dateOfBirth, personID, ssn } = this.searchForm.controls;

    if (firstName.valid && lastName.valid && dateOfBirth.valid) {
      this.clearCtrls(ssn);
      this.clearCtrls(personID)
    } else {
      ssn.setValidators([Vs.required, Vs.ssn]);
      ssn.updateValueAndValidity();
      personID.setValidators([Vs.required, Vs.personId]);
      personID.updateValueAndValidity();
    }

    if (dateOfBirth.invalid && !isNil(dateOfBirth.value)) {
      if (event.target.ariaLabel === 'dateOfBirth') {
        event.target.value = toMiddleEndian(dateOfBirth.value);
      }
    } 
  }

  validateSsn() {
    const { firstName, lastName, dateOfBirth, personID, ssn } = this.searchForm.controls;

    if (ssn.valid) {
      this.clearCtrls(personID);
      this.clearCtrls(firstName);
      this.clearCtrls(lastName);
      this.clearCtrls(dateOfBirth);
    } else {
      firstName.setValidators([Vs.required, Vs.invalidName]);
      firstName.updateValueAndValidity();
      lastName.setValidators([Vs.required, Vs.invalidName]);
      lastName.updateValueAndValidity();
      dateOfBirth.setValidators([Vs.required]);
      dateOfBirth.updateValueAndValidity();
      ssn.setValidators([Vs.required, Vs.ssn]);
      ssn.updateValueAndValidity();
      personID.setValidators([Vs.required, Vs.personId]);
      personID.updateValueAndValidity();
    }
  }

  validateIndvId() {
    const { firstName, lastName, dateOfBirth, personID, ssn } = this.searchForm.controls;

    if (personID.valid) {
      this.clearCtrls(ssn);
      this.clearCtrls(firstName);
      this.clearCtrls(lastName);
      this.clearCtrls(dateOfBirth)
    } else {
      firstName.setValidators([Vs.required, Vs.invalidName]);
      firstName.updateValueAndValidity();
      lastName.setValidators([Vs.required, Vs.invalidName]);
      lastName.updateValueAndValidity();
      dateOfBirth.setValidators([Vs.required]);
      dateOfBirth.updateValueAndValidity();
      ssn.setValidators([Vs.required, Vs.ssn]);
      ssn.updateValueAndValidity();
      personID.setValidators([Vs.required, Vs.personId]);
      personID.updateValueAndValidity();
    }
  }

  onSelect($event) {
    this.selected.splice(0, this.selected.length);
    this.nextDisaled = true;

    if ($event.selected && $event.selected.length &&  !$event.selected[0].isMedicaidEligible) {
      return;
    }

    this.selected.push(...$event.selected);
    if (this.selected.length > 0) {
        const firstName = this.selected[0].Name.split(' ').slice(0, -1).join(' ');
        const lastName = this.selected[0].Name.split(' ').slice(-1).join(' ');
        const dateOfBirth = this.selected[0].dob;
        sessionStorage.setItem("firstName", firstName);
        sessionStorage.setItem("lastName", lastName);
        sessionStorage.setItem("dob", dateOfBirth);
        sessionStorage.setItem("suffix", this.selected[0].suffix ? this.selected[0].suffix : '');
        sessionStorage.setItem("middleInitial", this.selected[0].middleInitial ? this.selected[0].middleInitial.substring(0, 1) : '');
        sessionStorage.setItem("dateOfBirth", this.selected[0].dob);
        sessionStorage.setItem("individualId", this.selected[0].personId);
        sessionStorage.setItem("gender", this.selected[0].gender);
        sessionStorage.setItem("ssn", this.selected[0].ssn);
        sessionStorage.setItem("caseNum", this.selected[0].caseNum);
        this.nextDisaled = false;
    } else {
        this.nextDisaled = true;
    }
  }

  singleSelectCheck (row: any) {
      return this.selected.indexOf(row) === -1;
  }
}
