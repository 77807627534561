<div class="py-2 pb-md-1 checkbox" [class.form-check-inline]="inline">
  <label class="form-check-label ml-1" [id]="index + name">
    <input class="form-check-input" type="checkbox" [id]="index + name" [name]="name" aria-label="name" 
    [formControl]="control" nh-value [checkedValue]="checkedValue" [uncheckedValue]="uncheckedValue" [attr.disabled]="isDisabled"/>
    <span class="cr"><i class="cr-icon fa fa-check" aria-hidden="true"></i></span>
    <span *ngIf="mandatory" class="text-danger">*</span>
    <span class="checkbox-l">{{ label }}</span>
    <ng-content select=[checkbox-label]></ng-content>
  </label>
  <ng-content></ng-content>
</div>
