<div class="d-lg-none" style="overflow-x: hidden;">
	<div *ngIf="disableQAM() && showLeftPanel" (click)="menuClick()" class="quick-access-header row">
		<div class="col-10 quick-access-menu-title"><strong>{{'QUICK_ACCESS_MENU' | translate }}</strong></div>
		<div class="col-2 p-0 quick-access-menu-icon">
			<img *ngIf="showTutorialInMobileView ? !isMenuOpened && !joyrideService.isTourInProgress() : !isMenuOpened" src="../../../assets/images/⚛️ Qam_Collapsed.svg" alt="collapsed-icon">
			<img *ngIf="showTutorialInMobileView ? isMenuOpened || joyrideService.isTourInProgress() : isMenuOpened" src="../../../assets/images/⚛️ Qam_Expanded.svg" alt="Expanded-icon">
		</div>
	</div>
	<div *ngIf="showTutorialInMobileView ? isMenuOpened || joyrideService.isTourInProgress() : isMenuOpened" class="card shadow-sm">
		<div class="card-user link-style">
			<section id="step6" #step6 joyrideStep="Step6" stepPosition="bottom" text="{{'TUTORIAL_1' | translate}}" 
				class="side-section-header">
				<div class="row">
					<div class="col-2  profile-pic">
						<img src="../../../assets/images/profile_pic.svg" alt="profile pic">
					</div>
					<div class="col-10 profile-details">
						<div class="profile-name">
							<span>
								{{userDetails?.firstName}}
								{{userDetails?.middleName}}
								{{userDetails?.lastName}}
							</span>
						</div>
						<div class="profile-email">
							{{userDetails?.email}}
						</div>
					</div>
				</div>
			</section> 
			<section  id="step7" #step7  joyrideStep="Step7" stepPosition="right" text="{{'TUTORIAL_2' | translate}}" class="side-section">
				<div class="sub-title" [routerLink]="['benefit-dashboard']"><span>{{'MY_COVERAGE' | translate }}</span>
				</div>
				<ul>
					<li [routerLink]="['benefit-dashboard']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'COVERAGE_DASHBOARD' | translate }}</a></li>
					<li *ngIf="!hoveredOnHealthPlan && !this.isFromPartnerPortal && mcoMasterSwitch && showHealthPlanDetails" [routerLink]="['healthplan-details']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnHealthPlan = (!showHealthPlanDetails)"><a class="add-primary-color"> {{'HEALTH_PLAN_DETAILS' | translate }}</a></li>
					<!-- <li *ngIf="hoveredOnHealthPlan && !this.isFromPartnerPortal && mcoMasterSwitch" class="renew-coverage" (mouseout)="hoveredOnHealthPlan = showHealthPlanDetails ">{{'REPORT_CHANGE_HOVER' | translate }}</li> -->
					<li *ngIf="!this.isFromPartnerPortal && !hoveredOnAFB" (mouseover)="hoveredOnAFB = isLinkDisable"><a [class.disabled]="isLinkDisable" class="add-primary-color" (click)="applyNow()">{{'WELCOME_APPLY_COVERAGE_HEADER' | translate }}</a></li>
					<li *ngIf="!this.isFromPartnerPortal && hoveredOnAFB" (mouseout)="hoveredOnAFB = false" class="renew-coverage"> {{'AFB_HOVER' | translate }}</li>
					<li *ngIf="!hasIndiv && !readTCC"><a class="add-primary-color" [routerLink]="['case-link']" [routerLinkActive]="['selected']" [ngClass]="{ selected : isMyLink }">{{'COVERAGE_DTL_11' | translate }}</a></li>
					<li *ngIf="!hoveredOnRenew" [routerLink]="['renew-benefits']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnRenew = (!renewalDisable)"><a class="add-primary-color" [class.disabled]="(!renewalDisable)"> {{'RENEW_MY_COVERAGE' | translate }}</a></li>
					<li *ngIf="hoveredOnRenew" class="renew-coverage" (mouseout)="hoveredOnRenew = !(disableRenewal || !renewalDisable)"> {{'RENEW_MY_COVERAGE_HOVER' | translate }}</li>
					<li *ngIf="!hoveredOnReport" [routerLink]="['report-change']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnReport = !disableRmc"><a class="add-primary-color" [class.disabled]="!disableRmc">{{'REPORT_CHANGE' | translate }}</a></li>
					<li *ngIf="hoveredOnReport" class="renew-coverage" (mouseout)="hoveredOnReport = disableRmc"> {{'REPORT_CHANGE_HOVER' | translate }}</li>
					<li *ngIf="appealsMasterSwitch && !this.isFromPartnerPortal && showMyAppeals" [routerLink]="['my-appeals']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_APPEALS' | translate }}</a></li>
					<!-- <li *ngIf="!showQFR" ><a [class.disabled]="true" class="add-primary-color">{{'QFR' | translate }}</a></li>
					<li *ngIf="showQFR" [routerLink]="['pre-term']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'QFR' | translate }}</a></li> -->
					<li *ngIf="!hoveredOnQFR" [routerLink]="['pre-term']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnQFR = (!showQFR)"><a class="add-primary-color" [class.disabled]="(!showQFR)"> {{'QFR' | translate }}</a></li>
					<li *ngIf="hoveredOnQFR" class="renew-coverage" (mouseout)="hoveredOnQFR = !(qfrDisable || !showQFR)"> {{'QFR_HOVER' | translate }}</li>
				</ul>
				<hr>
			</section>
			<section  id="step8" #step8  joyrideStep="Step8" stepPosition="right" text="{{'TUTORIAL_3' | translate}}" class="side-section">
				<div class="sub-title"><span>{{'HOUSE_HOLD_DOCUMENTS' | translate }}</span></div>
				<ul>
					<li [routerLink]="['my-documents']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_DOCUMENTS' | translate }}</a></li>
					<li [routerLink]="['my-letters']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_LETTERS' | translate }}</a></li>
					<li *ngIf="!readTCC" [routerLink]="['my-submissions']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_SUBMISSONS' | translate }}</a></li>
					<li *ngIf="readTCC"><a class="add-primary-color" [class.disabled]="readTCC">{{'MY_SUBMISSONS' | translate }}</a></li>
					
					<li *ngIf="!readTCC"><a (click)="dashboardTutorial()" class="add-primary-color">{{'DASHBOARD_TUTORIAL' | translate }}</a></li>
					<li *ngIf="readTCC"><a class="add-primary-color" [class.disabled]="readTCC">{{'DASHBOARD_TUTORIAL' | translate }}</a></li>
				</ul>
				<hr>
			</section>
			<section>
				<div class="sub-title"><span>{{'MANAGE_MY' | translate }}</span></div>
				<ul>
					<li *ngIf="!readTCC" [routerLink]="['/user-profile/manage-account']" [routerLinkActive]="['selected']"><a
							class="add-primary-color">{{'MANAGE_MY' | translate }}</a></li>
					<li *ngIf="readTCC"><a class="add-primary-color" [class.disabled]="readTCC">{{'MANAGE_MY' | translate }}</a></li>
					<li *ngIf="!hoveredOnCommunicate" [routerLink]="['/user-profile/communication-preferences']"
						(mouseover)="hoveredOnCommunicate = (!hasIndiv || readTCC)" [routerLinkActive]="['selected']"><a
							class="add-primary-color" [class.disabled]="(!hasIndiv || readTCC)">{{'COMMUNICATION_TITLE' | translate }}</a></li>
					<li *ngIf="hoveredOnCommunicate && !readTCC" class="renew-coverage" (mouseout)="hoveredOnCommunicate? (hoveredOnCommunicate = hasIndiv):''"
						[routerLinkActive]="['selected']">{{'COMMUNICATION_TITLE' | translate }}</li>
					<li *ngIf="hoveredOnCommunicate && readTCC" class="renew-coverage" (mouseout)="hoveredOnCommunicate? (hoveredOnCommunicate = !(hasIndiv || readTCC)):''"
						[routerLinkActive]="['selected']">{{'COMMUNICATION_TITLE' | translate }}</li>
				</ul>
				<hr>
			</section>
		</div>
	</div>
</div>
<div class="container">
	<br>
	<div *ngIf="isManageAccountActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'MANAGE_MY' | translate }}</h1>
		</div>
	</div>
	<nh-alert type="warning" [isOpen]="successMessage?.updatedEmail">
		<div class="row">
			<div class="icon">
				<img src="../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="danger" />
			</div>
			<div class="message">
				<span>{{'UPDATED_EMAIL' | translate }}</span>
			</div>
		</div>
	</nh-alert>
	<nh-alert type="success" [isOpen]="successMessage?.updatedPassword">
		<div class="row">
			<div class="icon">
				<img src="../../../../assets/images/Icons/⚛️ icons-checkmark-outline.svg" alt="success" />
			</div>
			<div class="message">
				<span>{{ 'PWD_CHANGE' | translate }}</span>
			</div>
		</div>
	</nh-alert>
	<nh-alert type="success" [isOpen]="successMessage?.updatedSecurityQuestions">
		<div class="row">
			<div class="icon">
				<img src="../../../../assets/images/Icons/⚛️ icons-checkmark-outline.svg" alt="success" />
			</div>
			<div class="message">
				<span>{{ 'SECURITY_QUES_CHANGE' | translate }}</span>
			</div>
		</div>
	</nh-alert>
	<div *ngIf="changePasswordLinkActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'CHANGE_PASSWORD' | translate }}</h1>
		</div>
	</div>
	<div *ngIf="iscommunicationPrefActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'COMMUNICATION_TITLE' | translate }}</h1>
		</div>
	</div>
	<div>
        <nh-alert type="danger" [isOpen]="communicationPreferencesSvc.communicationAlertShow">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
            </div>
            <div class="message">
              <span>
                {{'COMMUNICATION_PREFERENCE_ALERT' | translate}}
              </span>
            </div>
          </div>
        </nh-alert>
      </div>
	<div *ngIf="healthPlanDetailsTitle" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'HEALTH_PLAN_DETAILS' | translate }}</h1>
		</div>
		<div class="row pl-1 pb-1 hover-red">
			<div class="btn btn-link hover-red" (click)="onPrevious()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
					<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
						<path id="Combined_Shape" data-name="Combined Shape"
							d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
							transform="translate(0)" fill="#174a7c" />
					</g>
				</svg>
				<span class="pl-2">{{'BACK_TO_DASHBOARD' | translate}}</span>
			</div>
		</div>
	</div>
	<div *ngIf="appealContinuanceTitle || appealWithdrawalTitle" class="row mb-3">
		<div class="col-11" *ngIf="appealContinuanceTitle">
			<h1 class="page-title">{{'CONTINUANCE_REQUEST' | translate }}</h1>
			<br>
		</div>
		<div class="col-11" *ngIf="appealWithdrawalTitle">
			<h1 class="page-title">{{'WITHDRAWAL_REQUEST' | translate }}</h1>
			<br>
		</div>
		<div class="row pl-1 pb-1 hover-red">
			<div class="btn btn-link hover-red" (click)="toAppealsDashboard()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
					<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
						<path id="Combined_Shape" data-name="Combined Shape"
							d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
							transform="translate(0)" fill="#174a7c" />
					</g>
				</svg>
				<span class="pl-2">{{'BACK_TO_APPEALS_DASHBOARD' | translate}}</span>
			</div>
		</div>
	</div>
	<div *ngIf="myDocumentsTitle" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'MY_DOCUMENTS_1' | translate }}</h1>
		</div>
		<div *ngIf="showLeftPanel && !this.isFromPartnerPortal" class="row pl-1 pb-1 hover-red">
			<div class="btn btn-link hover-red" (click)="onPrevious()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
					<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
						<path id="Combined_Shape" data-name="Combined Shape"
							d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
							transform="translate(0)" fill="#174a7c" />
					</g>
				</svg>
				<span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
			</div>
		</div>
	</div>
	<div *ngIf="updateEmailLinkActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'CHANGE_EMAIL' | translate }}</h1>
		</div>
	</div>
	<div *ngIf="mySubmissions" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'MY_SUBMISSIONS_HEADER' | translate }}</h1>
		</div>
	</div>
	<div *ngIf="myLetters" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'MY_LETTERS_HEADER' | translate}}</h1>
		</div>
	</div>
	<div *ngIf="autoRenewal" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'COVERAGE_OVERVIEW' | translate}}</h1>
		</div>
		<div *ngIf="showLeftPanel" class="row pl-1 pb-1 hover-red">
			<div class="btn btn-link hover-red" (click)="onPrevious()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
					<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
						<path id="Combined_Shape" data-name="Combined Shape"
							d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
							transform="translate(0)" fill="#174a7c" />
					</g>
				</svg>
				<span class="pl-2">{{'BACK_TO_DASHBOARD' | translate}}</span>
			</div>
		</div>
	</div>
	<div *ngIf="updateSecurityQuestionsLinkActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'CHANGE_SECURITY_QUES' | translate }}</h1>
		</div>
	</div>
	<div *ngIf="isLinkYourCaseActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'LINK_CASE_TITLE' | translate}}</h1>
		</div>
	</div>
	<nh-alert type="danger" *ngIf="isLinkYourCaseActive" [isOpen]="linkCaseService.isError">
		<div class="row">
			<div class="icon">
				<img src="../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
			</div>
			<div *ngIf="linkCaseService.errorMessage == 'ERROR_PERSON_ID_18'" class="message">
				<span>{{ 'ERROR_PERSON_ID_18' | translate}}</span>
			</div>
			<div *ngIf="linkCaseService.errorMessage == 'INDIV_NOT_FOUND'" class="message">
				<span>{{ 'INDIV_NOT_FOUND' | translate}}</span>
			</div>
			<div *ngIf="linkCaseService.errorMessage == 'ERROR_EXISTING_INDIVIDUAL'" class="message">
				<span>{{ 'ERROR_EXISTING_INDIVIDUAL' | translate}}</span>
			</div>
			<div *ngIf="linkCaseService.errorMessage == 'APPLICATION_PENDING'" class="message">
				<span>{{ 'APPLICATION_PENDING' | translate}}</span>
			</div>
			<div *ngIf="linkCaseService.errorMessage == 'NO_LINKING_DCS_FOSTER'" class="message">
				<span>{{ 'NO_LINKING_DCS_FOSTER' | translate}}</span>
			</div>
			<div *ngIf="linkCaseService.errorMessage == 'GENERIC_LINKCASE_ERROR_MESSAGE'" class="message">
				<span>{{ 'GENERIC_LINKCASE_ERROR_MESSAGE' | translate}}</span>
			</div>
		</div>
	</nh-alert>
	<button *ngIf="this.isFromPartnerPortal" (click)="backToPartnerPortal()" class="btn btn-secondary btn-lg">Back to TennCare Access</button>
	<div *ngIf="isBenefitDashboardActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'DASHBOARD_TITLE' | translate}}</h1>
		</div>
	</div>
	<div *ngIf="appealDashboardTitle" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'APPEAL_DASHBOARD' | translate}}</h1>
		</div>
		<div class="row pl-1 pb-1 hover-red">
			<div class="btn btn-link hover-red" (click)="onPrevious()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
					<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
						<path id="Combined_Shape" data-name="Combined Shape"
							d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
							transform="translate(0)" fill="#174a7c" />
					</g>
				</svg>
				<span class="pl-2">{{'BACK_TO_DASHBOARD' | translate}}</span>
			</div>
		</div>
	</div>
	<div *ngIf="isCoverageDetailsActive" class="row mb-3">
		<div class="col-11">
			<h1 class="page-title">{{'COVERAGE_DETAILS_TITLE_UPDATED' | translate}}</h1>
		</div>
	</div>
	<div *ngIf="isCoverageDetailsActive" class="row pl-1 pb-1 hover-red">
		<div class="btn btn-link hover-red" (click)="onPrevious()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
		</div>
	</div>
	<div class="row my-1">
		<div *ngIf="showLeftPanel" class="col-3 pr-1 mt-md-6">
			<div class="card d-none d-lg-block d-md-block shadow-sm">
				<div class="card-user mobile-responsive link-style">
					<section  id="step1" #step1 joyrideStep="Step1" stepPosition="bottom" text="{{'TUTORIAL_1' | translate}}"
						class="side-section-header">
						<div class="row">
							<div class="col-2  profile-pic">
								<img src="../../../assets/images/profile_pic.svg" alt="profile pic">
							</div>
							<div class="col-10 profile-details">
								<div class="profile-name">
									<span>
										{{userDetails?.firstName}}
										{{userDetails?.middleName}}
										{{userDetails?.lastName}}
									</span>
								</div>
								<div class="profile-email">
									{{userDetails?.email}}
								</div>
							</div>
						</div>
					</section>
					<section id="step2" #step2 joyrideStep="Step2"  stepPosition="right"  text="{{'TUTORIAL_2' | translate}}" class="side-section">
						<div class="sub-title" [routerLink]="['benefit-dashboard']"><span>{{'MY_COVERAGE' | translate }}</span></div>
						<ul>
							<li [routerLink]="['benefit-dashboard']" [ngClass]="{'selected': isCoverageDetailsActive}" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'COVERAGE_DASHBOARD' | translate }}</a></li>
							<li *ngIf="!hoveredOnHealthPlan && !this.isFromPartnerPortal && mcoMasterSwitch && showHealthPlanDetails" [routerLink]="['healthplan-details']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnHealthPlan = (!showHealthPlanDetails)"><a class="add-primary-color"> {{'HEALTH_PLAN_DETAILS' | translate }}</a></li>
							<!-- <li *ngIf="hoveredOnHealthPlan && !this.isFromPartnerPortal && mcoMasterSwitch" class="renew-coverage" (mouseout)="hoveredOnHealthPlan = showHealthPlanDetails ">{{'REPORT_CHANGE_HOVER' | translate }}</li> -->
							<li *ngIf="!this.isFromPartnerPortal && !hoveredOnAFB" (mouseover)="hoveredOnAFB = isLinkDisable"><a [class.disabled]="isLinkDisable" class="add-primary-color" (click)="applyNow()">{{'WELCOME_APPLY_COVERAGE_HEADER' | translate }}</a></li>
							<li *ngIf="!this.isFromPartnerPortal && hoveredOnAFB" (mouseout)="hoveredOnAFB = false" class="renew-coverage"> {{'AFB_HOVER' | translate }}</li>
							<li *ngIf="!hasIndiv && !readTCC"><a class="add-primary-color" [routerLink]="['case-link']" [routerLinkActive]="['selected']" [ngClass]="{ selected : isMyLink }">{{'COVERAGE_DTL_11' | translate }}</a></li>
							<li *ngIf="!hoveredOnRenew" [routerLink]="['renew-benefits']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnRenew = (!renewalDisable)"><a class="add-primary-color" [class.disabled]="(!renewalDisable)"> {{'RENEW_MY_COVERAGE' | translate }}</a></li>
							<li *ngIf="hoveredOnRenew" class="renew-coverage" (mouseout)="hoveredOnRenew = !(disableRenewal || !renewalDisable)"> {{'RENEW_MY_COVERAGE_HOVER' | translate }}</li>
							<li *ngIf="!hoveredOnReport" [routerLink]="['report-change']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnReport = checkReportChangeEnabled()"><a class="add-primary-color" [class.disabled]="checkReportChangeEnabled()">{{'REPORT_CHANGE' | translate }}</a></li>
							<li *ngIf="hoveredOnReport" class="renew-coverage" (mouseout)="hoveredOnReport = disableRmc">{{'REPORT_CHANGE_HOVER' | translate }}</li>
							<li *ngIf="appealsMasterSwitch && !this.isFromPartnerPortal && showMyAppeals" [routerLink]="['my-appeals']" [routerLinkActive]="['selected']" (click) = "continuance()"><a class="add-primary-color">{{'MY_APPEALS' | translate }}</a></li>
							<!-- <li *ngIf="!showQFR"><a [class.disabled]="true" class="add-primary-color">{{'QFR' | translate }}</a></li>
							<li *ngIf="showQFR" [routerLink]="['pre-term']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'QFR' | translate }}</a></li> -->
							<li *ngIf="!hoveredOnQFR" [routerLink]="['pre-term']" [routerLinkActive]="['selected']" (mouseover)="hoveredOnQFR = (!showQFR)"><a class="add-primary-color" [class.disabled]="(!showQFR)"> {{'QFR' | translate }}</a></li>
					        <li *ngIf="hoveredOnQFR" class="renew-coverage" (mouseout)="hoveredOnQFR = !(qfrDisable || !showQFR)"> {{'QFR_HOVER' | translate }}</li>
						</ul>
						<hr>
					</section>
					<section  id="step3" #step3  joyrideStep="Step3" stepPosition="right" text="{{'TUTORIAL_3' | translate}}"
						class="side-section">
						<div class="sub-title"><span>{{'HOUSE_HOLD_DOCUMENTS' | translate }}</span></div>
						<ul>
							<li [routerLink]="['my-documents']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_DOCUMENTS' | translate }}</a></li>
							<li [routerLink]="['my-letters']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_LETTERS' | translate }}</a></li>
							<li *ngIf="!readTCC" [routerLink]="['my-submissions']" [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MY_SUBMISSONS' | translate }}</a></li>
							<li *ngIf="readTCC"><a class="add-primary-color" [class.disabled]="readTCC">{{'MY_SUBMISSONS' | translate }}</a></li>
							<li *ngIf="!readTCC"><a class="add-primary-color" (click)="dashboardTutorial()">{{'DASHBOARD_TUTORIAL' | translate }}</a></li>
							<li *ngIf="readTCC"><a class="add-primary-color" [class.disabled]="readTCC">{{'DASHBOARD_TUTORIAL' | translate }}</a></li>
						</ul>
						<hr>
					</section>
					 <section>
						<div class="sub-title"><span>{{'MANAGE_MY' | translate }}</span></div>
						<ul>
							<li *ngIf="!readTCC" [routerLink]="['/user-profile/manage-account']"  [routerLinkActive]="['selected']"><a class="add-primary-color">{{'MANAGE_MY' | translate }}</a></li>
							<li *ngIf="readTCC"><a class="add-primary-color" [class.disabled]="readTCC">{{'MANAGE_MY' | translate }}</a></li>
							<li *ngIf="!hoveredOnCommunicate" [routerLink]="['/user-profile/communication-preferences']" (mouseover)="hoveredOnCommunicate = (!hasIndiv || readTCC)" [routerLinkActive]="['selected']"><a class="add-primary-color" [class.disabled]="(!hasIndiv || readTCC)" >{{'COMMUNICATION_TITLE' | translate }}</a></li>
							<li *ngIf="hoveredOnCommunicate && !readTCC" class="renew-coverage" (mouseout)="hoveredOnCommunicate? (hoveredOnCommunicate = hasIndiv):''"
						         [routerLinkActive]="['selected']">{{'COMMUNICATION_TITLE' | translate }}</li>
					        <li *ngIf="hoveredOnCommunicate && readTCC" class="renew-coverage" (mouseout)="hoveredOnCommunicate? (hoveredOnCommunicate = !(hasIndiv || readTCC)):''"
						         [routerLinkActive]="['selected']">{{'COMMUNICATION_TITLE' | translate }}</li>
				        </ul>
						<hr>
					</section>
				</div>
			</div>
		</div>
		<div [ngClass]="{'col-md-12': !showLeftPanel, 'col-md-9 col-sm-12 pl-1 pr-1': showLeftPanel }">
			<router-outlet></router-outlet>
		</div>
		<div>
			<div *ngIf="updateEmailLinkActive" class="row mt-3 pl-4 pt-4">
				<div class="ml-2">
					<button id="continue" [disabled]="changeEmailService?.updateEmailFrom?.invalid" class="btn btn-block btn-secondary"
						(click)="updateEmail1()">
						{{'SAVE' | translate}}
					</button>
				</div>
			</div>
			<br>
			<br>
		</div>
		<div>
			<div *ngIf="changePasswordLinkActive" class="row mt-3 pl-4 pt-4">
				<div class="ml-2">
					<button id="continue" [disabled]="!isPasswordFormValid()" class="btn btn-block btn-secondary"
						(click)="updatePassword()">
						{{'SAVE' | translate }}
					</button>
				</div>
			</div>
			<br>
			<br>
		</div>
		<div>
			<div *ngIf="updateSecurityQuestionsLinkActive" class="row mt-3 pl-4 pt-4">
				<div class="ml-2">
					<button id="continue" [disabled]="updateSecurityQuestions?.invalid"
						class="btn btn-block btn-secondary" (click)="saveSecurityQuestion()">
						{{'UPDATE' | translate }}
					</button>
				</div>
			</div>
			<br>
			<br>
		</div>

		<div class="col-md-12">
			<div *ngIf="isLinkYourCaseActive" class="row mt-3 pl-2 py-4 text-center">
				<div class="ml-3">
					<button id="continue" class="btn btn-block btn-secondary btn-width"
						(click)="linkCaseService.saveCaseLink()" [disabled]="linkCaseService.caseLinkfrom.invalid">
						{{'NEXT' | translate}}
					</button>
				</div>
				<div class="ml-3">
					<button id="btn-cancel" class="btn btn-block btn-outline-secondary"
						(click)="linkCaseService.onCancel()">
						{{'CANCEL' | translate }}
					</button>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div *ngIf="iscommunicationPrefActive" class="row mt-3 py-4">
				<div class="ml-3">
					<button id="continue" class="btn btn-block btn-secondary btn-width"
						(click)="communicationPreferencesSvc.setUserAction('save')"
						[disabled]="!communicationPreferencesSvc.communicationPreferFormState">
						{{'UPDATE' | translate}}
					</button>
				</div>
				<div class="ml-3">
					<button id="btn-cancel" class="btn btn-block btn-outline-secondary"
						(click)="communicationPreferencesSvc.setUserAction('cancel')"
						[disabled]="!communicationPreferencesSvc.communicationPreferFormState">
						{{'CANCEL' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div class="col-md-12">
			<div *ngIf="isManageAccountActive" class="row mt-3 text-center py-4">
				<div class="ml-3">
					<button id="continue" class="btn btn-block btn-secondary btn-width"
						(click)="createAccount.setmanageAccountAction('next')"
						[disabled]="!createAccount.manageAccountFormUpdateState">
						{{'NEXT_BUTTON' | translate}}
					</button>
				</div>
				<div class="ml-3">
					<button id="btn-cancel" class="btn btn-block btn-outline-secondary"
						(click)="createAccount.setmanageAccountAction('cancel')"
						[disabled]="!createAccount.manageAccountFormUpdateState">
						{{'CANCEL' | translate }}
					</button>
				</div>
			</div>
		</div>

	</div>
</div>