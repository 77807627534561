import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { mergeDeepRight } from 'ramda';
import { merge } from 'ramda';

import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { ConfirmModalComponent } from '@app/components/modals/confirm/confirm.component';
import { ConfirmAppealModalComponent } from '@app/components/modals/confirm-appeal/confirm-appeal.component';
import { FeedbackSurveyModalComponent } from '@app/components/modals/feedback-survey/feedback-survey.component';
import { ReportAbuseModalComponent } from '@app/components/modals/report-abuse/report-abuse.component';
import { CommentsModalComponent } from '@app/components/modals/comments/comments.component';
import { TimeoutModalComponent } from '@app/components/modals/timeout/timeout.component';
import { AnalyticsModalComponent } from '@app/components/modals/analytics/analytics.component';
import { QuestionnaireModalComponent } from '@app/components/modals/questionnaire/questionnaire.component';
import { InfoMessagesModalComponent } from '@app/components/modals/info-messages/info-messages.component';
import { PrimaryCaregiversModalComponent } from '@app/components/modals/primary-caregivers/primary-caregivers.component';
import { MedicalBillsModalComponent } from '@app/components/modals/medical-bills/medical-bills.component';
import { AssistingPersonTypesModalComponent } from '@app/components/modals/assisting-person-types/assisting-person-types.component';
import { BurialResourcesModalComponent } from '@app/components/modals/burial-resources/burial-resources.component';

import { Messages } from '@app/models/payload.model';
import { ModalState, VerificationAnswer } from '@app/models/partials.model';
import { LiquidTypesModalComponent } from '@app/components/modals/liquid-types/liquid-types.component';
import { KatieBeckettModalComponent } from '@app/components/modals/katie-beckett/katie-beckett.component';
import { OtherResourcesTypesModalComponent } from '@app/components/modals/other-resource-types/other-resources.component';
import { AddressValidationComponent } from '@app/components/modals/address-validation/address-validation.component';
import { TypesOfProofsComponent } from '@app/components/modals/types-of-proofs/types-of-proofs.component';
import { SaveExitModalComponent } from '@app/components/modals/save-exit/save-exit.component';
import { EndCoverageSummaryModalComponent } from '@app/components/modals/end-coverage-summary-modal/end-coverage-summary-modal.component';
import { CoverageTutorialModalComponent } from '@app/components/modals/coverage-tutorial-modal/coverage-tutorial-modal.component';
import { BrowserIncompatibleModalComponent } from '@app/components/modals/browser-incompatible-modal/browser-incompatible-modal.component';
import { HealthPlansComponent } from '@app/components/modals/health-plans/health-plans.component';
import { HealthPlanConfirmModalComponent } from '@app/components/modals/health-plan-confirm/health-plan-confirm.component';
import { AppealEligibilityModalComponent } from '@app/components/modals/appeal-eligibility/appeal-eligibility.component';
import { ReportChangeModalComponent } from '@app/components/modals/report-change-modal/report-change-modal.component';
import { DuplicateContinuanceAppealModalComponent } from '@app/components/modals/duplicate-continuance-appeal/continuance-appeal.component';
import { DuplicateWithdrawlAppealModalComponent } from '@app/components/modals/duplicate-appeal-withdrawl/duplicate-appeal-withdrawl.component';
import { IncomeDeductionModalComponent } from '@app/components/modals/income-deduction-modal/income-deduction-modal.component';
import { DuplicateTaxDepOrJointFilerComponent } from '@app/components/modals/duplicate-taxdep-or-jointfiler/duplicate-taxdep-or-jointfiler.component';
import { mobileFAQComponent } from '@app/components/modals/mobileFAQ/mobileFAQ.component';
import { ConfirmHohComponent } from '@app/components/modals/confirm-hoh/confirm-hoh.component';
import { ConfirmSsnModalComponent } from '@app/components/modals/confirm-ssn/confirm-ssn.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  defaultConfig: ModalOptions = {
    animated: true,
    backdrop: 'static',
    class: 'primary modal-lg',
    keyboard: false,
    ignoreBackdropClick: true,
    ariaLabelledBy: 'my-modal-title'
  };
  constructor(private modal: BsModalService) { }

  openModal(config, CustomComponent: any = ConfirmModalComponent): Subject<any> {
    return this.modal.show(CustomComponent, mergeDeepRight(this.defaultConfig, config)).content['onClose'];
  }

  closeModal() {
    this.modal.hide();
  }

  openConfirm(state: ModalState, CustomComponent: any = ConfirmModalComponent): Subject<any> {
    const initialState = merge({ title: 'Confirm' }, state);
    return this.openModal({ initialState, class: 'primary modal-lg' }, CustomComponent);
  }

  openWarningAppeal(state: ModalState, CustomComponent: any = ConfirmAppealModalComponent): Subject<any> {
    const initialState = merge({ title: 'Warning' }, state);
    return this.openModal({ initialState, class: 'warning modal-lg' }, CustomComponent);
  }

  openWarningIncome(obj: any) {
    return this.openConfirm({ initialState: obj }, IncomeDeductionModalComponent);
  }

  openWarning(state: ModalState, CustomComponent: any = ConfirmModalComponent): Subject<any> {
    const initialState = merge({ title: 'Warning' }, state);
    return this.openModal({ initialState, class: 'warning modal-lg' }, CustomComponent);
  }

  openDynamicModal(sectionId, removeForm) {
    if (sectionId === 'HHIND') {
      return this.modal.show(removeForm.componentName, { ...this.defaultConfig, initialState: removeForm, class: 'modal-lg' }).content['onClose'];
    } else {
      return this.modal.show(removeForm.commonItems.componentName, { initialState: removeForm, class: 'modal-lg' }).content['onClose'];
    }
  }

  openAddressModal(obj: any, saveExit) {
    return this.openConfirm({ initialState: obj, saveExit }, AddressValidationComponent);
  }

  confirmDeactivate(): Subject<boolean> {
    return this.openConfirm({
      body: `
        <p>Are you sure you want to leave this page without saving your updates?</p>
        <p>Select <strong>'Cancel'</strong> to return to the page and save your updates.</p>
        <p>Select <strong>'Continue'</strong> to leave this page without saving.</p>
      `,
    });
  }

  confirmHohApp(): Subject<boolean> {
    return this.openConfirm({
      title: 'Confirm Head of Household',
      body: `
        <p>Please confirm you selected the correct Head of Household before you continue the in-progress application.</p>
      `,
      confirmButton: 'Confirm',
    }, ConfirmHohComponent);
  }

  confirmDeactivateAppeal(from, pageId): Subject<boolean> {
    if (from === 'exit') {
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
          <p><b>If you leave now, all your work will be lost!</b><br><br>
          Do you still want to exit?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: 'exit',
      pageId
    });
  } else if(from === 'cancel') {
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
          <p><b>If you leave now, all your work will be lost!</b><br><br>
          Do you still wish to cancel your appeal?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: 'cancel',
      pageId
    });
  } else if(from === 'TNIcon') {
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
          <p><b>If you leave now, all your work will be lost!</b><br><br>
          Do you still wish to cancel your appeal?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: 'TNIcon',
      pageId
    });
  } else if(from === 'backToPrevious') {
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
      <p><b>If you leave now, all your work will be lost!</b><br><br>
      Do you still want to exit?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: 'backToPrevious',
      pageId
    });
  } else if(from === 'readOnlyAplCancel'){
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
      <p><b>If you leave now, all your work will be lost!</b><br><br>
      Do you still wish to leave Appeals "Read-Only" view?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: 'readOnlyAPL',
      pageId
    });
  } else {
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
          <p><b>If you leave now, all your work will be lost!</b><br><br>
          Do you still want to go back to Appeals Dashboard?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: '',
      pageId
    });
  }
  }

  confirmDeactivateBCCExt(from): Subject<boolean> {
    return this.openWarningAppeal({
      title: 'WARNING!',
      body: `
          <p><b>If you leave now, all your work will be lost!</b><br><br>
          Do you still wish to cancel your request?</p>`,
      confirmButton: 'Yes',
      declineButton: 'No',
      from: from
    });
  }

  confirmDelete() {
    return this.openWarning({ body: `Are you sure you want to remove this record? This removal cannot be undone.`, confirmButton: 'Remove' });
  }

  openSurvey() {
    return this.openConfirm({ title: 'Feedback Survey' }, FeedbackSurveyModalComponent);
  }

  openMobileFAQ() {
    return this.openConfirm({ title: 'FAQ' }, mobileFAQComponent);
  }

  openReport() {
    return this.openConfirm({ title: 'I want to report:', body: 'Confirmation Text' }, ReportAbuseModalComponent);
  }

  openComments(body) {
    return this.openConfirm({ body }, CommentsModalComponent);
  }

  openTimeout(timeout) {
    return this.openConfirm({ timeout }, TimeoutModalComponent);
  }

  openAnalyticsPolicy() {
    return this.openConfirm({ title: 'Analytics Policy' }, AnalyticsModalComponent);
  }

  openPrimaryCaregivers() {
    return this.openConfirm({ title: 'Primary Caregivers' }, PrimaryCaregiversModalComponent);
  }

  openTypesOfProofs(cmbCaseIndvData) {
    return this.openConfirm({ title: 'Types of Proof', 'cmbCaseIndvData': cmbCaseIndvData }, TypesOfProofsComponent);
  }

  openMedicalBills() {
    return this.openConfirm({ title: 'Medical Bill Types' }, MedicalBillsModalComponent);
  }

  openKatieBeckettProgram() {
    return this.openConfirm({ title: 'Katie Beckett Program' }, KatieBeckettModalComponent);
  }

  openSaveExit(sectionId: any, appType: any) {
    const initialState = { title: 'Save and exit', sectionId, appType };
    return this.openModal({ initialState }, SaveExitModalComponent);
  }

  openAssistPersonTypes() {
    return this.openConfirm({ title: 'Assisting Person Types' }, AssistingPersonTypesModalComponent);
  }

  openLiquidTypes() {
    return this.openConfirm({ title: 'Financial Resource Types' }, LiquidTypesModalComponent);
  }

  openEndCoverageTypes(entityDetails: any, appNum: any) {
    return this.openConfirm({ title: 'ALERT', entityDetails, appNum }, EndCoverageSummaryModalComponent);
  }

  openBurialTypes() {
    return this.openConfirm({ title: 'Burial Resource Types' }, BurialResourcesModalComponent);
  }

  openHealthPlanTypes() {
    return this.openConfirm({ title: 'Health Plans' }, HealthPlansComponent);
  }
  
  openOtherResourcesTypes() {
    return this.openConfirm({ title: 'Other Resource Types' }, OtherResourcesTypesModalComponent);
  }

  openCoverageTutorial() {
   return this.openConfirm({ title: 'Tutorial' }, CoverageTutorialModalComponent);
 }

  openReportChangeModal() {
    return this.openConfirm({ title: 'ReportChange' }, ReportChangeModalComponent);
  }


  openBrowserIncomatiblePopup() {
    return this.modal.show(BrowserIncompatibleModalComponent);
  }

  confirmSubmit() {
    return this.openWarning({
      body: `
        <p>We have saved everything you have done <strong>up until</strong> this page. If you have incomplete information or errors on the <strong>current page</strong>, the page will not be saved if you submit now.</p>
        <p>You have the right to submit this application immediately. Incomplete applications only provide us with limited information. We will still need you to provide missing information.
        Providing the information now will allow us to process your application more quickly and find out if you qualify for benefits.</p>
        <p>Select <strong>'Cancel'</strong> to return to the page and complete your application.</p>
        <p>Select <strong>'Submit'</strong> to continue with the application submission process.</p>
      `,
      confirmButton: 'Submit',
    });
  }

  confirmUpdate() {
    return this.openWarning({
      body: `
        <p>We have found errors on the current page.</p>
        <p>You can select <strong>'Submit'</strong> again to continue without correcting the errors, however we will not save any information from this page.</p>
        <p>If you want to fix the identified errors, select <strong>'Cancel'</strong>.</p>
        <p>Once the errors have been corrected, you can select <strong>'Submit'</strong> again and we will save the information from this page.</p>
      `,
      confirmButton: 'Submit',
    });
  }

  confirmName(realName: string) {
    return this.openWarning({
      body: `
        <p>You have changed the name of ${realName}. Is this the same person?</p>
      `,
      confirmButton: 'Yes',
    });
  }

  confirmRemoveAllPrograms() {
    return this.openConfirm({
      body: `
        <p>Because you have asked to remove all programs, you are asking to close your case entirely. Do you want to proceed?</p>
      `,
      confirmButton: 'Yes',
      declineButton: 'No',
    });
  }

  questionnaire(questions, record, saveExit): Observable<VerificationAnswer> {
    return this.openConfirm({ title: 'Verify your identity', questions, record, saveExit }, QuestionnaireModalComponent);
  }

  infoMessages(messages: Messages, individualName: string) {
    return this.openConfirm({ title: 'Verification Error', messages, individualName }, InfoMessagesModalComponent);
  }

  verifyInput(body) {
    return this.openWarning({ body });
  }

  openHealthPlanConfirmModal(healthPlanDetails) {
    return this.openConfirm({ healthPlanDetails }, HealthPlanConfirmModalComponent);
  }

  openAppealEligibilityModal() {
    return this.openConfirm({ title: 'Appeal Type Descriptions' }, AppealEligibilityModalComponent);
  }

  openDuplicateContinuanceAppeal() {
    return this.openConfirm({ title: 'Attention!' }, DuplicateContinuanceAppealModalComponent);
  }

  openDuplicateWithdrawlAppeal() {
    return this.openConfirm({ title: 'Attention!' }, DuplicateWithdrawlAppealModalComponent);
  }

  openDuplicateTaxDepOrJointFiler(dupIndvs, saveExit, section) {
    return this.openConfirm({ title: 'Same Person', dupIndvs, saveExit, section}, DuplicateTaxDepOrJointFilerComponent);
  }

  openConfirmSsn(ssnVal) {
    return this.openConfirm({ title: 'CONFIRM_SSN_TITLE' , ssnVal}, ConfirmSsnModalComponent);
  }
}
