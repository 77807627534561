import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { isHeadOfHousehold } from '@app/helpers/individual.helpers';
import { isChangeReport } from '@app/helpers/mode.helpers';
import { Record, Identifier } from '@app/models/record.model';
import { NameagegenderPipe } from '@app/pipes/nameagegender/nameagegender.pipe';
import { pickBy, map, filter, isEmpty, join } from 'ramda';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { minus3Months } from '@app/helpers/date.helpers';
import { isNo } from '@app/helpers/tayn.helpers';


@Component({
  selector: 'nh-review-summary-table',
  templateUrl: './review-summary-table.component.html',
  styleUrls: ['./review-summary-table.component.scss'],
})

export class ReviewSummaryTableComponent implements OnChanges {
  @Input() columns: any[];
  @Input() rows: any[];
  @Input() individuals: any;
  @Input() limit = 6;
  @Input() sectionId: any;
  @Input() labels: any;
  @Input() mode: any;
  renewColumns: any = [];
  rowData: any = [];
  isRMC = false;
  enableEditReview = false;
  popContent;
  personName= [];
  popContentEn_1 = `The Head of Household cannot be removed from your case.`
  popContentEn_2 = `is the Head of Household. To make changes to`
  popContentEn_3 = `'s address, click the green check mark at the top of your screen for Start. Then click on Contact Information. If you need to make other changes to your Head of Household Information, please call us at 855-259-0701.`;
  popContentEn_4 = `'s name or address, click the green check mark at the top of your screen for Head of Household.`
  popContentSp_RC1 = `El cabeza de familia no puede ser removido de su caso.`
  popContentSp_RC2 = `es el cabeza de familia. Para realizar cambios en la dirección de`
  popContentSp_RC3 = `, haga clic en la marca de verificación verde en la parte superior de la pantalla para Inicio.Luego haga clic en Información de contacto. Si necesita realizar otros cambios en la información de cabeza de familia, llámenos al 855-259-0701`;
  popContentSp_Rb = ` es el Jefe de Familia. Para hacer cambios al nombre o la dirección de`
  popContentSp_Rb1 = `, haga clic en la marca de verificación verde en la parte superior de la pantalla de Jefe de Familia.`

  taxPopContent;
  taxPopContentEn = `The Tax Filer's information can only be edited, not deleted. If you need to change your Tax Filer's information, please call us at 1-855-259-0701.`
  taxPopContentSp = `La información del contribuyente solo se puede editar, no eliminar. Si necesita cambiar la información de su contribuyente, llámenos al 1-855-259-0701.`
  defaultLang;
  translatedLang;
  @Output() edit: EventEmitter<Record> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<Identifier> = new EventEmitter();
  @Output() undo: EventEmitter<any> = new EventEmitter();
  @ViewChild('onFocus', { static: false })
  elementRef: ElementRef;
  flexSection;
  replaceText;
  replaceTextEn = "Review";
  replaceTextSp = "Revisar";
  review;
  reviewEn = "REVIEW";
  reviewSp = "REVISAR";
  text;
  texten = 'Click Review to confirm';
  textsp = 'Haga clic en Revisar para confirmar la información de la';
  text4;
  text4en = 'Click Edit to confirm';
  text4sp = 'Haga clic en Editar para confirmar';
  text1;
  text1en = ' Information';
  text1sp = 'información';
  text2;
  text2En = 'No changes made to';
  text2Sp = 'No se hicieron cambios';
  editText;
  editTextEn = "EDIT";
  editTextSp = "EDITAR";
  text3;
  text3En = "These facts were added on";
  text3Sp = "Estos hechos se agregaron en";
  text5;
  text5En = "your Assisting Person";
  text5Sp = "su persona de asistencia";
  textMoveOut;
  textMoveOutEn="Moved Out";
  textMoveOutSp="mudado";
  textOn;
  textOnEn="on";
  textOnSp="sobre";
  textLeftHouseHold;
  textLeftHouseHoldEn="indiv left the household: ";
  textLeftHouseHoldSp="indiv dejó el hogar: ";
  indivName;
  filerSummaryText1;
  filerSummaryText1En = "Name of Tax Dependent outside of household";
  filerSummaryText1Sp = "Nombre del dependiente fiscal fuera del hogar";
  filerSummaryText2;
  filerSummaryText2En = "Date of birth";
  filerSummaryText2Sp = "Fecha de nacimiento";
  firlerSummaryText3;
  firlerSummaryText3En = "Tax Deduction facts were updated. Click edit to see more details.";
  firlerSummaryText3Sp = "Se actualizaron los datos de la deducción fiscal. Haga clic en editar para ver más detalles.";
  filerSummaryText4;
  filerSummaryText4En = "Relationship to indiv";
  filerSummaryText4Sp = "Relación con indiv";
  removed;
  removedEn = "Removed";
  removedSp = "Remota";
  isNewlyAddedPerson = false;
  updateDate;
  taxDepsnames = [];
  person = [];
  taxPopUpOne;
  taxPopUpOneEnOne = "You already told us about tax information for "
  taxPopUpOneSpOne = "Ya nos dio la información fiscal para "
  taxPopUpTwo;
  taxPopUpOneSpTwo = ". Haga clic en Editar para el declarante de impuestos si quiere hacer cambios.";
  taxPopUpOneEnTwo = ". Click Edit for the tax filer if you want to make changes.";
  taxPopUpThree;
  taxPopUpOneEnThree = ". Click Review for the tax filer if you want to make changes.";
  taxPopUpOneSpThree = ". Haga clic en Revisar para el declarante de impuestos si quiere hacer cambios.";
  incomeReviewStatus: boolean = false;
  closeIncomenudge: boolean = false;

  appType = sessionStorage.getItem('appType');
  user = sessionStorage.getItem('userType');
  isAHS = sessionStorage.getItem('isKepro');
  ismode = sessionStorage.getItem('mode');

  constructor(private datePipe: DatePipe, public translateService: TranslateService) { }

  ngOnChanges(): void {
    this.rows.forEach(element => {
      if (element.exportedData != null) {
        if ((element?.data?.medicalBillUnpaidAmt > element?.data?.medicalBillOweAmt) && element?.data?.medicalBillType?.code != 'DN') {
          const owe = element?.exportedData?.medicalBillOweAmt;
          element.exportedData.medicalBillOweAmt = element.exportedData.medicalBillUnpaidAmt;
          element.exportedData.medicalBillUnpaidAmt = owe;
        }
        if (element?.data?.medicalBillType?.code == 'DN') {
          element.exportedData.medicalBillOweAmt = element.exportedData.medicalBillUnpaidAmt;
          element.exportedData.medicalBillUnpaidAmt = null;
        }
      }
    });

    if(this.sectionId === 'HHAPL'){
      this.personName = this.rows?.length>0 && this.rows[0]?.data?.casehead?.fullName;
    }else{
      this.personName = this.individuals.length>0 && this.individuals[0].name.fullName;
    }
    const taxFiler = this.rows.filter(element => element['data']['taxFilingStatus'] === 'Filer')
    if ((this.rows.length > 1 && this.sectionId === 'HHTJF'&& taxFiler?.length)) {     
      this.enableEditReview = true; 
    }
    if((this.sectionId === 'HHAPL') || (this.sectionId === 'HHIND')){
      this.enableEditReview = true; 
    }

    this.defaultLang = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLang;
    if (this.defaultLang === 'en') {
      if (sessionStorage.getItem('appType') === 'RC') {
        this.popContent = this.popContentEn_1 + ' ' + this.personName + ' ' + this.popContentEn_2 + ' ' + this.personName + ' ' + this.popContentEn_3;
      }
      if (sessionStorage.getItem('appType') === 'RB') {
        this.popContent = this.popContentEn_1 + ' ' + this.personName + ' ' + this.popContentEn_2 + ' ' + this.personName + ' ' + this.popContentEn_4;
      }
      if ((sessionStorage.getItem('appType') === 'REDETERMINATION') || (sessionStorage.getItem('appType') === 'CHANGE_REPORT')) {
        this.popContent = this.popContentEn_1 + ' ' + this.personName + ' ' + this.popContentEn_2 + ' ' + this.personName + ' ' + this.popContentEn_4;
      }

      this.taxPopContent = this.taxPopContentEn;
      this.review = this.reviewEn;
      this.replaceText = this.replaceTextEn;
      this.text = this.texten;
      this.text1 = this.text1en;
      this.text2 = this.text2En;
      this.editText = this.editTextEn;
      this.text3 = this.text3En;
      this.text4 = this.text4en;
      this.text5 = this.text5En;
      this.textMoveOut=this.textMoveOutEn;
      this.textOn=this.textOnEn;
      this.removed = this.removedEn;
      this.textLeftHouseHold=this.textLeftHouseHoldEn;
      this.filerSummaryText1 = this.filerSummaryText1En;
      this.filerSummaryText2 = this.filerSummaryText2En;
      this.firlerSummaryText3 = this.firlerSummaryText3En;
      this.filerSummaryText4 = this.filerSummaryText4En;
      this.taxPopUpOne = this.taxPopUpOneEnOne;
      this.taxPopUpTwo = this.taxPopUpOneEnTwo;
      this.taxPopUpThree = this.taxPopUpOneEnThree;
    } else {
      if (sessionStorage.getItem('appType') === 'RC') {
        this.popContent = this.popContentSp_RC1 + ' ' + this.personName + ' '  + this.popContentSp_RC2 + ' ' + this.personName + this.popContentSp_RC3;;
      }
      if (sessionStorage.getItem('appType') === 'RB') {
        this.popContent = this.personName + ' ' + this.popContentSp_Rb + ' ' + this.personName + ' ' + this.popContentSp_Rb1 ;
      }
      if ((sessionStorage.getItem('appType') === 'REDETERMINATION') || (sessionStorage.getItem('appType') === 'CHANGE_REPORT')) {
        this.popContent = this.personName + ' ' + this.popContentSp_Rb + ' ' + this.personName + ' ' + this.popContentSp_Rb1 ;
      }
      this.taxPopContent = this.taxPopContentSp;
      this.review = this.reviewSp;
      this.replaceText = this.replaceTextSp;
      this.text = this.textsp;
      this.text1 = this.text1sp;
      this.text2 = this.text2Sp;
      this.editText = this.editTextSp;
      this.text3 = this.text3Sp;
      this.text4 = this.text4sp;
      this.text5 = this.text5Sp;
      this.textMoveOut=this.textMoveOutSp;
      this.textOn=this.textOnSp;
      this.removed = this.removedSp;
      this.textLeftHouseHold=this.textLeftHouseHoldSp;
      this.filerSummaryText1 = this.filerSummaryText1Sp;
      this.filerSummaryText2 = this.filerSummaryText2Sp;
      this.firlerSummaryText3 = this.firlerSummaryText3Sp;
      this.filerSummaryText4 = this.filerSummaryText4Sp;
      this.taxPopUpOne = this.taxPopUpOneSpOne;
      this.taxPopUpTwo = this.taxPopUpOneSpTwo;
      this.taxPopUpThree = this.taxPopUpOneSpThree;
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang
      if (this.translatedLang === 'en') {
        if (sessionStorage.getItem('appType') === 'RC') {
          this.popContent = this.popContentEn_1 + ' ' + this.personName + ' ' + this.popContentEn_2 + ' ' + this.personName + ' ' + this.popContentEn_3;
        }
        if (sessionStorage.getItem('appType') === 'RB') {
          this.popContent = this.popContentEn_1 + ' ' + this.personName + ' ' + this.popContentEn_2 + ' ' + this.personName + ' ' + this.popContentEn_4;
        }
        this.taxPopContent = this.taxPopContentEn;
        this.review = this.reviewEn;
        this.replaceText = this.replaceTextEn;
        this.text = this.texten;
        this.text1 = this.text1en;
        this.text2 = this.text2En;
        this.editText = this.editTextEn;
        this.text3 = this.text3En;
        this.text4 = this.text4en;
        this.text5 = this.text5En;
        this.textMoveOut=this.textMoveOutEn;
        this.textOn=this.textOnEn;
        this.removed = this.removedEn;
        this.textLeftHouseHold=this.textLeftHouseHoldEn;
        this.filerSummaryText1 = this.filerSummaryText1En;
        this.filerSummaryText2 = this.filerSummaryText2En;
        this.firlerSummaryText3 = this.firlerSummaryText3En;
        this.filerSummaryText4 = this.filerSummaryText4En;
        this.taxPopUpOne = this.taxPopUpOneEnOne;
        this.taxPopUpTwo = this.taxPopUpOneEnTwo;
        this.taxPopUpThree = this.taxPopUpOneEnThree;
      } else {
        if (sessionStorage.getItem('appType') === 'RC') {
          this.popContent = this.popContentSp_RC1 + ' ' + this.personName + ' '  + this.popContentSp_RC2 + ' ' + this.personName + this.popContentSp_RC3;;
        }
        if (sessionStorage.getItem('appType') === 'RB') {
          this.popContent = this.personName + ' ' + this.popContentSp_Rb + ' ' + this.personName + ' ' + this.popContentSp_Rb1 ;
        }
        this.taxPopContent = this.taxPopContentSp;
        this.review = this.reviewSp;
        this.replaceText = this.replaceTextSp;
        this.text = this.textsp;
        this.text1 = this.text1sp;
        this.text2 = this.text2Sp;
        this.editText = this.editTextSp;
        this.text3 = this.text3Sp;
        this.text4 = this.text4sp;
        this.text5 = this.text5Sp;
        this.textMoveOut=this.textMoveOutSp;
        this.textOn=this.textOnSp;
        this.removed = this.removedSp;
        this.textLeftHouseHold=this.textLeftHouseHoldSp;
        this.filerSummaryText1 = this.filerSummaryText1Sp;
        this.filerSummaryText2 = this.filerSummaryText2Sp;
        this.firlerSummaryText3 = this.firlerSummaryText3Sp;
        this.filerSummaryText4 = this.filerSummaryText4Sp;
        this.taxPopUpOne = this.taxPopUpOneSpOne;
        this.taxPopUpTwo = this.taxPopUpOneSpTwo;
        this.taxPopUpThree = this.taxPopUpOneSpThree;
      }
      this.getWhatChangedData();
    })
    this.getWhatChangedData();

  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  getWhatChangedData() {
    let nameAge = '';
    this.rowData = [];
    this.isRMC = isChangeReport(this.mode);
    if (this.sectionId === 'HHTJF') {
      for (let i = 0; this.rows.length > i; i++) {
        const fullName = this.individuals.filter(value => value.identifier === this.rows[i].identifier.individualId)[0]?.name?.fullName;
        const status = this.isRMC ?
          this.editText : (!this.isRMC && this.rows[i].exportingStatus == 'EXPORTED') ? this.review :
            this.editText
        if (status === 'EDIT') {
          this.person.push({
            "name": fullName,
            "status": status
          })
        } else {
          this.person.push({
            "name": fullName,
            "status": status
          })
        }
      }
    }

    if (this.rows.length > 0 && this.sectionId === 'HHIND') {
      this.rowData = this.rows.map(item => {
        const individualId = item.identifier.individualId;
        nameAge = NameagegenderPipe.prototype.transform(this.individuals.filter(x => x.identifier == individualId)[0]);
        const combinedData = { ...item.data.demographicsData, ...item.data.additionalData, ...item.data.immigrationData };
        return this.getChangeData(item, nameAge, combinedData, this.getIndividual(individualId))
      });
    } else if (this.rows.length > 0 && this.sectionId === 'HHTJF') {
      this.rowData = this.rows.map(item => {
        const individualId = item.identifier.individualId;
        nameAge = NameagegenderPipe.prototype.transform(this.individuals.filter(x => x.identifier == individualId)[0]);
        if (item.data.taxFilingStatus === 'Filer') {
          const combinedData = { ...item.data.taxFilingDifference, ...item.data.taxDeductionsDifference, ...item.data.jointFilerOutsideDifference, ...item.data.taxDependentOutsideDifference };
          return this.getTaxChangeData(item, nameAge, combinedData, this.getIndividual(individualId))
        } else if (item.data.taxFilingStatus === 'In Household Dependent' || item.data.taxFilingStatus === 'Out of Household Dependent' || (item.data.taxFilingStatus === null && isNo(item.data.filing))) {
          return this.getTaxChangeData({ ...item, ...{ filer: false } }, nameAge, item.data.taxDepen, this.getIndividual(individualId))
        } else {
          return this.getTaxChangeData(item, nameAge, item.data.taxFilerOutsideDifference, this.getIndividual(individualId))
        }
      });

    } else if (this.rows.length > 0 && this.sectionId === 'HHAPL') {
      const household = { ...this.rows[0], ...{ canDelete: false } };
      const { assistingPerson, ...rest } = household.exportedData;
      const nameAgeIndiv = `${household.data.hohIndividual.name.fullName}, ${household.data.hohIndividual.age}  ${household.data.hohIndividual.gender.code}`;
      this.rowData = [{ ...this.getChangeData(this.rows[0], nameAgeIndiv, rest, household.data.hohIndividual.name.fullName) }];
      
      if (((household ?.data ?.assistingPerson ?.name ?.firstName !== null && household ?.data ?.assistingPerson ?.name ?.firstName !== undefined) || ( household.data.assistingPerson ?.organizationName !== null && household.data.assistingPerson ?.organizationName !== undefined)) && (household.data.assistingOnBehalf?.code !== 'S' || household.data.assistingPerson ?.exportedStatus === 'REMOVED' || household.data.addAssistingPersonToApp?.code !== 'N' || household.data.ppAddAssistingPersonToApp?.code !== 'N' || household.data.ppAssistingOnBehalf?.code !== 'N')) {
                const newRow = { data: household.data, identifier: household.identifier, exportingStatus: household.data.assistingPerson ?.exportedStatus, exportedData: household.exportedData.assistingPerson }
        const obj = this.getChangeData({ ...newRow, ...{ canDelete: true } }, household.data.assistingPerson ?.name ?.fullName || household.data.assistingPerson ?.organizationName, assistingPerson, household.data.assistingPerson ?.name ?.fullName);
        const hohObj = { 'value': newRow.data.casehead.fullName, 'spValue': newRow.data.casehead.fullName }
        obj['data']['recordRemovedType'] = hohObj;
        this.rowData = [...this.rowData, ...[obj]]
      }
    } else if (this.rows.length > 0 && this.sectionId === 'ASLIQ') {
      this.rowData = this.rows.map(item => {
        const individualId = item.identifier.individualId;
        nameAge = NameagegenderPipe.prototype.transform(this.individuals.filter(x => x.identifier == individualId)[0]);
        item = this.getChangeData(item, nameAge, item.exportedData, this.getIndividual(individualId));
        return item;
      });

    } else if (this.rows.length > 0 && this.sectionId === 'INMED') {
      this.rowData = this.rows.map(item => {
        const individualId = item.identifier.individualId;
        nameAge = NameagegenderPipe.prototype.transform(this.individuals.filter(x => x.identifier == individualId)[0]);
        item = this.getChangeData(item, nameAge, item.exportedData, this.getIndividual(individualId))
        if (new Date(item.data.careReceiveDate) <  minus3Months() ) {
          if (item.changeValue && Array.isArray(item.changeValue)) {
            const change = item.changeValue.filter(r => r.key === "RMB_MEDICAL_BILLS_STICT_TEXT10");
            if (change.length > 0) {
              change[0].key = "RMB_MEDICAL_BILLS_STICT_TEXT12";
            }
          }
        }
        return item;
      });
    } else {
      this.rowData = this.rows.map(item => {
        const individualId = item.identifier.individualId;
        if ( this.sectionId === 'HHHLC') {
          if(item.exportedData?.groupNumber === "R" || item.exportedData?.groupNumber === "Removed" || item.exportedData?.groupNumber === "Remota"){
             item.exportedData.groupNumber = this.removed
           }
           if(item.exportedData?.policyNumber === "R" || item.exportedData?.policyNumber === "Removed" || item.exportedData?.policyNumber === "Remota"){
            item.exportedData.policyNumber = this.removed
          }
          if(item.exportedData?.monthlyPremiumAmountForKB === 0 || item.exportedData?.monthlyPremiumAmountForKB === "Removed" || item.exportedData?.monthlyPremiumAmountForKB === "Remota"){
            item.exportedData.monthlyPremiumAmountForKB = this.removed
          }
          if(item.exportedData?.longTermCarePremiumAmount === 0 || item.exportedData?.longTermCarePremiumAmount === "Removed" || item.exportedData?.longTermCarePremiumAmount === "Remota"){
            item.exportedData.longTermCarePremiumAmount = this.removed
          }
          if (item.data?.policyHolder?.houseMember != 0) {
            nameAge = NameagegenderPipe.prototype.transform(this.individuals.filter(x => x.identifier == item.data?.policyHolder?.houseMember)[0]);
          } else if (item.data?.policyHolder?.policyHolderFirstName && item.data?.policyHolder?.policyHolderLastName ) {
            nameAge = `${item.data.policyHolder.policyHolderFirstName} ${item.data.policyHolder.policyHolderLastName}`;
          }
        } else {
          nameAge = NameagegenderPipe.prototype.transform(this.individuals.filter(x => x.identifier == individualId)[0]);
        }
        return this.getChangeData(item, nameAge, item.exportedData, this.getIndividual(individualId))
      });
    }

    if (!this.isRMC) {
      const incomeSections = {
        ICEMI: { sessionKey: 'employIncomeReviewStatus', nudgeKey: 'closeEmployIncomeNudge' },
        ICSEM: { sessionKey: 'selfIncomeReviewStatus', nudgeKey: 'closeSelfIncomeNudge' },
        ICOTH: { sessionKey: 'otherIncomeReviewStatus', nudgeKey: 'closeOtherIncomeNudge' },
      };

      const sectionInfo = incomeSections[this.sectionId];
      if (sectionInfo) {
        this.incomeReviewStatus = this.rowData.every((item) => item?.changeValue?.length === 0);
        if (this.incomeReviewStatus && !sessionStorage.getItem(sectionInfo.sessionKey)) {
          sessionStorage.setItem(sectionInfo.sessionKey, 'true');
        }
        const getNudgeStatus = sessionStorage.getItem('closeNudge') ? sessionStorage.getItem('closeNudge') : sessionStorage.getItem(sectionInfo.nudgeKey);
        if (getNudgeStatus === 'true') {
          this.closeIncomenudge = true;
        }
      }
    }
  }

  checkIndvs(row) {
    const newlyAddedIndvs = this.individuals.filter(indv => indv.someoneMovedIn === 'Y');
      newlyAddedIndvs.forEach(indv => {
        if (row.identifier.individualId === indv.identifier) {
          this.isNewlyAddedPerson = true;
          row['newlyAdded'] = true;
        } else {
          this.isNewlyAddedPerson = false;
        }
      });

    if (row.changeValue.length === 0 && row['newlyAdded'] === true) {
      return true
    } else {
      return false;
    }
  }

  getTaxChangeData(item, nameAge, data, indivName?) {
    this.updateDate = this.datePipe.transform(item.data.updateDate, "MM/dd/yyyy");

    const flattenedLabelObj = Object.assign({}, ...this.flattenedObject(this.labels));
    switch (item.exportingStatus) {
      case 'EXPORTED':
        if (item.hasOwnProperty('filer') && item.filer === false && !this.isRMC) {
          item = { ...item, ...{ nameAge: nameAge, changeValue: `To update ${indivName}'s ${item.data.taxFilingStatus} facts, click Review.` } }
        } else {
          if (this.isRMC)
            item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text4} ${item.canDelete && item.canDelete === true ? this.text5 : ''} ${indivName}${this.text1}` } }
          else
            item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text} ${item.canDelete && item.canDelete === true ? this.text5 : ''} ${indivName}${this.text1}` } }
        }
        break;
      case 'REMOVED':
        item = { ...item, ...{ nameAge: nameAge, changeValue: this.getRemovedData(item, indivName) } }
        break;
      case 'REVIWED':
        item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text2} ${item.canDelete && item.canDelete === true ? nameAge : indivName}'s information` } }
        break;
      case 'ADDED':
        if (item.hasOwnProperty('filer') && item.filer === false && item.data.taxFilingStatus !== 'In Household Dependent') {
          item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text4} ${indivName}${this.text1}` } }
        } else if (item.filer === false && item.data.taxFilingStatus === 'In Household Dependent') {
          item = { ...item, ...{ nameAge: nameAge, changeValue: this.text3 + ' ' + this.updateDate } }
        } else {
          item = { ...item, ...{ nameAge: nameAge, changeValue: this.text3 + ' ' + this.updateDate } }
        }
        break;
      case 'CHANGED':
        if (data !== null) {
          const labelObj = this.getLabels(flattenedLabelObj, indivName);
          const getObj = this.getFlattenedObj(data, labelObj);
          if (!item.hasOwnProperty('filer')) {
            if (data ?.taxDependentsOutside && data ?.taxDependentsOutside ?.length !== 0) {
              getObj.push({ key: this.filerSummaryText1, value: data ?.taxDependentsOutside[0].name});
              getObj.push({ key: this.filerSummaryText2, value: data ?.taxDependentsOutside[0].birthDate});
              getObj.push({ key: this.filerSummaryText4, value: data ?.taxDependentsOutside[0].relation})
            }
            if (data?.recordsUpdated === 'Y' && data?.taxDeductions?.code !== 'N') {
              getObj.push({ key: this.firlerSummaryText3, value: '' })
            }
          };
          if (data?.taxDependent?.code !== 'N' && data?.taxDependents?.length > 0) {
            this.taxDepsnames = [];
            data?.taxDependents?.forEach(indivId => {
              const fullNames = this.individuals.filter(indiv => indiv.identifier == indivId.individualId)[0].name?.fullName;
              this.taxDepsnames.push(fullNames);
            })

            if (!item.hasOwnProperty('filer')) {
              const taxDepsNames = { key: `Who are ${indivName}'s tax dependents?`, value: join(', ', this.taxDepsnames)};
              getObj.push(taxDepsNames);
            };
          };

          const jointFilerName = this.individuals.filter(indv => indv.identifier == item?.data?.jointTaxFilingIndividualId)[0]?.name?.fullName;
          const jointFilerText = `Does ${indivName} plan to file jointly with` + ' ' + jointFilerName + '?';
          getObj.map(i => i.key === 'TAX_RMB_SUMARY_STATIC33' ? i.key = jointFilerText : '');

          item = getObj.length >= 4 ?
            { ...item, ...{ nameAge: nameAge, changeValue: getObj.slice(0, 3), greaterLength: true } } :
            { ...item, ...{ nameAge: nameAge, changeValue: getObj, greaterLength: false } }
          this.getFormattedPhone(item);
          this.removeCoOwners(item);
        } else {
          item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text2} ${indivName}'s information` } }
        }
        break;
      default:
        item = { ...item, ...{ nameAge: nameAge, changeValue: '' } }
        break;
    }
    return item;
  }

  getChangeData(item, nameAge, data, indivName?) {
    this.indivName = indivName;

    this.updateDate = this.datePipe.transform(item.data.updateDate, "MM/dd/yyyy");

    const flattenedLabelObj = Object.assign({}, ...this.flattenedObject(this.labels));
    switch (item.exportingStatus) {
      case 'EXPORTED':
        if (this.isRMC)
          item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text4} ${item.canDelete && item.canDelete === true ? this.text5 : ''} ${indivName? indivName : nameAge? nameAge.split(',')[0]: 'Person'}'s ${this.text1}` } }
        else
          item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text} ${item.canDelete && item.canDelete === true ? this.text5 : ''} ${indivName? indivName : nameAge? nameAge.split(',')[0]: 'Person'}'s ${this.text1}` } }
        break;
      case 'REMOVED':
        item = { ...item, ...{ nameAge: nameAge, changeValue: this.getRemovedData(item, indivName) } }
        break;
      case 'REVIWED':
        item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text2} ${item.canDelete && item.canDelete === true ? nameAge : indivName}'s information` } }
        break;
      case 'ADDED':
        item = { ...item, ...{ nameAge: nameAge, changeValue: this.text3 + ' ' + this.updateDate } }
        break;
      case 'CHANGED':
        if (data !== null) {
          const labelObj = this.sectionId === 'HHAPL' ?
            (item.canDelete === true ? this.getLabels(Object.assign({}, ...this.flattenedObject(this.labels.assistingPerson))) : this.getLabels(flattenedLabelObj)) :
            this.getLabels(flattenedLabelObj, indivName);
          const getObj = this.getFlattenedObj(data, labelObj);
          item = getObj.length >= 4 ?
            { ...item, ...{ nameAge: nameAge, changeValue: getObj.slice(0, 3), greaterLength: true } } :
            { ...item, ...{ nameAge: nameAge, changeValue: getObj, greaterLength: false } }
          this.getFormattedPhone(item);
          this.removeCoOwners(item);
        } else {
          item = { ...item, ...{ nameAge: nameAge, changeValue: `${this.text2} ${item.canDelete && item.canDelete === true ? nameAge : indivName}'s information` } }
        }
        break;
      default:
        item = { ...item, ...{ nameAge: nameAge, changeValue: '' } }
        break;
    }
    if (this.sectionId === 'OTKBR' || this.sectionId === 'HHHLC') {
      for (let i = 0; i < item.changeValue.length; i++ ) {
        const element = item.changeValue[i];
        if (element.value === '0' && element.key === "POLICY_HOLDER" ) {
          const firstName = item.changeValue.filter(s => s.key === "POLICY_HOLDER_FIRSTNAME");
          const lastName = item.changeValue.filter(s => s.key === "POLICY_HOLDER_LASTNAME");
          if (firstName.length > 0 && lastName.length > 0) {
            element.value = firstName[0].value + " " + lastName[0].value;
          }
        }
        if (element.value && element.value === 'REMOVED') {
          item.changeValue.splice(1, 1);
          i--;
        }
      }
    }
    if (item.changeValue.length) {
      for (let i = 0; i < item.changeValue.length; i++) {
        const element = item.changeValue[i];
        let amountKeys = ['RESOURCES_PROPERTY_STATIC8', 'RMB_REAL_ESTATE_STATIC_TEXT5', 'RMB_REAL_ESTATE_STATIC_TEXT6', 'RMB_FINANCIAL_STATIC_TEXT7', 'RMB_VEHICLE_STATIC_TEXT3',
         'RMB_VEHICLE_STATIC_TEXT4', 'BURRIAL_STATIC_TEXT7','BURRIAL_STATIC_TEXT5', 'RMB_BURRIAL_STATIC2', 'LIFE_INSURANCE_STATIC_TEXT5', 'LIFE_INSURANCE_STATIC_TEXT3', 'RMB_OTHER_RESOURCES_TEXT2',
         'RMB_OTHER_RESOURCES_TEXT3', 'TRANSFERED_STATIC_TEXT8','TRANSFERED_STATIC_TEXT9', 'OTHER_COVERAGE_STATICTEXT7', 'RMB_SUPPORT_PAYMENT_STATIC1', 'UTILITIES_EXPENSES_STATIC_TEXT9',
         'RMB_EMPLOYMENT_STATIC_TEXT2', 'RMB_EMPLOYMENT_STATIC_TEXT4',
         'OTHER_INCOME_SATIC_TEXT21', 'OTHER_INCOME_SATIC_TEXT23', 'OTHER_INCOME_SATIC_TEXT24'];
        let sahKey = ['SAFEATHOME_MAILID'];
        let premiumAmtKey = ['OTHER_HEALTH_RMB_SUMMARY_STATC2'];
        let longTermPremiumAmt = ['HEALTH_INSURANCE_STATIC_TEXT18'];
        if (sahKey.includes(element.key)) {
          element.value = element.value? element.value + '<br>' : element.value;
        }
        if (premiumAmtKey.includes(element.key)) {
          if(item.exportedData?.monthlyPremiumAmountForKB === 0 || item.exportedData?.monthlyPremiumAmountForKB === "Removed" || item.exportedData?.monthlyPremiumAmountForKB === "Remota"){
          element.value = element.value;
          }else {
            element.value = element.value? '$' + element.value : element.value;
          }
        }
        if (longTermPremiumAmt.includes(element.key)) {
          if(item.exportedData?.longTermCarePremiumAmount === 0 || item.exportedData?.longTermCarePremiumAmount === "Removed" || item.exportedData?.longTermCarePremiumAmount === "Remota"){
          element.value = element.value;
          }else {
            element.value = element.value? '$' + element.value : element.value;
          }
        }
        if (amountKeys.includes(element.key)) {
          element.value = element.value? '$' + element.value : element.value;
        }
      }
    }
      return item;
  }

  getRemovedData(item, indivName?) {
    return [...Object.entries(this.labels.removed).map(([k, val]: any) => {
      const opp = {}
      if (this.sectionId === 'HHIND') {
        val = `${this.textLeftHouseHold}${item.data.moveOutReason ?.code === 'MO' ? this.textMoveOut : (this.translatedLang == 'en'? item.data.moveOutReason?.value : item.data.moveOutReason?.spValue )} `;
      }

      if (typeof item.data != null && typeof item.data != "undefined") {
        if (item.data.otherIncomeDetails != null
          && item.data.otherIncomeDetails.otherIncomeTypeCode != null
          && item.data.otherIncomeDetails.otherIncomeTypeCode.code != null) {
          val = val.replace(/Other Income/gi, `${item.data.otherIncomeDetails.otherIncomeTypeCode.value}`);
        }
      }

      opp['key'] = indivName ? val.replace(/indiv/, `${indivName}`) : val,
        opp['value'] = item.data[k] && item.data[k] !== null ?
          (typeof item.data[k] === 'string' && item.data[k].includes('-') ? `${this.textOn} ${item.data[k].split('-')[1]}/${item.data[k].split('-')[2]}/${item.data[k].split('-')[0]}` : item.data[k])
          : '';
      if (item.filer == false) {
        if(this.getIndividual(item.data.filer)){
        opp['key'] = `${this.getIndividual(item.data.filer)} removed ${indivName} tax information on `;
        } else {
        opp['key'] = `${indivName} was removed as an ${item.data.taxFilingStatus} `;
        }
      }
      return opp;
    })]
  }

  getFormattedPhone(item) {
    if (Array.isArray(item.changeValue)) {
      item.changeValue.forEach(element => {
        if (['HOME_PHONE', 'CELL_PHONE', 'WORK_PHONE'].includes(element.key) && element.value !== 'REMOVED') {
          element.value = `(${element.value.substr(0, 3)}) ${element.value.substr(3, 3)}-${element.value.substr(6)}`;
        }
        if(element.key === 'EXTENSION'){
          if(element.value === 0){
            element.value = 'REMOVED';
          }
        }
      });
    }
  }

  getFlattenedObj(data, finalLabels) {
    const predicate = (val) => !isEmpty(val) && val !== null && val !== " "
    const newObj = Object.assign({}, ...this.flattenedObject(pickBy(predicate, data)));
    const removeNulls = pickBy(predicate, newObj);
    const finalData = [...Object.entries(removeNulls).map(([key, value]) => {
      const opp = {}
      if (finalLabels[key]) {
        opp['key'] = finalLabels[key];
        opp['value'] = value
      }
      return opp
    })]
    return filter(predicate, finalData);
  }

  getLabelKey = (key: string) => {
    if(key === 'APPLICATION_SOURCE') {
      const isAhsAfb = (this.isAHS && this.user === 'RP') && this.appType === 'AFB' && this.ismode === 'NEW';
      const isAhsRmb = (this.isAHS && this.user === 'RP') && (this.appType === 'RB' || this.appType === 'REDETERMINATION') && this.ismode === 'REDETERMINATION';
      const isAhsRmc = (this.isAHS && this.user === 'RP') && (this.appType === 'RC' || this.appType === 'CHANGE_REPORT') && this.ismode === 'CHANGE_REPORT';
      if(isAhsAfb) {
      }
      else if(isAhsRmc) {
        key = 'CHANGEREQUEST_SOURCE';
      }
      else if(isAhsRmb) {
        key = 'RENEWAL_SOURCE';
      }
    }
    return key;
  };

  getLabels(labels, name?) {
    const labelData = Object.assign({}, ...this.flattenedObject(labels));
    const pred = val => typeof val === 'string' ? val.replace(/indiv/gi, `${name}`) : val;
    const newObj = map(pred, labelData)
    return name ? newObj : labelData;
  }

  onDelete(record: Record) {
    if (record?.data?.medicalBillOweAmt === "N/A")
      record.data.medicalBillOweAmt = null;
    if(record?.data?.addAssistingPersonToApp?.code === "Y"){
         record.data.addAssistingPersonToApp = null;
    }
    if(record?.data?.ppAddAssistingPersonToApp?.code === "Y"){
      record.data.ppAddAssistingPersonToApp = null;
    }
    if(record?.data?.ppAssistingOnBehalf?.code === "Y"){
      record.data.ppAssistingOnBehalf = null;
    }
    this.delete.emit({ record: record });
  }

  canDelete(row) {
    return (this.sectionId === 'HHAPL') ? row.canDelete : !isHeadOfHousehold(row.data);
  }

  onEdit(record: Record) {
    if (record.exportingStatus === 'REMOVED') {
      return this.onDelete(record);
    }
    this.edit.emit(record);
  }

  onUndo(record: any) {
    const data = this.individuals.filter(r => record.nameAge.indexOf(r.name.firstName) > -1);
    if (data.length > 0)
    record.identifier.individualId = data[0].identifier;
    this.undo.emit(record);
  }

  isIndividual(val) {
    return this.individuals.some(ind => ind.identifier === val);
  }

  getIndividual(indNum) {
    return this.individuals.filter(ind => ind.identifier === indNum)[0] ?.name.fullName;
  }

  flattenedObject(obj, pre?) {
    const isNull = (key) => key === null;
    const isCode = (key) => !isNull(key) && key.hasOwnProperty('code');
    const isAddress = (value, key) => !isNull(value) && (key.includes('address') || key.includes('assetAddress')) && value.hasOwnProperty('addressLine1') && !isNull(value.addressLine1);
    const isAddressLine2 = (value, key) => !isNull(value) && (key.includes('address')  || key.includes('assetAddress')) && value.hasOwnProperty('addressLine1') && !isNull(value.addressLine1) && value.hasOwnProperty('addressLine2') && !isNull(value.addressLine2);
    const isMilitary = (value) => value.hasOwnProperty('addrAaAeAp') && !isNull(value.addrAaAeAp)
    const isObject = (value) => !isNull(value) && !Array.isArray(value) && typeof value === 'object'

    return [].concat(...Object.keys(obj)
      .map((k) => {
        const prefix = pre === undefined ? k : `${pre}_${k}`;
        if (isObject(obj[k]) && !isCode(obj[k]) && !isAddress(obj[k], k)) {
          return this.flattenedObject(obj[k], prefix);

        } else if (isCode(obj[k]) && !isAddress(obj[k], k)) {
          if (obj[k].spValue && this.translatedLang !== 'en') {
            return { [prefix]: obj[k].spValue };
          } else {
            return { [prefix]: obj[k].value };
          }

        } else if (isAddress(obj[k], k)) {
          if (obj[k].addressLine1 === 'REMOVED') return {[prefix]: 'REMOVED'}
          else {return !isMilitary(obj[k]) ? isAddressLine2(obj[k], k) ?
            { [prefix]: `${obj[k].addressLine1}<br> ${obj[k].addressLine2}<br> ${obj[k].city}, ${obj[k].state.code} ${this.zipFormat(obj[k].zip)}<br> ${obj[k].county ? obj[k].county.value : ''}` } :
            { [prefix]: `${obj[k].addressLine1}<br> ${obj[k].city}, ${obj[k].state.code} ${this.zipFormat(obj[k].zip)}<br> ${obj[k].county ? obj[k].county.value : ''}` } :

            { [prefix]: `${obj[k].addressLine1}<br> ${obj[k].addrAaAeAp.code}, ${obj[k].addrApoFpo.code} ${this.zipFormat(obj[k].zip)}` }}
        } else if (Array.isArray(obj[k])) {
          return {
            [prefix]: obj[k].reduce((acc, val) => {
              if (isObject(val) && isCode(val)) {
                if (val.spValue && this.translatedLang !== 'en') {
                acc.push(val.spValue);
                return acc;
                } else {
                  acc.push(val.value);
                  return acc;
                }
              } else if (isObject(val) && !isCode(val)) {
                acc = this.flattenedObject(val)
                return acc
              }
            }, [])
          }
        } else {
          if (obj[k] === '9999-12-31') {
            return { [prefix]: "REMOVED" }
          } else {
            if (k === 'ssn' && obj[k] != null && obj[k] != 'SOCIAL_LABEL') {
              obj[k] = [obj[k].substring(0, 3), obj[k].substring(3, 5), obj[k].substring(5)].join('-');
            }
            return this.isIndividual(obj[k]) ? { [prefix]: this.getIndividual(obj[k]) } : { [prefix]: obj[k] }
          }
        }
      })
    );
  }

  isObject(item) {
    return typeof item.changeValue === 'object' ? true : false;
  }

  zipFormat(input) {
    if (!input) {
      return input;
    }
    if (input.toString().length === 9) {
      return input.toString().slice(0, 5) + "-" + input.toString().slice(5);
    } else if (input.toString().length === 5) {
      return input.toString();
    } else {
      return input;
    }
  }

  removeCoOwners(item){
    if (Array.isArray(item.changeValue)) {
      item.changeValue.forEach(element => {
        if (['RMB_C_CO_OWNERS'].includes(element.key)) {
          element.value = '';
        }
      });
    }
  }

}