import { ApiService } from '@app/gateway/services/api.service'
import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { StateService } from '@app/services/state/state.service'
import { Router } from '@angular/router'
import { ApplicationService } from '@app/services/application/application.service'
import { UserProfileService } from '../user-profile.service'
import { ModalService } from '@app/services/modal/modal.service'
import { UserProfileComponent } from '../user-profile.component'
import { Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { HttpHeaders  } from '@angular/common/http';
import { ServiceConfigService } from '@app/services/service-config.service'

@Component({
  selector: 'nh-my-subbmissions',
  templateUrl: './my-submissions.component.html',
  styleUrls: ['./my-submissions.component.scss']
})
export class MySubmissionsComponent implements OnInit {
  appNum;
  renewals;
  reportChanges;
  myApplications;
  rows;
  isLinkYourCaseActive = false;
  hoh = sessionStorage.getItem('hoh') == 'true';
  memberInfo;
  indvId = sessionStorage.getItem('individualId');
  isLinkedAcc = false;
  rowData = [];
  isPartnerPortal = sessionStorage.getItem('userType') === 'RP' ? true : false;
  indivSw = true;
  showSubmitRenewalMessage = false;
  columns = [{
  name: 'Submitted By', prop: 'submittedBy'},
  { name: 'Head of Household', prop: 'headOfHoushold' }];
  changesArray;
  daysAfterRenewal: number
  renewalDisable: boolean
  accountType;
  appeals;
  defaultLanguage;
  translatedLang;
  appealReason;
  appealSubmissionType;
  appealResponse;
  qfrRows = [];
  rmblinkStatus: boolean = false;
  rmcLinkStatus : boolean = false;
  afbLinkToggle: boolean = false;
  constructor(public apiService: ApiService, public stateService: StateService, public router: Router, private app: ApplicationService,
    private userService: UserProfileService, private modalService: ModalService, public userProfileComponent: UserProfileComponent,private titleService: Title,public translateService: TranslateService,
    private serverConfig: ServiceConfigService,public cd: ChangeDetectorRef
    ) {
      this.titleService.setTitle('My Submissions');
     }

  onLoad() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    const indvId = sessionStorage.getItem('individualId');
    this.accountType = user ? user.accountTypeCd : sessionStorage.getItem('userType');
    const url = 'indi/mysubmisson/accountType/' + `${this.accountType}/` + 'indv/' + indvId;
    this.afbLinkToggle = this.serverConfig.getServerConfig().AFB_LINK_SWITCH;
    this.rmblinkStatus = sessionStorage.getItem('rmbLinkStatus') === 'true';
    this.rmcLinkStatus = sessionStorage.getItem('rmcLinkStatus') === 'true';
    this.cd.detectChanges();
    this.apiService.get(url, null,  {headers: new HttpHeaders({'pageId': 'TCMSS'})}).subscribe(res => {
      if (res && res['myApplications']) {
        const myAppeals = res['myAppeals']
        const myApplications = res['myApplications']
        const myRenewals = res['myRenewals']
        const myReportedChanges = res['myReportedChanges']
        const indvLinkedToAccountSw = res['indvLinkedToAccountSw']
        const myPretermApplications = res['myPretermApplications']
        this.indivSw =  indvLinkedToAccountSw !== 'Y' && this.isPartnerPortal  ? false : true;
        this.myApplications = myApplications
        this.rows = this.myApplications;
        this.qfrRows = myPretermApplications?.length > 0 ?  myPretermApplications : [];
        this.renewals = myRenewals;
        this.reportChanges = myReportedChanges;
        this.appeals = myAppeals;
        this.appNum = this.myApplications.length > 0 ? this.myApplications[0] : undefined;
        for (let i = 0; this.reportChanges.length > i; i++) {
          this.changesArray = this.reportChanges[i]?.typeOfChange?.split(', ')
          this.changesArray = this.filterDuplicate(this.changesArray);
          this.reportChanges[i]["changesArray"] = this.changesArray;  
        }       
      }
    })
  }
  filterDuplicate(arr){
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  ngOnInit(): void {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.onLoad()
    });
    this.onLoad()
    this.isLinkedAcc = this.indvId !== 'null' ? true : false;
    const renew_duedate =  sessionStorage.getItem('renewalDueDate')
    this.showSubmitRenewalMessage = sessionStorage.getItem('openRenewalSW') !== 'Y' && renew_duedate && new Date(renew_duedate) < new Date() ;
    this.daysAfterRenewal = this.calculateDays(renew_duedate);
    this.userService.hideRenew$.subscribe((bool) => {
      this.renewalDisable = bool;
    })
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }
  
  calculateDays(renew_duedate) {
    const currentDate = new Date();
    const newRenew_duedate = new Date(renew_duedate);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(newRenew_duedate.getFullYear(), newRenew_duedate.getMonth(), newRenew_duedate.getDate()) ) / (1000 * 60 * 60 * 24));
}

  continueApp() {
    this.router.navigate(['/start', 'welcomeback']);
  }


  startApp() {
    if (this.accountType === 'CL') {
      this.userProfileComponent.applyNow();
    } else {
      this.router.navigateByUrl("api/prov/atglance");
    }
  }

  renewBenefits() {
    this.router.navigateByUrl("/user-profile/renew-benefits");
  }

  onDeleteApp(row) {
    this.modalService.confirmDelete().subscribe((res) => {
      if(res) {
        this.app.deleteApp(row?.tccAppnumber, 'TCMSS').subscribe(()=>this.onLoad());
      }
    });
    // () => location.reload()
  }

  onViewApp(pdfId){
    this.userService.getPrintPdf(pdfId, 'TCMSS').subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      let w = window.open(fileURL);
      w.onload = () => {
				setTimeout(() => {	w.document.title = 'Application Summary'}, 500);
			};
    })
  }

  onViewAppAppeal(pdfId, item){
    this.userService.getAppealPrintPdf(pdfId, 'TCMSS', this.translatedLang, item, 'mySubmissions').subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      let w = window.open(fileURL);
      w.onload = () => {
				setTimeout(() => {	w.document.title = 'Application Summary'}, 500);
			};
    })
  }

  continueRenewal() {
    this.router.navigateByUrl('/user-profile/renew-benefits');
  }

  get showAFBLink(): boolean {
    return this.afbLinkToggle && !(this.rmblinkStatus || this.rmcLinkStatus);
  }
}
