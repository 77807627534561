<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ title | translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="pb-2 pt-2"> {{'CONFIRM_SSN_TEXT1' | translate}}</p>
    <nh-field label="{{'CONFIRM_SSN_TEXT2' | translate }}" [mandatory]="true">
      <input placeholder="XXX-XX-XXXX" mask="000-00-0000" type="text" name="confirmssn" aria-label ="confirmssn" class="form-control"
          [formControl]="confirmssn" (keyup)="ssnValidate()" onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" >
    </nh-field>
  </div>
  
  <div class="modal-footer align-button" [ngClass]="((!isFormValid() && !this.confirmssn.touched) || (isFormValid() && this.confirmssn.touched)) ? 'withoutError' : 'withError' ">
      <div class="mr-2 ml-2">
        <button type="button" id="dialog" class="btn btn-block btn-secondary" *ngIf="showConfirm" (click)="onConfirm()" [disabled]="!isFormValid()">{{
          'SUBMIT' | translate }}</button>
      </div>
      <div class="mr-2 ml-2">
        <button type="button" id="dialog" class="btn btn-block btn-link" *ngIf="showDecline" (click)="onCancel()">{{ declineButton | translate
          }}</button>
      </div>
  </div>
</div>