import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder, FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { minus120Years } from '@app/helpers/date.helpers';
import Vs from '@app/services/validators/validators.service';

@Component({
  templateUrl: './appeal-details.component.html',
  styleUrls: ['./appeal-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppealDetailsComponent extends SectionFormDirective implements AfterContentInit {
  defaultLanguage;
  translatedLang;
  packetReturnDateValidation = true;
  addInfoSentDateValidation = true;
  selectAppelantsColumns = [
    { name: 'Name', prop: 'memberName' },
    { name: 'PERSON_ID', prop: 'personId' },
  ];
  coverageType = 'Coverage Type';
  today = new Date();
  minimumDate = minus120Years();
  appealReasonCode: string;
  appealTypeValue: string;
  appealNumber;
  startTypingPlaceHolder= "Start typing here...";
  appealNumberPlaceHolder="List each appeal number separated by a comma..."
  data = {
    'APE': [
      {
        'code': 'CB',
        'fields': ['benefitChgTxt']
      },
      {
        'code': 'CEE',
        'description': 'My coverage is ending or ended',
        'rank': '2',
        'spDescription': 'My coverage is ending or ended',
        'fields': ['terminationIncorrectTxt']
      },
      {
        'code': 'D',
        'description': 'I was denied benefits',
        'rank': '3',
        'spDescription': 'I was denied benefits',
        'fields': ['applyDateSW', 'applyDateRangeTxt', 'denialSW', 'denialTxt']
      },
      {
        'code': 'ED',
        'description': 'The start/end date for my coverage is incorrect',
        'rank': '4',
        'spDescription': 'The start/end date for my coverage is incorrect',
        'fields': ['applyDateSW', 'applyDateRangeTxt', 'appealFilingLocation', 'elgBeginDate', 'elgEffectiveDateTxt']
      },
      {
        'code': 'PL',
        'description': 'My patient liability amount is incorrect',
        'rank': '6',
        'spDescription': 'My patient liability amount is incorrect',
        'fields': ['patientLiabilityTxt']
      }
    ],
    'APK': [
      {
        'code': 'CB',
        'description': 'Change in type of coverage',
        'rank': '1',
        'spDescription': 'Change in type of coverage',
        'fields': ['benefitChgTxt']
      },
      {
        'code': 'CEE',
        'description': 'My coverage is ending or ended',
        'rank': '2',
        'spDescription': 'My coverage is ending or ended',
        'fields': ['terminationIncorrectTxt']
      },
      {
        'code': 'D',
        'description': 'I was denied benefits',
        'rank': '3',
        'spDescription': 'I was denied benefits',
        'fields': ['applyDateSW', 'applyDateRangeTxt', 'denialSW', 'denialTxt']
      },
      {
        'code': 'PA',
        'description': 'My premium amount is incorrect',
        'rank': '4',
        'spDescription': 'My premium amount is incorrect',
        'fields': ['premiumAmount']
      },
      {
        'code': 'PAR',
        'description': 'My premium assistance reimbursement amount is incorrect or I was denied a hardship waiver',
        'rank': '5',
        'spDescription': 'My premium assistance reimbursement amount is incorrect or I was denied a hardship waiver',
        'fields': ['cvgSuspensionBeginDate', 'cvgSuspensionEndDate', 'qualifyHardshipAndAssistanceTxt']
      },
      {
        'code': 'S',
        'description': 'My coverage has been suspended',
        'rank': '6',
        'spDescription': 'My coverage has been suspended',
        'fields': ['cvgSuspensionBeginDate', 'cvgSuspensionEndDate']
      }
    ],
    'APR': [
      {
        'code': 'CB',
        'description': 'Change in type of coverage',
        'spDescription': 'Change in type of coverage',
        'fields': ['terminationIncorrectTxt']
      },
      {
        'code': 'FP',
        'description': 'I lost coverage for Failure to return a Renewal Packet',
        'rank': '2',
        'spDescription': 'I lost coverage for Failure to return a Renewal Packet',
        'fields': ['terminationIncorrectTxt', 'renewalPktSw', 'sendCompletedPacketSw', 'packetReturnDate', 'otherPeopleListedSw', 'packetNamesList', 'packetSendType']
      },
      {
        'code': 'FT',
        'description': 'I lost coverage for Failure to return a requested Verification',
        'rank': '3',
        'spDescription': 'I lost coverage for Failure to return a requested Verification',
        'fields': ['terminationIncorrectTxt', 'otherPeopleListedSw', 'letterReceivedMoreInfoSw', 'requestedInfoSentSw', 'addInfoSentDate', 'addInfoSentType']

      },
      {
        'code': 'FD',
        'description': 'I was denied coverage for Failure to return a Verification Document',
        'rank': '3',
        'spDescription': 'I was denied coverage for Failure to return a Verification Document',
        'fields': ['denialTxt', 'otherPeopleListedSw', 'letterReceivedMoreInfoSw', 'requestedInfoSentSw', 'addInfoSentDate', 'addInfoSentType']

      },
      {
        'code': 'RI',
        'description': 'Renewal - Other',
        'rank': '4',
        'spDescription': 'Renewal - Other',
        'fields': ['terminationIncorrectTxt', 'renewalPktSw', 'sendCompletedPacketSw', 'packetReturnDate', 'otherPeopleListedSw', 'packetNamesList', 'packetSendType', 'letterReceivedMoreInfoSw', 'requestedInfoSentSw', 'addInfoSentDate', 'addInfoSentType']
      },
      {
        'code': 'TD',
        'description': 'My coverage is ending/has ended or I was denied benefits',
        'rank': '5',
        'spDescription': 'My coverage is ending/has ended or I was denied benefits',
        'fields': ['terminationIncorrectTxt']
      }
    ],
    'APL': [
      {
        'code': 'CB',
        'fields': ['nursingFacilityOrHCBS','benefitChgTxt']
      },
      {
        'code': 'CEE',
        'description': 'My coverage is ending or ended',
        'rank': '2',
        'spDescription': 'My coverage is ending or ended',
        'fields': ['terminationIncorrectTxt','nursingFacilityOrHCBS']
      },
      {
        'code': 'D',
        'description': 'I was denied benefits',
        'rank': '3',
        'spDescription': 'I was denied benefits',
        'fields': ['nursingFacilityOrHCBS','applyDateSW', 'applyDateRangeTxt', 'denialSW', 'denialTxt']
      },
      {
        'code': 'ED',
        'description': 'The start/end date for my coverage is incorrect',
        'rank': '4',
        'spDescription': 'The start/end date for my coverage is incorrect',
        'fields': ['nursingFacilityOrHCBS','applyDateSW', 'applyDateRangeTxt', 'appealFilingLocation', 'elgBeginDate', 'elgEffectiveDateTxt']
      },
      {
        'code': 'PL',
        'description': 'My patient liability amount is incorrect',
        'rank': '6',
        'spDescription': 'My patient liability amount is incorrect',
        'fields': ['nursingFacilityOrHCBS','patientLiabilityTxt']
      }
    ]
  }
  appealCategoryValues = [];
  applyDateForBenefit: string = '';

  constructor(public builder: FormBuilder, private titleService: Title, public translateService: TranslateService) {
    super(builder)
    this.titleService.setTitle('Apply For Details');
  };

  ngAfterContentInit() {
    this.appealReasonCode = this.getValue('data.appealReasonCd', this.formGroup);
    this.appealTypeValue = this.getValue('data.appealType', this.formGroup);
    this.formGroup.controls.data.disable();
    this.getControl('data.aplNum', this.formGroup).enable();
    const appealNum = this.getValue('data.aplNum');
    sessionStorage.setItem('appealNum', appealNum);
    this.getControl('data.appealCategory', this.formGroup).enable();
    this.applyDateForBenefit = this.getValue('data.applyDateForBenefit', this.formGroup);
    this.appealCategoryValues = this.getValue('data.appealCategory', this.formGroup) || [];
    const renewalExcludeCodes = ['FP', 'RI']
    if((this.appealTypeValue === 'APR' && !renewalExcludeCodes.includes(this.appealReasonCode)) || this.appealTypeValue !== 'APR') {
       this.getControl('data.sendCompletedPacketSw', this.formGroup).patchValue(null);
    }
    if (this.data[this.appealTypeValue]) {
      const commonFields = ['appealReasonTxt', 'elgNoticeIssuesSw', 'elgNoticeIssuesTxt', 'appellantPregnantOrChildSw', 'priorMedServReceivedSw', 'filingWithin90DaysPregSw', 'newBornSw', 'appealIssueSame', 'previousFiledAppeal', 'enterAppealNum'];
      const conditionalValidationFields = ['sendCompletedPacketSw', 'packetReturnDate', 'packetReturnDateRangeTxt', 'otherPeopleListedSw','packetSendType', 'requestedInfoSentSw', 'addInfoSentDate', 'addInfoDateRangeTxt', 'addInfoSentType', 'elgNoticeIssuesTxt'];
      const noValidationFields = ['cvgSuspensionBeginDate', 'cvgSuspensionEndDate', 'sendCompletedPacketSw', 'otherPeopleListedSw', 'packetNamesList', 'packetSendType', 'requestedInfoSentSw', 'enterAppealNum'];
      const appeal = this.data[this.appealTypeValue].find(appeal => appeal.code === this.appealReasonCode);

      [...(appeal.fields || []), ...commonFields].forEach(field => {
        const control = this.getControl(`data.${field}`, this.formGroup);
        if (![...conditionalValidationFields].includes(field)) {
          control.enable();
        }
        if (![...conditionalValidationFields, ...noValidationFields].includes(field)) {
          control.setValidators(Vs.required);
        }
      });
      const applyDateSWValue = this.getControl(`data.applyDateSW`, this.formGroup)
      if(applyDateSWValue) {
        this.onChange('applyDateSW')
      }
      const elgNoticeIssuesSwControl = this.getControl(`data.elgNoticeIssuesSw`, this.formGroup)
      const elgNoticeIssuesSwValue = this.getValue(`data.elgNoticeIssuesSw`, this.formGroup)
      if (elgNoticeIssuesSwControl.status !== 'DISABLED' && elgNoticeIssuesSwValue?.code) {
        this.setValidationAndEnableOnLoad('elgNoticeIssuesTxt', elgNoticeIssuesSwValue?.code);
      } else if (elgNoticeIssuesSwControl.status === 'DISABLED') {
        this.getControl(`data.elgNoticeIssuesTxt`, this.formGroup).reset();
        this.getControl(`data.elgNoticeIssuesTxt`, this.formGroup).clearValidators();
        this.getControl(`data.elgNoticeIssuesTxt`, this.formGroup).updateValueAndValidity();
      }

      const renewalPktSwControl = this.getControl(`data.renewalPktSw`, this.formGroup)
      const renewalPktSwValue = this.getValue(`data.renewalPktSw`, this.formGroup)
      if (renewalPktSwControl.status !== 'DISABLED' && renewalPktSwValue?.code) {
        this.setValidationAndEnableOnLoad('sendCompletedPacketSw', renewalPktSwValue?.code);
      } else if (elgNoticeIssuesSwControl.status === 'DISABLED') {
        this.getControl(`data.sendCompletedPacketSw`, this.formGroup).reset();
        this.getControl(`data.sendCompletedPacketSw`, this.formGroup).clearValidators();
        this.getControl(`data.sendCompletedPacketSw`, this.formGroup).updateValueAndValidity();
      }

      const sendCompletedPacketSwControl = this.getControl(`data.renewalPktSw`, this.formGroup)
      const sendCompletedPacketSwValue = this.getValue(`data.sendCompletedPacketSw`, this.formGroup)
      if (sendCompletedPacketSwControl.status !== 'DISABLED' && sendCompletedPacketSwValue?.code) {
        this.setValidationAndEnableOnLoad('packetReturnDate', sendCompletedPacketSwValue?.code);
        this.setValidationAndEnableOnLoad('packetReturnDateRangeTxt', sendCompletedPacketSwValue?.code);
        this.setValidationAndEnableOnLoad('otherPeopleListedSw', sendCompletedPacketSwValue?.code);
        this.setValidationAndEnableOnLoad('packetSendType', sendCompletedPacketSwValue?.code);
      } else if (sendCompletedPacketSwControl.status === 'DISABLED' && (!(["FT", "FD"]).includes(this.appealReasonCode))) {
        this.getControl(`data.packetReturnDate`, this.formGroup).reset();
        this.getControl(`data.packetReturnDate`, this.formGroup).clearValidators();
        this.getControl(`data.packetReturnDate`, this.formGroup).updateValueAndValidity();
        this.getControl(`data.packetReturnDateRangeTxt`, this.formGroup).reset();
        this.getControl(`data.packetReturnDateRangeTxt`, this.formGroup).clearValidators();
        this.getControl(`data.packetReturnDateRangeTxt`, this.formGroup).updateValueAndValidity();
        this.getControl(`data.otherPeopleListedSw`, this.formGroup).reset();
        this.getControl(`data.otherPeopleListedSw`, this.formGroup).clearValidators();
        this.getControl(`data.otherPeopleListedSw`, this.formGroup).updateValueAndValidity();
        this.getControl(`data.packetNamesList`, this.formGroup).reset();
        this.getControl(`data.packetNamesList`, this.formGroup).updateValueAndValidity();
        this.getControl(`data.packetSendType`, this.formGroup).reset();
        this.getControl(`data.packetSendType`, this.formGroup).clearValidators();
        this.getControl(`data.packetSendType`, this.formGroup).updateValueAndValidity();
      } else if(sendCompletedPacketSwControl.status === 'DISABLED' && (this.appealReasonCode === "FT" || this.appealReasonCode === "FD")) {
        this.setValidationAndEnableOnLoad('otherPeopleListedSw', 'Y');
      }

      const letterReceivedMoreInfoSwControl = this.getControl(`data.letterReceivedMoreInfoSw`, this.formGroup)
      const letterReceivedMoreInfoSwValue = this.getValue(`data.letterReceivedMoreInfoSw`, this.formGroup)
      if (letterReceivedMoreInfoSwControl.status !== 'DISABLED' && letterReceivedMoreInfoSwValue?.code) {
        this.setValidationAndEnableOnLoad('requestedInfoSentSw', letterReceivedMoreInfoSwValue?.code);
      } else if (letterReceivedMoreInfoSwControl.status === 'DISABLED') {
        this.getControl(`data.requestedInfoSentSw`, this.formGroup).reset();
        this.getControl(`data.requestedInfoSentSw`, this.formGroup).clearValidators();
        this.getControl(`data.requestedInfoSentSw`, this.formGroup).updateValueAndValidity();
      }

      const requestedInfoSentSwControl = this.getControl(`data.requestedInfoSentSw`, this.formGroup)
      const requestedInfoSentSwValue = this.getValue(`data.requestedInfoSentSw`, this.formGroup)
      if (requestedInfoSentSwControl.status !== 'DISABLED' && requestedInfoSentSwValue?.code) {
        this.setValidationAndEnableOnLoad('addInfoSentDate', requestedInfoSentSwValue?.code);
        this.setValidationAndEnableOnLoad('addInfoDateRangeTxt', requestedInfoSentSwValue?.code);
        this.setValidationAndEnableOnLoad('addInfoSentType', requestedInfoSentSwValue?.code);
      } else {
        this.getControl(`data.addInfoSentDate`, this.formGroup).reset();
        this.getControl(`data.addInfoSentDate`, this.formGroup).clearValidators();
        this.getControl(`data.addInfoSentDate`, this.formGroup).updateValueAndValidity();
        this.getControl(`data.addInfoDateRangeTxt`, this.formGroup).reset();
        this.getControl(`data.addInfoDateRangeTxt`, this.formGroup).clearValidators();
        this.getControl(`data.addInfoDateRangeTxt`, this.formGroup).updateValueAndValidity();
        this.getControl(`data.addInfoSentType`, this.formGroup).reset();
        this.getControl(`data.addInfoSentType`, this.formGroup).clearValidators();
        this.getControl(`data.addInfoSentType`, this.formGroup).updateValueAndValidity();
      }
      
        const packetReturnDateControl = this.getControl(`data.packetReturnDate`, this.formGroup)
        const packetReturnDateRangeTxtControl = this.getControl(`data.packetReturnDateRangeTxt`, this.formGroup);
        if(packetReturnDateControl.status === 'VALID') {
          packetReturnDateRangeTxtControl.reset();
          packetReturnDateRangeTxtControl.disable();
        } else {
          packetReturnDateRangeTxtControl.enable();
        }
      
        if(packetReturnDateRangeTxtControl.status === 'VALID') {       
          packetReturnDateControl.reset();
          packetReturnDateControl.disable();
          this.packetReturnDateValidation = false;
        } else {
          packetReturnDateControl.enable();
          this.packetReturnDateValidation = true;
        }
            
        const addInfoSentDateControl = this.getControl(`data.addInfoSentDate`, this.formGroup)
        const addInfoDateRangeTxtControl = this.getControl(`data.addInfoDateRangeTxt`, this.formGroup);
        if(addInfoSentDateControl.status === 'VALID') {
          addInfoDateRangeTxtControl.reset();
          addInfoDateRangeTxtControl.disable();
        } else {
          addInfoDateRangeTxtControl.enable();
        }
        if(addInfoDateRangeTxtControl.status === 'VALID') {       
          addInfoSentDateControl.reset();
          addInfoSentDateControl.disable();
          this.addInfoSentDateValidation = false;
        } else {
          addInfoSentDateControl.enable();
          this.addInfoSentDateValidation = true;
        }
      
    }
    if (this.getValue(`data.appellantPregnantOrChildSw`, this.formGroup).code === 'N') {
      this.getControl(`data.priorMedServReceivedSw`, this.formGroup).reset();
      this.getControl(`data.priorMedServReceivedSw`, this.formGroup).clearValidators();
      this.getControl(`data.priorMedServReceivedSw`, this.formGroup).updateValueAndValidity();
    } else {
      this.getControl(`data.priorMedServReceivedSw`, this.formGroup).setValidators(Vs.required);
      this.getControl(`data.priorMedServReceivedSw`, this.formGroup).updateValueAndValidity();
    }

    this.defaultLanguage = this.translateService.getDefaultLang();
    this.languageCheck(this.defaultLanguage);
    this.translatedLang = this.defaultLanguage
    if(this.translatedLang === 'en') {
      this.startTypingPlaceHolder = "Start typing here..."
      this.appealNumberPlaceHolder = "List each appeal number separated by a comma...";
    } else {
      this.startTypingPlaceHolder = "Empieza a escribir aquí..."
      this.appealNumberPlaceHolder = "Escriba cada número de apelación separándolos con una coma...";
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      if(this.translatedLang === 'en') {
        this.startTypingPlaceHolder = "Start typing here..."
        this.appealNumberPlaceHolder = "List each appeal number separated by a comma..."
      } else {
        this.startTypingPlaceHolder = "Empieza a escribir aquí..."
        this.appealNumberPlaceHolder = "Escriba cada número de apelación separándolos con una coma...";
      }
      this.languageCheck(res.lang);
    })
    const packetReturnDateControl = this.getControl('data.packetReturnDate', this.formGroup);
    packetReturnDateControl.valueChanges.subscribe(() => {
      this.onChange('packetReturnDate');
    })
    const addInfoSentDateControl = this.getControl('data.addInfoSentDate', this.formGroup);
    addInfoSentDateControl.valueChanges.subscribe(() => {
      this.onChange('addInfoSentDate');
    })
  }

  get appealCategory(): FormArray {
    return this.getControl('data.appealCategory') as FormArray;
  }

  onInput(event : Event){
    const input = event.target as HTMLTextAreaElement;
    const cursorPosition = input.selectionStart;
    const value = input.value;
    const filteredValue = this.removeEmojis(value)
    // Update the textarea value without emojis
     input.value = filteredValue;
     // Restore the cursor position
     input.setSelectionRange(cursorPosition, cursorPosition);
     this.getControl(`data.${input.name}`, this.formGroup).patchValue(filteredValue);
 
  }
  removeEmojis(value: string) {
    const emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|[\r]|[^a-zA-Z0-9\n\\\\s~`! @#$%^&*()_|'"\-+={}\[\]:;”’,.?/])/g;
    return value.replace(emojiRegex, '');
  }

  onAppealNumberInput(event : Event){
    const input = event.target as HTMLTextAreaElement;
    const cursorPosition = input.selectionStart;
    const value = input.value;
    const filteredValue = this.restrictSpecialCharsandAlphabets(value)
    // Update the textarea value without emojis
     input.value = filteredValue;
     // Restore the cursor position
     input.setSelectionRange(cursorPosition, cursorPosition);
     this.getControl(`data.${input.name}`, this.formGroup).patchValue(filteredValue);
  }
  restrictSpecialCharsandAlphabets(value: string) {
    const emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|[\r]|[^0-9\n ,])/g;
    return value.replace(emojiRegex, '');
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang, false)
  }

  onChange(control) {
    if (control === 'elgNoticeIssuesSw') {
      const val = this.getValue(`data.elgNoticeIssuesSw`, this.formGroup)
      this.setValidationAndEnable('elgNoticeIssuesTxt', val?.code);
    }
    if (control === 'renewalPktSw') {
      const val = this.getValue(`data.renewalPktSw`, this.formGroup)
      this.setValidationAndEnable('sendCompletedPacketSw', val?.code);
    }
    if (control === 'otherPeopleListedSw') {
      this.getControl(`data.packetNamesList`, this.formGroup).reset();
      this.getControl(`data.packetNamesList`, this.formGroup).updateValueAndValidity();
    }
    if (control === 'sendCompletedPacketSw') {
      const val = this.getValue(`data.sendCompletedPacketSw`, this.formGroup)
      this.setValidationAndEnable('packetReturnDate', val?.code);
      this.setValidationAndEnable('packetReturnDateRangeTxt', val?.code);
      this.setValidationAndEnable('otherPeopleListedSw', val?.code);
      this.getControl(`data.packetNamesList`, this.formGroup).reset();
      this.getControl(`data.packetNamesList`, this.formGroup).updateValueAndValidity();
      this.setValidationAndEnable('packetSendType', val?.code);
    }
    if (control === 'letterReceivedMoreInfoSw') {
      const val = this.getValue(`data.letterReceivedMoreInfoSw`, this.formGroup)
      this.setValidationAndEnable('requestedInfoSentSw', val?.code);
      this.setValidationAndEnable('addInfoSentDate', val?.code);
      this.setValidationAndEnable('addInfoDateRangeTxt', val?.code);
      this.setValidationAndEnable('addInfoSentType', val?.code);
    }
    if (control === 'appellantPregnantOrChildSw') {
      const val = this.getValue(`data.appellantPregnantOrChildSw`, this.formGroup)
      this.setValidationAndEnable('priorMedServReceivedSw', val?.code);
    }
    if (control === 'requestedInfoSentSw') {
      const val = this.getValue(`data.requestedInfoSentSw`, this.formGroup)
      this.setValidationAndEnable('addInfoSentDate', val?.code);
      this.setValidationAndEnable('addInfoDateRangeTxt', val?.code);
      this.setValidationAndEnable('addInfoSentType', val?.code);
    }
    if (control === 'applyDateSW') {
      const val = this.getValue(`data.applyDateSW`, this.formGroup)
      if(val.code === 'N') {
        const applyDateRangeTxtControl = this.getControl(`data.applyDateRangeTxt`, this.formGroup)
        applyDateRangeTxtControl.setValidators(Vs.required);
        applyDateRangeTxtControl.updateValueAndValidity();
      } else {
        const applyDateRangeTxtControl = this.getControl(`data.applyDateRangeTxt`, this.formGroup)
        applyDateRangeTxtControl.reset();
        applyDateRangeTxtControl.clearValidators();
        applyDateRangeTxtControl.updateValueAndValidity();
      }
    }
    if (control === 'packetReturnDate') {
      const packetReturnDateControl = this.getControl(`data.packetReturnDate`, this.formGroup)
      const packetReturnDateRangeTxtControl = this.getControl(`data.packetReturnDateRangeTxt`, this.formGroup);
      if(packetReturnDateControl.status === 'VALID') {
        packetReturnDateRangeTxtControl.reset();
        packetReturnDateRangeTxtControl.disable();
      } else {
        packetReturnDateRangeTxtControl.enable();
      }
    }
    if (control === 'packetReturnDateRangeTxt') {
      const packetReturnDateRangeTxtControl = this.getControl(`data.packetReturnDateRangeTxt`, this.formGroup)
      const packetReturnDateControl = this.getControl(`data.packetReturnDate`, this.formGroup)
      if(packetReturnDateRangeTxtControl.status === 'VALID') {       
        packetReturnDateControl.reset();
        packetReturnDateControl.disable();
        this.packetReturnDateValidation = false;
      } else {
        packetReturnDateControl.enable();
        this.packetReturnDateValidation = true;
      }
    }
    if (control === 'addInfoSentDate') {
      const addInfoSentDateControl = this.getControl(`data.addInfoSentDate`, this.formGroup)
      const addInfoDateRangeTxtControl = this.getControl(`data.addInfoDateRangeTxt`, this.formGroup);
      if(addInfoSentDateControl.status === 'VALID') {
        addInfoDateRangeTxtControl.reset();
        addInfoDateRangeTxtControl.disable();
      } else {
        addInfoDateRangeTxtControl.enable();
      }
    }
    if (control === 'addInfoDateRangeTxt') {
      const addInfoDateRangeTxtControl = this.getControl(`data.addInfoDateRangeTxt`, this.formGroup)
      const addInfoSentDateControl = this.getControl(`data.addInfoSentDate`, this.formGroup)
      if(addInfoDateRangeTxtControl.status === 'VALID') {       
        addInfoSentDateControl.reset();
        addInfoSentDateControl.disable();
        this.addInfoSentDateValidation = false;
      } else {
        addInfoSentDateControl.enable();
        this.addInfoSentDateValidation = true;
      }
    }
  }

  setValidationAndEnable(controlName, code) {
    const control = this.getControl(`data.${controlName}`, this.formGroup);
    control.reset()
    if (code === 'Y') {
      control.setValidators(Vs.required);
      control.enable();
    } else {
      control.clearValidators();
      control.disable();
    }

  }

  setValidationAndEnableOnLoad(controlName, code) {
    const control = this.getControl(`data.${controlName}`, this.formGroup);
    if (code === 'Y') {
      control.setValidators(Vs.required);
      control.enable();
    } else {
      control.clearValidators();
      control.disable();
    }

  }


  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

}
