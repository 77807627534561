import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotificationsService } from "@app/services/notification/notification.service";
import { NotificationError } from "@app/models/partials.model";

import { ServiceConfigService } from '@app/services/service-config.service';

@Injectable()
export class ApiService {
  entityId: number;
  mpToken: any;
  mtlId: string;
  constructor(private http: HttpClient, private notify: NotificationsService, private serverConfig: ServiceConfigService) {}

  apiContext() {
    return `${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/`;
  }

  appApiContext() {
    return `${this.serverConfig.getServerConfig()['gatewayURL']}easy-app/api/indi/`;
  }

  get(url: string, isApp?: any, httpOptions?: any) {
    const context = isApp && isApp === true ? this.appApiContext() : this.apiContext();
    const path = context + url;
    return this.http.get<any>(path, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  
  post(url: string, data: any, httpOptions?: any, isApp?: any) {
    const context = isApp ? this.appApiContext() : this.apiContext();
    const path = context + url;
    return this.http.post<any>(path, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  put(url: string, data: any, httpOptions?: any) {
    const path = this.apiContext() + url;
    return this.http.put<any>(path, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  delete(url: string, id: any, httpOptions?: any) {
    const path = `${this.apiContext()}${url}/${id}`;
    return this.http.delete<any>(path, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    const errorMessages = error.errors?.map(e => new NotificationError(e.message, e.code, '', null));
    if (errorMessages && errorMessages.length && this.notify) {
      errorMessages.forEach(errorMessage => this.notify.add(errorMessage));
    }
    return throwError(error);
  }

  // @ts-ignore
  deleteTest(url: string, id: any, httpOptions?: any) {
    return of(null);
  }

  setMtlId(mtlId: string) {
    this.mtlId = mtlId;
    if(this.mtlId) {
      sessionStorage.setItem('mtlToken', this.mtlId)
    }
  }

  setEntityId(entityId: number) {
    this.entityId = entityId;
  }

  getEntityId() {
      // return this.entityId;
    return sessionStorage.getItem('mtlToken');
  }

  setMpToken(mpToken: number) {
    this.mpToken = mpToken;
  }

  setAhsMpToken(mpToken) {
    this.mpToken = mpToken;
    if (this.mpToken) {
      sessionStorage.setItem('AhsMpToken', encodeURIComponent(this.mpToken));
    }
  }

  getAhsMpToken() {
    return sessionStorage.getItem('AhsMpToken');
  }

  getMpToken() {
    return this.mpToken;
  }

  removeQueryParam(param: string) {
    const url = new URL(window.location.href);
    if(url.searchParams.has(param)) {
      url.searchParams.delete(param);
      window.history.replaceState({}, document.title, url);
    }
  }
}
