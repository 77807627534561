import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/gateway/services/api.service";
// import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppealsService {
  appealContinuanceForm;
  selectedItem;
  response;
  message;
  showMessage;
  messageWithdrawal;
  showMessageWithdrawal;
  loginIndvId;

  constructor(private apiService: ApiService) {}

  saveContinuance(info, controls, currentLanguage) {
    const url = "appeals/submitcontinuance";
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.loginIndvId = currentUser?.individualId;
    const saveContinuance = {}
    saveContinuance['reasonForContinuance'] = controls['reasonForContinuance'].value;
    saveContinuance['relationshipWithAppellant'] = controls['relationshipWithAppellant'].value;
    saveContinuance['appealNumber'] = info?.appealNumber;
    saveContinuance['requestDate'] = info?.requestDate;
    saveContinuance['hearingDate'] = info?.hearingDate ? info?.hearingDate : null;
    saveContinuance["userId"] = JSON.parse(sessionStorage.getItem('currentUser')).userId;
    saveContinuance["loginIndvId"] = this.loginIndvId;
    saveContinuance['lang'] = currentLanguage?.toUpperCase();
    saveContinuance['appellantSignSw'] = controls['appellantSignSW'].value;
    saveContinuance['appellantSign'] = controls['appellantSign'].value;
    saveContinuance['submittedBy'] = controls['submittedBy'].value;


      this.apiService.post(url, saveContinuance, {headers: new HttpHeaders({'pageId': 'TCAPC'})}, true).subscribe((res) => {
        this.response = res;

        if (res && res['returnType'].message === 'SUCCESS') {
          this.showMessage = true;
          this.message = 'SUCCESS_CONTINUANCE'
        } else if (res && res['returnType'].message === 'ERROR') {
          this.showMessage = true;
          this.message = 'ERROR_MESSAGE'
        } else {
          this.showMessage = false;
        }
      });
  }

  saveWithdrawal(info, controls, currentLanguage) {
    const url = "appeals/submitwithdrawal";
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.loginIndvId = currentUser?.individualId;
    const saveWithdrawal = {}
    saveWithdrawal['appealNumber'] = info?.appealNumber;
    saveWithdrawal['caseNumber'] = info?.caseNumber;
    saveWithdrawal['appealStatus'] = info?.appealStatusDesc;
    saveWithdrawal['appealReason'] = info?.appealReason;
    saveWithdrawal['continueBenefits'] = info?.continueBenefits;
    saveWithdrawal['phoneNumber'] = controls['phoneNumber']?.value;
    saveWithdrawal['hearingNoticeSw'] = controls['hearingNoticeSw']?.value;
    saveWithdrawal['moveOutStateSW'] = controls['moveOutStateSW']?.value;
    saveWithdrawal['validateSw'] = controls['validateSw']?.value;
    saveWithdrawal["userId"] = JSON.parse(sessionStorage.getItem('currentUser')).userId;
    saveWithdrawal["loginIndvId"] = this.loginIndvId;
    saveWithdrawal['indvId'] = info?.indvId;
    saveWithdrawal['lang'] = currentLanguage?.toUpperCase();
    saveWithdrawal['reasonForWithdrawal'] = controls['reasonForWithdrawal'].value;
    saveWithdrawal['relationshipWithAppellant'] = controls['relationshipWithAppellant'].value;
    saveWithdrawal['appellantSignSw'] = controls['appellantSignSW'].value;
    saveWithdrawal['appellantSign'] = controls['appellantSign'].value;
    saveWithdrawal['submittedBy'] = controls['submittedBy'].value;

/* add pageid */
      this.apiService.post(url, saveWithdrawal,  {headers: new HttpHeaders({'pageId': 'TCAPC'})}, true).subscribe((res) => {
        this.response = res;

        if (res && res['returnType'].message === 'SUCCESS') {
          this.showMessageWithdrawal = true;
          this.messageWithdrawal = 'SUCCESS_CONTINUANCE'
        } else if (res && res['returnType'].message === 'ERROR') {
          this.showMessageWithdrawal = true;
          this.messageWithdrawal = 'ERROR_MESSAGE'
        } else {
          this.showMessageWithdrawal = false;
        }
      });
  }

}