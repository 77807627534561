<div *ngIf="isRmbRmc"><span>{{ staticText }}</span></div>
<div *ngIf="isRmbRmc && this.selectedIndividual">
  <br>
</div>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label">{{ selectedIndividual | fullname }}</h6>
</div><br>
<nh-form [topLevelName] = "'liq'" [individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  topLevelQuestion="{{ this.topLevelQuestion }}"
  individualQuestion="{{ this.individualQuestion }}"
  (validate)="onValidate($event)" (individualAnswerChanged)="onIndividualChange($event)">
  <section [formGroup]="getControl('data')">
    <div *ngIf="!hideAssetType">
    <h5 class="section-header">{{'FINANCIAL_RESOURCES_STATIC_TEXT1' | translate }}</h5>
    <hr>
    <nh-field id = "liq_assetType"
      label="{{'FINANCIAL_RESOURCES_STATIC_TEXT2' | translate }} {{ selectedIndividual | fullname }} {{'FINANCIAL_RESOURCES_STATIC_TEXT3' | translate }}" class="label-section">
      <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'FINANCIAL_RESOURCES_STATIC_TEXT4' | translate }}</div>
      <select nh aria-label="assetType" name="assetType" (change)="onValueChange()" [items]="tableCodeValue('FINRESTYPE', 'data.assetType')" [attr.disabled]="this.exported ? true : null" formControlName="assetType"></select>
    </nh-field>
  </div>

    <div class="row pl-1 pt-1 hover-red">
      <a class="btn btn-link hover-red" (click)="financialModal()">
        <span class="pr-2">{{'FINANCIAL_RESOURCES_STATIC_TEXT5' | translate }}</span>
        <span><i class="fa fa-external-link"></i></span>
      </a>
    </div>

    <br>
    <div [formGroup]="getControl('data.trustInformation')">
      <nh-field id = "liq_trustType" *ngIf="isEnabled('data.trustInformation.trustType')" label="{{'FINANCIAL_RESOURCES_STATIC_TEXT6' | translate }}" class="label-section">
        <select nh name="trustType" formControlName="trustType" [items]="tableCodeValue('TRUSTTYPE', 'data.trustInformation.trustType')"></select>
      </nh-field>

      <nh-field id = "liq_trusteeName" *ngIf="isEnabled('data.trustInformation.trusteeName')" label="{{'FINANCIAL_RESOURCES_STATIC_TEXT7' | translate }}"
        class="label-section">
        <input type="text" name="trusteeName" formControlName="trusteeName" maxlength="20" class="form-control">
      </nh-field>

      <nh-field id = "liq_beneficiaryOfTrust" *ngIf="isEnabled('data.trustInformation.beneficiaryOfTrust')"
        label="{{'IS_1' | translate }}{{ selectedIndividual | fullname }} {{'FINANCIAL_RESOURCES_STATIC_TEXT8' | translate }}" class="label-section">
        <nh-popover class="pl-1" [content]="'FINANCIAL_RESOURCES_STATIC_TEXT9' | translate"></nh-popover>
        <select nh name="beneficiaryOfTrust" formControlName="beneficiaryOfTrust" [items]="tableCodeValue('YESNO', 'data.trustInformation.beneficiaryOfTrust')"></select>
      </nh-field>

      <nh-field id = "liq_ownTrust" *ngIf="isEnabled('data.trustInformation.ownTrust')"
        label="{{'FINANCIAL_RESOURCES_STATIC_TEXT10' | translate }} {{ selectedIndividual | fullname }}?" class="label-section">
        <select nh name="ownTrust" formControlName="ownTrust" [items]="tableCodeValue('YESNO', 'data.trustInformation.ownTrust')"></select>
      </nh-field>
    </div>

    <nh-field id = "liq_retireOrJobEnd" *ngIf="isEnabled('data.retireOrJobEnd')"
      label="{{'DOES_2' | translate }}{{ selectedIndividual | fullname }} {{'FINANCIAL_RESOURCES_STATIC_TEXT11' | translate }}"
      class="label-section">
      <select nh name="retireOrJobEnd" aria-label="retireOrJobEnd" formControlName="retireOrJobEnd"
      [items]="tableCodeValue('YESNO', 'data.retireOrJobEnd')"></select>
    </nh-field>

    <nh-field id = "liq_beneficiaryOrDonor" *ngIf="isEnabled('data.beneficiaryOrDonor')"
      label="{{'IS_1' | translate }}{{ selectedIndividual | fullname }} {{'FINANCIAL_RESOURCES_STATIC_TEXT12' | translate }}" class="label-section">
      <select nh name="beneficiaryOrDonor" formControlName="beneficiaryOrDonor" [items]="tableCodeValue('BNDN', 'data.beneficiaryOrDonor')"></select>
    </nh-field>

    <nh-field id = "liq_assetAmount" [mandatory]="false" *ngIf="isEnabled('data.assetAmount')"
      label="{{'FINANCIAL_RESOURCES_STATIC_TEXT13' | translate }} {{ selectedIndividual | fullname }}{{'FINANCIAL_RESOURCES_STATIC_TEXT14' | translate }}{{ getValue('data.assetType').value === 'CC'|| getValue('data.assetType').value === 'CIA' ? '': getValue('data.assetType').value}}?"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="assetAmount" formControlName="assetAmount" class="form-control">
      </nh-input-group>
    </nh-field>

    <div [formGroup]="getControl('data.accountInformation')">
      <div *ngIf="this.translatedLang === 'en'">
      <nh-field id = "liq_accountNumber" *ngIf="isEnabled('data.accountInformation.accountNumber')" [mandatory]="false"
        label="{{'FINANCIAL_RESOURCES_STATIC_TEXT15' | translate }} {{ selectedIndividual | fullname }}'s {{ getValue('data.assetType').value === 'CC' || getValue('data.assetType').value === 'CIA' ? '': getValue('data.assetType').value}} account number?"
        class="label-section">
        <input type="text" name="accountNumber" aria-label="accountNumber" formControlName="accountNumber"
          class="form-control" maxlength="20">
      </nh-field>
    </div>

    <div *ngIf="this.translatedLang !== 'en'">
      <nh-field id = "liq_accountNumber" *ngIf="isEnabled('data.accountInformation.accountNumber')" [mandatory]="false"
        label="{{'FINANCIAL_RESOURCES_STATIC_TEXT15' | translate }} {{ getValue('data.assetType').value === 'CC' || getValue('data.assetType').value === 'CIA' ? '': getValue('data.assetType').value}} de {{ selectedIndividual | fullname }}?"
        class="label-section">
        <input type="text" name="accountNumber" aria-label="accountNumber" formControlName="accountNumber"
          class="form-control" maxlength="20">
      </nh-field>
    </div>

      <nh-search *ngIf="showBankSearch" [applicationNumber]="applicationNumber" keyword="bank" resource="banks"
        [searchOnOpen]="true" [columns]="resultsColumns" [searchGroup]="getControl('extras.helpers.search')"
        [showSearchButton]="false" [showSearch]="true" [sectionId]="'ASLIQ'"  (row)="selectItem($event)" (reset)="deleteItem()" (search)="searchHandler()"
        selectColumn="true">


        <ng-container search-form [formGroup]="getControl('extras.helpers.search')">

          <div *ngIf="isEnabled('extras.helpers.search.bankName')">
            <h5 class="section-header">{{'FINANCIAL_RESOURCES_STATIC_TEXT16' | translate }}</h5>
            <hr>
          </div>

          <nh-field id = "liq_bankName" *ngIf="isEnabled('extras.helpers.search.bankName')" [mandatory]="true"
                    label="{{'FINANCIAL_RESOURCES_STATIC_TEXT17' | translate }}" class="label-section">
            <input type="text" name="bankName" formControlName="bankName" aria-label="bankName" class="form-control"
                   [maxlength]="bankNameLength" [required]="isEnabled('extras.helpers.search.bankName')">
          </nh-field>

          <div class="row">
            <div class="col-12 col-md-4">
              <nh-field id = "liq_state" [isRow]="true" [mandatory]="false" label="{{'STATE' | translate }}" class="label-section">
                <select nh name="state" aria-label="state" [items]="tables.STATE" bindValue="code"
                  formControlName="state" [required]="isEnabled('extras.helpers.search.bankName')"></select>
              </nh-field>
            </div>

            <div class="col-12 col-md-4">
              <nh-field id = "liq_zipCode" [isRow]="true" [mandatory]="false" label="{{'ZIP_CODE' | translate }}" class="label-section">
                <input type="text" name="zipCode" formControlName="zipCode" mask="00000-9999" placeholder="XXXXX-XXXX"
                  class="form-control" />
              </nh-field>
            </div>
        </div>
        </ng-container>
      </nh-search>

      <nh-field id = "liq_bankName" *ngIf="showBankAddress" [mandatory]="true" label="{{'FINANCIAL_RESOURCES_STATIC_TEXT18' | translate }}"
        formGroupName="bankSearch" search-field class="label-section">
        <input type="text" name="bankName" formControlName="bankName" aria-label="bankName" class="form-control"
          [maxlength]="bankNameLength" [attr.disabled]="isAddressDisabled ? true : null" [required]="true">
      </nh-field>

      <nh-name-address [addressname] = "'liq_bankName'" *ngIf="showBankAddress && hasValue('data.accountInformation.bankSearch.bankName')"
        [address]="getControl('data.assetAddress')" [stateDefault]="false" [showPopup]="false" [isAssistingPerson]="true" [tables]="tables"
        [addressFieldMandatory]="true" [isDisabled]="isAddressDisabled"></nh-name-address>
    </div><br>

    <div *ngIf="selectedIndividual">


    <h5 class="section-header">
      {{'CO-OWENERS_TITLE' | translate }}
    </h5>
    <hr>
    <nh-field id = "liq_jointOwnership"
      label="{{'FINANCIAL_RESOURCES_STATIC_TEXT19' | translate }} {{ getValue('data.assetType').value === 'CC'|| getValue('data.assetType').value === 'CIA' ? '': getValue('data.assetType').value}} {{'FINANCIAL_RESOURCES_STATIC_TEXT20' | translate }} {{ selectedIndividual | fullname }}? "
      class="label-section">
      <div class=hint> {{'FINANCIAL_RESOURCES_STATIC_TEXT21' | translate }} {{ selectedIndividual | fullname }} {{'FINANCIAL_RESOURCES_STATIC_TEXT22' | translate }}</div>
      <select nh name="jointOwnership" aria-label="jointOwnership" [items]="tableCodeValue('YESNO', 'data.jointOwnership')"
        formControlName="jointOwnership"></select>
    </nh-field>

    <div *ngIf="isY('data.jointOwnership')">
      <nh-co-owner [jointOwnershipInformation]="getControl('data.jointOwnershipInformation')"
      [coOwnerIndivErrorMessage]="coOwnerIndivErrorMessage"
      [formData]="getControl('data')" [addRowValid]="checkValid()" [coOwnerIndividuals]="coOwnerIndividuals"
        [error]="error" (emitValidate)="coOwnerValidate($event)" (errorMessage)="getErrorMessage($event)"
        (removeRow)="removeRow($event)" (addRow)="addRow()"></nh-co-owner>
    </div>
  </div>
  </section>
</nh-form>
