<p *ngIf="isRmbRmc">{{'RENEWAL_TEXT' | translate}}</p>
<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

  <div [formGroup]="getControl('data')">
    <div class="d-flex align-items-center"><h6 class="person-label">{{'HEAD_OF_HOUSEHOLD' | translate}}: </h6><span class="pl-2 pb-1">{{ fullName }}</span></div>
    <p class="static-text" *ngIf="user !== 'RP'">{{'HOH_MORE_INFO' | translate}}</p>

    <div *ngIf="appType === 'PRG' || appType === 'HPE' || this.appType === 'BCC'">
      <h5 class="section-header">{{'APPLICATION_DATE' | translate}}</h5><hr>
      <div class="form-row">
        <div class="col-6 col-md-6">
          <nh-field id = "hh_dateOfApplication" label="{{'DATE_OF_APPLICATION' | translate}}" class="label-section">
            <nh-datepicker>
              <input type="text" class="form-control" name="dateOfApplication" formControlName="dateOfApplication" aria-label="dateOfApplication"
                placeholder="mm/dd/yyyy" [minDate]="minimumDate" [maxDate]="today" bsDatepicker nh required>
            </nh-datepicker>
          </nh-field>
        </div>
      </div>
    </div>
    <h5 class="section-header">{{'HOH_DEMOGRAPHICS' | translate}}</h5><hr>

    <nh-name-address [addressname] = "'hh_casehead'" [namePopup]="true" [personalName]="getControl('data.casehead')" [isAssistingPerson]="false" [tables]="tables">
    </nh-name-address>
    <br>

    <section formGroupName="languagePreference">
      <nh-field id = "hh_primaryLanguage" [mandatory]="false" label="{{'HOH_LANG_PREFERENCE' | translate}}" class="label-section">
        <select nh name="primaryLanguage" [items]="tables.PRIMARYLANGUAGE" aria-label="primaryLanguage" formControlName="primaryLanguage"></select>
      </nh-field>

      <nh-field id = "hh_otherLanguageName" *ngIf="isEnabled('data.languagePreference.otherLanguageName')" name="otherLanguageName"
        label="{{'IF_OTHER_LANG' | translate}}" class="label-section">
        <input type="text" name="otherLanguageName" class="form-control" aria-label= "otherLanguageName" formControlName="otherLanguageName"
          maxlength="50">
      </nh-field>

      <nh-field id = "hh_letterLanguage" name="letterLanguage" label="{{'LETTER_PREF_LANG' | translate}}" [labelSize]="false">
        <nh-popover *ngIf="user !== 'RP'" style="padding-left: 1px;" content="{{'LANG_POPUP' | translate}}"></nh-popover>
        <select nh name="letterLanguage" [items]="tables.WRITTENLANGUAGE" aria-label="letterLanguage" formControlName="letterLanguage"
          required></select>
      </nh-field>
    </section>

      <!-- <nh-field *ngIf="this.isMedicAidApp" name="letterLanguage" label="{{'LETTER_PREF_LANG' | translate}}"> -->

    <nh-field id = "hh_homeless" name="homeless" label="{{'ARE_YOU_HOMELESS' | translate}}" class="label-section">
      <select nh name="homeless" [items]="getLangData(tables.YESNO)" formControlName="homeless" aria-label="homeless" (change)="homelessOnChange($event)" required></select>
    </nh-field>

    <nh-field id = "hh_safeAtHome" [mandatory]="false" name="safeAtHome" label="{{'MAIL_THROUGH_SAFEATHOME' | translate}}" [labelSize]="false">
      <div class="tooltip">
        <span><a class="toolTip"> <img [src]="'assets/images/Icons/tool-tip-icon.svg'" alt="tooltip"/></a></span>
        <span class="tooltiptext" [innerHTML]="'SAFEATHOME_POPOVER'| translate" placement="auto"></span>
      </div>      
      <select nh name="safeAtHome" [items]="getLangData(tables.YESNO)" aria-label="safeAtHome" formControlName="sahPrgSw" (change)="safeAtHomeOnChange($event)"></select>
    </nh-field>

    <nh-field *ngIf="isY('data.sahPrgSw')" id = "hh_mailId" label="{{'SAFEATHOME_MAILID' | translate }}" class="label-section">
      <div class="hint ml-2">{{'SAFEATHOME_MAILID_HELPTEXT' | translate}}</div>
      <input type="text" name="sahMailId" aria-label="mailId" class="form-control" formControlName="sahMailId" maxlength="50">
    </nh-field>
    
    <nh-field *ngIf="isY('data.sahPrgSw')" id = "hh_safeAtHomeCounty"  name="sahCountyCd" label="{{'SAFEATHOME_COUNTY' | translate}}">
      <select nh name="sahCountyCd" [items]="tables.COUNTY" aria-label="safeAtHomeCounty" formControlName="sahCountyCd"></select>
    </nh-field>

    <div *ngIf="isN('data.homeless') && !isY('data.sahPrgSw')">
    <h5 class="section-header">{{'HOH_HOME_ADDRESS' | translate}}
    </h5><hr>
    <div [formGroup]="getControl('data.physicalAddress')" *ngIf="isEnabled('data.physicalAddress.addressFormat') && !isY('data.sahPrgSw') ">
      <div *ngIf="isEnabled('data.physicalAddress.addressFormat')&& !isY('data.sahPrgSw')">
        <nh-field id = "hh_physical_addressFormat" name="addressFormat" label="{{'ADDRESS_FORMAT' | translate}}" class="label-section">
          <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
          <select nh name="addressFormat" [items]="tables.ADDRESSFORMATTYPES" aria-label="addressFormat" formControlName="addressFormat"></select>
        </nh-field>
      </div>
      <nh-name-address [addressname] = "'hh_physicalAddress'" [addressFormat]="getControl('data.physicalAddress.addressFormat')"
        [address]="getControl('data.physicalAddress.address')" [isAssistingPerson]="false" [tables]="tables">
      </nh-name-address><br>
    </div>
</div>
<div *ngIf="!isY('data.sahPrgSw')">
    <h5 class="section-header">{{'HOH_MAILING_ADDRESS' | translate}}</h5><hr>
    <div [formGroup]="getControl('extras.helpers')">
      <nh-field id = "hh_addMailingInfo" *ngIf="isEnabled('extras.helpers.addMailingInfo') && !isY('data.sahPrgSw')" label="{{'IS_MAILING_ADDRESS_SAME' | translate}}" class="label-section">
        <select nh name="addMailingInfo" [items]="tables.YESNO"  formControlName="addMailingInfo" aria-label="addMailingInfo" required></select>
      </nh-field>
    </div>

    <div *ngIf="isY('data.homeless') && !isY('data.sahPrgSw')" class="static-text">
      <p> {{'HOMELESS_CONTENT_1' | translate}} </p>
      <p> {{'HOMELESS_CONTENT_2' | translate}} </p>
    </div>

    <section [formGroup]="getControl('data.mailingAddress')" *ngIf="isEnabled('data.mailingAddress.addressFormat')">
      <div *ngIf="isEnabled('data.mailingAddress.addressFormat')">
        <nh-field id = "hh_mailing_addressFormat" name="addressFormat" label="{{'ADDRESS_FORMAT' | translate}}" class="label-section">
          <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
          <select nh name="addressFormat" [items]="tables.ADDRESSFORMATTYPES" aria-label="addressFormat" formControlName="addressFormat"></select>
        </nh-field>
      </div>
      <nh-name-address [addressname] = "'hh_mailingAddress'" [addressFormat]="getControl('data.mailingAddress.addressFormat')"
        [address]="getControl('data.mailingAddress.address')" [showPopup]="toolTip" [tables]="tables"></nh-name-address>
    </section>
  </div>

    <h5 class="section-header">{{'HOH_CONTACT_INFO' | translate}}</h5><hr>

    <nh-field id = "hh_applicationDt" label="{{'DATE_OF_APPLICATION' | translate}}" class="label-section" *ngIf="isDateOfApplicationEnabled()">
      <nh-datepicker>
        <input type="text" class="form-control" name="applicationDt" formControlName="applicationDt" aria-label="applicationDt"
          placeholder="mm/dd/yyyy" [minDate]="minimumDate" [maxDate]="today" bsDatepicker nh>
      </nh-datepicker>
    </nh-field>

    <section formGroupName="physicalAddress">
      <nh-field id = "hh_applicationSource" *ngIf="isEnabled('data.physicalAddress.applicationSource') && (isAhsAfb) "  label="{{'APPLICATION_SOURCE' | translate}}" class="label-section">
        <select nh name="applicationSource" [items]="tables.APRNSOURCE"  aria-label="applicationSource" formControlName="applicationSource"></select>
      </nh-field>

      <nh-field id = "hh_applicationSource" *ngIf="isEnabled('data.physicalAddress.applicationSource') && (isAhsRmc) "  label="{{'CHANGEREQUEST_SOURCE' | translate}}" class="label-section">
        <select nh name="applicationSource" [items]="tables.APRNSOURCE"  aria-label="applicationSource" formControlName="applicationSource"></select>
      </nh-field>

      <nh-field id = "hh_applicationSource" *ngIf="isEnabled('data.physicalAddress.applicationSource') && (isAhsRmb) "  label="{{'RENEWAL_SOURCE' | translate}}" class="label-section">
        <select nh name="applicationSource" [items]="tables.APRNSOURCE"  aria-label="applicationSource" formControlName="applicationSource"></select>
      </nh-field>
      <nh-contact-phones [contactPhoneName] = "'hh_physicalAddress_contactPhones'" [phoneNumbers]="getControl('data.physicalAddress.contactPhones')"></nh-contact-phones>

      <nh-field id = "hh_bestTime" [mandatory]="false" *ngIf="isEnabled('data.physicalAddress.bestTime')"
        label="{{'BEST_TIME_TO_CALL' | translate}}" class="label-section">
        <select nh name="bestTime" [items]="tables.CONTACTTIME" aria-label="bestTime" formControlName="bestTime">
        </select>
      </nh-field>
    </section>

    <nh-field id = "hh_healthPlan" *ngIf="appType === 'PRG' || appType === 'HPE' || this.appType === 'BCC'" label="{{'HEALTH_PLAN_PREFERENCE' | translate}}" [mandatory]="false">
      <select *ngIf="!showWellpoint" nh name="healthPlan" [items]="tables.MCONAME" aria-label="healthPlan" formControlName="healthPlan"></select>
      <select *ngIf="showWellpoint" nh name="healthPlan" [items]="tables.MCONAME_UBW" aria-label="healthPlan" formControlName="healthPlan"></select>
    </nh-field>
    <br>
    
    <!-- Header and Label only for FC and FRM -->
    <div *ngIf="(user == 'RP' && (appType === 'AFB' || isFinReassessment))">
      <h5 class="section-header">Assisting Person</h5>
      <hr>

      <p>An Assisting Person can be someone who is helping you complete this application, or it can be someone that,
        with your permission, can make decisions and talk to us on behalf of your household.</p><br>
        
      <p>If you want an Assisting Person to help you, you can tell us what you want your Assisting Person to help with.</p>
    </div>


<!-- MEMBER PORTAL SEQUENCING -->

<!-- 
"assistingOnBehalf - Assis/Self"
"addAssistingPersonToApp - Yes/No" -->


<!-- FIRST QUESTION FOR *MP* AFB, RMC, RMB,  also for AHS AFB, RMB, RMC-->
    <nh-field *ngIf="(user !== 'RP' || isAhsRmbOrRmc || ppViewTccHoHrmbrmc) "  id = "hh_assistingOnBehalf"
      label="{{'ASSISTING_ON_BEHALF_1' | translate}} {{appKey |translate}} {{'ASSISTING_ON_BEHALF_2'|translate}} {{appKey | translate}} {{'ASSISTING_ON_BEHALF_3'|translate}}" class="label-section">
      <nh-popover class="pl-1" field-label content="{{'ASSISTING_PERSON_POPOVER_1' | translate}}"></nh-popover>
      <select nh name="assistingOnBehalf" [items]="tables.MPASSTPER" aria-label="assistingOnBehalf" formControlName="assistingOnBehalf" (change)="onAssistingPersonBehalfSelected($event)"></select>
    </nh-field>

    <!-- SECOND QUESTION FOR *MP* AFB RMB RMC,  also for AHS AFB, RMB, RMC-->
    <nh-field *ngIf="isEnabled('data.addAssistingPersonToApp') && getValue('data.assistingOnBehalf').code === 'S' && (user !== 'RP' || isAhsRmbOrRmc || ppViewTccHoHrmbrmc)"
       id = "hh_addAssistingPersonToApp" label="{{'ADD_TO_APPLICATION' | translate}} {{appKey | translate}}?" [labelSize]="false" class="label-section">
      <nh-popover class="pl-1" field-label content="{{'ASSISTING_PERSON_POPOVER_2' | translate}}"></nh-popover>
      <select nh name="addAssistingPersonToApp" [items]="tables.YESNO" aria-label="addAssistingPersonToApp"
        [formControl]="getControl('data.addAssistingPersonToApp')" (change)="onAssistingPersonSelected($event)"></select>
    </nh-field>






<!-- PARTNER PORTAL SEQUENCING -->

  <!-- FRM
  "ppAddAssistingPersonToApp - Yes/No"
  "ppAssistingOnBehalf - Assis/Self"

  PP FC
  "ppAddAssistingPersonToApp - Yes/No"
  "ppAssistingOnBehalf - Yes/No" -->


    
<!-- FIRST QUESTION FOR FIN RES -->

    <nh-field id = "hh_ppAddAssistingPersonToApp" *ngIf="isEnabled('data.ppAddAssistingPersonToApp') && (user === 'RP'  && this.isFinReassessment)"
      label="Does {{ fullName }} want to add {{partnerName}} as an Assisting Person on this reassessment?" class="label-section">
      <nh-popover class="pl-1" field-label content="{{'ASSISTING_PERSON_POPOVER_2' | translate}}"></nh-popover>
      <select nh name="ppAddAssistingPersonToApp" [items]="tables.YESNO" aria-label="ppAddAssistingPersonToApp"
        [formControl]="getControl('data.ppAddAssistingPersonToApp')" (change)="ppOnAssistingPersonSelected($event)"></select>
    </nh-field>

    <!-- Check the onchange function -->


<!-- FIRST QUESTION FOR FULL COVERAGE-->

    <nh-field *ngIf="isEnabled('data.ppAddAssistingPersonToApp') && (user === 'RP' && appType === 'AFB' && !isAHS)"
    id = "hh_ppAddAssistingPersonToApp" label="Does {{ fullName }} want to add {{partnerName}} as an Assisting Person on this application?" class="label-section">
      <nh-popover class="pl-1" field-label content="{{'ASSISTING_PERSON_POPOVER_2' | translate}}"></nh-popover>
      <select nh name="ppAddAssistingPersonToApp" [items]="tables.YESNO" aria-label="ppAddAssistingPersonToApp"
        [formControl]="getControl('data.ppAddAssistingPersonToApp')" (change)="ppOnAssistingPersonSelected($event)"></select>
    </nh-field>

  <!-- Check the onchange function -->

  <!-- static text -->
    <div  *ngIf = "user === 'RP' && (appType === 'AFB' || isFinReassessment) && !isAHS && isY('data.ppAddAssistingPersonToApp')" class="pl-2 form-check-label" [innerHTML]="'REPRESENTATIVE_ORGANIZATION' | translate"></div>
    <ul *ngIf = "user === 'RP' && (appType === 'AFB' || isFinReassessment) && !isAHS && isY('data.ppAddAssistingPersonToApp')" class="pb-4 list-inline form-check-label">
      <li> {{'REPRESENTATIVE_ORGANIZATION_1' | translate }}</li>
      <li> {{'REPRESENTATIVE_ORGANIZATION_2' | translate }}</li>
      <li> {{'REPRESENTATIVE_ORGANIZATION_3' | translate }}</li>
      <li> {{'REPRESENTATIVE_ORGANIZATION_4' | translate }}</li>
      <li> {{'REPRESENTATIVE_ORGANIZATION_5' | translate }}</li>
    </ul>


<!-- SECOND QUESTION FOR FULL COVERAGE -->

    <nh-field *ngIf="isEnabled('data.ppAssistingOnBehalf') && (isN('data.ppAddAssistingPersonToApp')) && (user === 'RP' && appType === 'AFB' && !isAHS)"
    id = "hh_ppAssistingOnBehalf" label="Does {{ fullName }} want to list an Assisting Person?" class="label-section">
    <div class="hint ml-2">(This would be someone other than {{partnerName}})</div>
    <nh-popover class="pl-1" field-label content="{{'ASSISTING_PERSON_POPOVER_1' | translate}}"></nh-popover>
    <select nh name="ppAssistingOnBehalf" [items]="tables.YESNO" aria-label="ppAssistingOnBehalf" formControlName="ppAssistingOnBehalf" (change)="ppOnAssistingPersonBehalfSelectedFCFRM($event)"></select>
    </nh-field>


<!-- SECOND QUESTION FOR FIN RES -->

    <nh-field id = "hh_ppAssistingOnBehalf" *ngIf="(user === 'RP' && this.isFinReassessment && (isN('data.ppAddAssistingPersonToApp')))"
      label="Does {{ fullName }} want to list an Assisting Person?" class="label-section">
      <div class="hint ml-2">(This would be someone other than {{partnerName}})</div>
      <nh-popover class="pl-1" field-label content="{{'ASSISTING_PERSON_POPOVER_1' | translate}}"></nh-popover>
      <select nh name="ppAssistingOnBehalf" [items]="tables.YESNO"  aria-label="ppAssistingOnBehalf" formControlName="ppAssistingOnBehalf" (change)="ppOnAssistingPersonBehalfSelectedFCFRM($event)"></select>
    </nh-field>




    <section [formGroup]="getControl('data.assistingPerson')"  *ngIf="(isY('data.addAssistingPersonToApp') || getValue('data.assistingOnBehalf').value === 'Assisting Person' || getValue('data.assistingOnBehalf').value === 'Persona que Ayuda' || isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf'))">
      <div>
        <h5 class="section-header"
          *ngIf="(isY('data.addAssistingPersonToApp') || getValue('data.assistingOnBehalf').value === 'Assisting Person' || getValue('data.assistingOnBehalf').value === 'Persona que Ayuda' || isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf'))">
          {{'ASSISTING_PERSON_INFORMATION' | translate}}
        </h5>
        <!-- check heading for FRM and PP FC -->
        <hr>

 <!-- Help Duration - First Question 'NO' or 'SELF'. Second question - 'Yes' -->
        <!-- Help Duration MP, ,  also for AHS AFB, RMB, RMC--->
        <nh-field id = "hh_helpDuration"  [mandatory]='false' *ngIf="( isEnabled('data.assistingPerson.helpDuration') && ((getValue('data.assistingOnBehalf').code === 'S') &&
        isY('data.addAssistingPersonToApp')) && (user !== 'RP' || isAhsRmbOrRmc || ppViewTccHoHrmbrmc))"
          label="{{'ASSIST_PERSON_HELP_DURATION' | translate}} {{'ASSIST_PERSON_HELP_DURATION_NO_ANSWER' | translate}}" class="label-section">
          <select nh name="helpDuration" [items]="tables.AUTHREPTIME" aria-label="helpDuration" formControlName="helpDuration"></select>
        </nh-field>

        <!-- Help Duration PP FC -->
        <nh-field id = "hh_helpDuration" [mandatory]='false' *ngIf="(isEnabled('data.assistingPerson.helpDuration') &&
        ((user === 'RP' && appType === 'AFB' && !isAHS) && (isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf'))))"
          label="{{'ASSIST_PERSON_HELP_DURATION' | translate}} {{'ASSIST_PERSON_HELP_DURATION_NO_ANSWER' | translate}}" class="label-section">
          <select nh name="helpDuration" [items]="tables.AUTHREPTIME" aria-label="helpDuration" formControlName="helpDuration"></select>
        </nh-field>

        <!-- Help Duration PP FRM -->
        <nh-field id = "hh_helpDuration" [mandatory]='false' *ngIf=" isEnabled('data.assistingPerson.helpDuration') &&  
        ((user === 'RP' && this.isFinReassessment) && ((isY('data.ppAddAssistingPersonToApp')) || isY('data.ppAssistingOnBehalf')))"
          label="{{'ASSIST_PERSON_HELP_DURATION' | translate}} {{'ASSIST_PERSON_HELP_DURATION_NO_ANSWER' | translate}}" class="label-section">
          <select nh name="helpDuration" [items]="tables.AUTHREPTIME" aria-label="helpDuration" formControlName="helpDuration"></select>
        </nh-field>

  
<!-- RelationShip Question - First or Second has to be 'YES' -->
      <!-- Only for MP , also for AHS AFB, RMB,RMC -- RelationShip Question -->
      <div *ngIf="(isY('data.addAssistingPersonToApp') || (getValue('data.assistingOnBehalf').value === 'Assisting Person' || getValue('data.assistingOnBehalf').value === 'Persona que Ayuda')) && (user !== 'RP'|| isAhsRmbOrRmc || ppViewTccHoHrmbrmc )">
          <nh-field id = "hh_relationship" *ngIf="isEnabled('data.assistingPerson.relationship')" label="{{'WHAT_IS_YOUR_RELATINSHIP_TO' | translate}} {{ this.fullName }}?" class="label-section">
            <select nh name="relationship" [items]="tables.AUTHREPRELATIONSHIP" (change)="onChange(getValue('data.assistingPerson.relationship'))" aria-label="relationship" formControlName="relationship"></select>
          </nh-field>

          <div class="row pl-1 pt-1 hover-red">
            <a class="btn btn-link hover-red" (click)="showAssistPerson()">
              <span class="pr-2">{{'ASSIST_PERSON_RELATIONSHIP_LINK' | translate}}</span>
              <span><i class="fa fa-external-link"></i></span>
            </a>
          </div>
        </div>

        <!-- PP FRM- RelationShip Question -->
        <div *ngIf="(isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf')) && (user === 'RP' && isFinReassessment )">
          <nh-field id = "hh_relationship" *ngIf="isEnabled('data.assistingPerson.relationship')" label="{{'WHAT_IS_YOUR_RELATINSHIP_TO' | translate}} {{ this.fullName }}?" class="label-section">
            <select nh name="relationship" [items]="tables.AUTHREPRELATIONSHIP" (change)="onChange(getValue('data.assistingPerson.relationship'))" aria-label="relationship" formControlName="relationship"></select>
          </nh-field>

          <div class="row pl-1 pt-1 hover-red">
            <a class="btn btn-link hover-red" (click)="showAssistPerson()">
              <span class="pr-2">{{'ASSIST_PERSON_RELATIONSHIP_LINK' | translate}}</span>
              <span><i class="fa fa-external-link"></i></span>
            </a>
          </div>
        </div>

        <!-- PP FC- RelationShip Question -->
        <div *ngIf="(isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf')) && (user === 'RP' && appType === 'AFB' && !isAHS)">
          <nh-field id = "hh_relationship" *ngIf="isEnabled('data.assistingPerson.relationship')" label="What is this person's relationship to the applicant?" class="label-section">
            <select nh name="relationship" [items]="tables.AUTHREPRELATIONSHIP" aria-label="relationship" formControlName="relationship" (change)="onChange(getValue('data.assistingPerson.relationship'))"></select>
          </nh-field>

          <div class="row pl-1 pt-1 hover-red">
            <a class="btn btn-link hover-red" (click)="showAssistPerson()">
              <span class="pr-2">{{'ASSIST_PERSON_RELATIONSHIP_LINK' | translate}}</span>
              <span><i class="fa fa-external-link"></i></span>
            </a>
          </div>
        </div>

        <nh-alert type="warning" [isOpen]="getValue('data.assistingPerson.relationship').code === 'CO' || getValue('data.assistingPerson.relationship').code === 'PA' || getValue('data.assistingPerson.relationship').code === 'LG'">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{'RELATIONSHIP_WARNING' | translate: getValue('data.assistingPerson.relationship')}}</span>
            </div>
          </div>
        </nh-alert>

      </div>

      
      <nh-field id = "hh_otherRelationship" *ngIf="isEnabled('data.assistingPerson.otherRelationship')" name="otherRelationship" [mandatory]="true"
        label="{{'OTHER_RELATIONSHIP' | translate}}" class="label-section">
        <input type="text" name="otherRelationship" class="form-control" formControlName="otherRelationship" maxlength="20">
      </nh-field>

      <div
        *ngIf="getValue('data.assistingPerson.relationship').code === 'AR' || getValue('data.assistingPerson.relationship').code === 'OH'">
        <h5 class="section-header">{{'ASSIST_PERSON_RESP' | translate}}</h5><hr>

        <label for="Responsibilities Warning" class="mb-1 label-section"><span class="text-danger">*</span>{{'ASSIST_PERSON_RESP_WARN' | translate}}:</label>

        <div [formGroup]="getControl('data.assistingPerson.responsibilities')" class="form-check pb-2 pb-md-1">
          <nh-checkbox name="applicantBehalf" [control]="getControl('data.assistingPerson.responsibilities.applicantBehalf')" checkedValue="A"
            label="{{applicantBehalf1 | translate}} {{appKey2 | translate}} {{'APPLICANT_BEHALF_2' | translate}}" class="label-section" (change)="onChange($event, 'A')">
          </nh-checkbox>
          <div *ngIf= "appKey === 'CHANGE_TEXT'">
            <nh-checkbox name="submitRenewal" [control]="getControl('data.assistingPerson.responsibilities.submitRenewal')" checkedValue="S"
            label="{{'SUBMIT_RENEWAL_FORM2' | translate}}" class="label-section" (change)="onChange($event, 'S')">
          </nh-checkbox>
          </div>
          <div *ngIf= "appKey !== 'CHANGE_TEXT'">
            <nh-checkbox name="submitRenewal" [control]="getControl('data.assistingPerson.responsibilities.submitRenewal')" checkedValue="S"
            label="{{'SUBMIT_RENEWAL_FORM' | translate}}" class="label-section" (change)="onChange($event, 'S')">
          </nh-checkbox>
          </div>
          <nh-checkbox name="letterOtherCommunication" [control]="getControl('data.assistingPerson.responsibilities.letterOtherCommunication')" checkedValue="L"
            label="{{'LETTER_OTHER_COMMUNICATION' | translate}}" class="label-section" (change)="onChange($event, 'L')">
          </nh-checkbox>
          <nh-checkbox name="yourBehalf" [control]="getControl('data.assistingPerson.responsibilities.yourBehalf')" checkedValue="Y"
            label="{{'YOUR_BEHALF' | translate}}" class="label-section" (change)="onChange($event, 'Y')">
          </nh-checkbox>
            <ul class="list-inline form-check-label" style="padding-left:2rem">
              <li> {{'YOUR_BEHALF_1' | translate }}</li>
              <li> {{'YOUR_BEHALF_2' | translate }}</li>
              <li> {{'YOUR_BEHALF_3' | translate }}</li>
            </ul>
        </div>

        <div class="pl-5 pt-5 form-check-label" [innerHTML]="'REPRESENTATIVE_INDIVIDUAL' | translate"></div>
        <ul class="pl-5 pb-5 list-inline form-check-label">
          <li> {{'REPRESENTATIVE_INDIVIDUAL_1' | translate }}</li>
          <li> {{'REPRESENTATIVE_INDIVIDUAL_2' | translate }}</li>
          <li> {{'REPRESENTATIVE_INDIVIDUAL_3' | translate }}</li>
          <li> {{'REPRESENTATIVE_INDIVIDUAL_4' | translate }}</li>
        </ul>

        <div class="pl-5 form-check-label" [innerHTML]="'REPRESENTATIVE_ORGANIZATION' | translate"></div>
        <ul class="pl-5 pb-5 list-inline form-check-label">
          <li> {{'REPRESENTATIVE_ORGANIZATION_1' | translate }}</li>
          <li> {{'REPRESENTATIVE_ORGANIZATION_2' | translate }}</li>
          <li> {{'REPRESENTATIVE_ORGANIZATION_3' | translate }}</li>
          <li> {{'REPRESENTATIVE_ORGANIZATION_4' | translate }}</li>
          <li> {{'REPRESENTATIVE_ORGANIZATION_5' | translate }}</li>
        </ul>
          
        <nh-alert type="warning" [isOpen]="getControl('data.assistingPerson.responsibilities.letterOtherCommunication')?.value === 'L' && isFullCoverageApp && !isAHS">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{'AUTHREP_LETTER_OTHER_COMM_NOTICES' | translate}}</span>
            </div>
          </div>
        </nh-alert>

        <nh-alert type="warning" [isOpen]="this.showAssistingNotices || (this.showAssistingNotices && user === 'RP' && appType === 'AFB' && !isAHS)">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{'ASSISTING_PERSON_NOTICES' | translate}}</span>
            </div>
          </div>
        </nh-alert>
      </div>
      <br>



  <!-- Checkbox and signature box in MP-AFB,RMB,RMC,  AHS-AFB, RMB, RMC,  PP-view tenncare RMB, RMC--->
      <div>
        <div *ngIf="(((getValue('data.assistingOnBehalf').value === 'Assisting Person' || getValue('data.assistingOnBehalf').value === 'Persona que Ayuda' ) 
                    || (getValue('data.addAssistingPersonToApp').value === 'Yes' || getValue('data.addAssistingPersonToApp').value === 'Si' )) 
                    && (user !== 'RP' || isAhsRmbOrRmc || ppViewTccHoHrmbrmc))">
          <div>
            <nh-checkbox name="ackSignSw"
            [control]="getControl('data.assistingPerson.ackSignSw')" checkedValue="E" [mandatory]=true 
            label="{{'Assisting_Person_Checkbox' | translate}}" class="label-section"></nh-checkbox>
          </div>
          <div>
            <nh-field id = "hh_assistingPersonSignName" label="{{'SIGNATURE' | translate}}" class="label-section">
              <input type="text" class="form-control" name="assistingPersonSignName" formControlName="assistingPersonSignName"
                aria-label="assistingPersonSignName" maxlength="30">
            </nh-field>
          </div>
        </div>
      </div>



  <!-- Checkbox and signature box in FRM -->
      <div>
        <div *ngIf="(( isY('data.ppAddAssistingPersonToApp')) || isY('data.ppAssistingOnBehalf')) && (user === 'RP' && this.isFinReassessment) ">
          <div>
            <nh-checkbox name="ackSignSw"
            [control]="getControl('data.assistingPerson.ackSignSw')" checkedValue="E" [mandatory]=true 
            label="By checking this box and typing my name below, I am electronically signing as the assisting person." class="label-section"></nh-checkbox>
          </div>
          <div>
            <nh-field id = "hh_assistingPersonSignName" label="Signature:" class="label-section">
              <input type="text" class="form-control" name="assistingPersonSignName" formControlName="assistingPersonSignName"
                aria-label="assistingPersonSignName" maxlength="30">
            </nh-field>
          </div>
        </div>
      </div>

      <!-- Checkbox and signature box in PP FC -->
      <div>
        <div *ngIf="( isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf')) && (user === 'RP' && appType === 'AFB' && !isAHS) ">
          <div>
            <nh-checkbox  name="ackSignSw"
            [control]="getControl('data.assistingPerson.ackSignSw')" checkedValue="E" [mandatory]=true 
            label="By checking this box and typing my name below, I am electronically signing as the assisting person." class="label-section"></nh-checkbox>
          </div>
          <div>
            <nh-field id = "hh_assistingPersonSignName" label="Signature:" class="label-section">
              <input type="text" class="form-control" name="assistingPersonSignName" formControlName="assistingPersonSignName"
                aria-label="assistingPersonSignName" maxlength="30">
            </nh-field>
          </div>
        </div>
      </div>


      <section class="pt-3">
        <nh-alert type="warning" [isOpen]="validateAssistingPersonName()">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{ 'ASSISTING_PERSON_ERROR' | translate }}</span>
            </div>
          </div>
        </nh-alert>
        <div *ngIf="isEnabled('data.assistingPerson.name')">
          <h5 class="section-header">{{'ASSISTING_PERSON_NAME' | translate}}</h5>
          <hr>
          <nh-name-address [addressname] = "'hh_name'" [isAssistingPerson]="true" [personalName]="getControl('data.assistingPerson.name')" [tables]="tables"></nh-name-address>
          <br>
        </div>

        <nh-field id = "hh_organizationName" *ngIf="isEnabled('data.assistingPerson.organizationName')" name="organizationName" [mandatory]="false"
          label="{{'ORG_NAME_IF' | translate}}" class="label-section">
          <input type="text" name="organizationName" class="form-control" aria-label="organizationName" formControlName="organizationName"
            maxlength="256"  >
        </nh-field>

        <nh-field id = "hh_idNumber" *ngIf="isEnabled('data.assistingPerson.idNumber')" name="idNumber" [mandatory]="false"
          label="{{'ID_NUMBER_IF' | translate}}" mask="9999999999" class="label-section">
          <input type="text" name="idNumber" class="form-control" aria-label="idNumber" formControlName="idNumber" maxlength="30">
        </nh-field>

        <div *ngIf="isEnabled('data.assistingPerson.address')">
          <h5 class="section-header">{{'ASSISTING_PERSON_ADDRESS' | translate}}</h5>
          <hr>
          <div>
            <nh-field id = "hh_assisting_addressFormat" name="addressFormat" label="{{'ADDRESS_FORMAT' | translate}}" class="label-section">
              <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
              <select nh name="addressFormat" [items]="tables.ADDRESSFORMATTYPES"
              aria-label="addressFormat" formControlName="addressFormat"></select>
            </nh-field>
          </div>
          <nh-name-address [addressname] = "'hh_assisting_addressFormat'" [addressFormat]="getControl('data.assistingPerson.addressFormat')"
            [address]="getControl('data.assistingPerson.address')" [showPopup]="toolTip" [isAssistingPerson]="true" [tables]="tables">
          </nh-name-address>
          <br>
        </div>

        <div *ngIf="isEnabled('data.assistingPerson.email')">
          <h5 class="section-header">{{'ASSISTING_PERSON_CONTACT' | translate}}</h5>
          <hr>
          <nh-field id = "hh_email" [mandatory]="false" label="{{'EMAIL_ADDRESS' | translate}}:" class="label-section">
            <input type="email" name="email" class="form-control" aria-label="email" formControlName="email" maxlength="63">
          </nh-field>
        </div>

        <nh-field id = "hh_bestTime" *ngIf="isEnabled('data.assistingPerson.bestTime')" [mandatory]="false"
          label="{{'BEST_TIME_TO_CALL' | translate}}" class="label-section">
          <select nh name="bestTime" [items]="tables.CONTACTTIME" aria-label="helpDuration" aria-label="bestTime" formControlName="bestTime">
          </select>
        </nh-field>

        <nh-contact-phones [contactPhoneName] = "'hh_assistingPerson_contactPhones'" *ngIf="isEnabled('data.assistingPerson.contactPhones')"
          [phoneNumbers]="getControl('data.assistingPerson.contactPhones')"></nh-contact-phones>
      </section>
      <br>
    </section>
    <div>
      <input id = "hh_hiddenField" type="hidden" formControlName="hiddenField">
    </div>


    <!-- new checkbox and signature for Applicant/Member -->
    <div>
      <div [formGroup]="getControl('data.assistingPerson')" *ngIf="(getValue('data.assistingPerson.relationship')?.code === 'AR') && 
      (isY('data.addAssistingPersonToApp') || 
      (getValue('data.assistingOnBehalf')?.value === 'Assisting Person' || getValue('data.assistingOnBehalf')?.value === 'Persona que Ayuda')
      || isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf'))">
        <h5 class="section-header">{{'SIGNATURE_APPLICANT' | translate }} </h5> 

        <div class="pt-3 form-check-label" [innerHTML]="'SIGNATURE_APPLICANT_1' | translate"></div>
        <ul class="pb-3 list-inline form-check-label">
          <li> {{'SIGNATURE_APPLICANT_2' | translate }}</li>
          <li> {{'SIGNATURE_APPLICANT_3' | translate }}</li>
          <li> {{'SIGNATURE_APPLICANT_4' | translate }}</li>
          <li> {{'SIGNATURE_APPLICANT_5' | translate }}</li>
          <li> {{'SIGNATURE_APPLICANT_6' | translate }}</li>
          <li> {{'SIGNATURE_APPLICANT_7' | translate }}</li>
        </ul>

        <div>
          <nh-checkbox name="applicantAckSignSw"
          [control]="getControl('data.assistingPerson.applicantAckSignSw')" checkedValue="A" [mandatory]=true 
          label="{{'APPLICANT_CHECKBOX' | translate}}" class="label-section"></nh-checkbox>
        </div>

        <div>
          <nh-field id = "hh_applicantSignName" label="{{'SIGNATURE' | translate}}" class="label-section">
            <input type="text" class="form-control" name="applicantSignName" formControlName="applicantSignName"
              aria-label="applicantSignName" maxlength="30">
          </nh-field>
        </div> 

      </div>
    </div>
    
  </div>
</nh-form>