import { identity } from 'ramda';
import { contains } from 'ramda';
import { compose } from 'ramda';
import { pathOr } from 'ramda';
import { ifElse } from 'ramda';
import { always } from 'ramda';
import { evolve } from 'ramda';
import { values } from 'ramda';
import { isNil } from 'ramda';
import { merge } from 'ramda';
import { objOf } from 'ramda';

import { stubString, isEmptyArray } from 'ramda-adjunct';

import Vs from '@app/services/validators/validators.service';
import { value } from './value.helpers';
import { toDate } from './date.helpers';
import { refValue } from './tables.helpers';
import { controlWith } from './form.helpers';
import { notYes, isYes } from './tayn.helpers';
import { tennessee } from './types.helpers';
import { toIndividualId } from './record.helpers';
import { disableReset } from './reset-able.helpers';
import { individualRecords } from './individual.helpers';
import { disabled, enable, disable } from './able.helpers';
import { groupControls, formGroup } from './form-group.helpers';
import { codeUnequal, codeEqual, codeIn } from './code.helpers';
import { setValidators, controlRequired, nilControl, notNilControl } from './form-control.helpers';

import { Modes } from '@app/models/app.model';
import { Record } from '@app/models/record.model';
import { TableCodes } from '@app/models/tables.model';
import { PersonalName, Address, ContactPhones } from '@app/models/partials.model';
import { SectionConfiguration, FormStatus, Columns } from '@app/models/section.model';
import { Section, TopLevelAnswer } from '@app/models/section.model';
import { FormValidation, Config, ContinueLabels, ExitLabels, CancelLabels } from '@app/models/section.model';

export const modes = values(Modes);

export const sectionConfiguration = (
  component,
  formValidation: FormValidation,
  tables: TableCodes,
  columns: Columns = [],
  labelData?: any,
  {
    postObject = identity,
    individualsFilter = individualRecords,
    hasSummary = true,
    showComments = true,
    showSaveAndExit = true,
    showSubmit = true,
    labelContinue = ContinueLabels.NEXT,
    labelExit = ExitLabels.EXIT,
    labelCancel = CancelLabels.CANCEL,
    tooltipAdd = '.',
    buttonAdd = null,
    validateSubmit = false,
  }: Config = {}
): SectionConfiguration =>
  <SectionConfiguration>{
    component,
    formValidation,
    tables,
    columns,
    labelData,
    postObject,
    individualsFilter,
    hasSummary,
    showComments,
    showSaveAndExit,
    showSubmit,
    labelContinue,
    tooltipAdd,
    buttonAdd,
    validateSubmit,
    labelExit,
    labelCancel
  };

export const isNH = codeEqual('NH');

export const isOther = codeEqual('1002');
export const isOtherRelation = codeEqual('OTR');
export const isAuthRepresentative = codeEqual('AR');

export const isIndividual = codeEqual('I');
export const notIndividual = codeUnequal('I');

export const notOther = codeUnequal('O');
export const notOtherRelation = codeUnequal('OT');

export const isStopCoverage = codeEqual('E');
export const notStopCoverage = codeUnequal('E');

export const isStartCoverage = codeEqual('S');
export const notStartCoverage = codeUnequal('S');

export const isUndocumentedImmigrant = codeUnequal('UA');
export const isOtherStatus = codeEqual('OT');
export const isNC = codeEqual('NC');
export const isAssistingPerson = codeEqual('A');
export const isSelf = codeEqual('S');
export const isMilitary = codeEqual('MI')

export const isTrust = codeEqual('TR');
export const isQualified = codeEqual('QTS');

export const emptyRecord = (identifier): Record => ({ identifier, data: null, exportingStatus: '' });

export const emptyFormStatus = (): FormStatus => ({ value: null, valid: false, pristine: true, dirty: false });

const emptyPersonalName = (): PersonalName | any => ({
  firstName: null,
  lastName: null,
  middleInitial: null,
  suffix: null,
});

export const emptyAddress = (): Address | any => ({
  addressLine1: null,
  addressLine2: null,
  city: null,
  tnState: controlWith(value(tennessee), true),
  zip: '',
  county: '',
  addrApoFpo: '',
  addrAaAeAp: '',
});

const emptyPhones = (): ContactPhones | any => ({ home: null, cell: null, message: null, extension: null });

export const emptyRefValue = refValue('', '');

export const emptyTables = objOf('referenceGroup', []);

export const emptySection = (): Section | any => ({
  identifier: null,
  topLevelAnswer: TopLevelAnswer.EMPTY,
  records: [],
});

export const addressCreator = (
  {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    county,
    addrApoFpo,
    addrAaAeAp,
  } = emptyAddress()
) => ({
  addressLine1: [addressLine1, [Vs.streetAddress, Vs.required, Vs.maxLength(50)]],
  addressLine2: [addressLine2, [Vs.streetAddress, Vs.maxLength(75)]],
  state: state,
  city: [city, [Vs.required, Vs.city, Vs.maxLength(25)]],
  zip: [zip, [Vs.required, Vs.zipCode]],
  county: [county, Vs.required],
  addrApoFpo: [addrApoFpo, Vs.required],
  addrAaAeAp: [addrAaAeAp, Vs.required]
});

export const nhAddressCreator = address =>
  merge(addressCreator(address), {
    //state: [controlWith(address?.state ? address?.state : tennessee, false), Vs.required]
            state: [controlWith(address?.state ? address?.state : tennessee, false), Vs.required]
    // tnState: tnCreateState(false),
  });

export const nameCreator = ({ firstName, lastName, middleInitial = null, suffix = null } = emptyPersonalName()) => ({
  firstName: [firstName, [Vs.maxLength(45), Vs.firsName]],
  lastName: [lastName, [Vs.maxLength(45), Vs.minLength(2), Vs.lastName]],
  middleInitial: [middleInitial, Vs.individualName],
  suffix,
});

export const otherNameCreator = ({ firstName, lastName, middleInitial = null, suffix = null } = emptyPersonalName()) => ({
  firstName: [firstName, [Vs.maxLength(45), Vs.firsName, Vs.required]],
  lastName: [lastName, [Vs.maxLength(45), Vs.minLength(2), Vs.lastName, Vs.required]],
  middleInitial: [middleInitial, Vs.individualName],
  suffix,
});

export const phonesCreator = ({ home, cell, message, extension} = emptyPhones()) => ({
  home: [home, Vs.phoneNumber],
  cell: [cell, Vs.phoneNumber],
  message: [message, Vs.phoneNumber],
  extension: [extension]
});

export const phonesCreatorAppeal = ({ cell, work, fax, email } = emptyPhones()) => ({
  cell: [cell, Vs.phoneNumber],
  work: [work, Vs.phoneNumber],
  fax: [fax, Vs.phoneNumber],
  email: [email, Vs.email]
});

export const datesCreator = ({ beginDate, endDate }) => ({
  beginDate: [toDate(beginDate), [Vs.required]],
  endDate: [toDate(endDate), [Vs.required]],
});

export const outsideHouseholdMembers = compose(
  formGroup,
  evolve({
    name: controlRequired,
    birthDate: controlRequired,
    relation: controlRequired
  }),
  evolve({ birthDate: toDate })
);


export const taxHouseHold = compose(
  formGroup,
  evolve({
    taxFilingIndividualId: notNilControl,
    taxDependentIndividualId: notNilControl,
  }),
);

export const inkindSupportGroup = compose(
  formGroup,
  evolve({
    amountPerMonth: controlRequired,
    expenseType: controlRequired,
    amountPaidByInHome: controlRequired,
    amountPaidByOutHome: controlRequired,
    inkindSequence: disable
  }),
);

export const earnedIncomeGroup = compose(
  formGroup,
  evolve({
    personName: controlRequired,
    frequency: controlRequired,
    grossIncome: controlRequired
  }),
);

export const selfEmpIncomeGroup = compose(
  formGroup,
  evolve({
    indvId: controlRequired,
    type: controlRequired,
    averageMonthlyProfit: controlRequired
  }),
);

export const otherIncomeGroup = compose(
  formGroup,
  evolve({
    personName: controlRequired,
    type: controlRequired,
    frequency: controlRequired,
    grossAmount: controlRequired
  }),
);

export const taxDeductionsGroup = compose(
  formGroup,
  evolve({
    indvId: controlRequired,
    taxDeductions: controlRequired,
    businessExpenseAmount: controlRequired
  }),
);

const emptyCreateHousehold = {
  name: null,
  birthDate: null,
  relation: null,
  sequenceNumber: null
}

export const createOutsideHousehold = ({
  name = null,
  birthDate = null,
  relation = null,
  sequenceNumber = null
} = emptyCreateHousehold, nameValidators = [Vs.required, Vs.individualName]) => ({
  name: [name, nameValidators],
  birthDate: [toDate(birthDate)],
  relation: [relation, Vs.required],
  sequenceNumber: [sequenceNumber]
})

export const emptyTaxHousehold = {
  taxFilingIndividualId: null,
  taxDependentIndividualId: null
}

export const createTaxHousehold = ({
  taxFilingIndividualId = null,
  taxDependentIndividualId = null,
} = emptyTaxHousehold) => ({
  taxFilingIndividualId: taxFilingIndividualId ? [taxFilingIndividualId.toString()] : null,
  taxDependentIndividualId: taxDependentIndividualId ? [taxDependentIndividualId.toString()] : null
});

export const createTaxFilerOutside = ({
  dependent = null,
  taxFilerName = null,
  relationToTaxFiler = null,
  sequenceNumber = null,
  exportingStatus = null
}) => ({
  dependent: [dependent, Vs.required],
  taxFilerName: [taxFilerName, [ Vs.required, Vs.invalidName]],
  relationToTaxFiler: [relationToTaxFiler, Vs.required],
  sequenceNumber: sequenceNumber,
 exportingStatus: exportingStatus
})

const enableTaxIndiv = compose(
  evolve({
    dependent: enable,
    taxFilerName: enable,
    relationToTaxFiler: enable,
    sequenceNumber: enable,
    exportingStatus: enable
  }),
  groupControls
);

const disableTaxIndiv = compose(
  evolve({
    dependent: disable,
    taxFilerName: disableReset,
    relationToTaxFiler: disableReset,
    sequenceNumber: disable,
   exportingStatus: disable
  }),
  groupControls
);

export const createCovered = ({
  coveredIndividualNumber,
  coverageStartDate = null,
  coverageEndDate = null,
  coverageEndReason = null,
}) => ({
  coveredIndividualNumber: [coveredIndividualNumber, Vs.required],
  coverageStartDate: [toDate(coverageStartDate), Vs.required],
  coverageEndDate: toDate(coverageEndDate),
  coverageEndReason: [controlWith(coverageEndReason, isNil(coverageEndDate)), Vs.required],
});

export const createCoverage = ({
  individual,
  eligible = null,
  employed = null,
  enrolled = null,
  beginDate = null,
}) => ({
  individual,
  eligible,
  employed,
  enrolled,
  beginDate: toDate(beginDate),
});

const enableCovered = compose(
  evolve({
    coveredIndividualNumber: enable,
    coverageStartDate: enable,
    coverageEndDate: enable,
    coverageEndReason: disableReset,
  }),
  groupControls
);

const disableCovered = compose(
  evolve({
    coveredIndividualNumber: disable,
    coverageStartDate: disableReset,
    coverageEndDate: disableReset,
    coverageEndReason: disableReset,
  }),
  groupControls
);

export const toggleCovered = ifElse(disabled, enableCovered, disableCovered);
export const toggleTaxIndiv = ifElse(disabled, enableTaxIndiv, disableTaxIndiv);

export const setStartAfterEnd = (startDate, endDate) => setValidators([Vs.startAfterEnd(startDate, endDate)]);

export const inpatientDates = compose(
  formGroup,
  evolve({
    beginDate: controlRequired,
    endDate: controlRequired,
  }),
  evolve({ beginDate: toDate, endDate: toDate })
);

export const coOwnerRowCreator = ({ coOwnerIndividual, outsideTheHomeFullName, ownershipPercentage, coOwnerSequence }) => ({
  coOwnerIndividual: [coOwnerIndividual, [Vs.required]],
  outsideTheHomeFullName: [outsideTheHomeFullName, [Vs.required, Vs.invalidName]],
  ownershipPercentage: [ownershipPercentage, [Vs.required, Vs.decimal]],
  coOwnerSequence: [{
    value: coOwnerSequence,
    disabled: !coOwnerSequence ? true : false
  }],
});

export const coOwnerGroup = compose(
  formGroup,
  evolve({
    coOwnerIndividual: controlRequired,
    outsideTheHomeFullName: controlRequired,
    ownershipPercentage: controlRequired,
  }),
);

export const resourcesCoOwnerGroupRowCreator = ({ jointOwnerIndividual, outOfHouseHoldname, jointOwnerPercentage, jointOwnerSequnceNumber}) => ({
  jointOwnerIndividual: [jointOwnerIndividual, Vs.required],
  outOfHouseHoldname: [outOfHouseHoldname, [Vs.required, Vs.invalidName]],
  jointOwnerPercentage: [jointOwnerPercentage, [Vs.required, Vs.decimal]],
  jointOwnerSequnceNumber: 
    [{
      value: jointOwnerSequnceNumber,
      disabled: !jointOwnerSequnceNumber ? true : false
    }]
});

export const resourcesCoOwnerGroup = compose(
  formGroup,
  evolve({
    jointOwnerIndividual: controlRequired,
    outOfHouseHoldname: controlRequired,
    jointOwnerPercentage: controlRequired
  }),
);


const emptyPlanNew = {
  entitled: null,
  beginDate: null,
  premiumAmount: null,
};

const emptyPlan = {
  entitled: null,
  beginDate: null,
  endDate: null,
  premiumAmount: null,
};

export const planCreatorNew = (
  { entitled = null, beginDate = null, premiumAmount = null } = emptyPlanNew,
  amountValidators = [Vs.expense]
) => ({
  entitled: [entitled, [Vs.required]],
  beginDate: [nilControl(toDate(beginDate)), Vs.required],
  premiumAmount: [controlWith(premiumAmount, notYes(entitled)), amountValidators],
});

export const planCreator = (
  { entitled = null, beginDate = null, endDate = null, premiumAmount = null } = emptyPlan,
  amountValidators = [Vs.expense]
) => ({
  entitled: [entitled, [Vs.required]],
  beginDate: [toDate(beginDate)],
  endDate: [toDate(endDate)],
  premiumAmount: [controlWith(premiumAmount, notYes(entitled)), amountValidators],
});

export const permissionCodes = codeIn(['OH', 'AR']);
export const showRetirementCodes = codeIn(['IRA', 'PNF', 'RAC']);
export const showSearchAndAccCodes = codeIn(['CA', 'HRA', 'IDA', 'IRA', 'KEO', 'SA', 'TR', 'LON', 'PTF']);
export const showAccountNumberCodes = codeIn(['ANN', 'CA', 'HRA', 'IDA', 'IRA', 'KEO', 'SA', 'TR']);

// Different dropdown values of Other income types (RT = TAUI)
export const socialSecurityCodes = codeIn([
  '41',
  '42',
  '05',
  '04',
  '06',
  '10',
  '11',
  '07',
  '13',
  '14',
  '15',
  '16',
  '09',
  '08',
  '12',
  '01',
]);

const isCourtOrdered = compose(
  isYes,
  pathOr(false, ['courtOrderSwitch'])
);

const orderedFrequencyValue = pathOr('', ['orderedPayFreqCode', 'value']);
const expenseFrequencyValue = pathOr('', ['expenseFrequencyCode', 'value']);

export const frequency = paymentInfo =>
  isCourtOrdered(paymentInfo) ? orderedFrequencyValue(paymentInfo) : expenseFrequencyValue(paymentInfo);

const orderedAmountValue = pathOr(0, ['orderedPayAmount']);
const expenseAmountValue = pathOr(0, ['expenseAmount']);

export const amount = (paymentInfo, { currency }) =>
  currency.transform(isCourtOrdered(paymentInfo) ? orderedAmountValue(paymentInfo) : expenseAmountValue(paymentInfo));

export const policyHolderName = ({ data }, { individual }) =>
  data.policyHolder.houseMember === '0' || !data.policyHolder.houseMember ?
    `${data.policyHolder.policyHolderFirstName} ${data.policyHolder.policyHolderLastName}` :
    `${individual.transform(toIndividualId(data.policyHolder.houseMember))}`

export const personReceivingCare = ({ data }, { individual }) =>
  data.dependantCareInformation.dependantIndivNumber === "1" ?
    `${data.dependantCareInformation.personOutOfHouseholdName}` :
    `${individual.transform(toIndividualId(data.dependantCareInformation.dependantIndivNumber))}`

export const personReceivingMedical = ({ data }, { individual }) =>
  data.careReceivingIndvNumber === "1" ||  data.careReceivingIndvNumber === "N/A" ? data.personOutOfHouseholdName !== null ?
    `${data.personOutOfHouseholdName}`: "N/A" :
    `${individual.transform(toIndividualId(data.careReceivingIndvNumber))}`

export const insuranceInfo = (data) =>
  data.insuranceCompanyName && data.policyNumber ? `${data.insuranceCompanyName} - ${data.policyNumber}` :
    data.insuranceCompanyName && !data.policyNumber ? `${data.insuranceCompanyName}` :
      !data.insuranceCompanyName && data.policyNumber ? `${data.policyNumber}` : '';

const hasNL = ifElse(contains('NL'), always('Yes'), always('No'));
export const receiveNotice = ifElse(isEmptyArray, stubString, hasNL);

export const hasChildSupport = codeEqual('20');
export const sortOrder = ['NH', 'EM', 'TR', 'BE', 'SE', 'MH', 'SM'];
export const otherResourceUseCodes = codeIn(['LV', 'OM', 'OT', 'NB']);

export const nonMandatoryAddress = (
  {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    county,
    addrApoFpo,
    addrAaAeAp,
  } = emptyAddress()
) => ({
  addressLine1: [addressLine1, [Vs.streetAddress, Vs.maxLength(50)]],
  addressLine2: [addressLine2, [Vs.streetAddress, Vs.maxLength(75)]],
  state: state,
  city: [city, [Vs.city, Vs.maxLength(25)]],
  zip: [zip, [Vs.zipCode]],
  county: [county],
  addrApoFpo: [addrApoFpo],
  addrAaAeAp: [addrAaAeAp]
});

export const newBornListCreator = ({ newbornDOB, ssn, gender, newbornLastName, newbornFirstName, newbornMiddleName
}) => ({
  ssn: ssn,
  gender: gender,
  newbornDOB: newbornDOB[0]? toDate(newbornDOB) : newbornDOB,
  newbornLastName: newbornLastName,
  newbornFirstName: newbornFirstName,
  newbornMiddleName: newbornMiddleName,
});

export const taxBlocks = ['taxfiling', 'taxdeductions', 'taxdependoutside', 'jointfileroutside', 'taxfileroutside']
export const excludeSections = ['SUCOV', 'PRRLS', 'PRIND', 'PRASM', 'ADPIN', 'ADNFI', 'HHRLS', 'NBINF', 'APREQ','APDET', 'APDEM', 'ARPDT', 'APSUM', 'IMEST', 'IMEDT'];
export const eligibleSections = ['HHAPL', 'HHIND', 'HHHLC', 'HHTJF', 'ICEMI', 'ICSEM', 'ICOTH', 'OTKBR', 'XPUTL', 'XPDEP', 'XPSUP', 'INMED', 'XPOTH', 'ASBUR', 'ASLIF', 'ASLIQ', 'ASPRP', 'ASCLD', 'ASVEH', 'OTHRS', 'ARPDT'];
export const excludeComments = ['SACHS', 'HACHS', 'SUSIG', 'ADPIN', 'ADNFI', 'ADSUB', 'NBSRH', 'NBINF', 'NBIMM', 'NBSUB', 'SUCOM', 'NBSUM', 'PRASM', 'APREQ', 'APDET', 'ARPDT', 'APDEM', 'APSUM', 'BCEXS', 'IMEST', 'IMEDT'];
export const hideSaveExit = ['NBINF', 'SUSIG', 'NBIMM', 'NBSUB', 'NBSUM', 'PRHAS', 'BCEXS', 'IMEST', 'IMEDT'];
