<section class="container mb-2">
  <div>
    <h1 class="page-title">Welcome to TennCare Access</h1>
  </div>
  <div>
    <div *ngIf="hasPpAccess">
    <p class="mt-2">You haven't been approved to use TennCare Access yet. Contact your Organization Administrator.</p>
    <hr>
    </div>
    <div>
      <div *ngIf="peAccessCdArr.includes('HPE') || peAccessCdArr.includes('PRG') || peAccessCdArr.includes('BCC')" >
        <h5 class="ml-2 section-header">Presumptive Application</h5><hr>
      </div>
      <div class="card m-3 mt-2" *ngIf="peAccessCdArr.includes('HPE')">
          <div class="row align-items-center p-3">
            <div>
              <div><img class="col dot" aria-label="hpe" src="../../../assets/images/Icons/Welcome_Hospital_Pres.svg"></div>
            </div>
            <div class="col" (click)="startApp('HPE')">
              <strong class="tile_header">Hospital Presumptive Application</strong>
              <p>Start an application for Hospital Presumptive Eligibility. After completing the presumptive
                eligibility application, you will have the option to also submit an application for Medicaid.</p>
            </div>
          </div>
      </div>

      <div class="card m-3 mt-2" *ngIf="peAccessCdArr.includes('PRG')">
          <div class="row align-items-center p-3">
            <div>
              <div><img class="col dot"  aria-label="prg" src="../../../assets/images/Icons/Welcome_Presump_Preg.svg"></div>
            </div>
            <div class="col" (click)="startApp('PRG')">
              <strong class="tile_header">Presumptive Pregnancy Application</strong>
              <p>Start a Department of Health application for Pregnancy Presumptive Eligibility. After
                completing the pregnancy presumptive eligibility application, you will have the option to also submit an
                application for Medicaid.
              </p>
            </div>
          </div>
      </div>

      <div class="card m-3 mt-2" *ngIf="peAccessCdArr.includes('BCC')">
          <div class="row align-items-center p-3">
            <div>
              <div><img class="col dot"  aria-label="bcc" src="../../../assets/images/Icons/Welcome_PresumpBCC.svg"></div>
            </div>
            <div class="col" (click)="startApp('BCC')">
              <strong class="tile_header">Presumptive BCC Application</strong>
              <p>Start a Department of Health application for Breast and/or Cervical Cancer (BCC)
                Presumptive Eligibility. After completing the BCC presumptive eligibility application, you will have the
                option to also submit an application for Medicaid.
              </p>
            </div>
          </div>
      </div>
  </div>
    <div *ngIf="peAccessCdArr.includes('TCA') || peAccessCdArr.includes('KPU')">
      <div class="mt-3">
        <h5 class="section-header">Full Coverage Application</h5><hr>
      </div>
      <div class="card m-3 mt-2">
        <div class="row align-items-center p-3">
          <div>
            <div><img class="col dot"  aria-label="afb" src="../../../assets/images/Icons/Welcome_FullCovgApp.svg"></div>
          </div>
          <div class="col" (click)="startApp('AFB')">
            <strong class="tile_header">Apply for Full Coverage</strong>
            <p>Start an application for TennCare coverage. This includes TennCare and CoverKids. Don’t use
              this application if you are trying to help someone apply for presumptive eligibility (PE) coverage.
            </p>
          </div>
        </div>
      </div>
    </div>
    <h5 class="ml-3 section-header">Other Online Forms and Features</h5><hr>
    <div class="d-flex flex-column w-100">
      <div class="flex-fill card m-3 mt-3" *ngIf="peAccessCdArr.includes('NBN')">
          <div class="row align-items-center p-3">
            <div>
              <div><img class="col dot"  aria-label="newBorn" src="../../../assets/images/Icons/Welcome_Newborn.svg"></div>
            </div>
              <div class="col" (click)="viewAccount('NBN')">
              <strong class="tile_header">Add a Newborn</strong>
              <p>You can add a newborn to the mother's case if she is currently enrolled in TennCare or
                CoverKids.</p>
            </div>
          </div>
      </div>
    </div>

    <div class="card m-3 mt-3" *ngIf="peAccessCdArr.includes('ADF')">
        <div class="row align-items-center p-3">
          <div>
            <div><img class="col dot"  aria-label="admitDischarge" src="../../../assets/images/Icons/Welcome_AdmitDischarge.svg"></div>
          </div>
          <div class="col" (click)="viewAccount('ADF')">
            <strong class="tile_header">Admit/Discharge Form for Long-Term Care Facility</strong>
            <p>Nursing facilities can add the 2350 Form when a patient is admitted to or discharged from
              intermediate long-term care. Only choose this option if your patient is enrolled in TennCare or has recently
              applied for TennCare. Form 2350 is not needed if your patient is only receiving skilled, short-term care. If
              your patient has not applied for TennCare yet, choose <strong>Apply for Full Coverage</strong> to apply on
              their behalf.
            </p>
          </div>
        </div>
    </div>

    <div class="card m-3 mt-3" *ngIf="peAccessCdArr.includes('BXT')">
      <div class="row align-items-center p-3">
        <div>
          <div><img class="col dot"  aria-label="bccRenewal" src="../../../assets/images/Icons/Welcome_PresumpBCC.svg"></div>
        </div>
        <div class="col" (click)="viewAccount('BXT')">
          <strong class="tile_header">Breast and Cervical Cancer Treatment Plan Extension</strong>
          <p>You can submit a BCC Treatment Plan Extension Form for members who need more time to return their treatment plan for BCC coverage.
          </p>
        </div>
      </div>
  </div>

    <div class="card m-3 mt-3" *ngIf="peAccessCdArr.includes('LOC')">
        <div class="row align-items-center p-3">
          <div>
            <div><img class="col dot"  aria-label="financialReassesment" src="../../../assets/images/Icons/Welcome_FinancialReassessment.svg"></div>
          </div>
          <div class="col" (click)="viewAccount('LOC')">
            <strong class="tile_header">Financial Reassessment for Medicaid</strong>
            <p>You can start a financial reassessment for a member who is receiving Institutional Medicaid. Review the member’s financial eligibility information for the member and other people in their household and make updates if needed.
            </p>
          </div>
        </div>
    </div>

    <div class="card m-3 mt-2" *ngIf="peAccessCdArr.includes('IME')">
      <div class="row align-items-center p-3">
        <div>
          <div><img class="col dot" aria-label="hpe" src="../../../assets/images/Icons/Welcome_Hospital_Pres.svg"></div>
        </div>
        <div class="col" (click)="viewAccount('IME')">
          <strong class="tile_header">Incurred Medical Expenses (IMEs)</strong>
          <p>You can add an Incurred Medical Expense (IME) for individuals who are eligible for Institutional Medicaid and receiving Long-Term Services and Supports (LTSS) or hospice.</p>
        </div>
      </div>
    </div>

    <div class="card m-3 mt-3" *ngIf="peAccessCdArr.includes('TCC')">
        <div class="row align-items-center p-3">
          <div>
            <div><img class="col dot"  aria-label="viewTcc" src="../../../assets/images/Icons/Welcome_ViewTCC.svg"></div>
          </div>
          <div class="col" (click)="viewAccount('TCC')">
            <strong class="tile_header">View TennCare Connect Account </strong>
            <p>See a member's TennCare Connect account. This allows you to view their coverage and letters,
              upload documents, report changes, and submit renewals on their behalf.
            </p>
          </div>
        </div>
    </div>

    <div class="card m-3 mt-3" *ngIf="peAccessCdArr.includes('VTC')">
      <div class="row align-items-center p-3">
        <div>
          <div><img class="col dot" aria-label="readViewTcc" src="../../../assets/images/Icons/Welcome_ViewTCC.svg"></div>
        </div>
        <div class="col" (click)="viewAccount('VTC')">
          <strong class="tile_header">View TennCare Connect Account (View Only)</strong>
          <p>See a member's TennCare Connect account. This allows you to view their coverage, letters, and documents.</p>
        </div>
      </div>
    </div>

    <div class="card m-3">
        <div class="row align-items-center p-3">
          <div>
            <div><img class="col dot"  aria-label="searchSubmission" src="../../../assets/images/Icons/Welcome_SearchSubmissions.svg"></div>
          </div>
          <div class="col" (click)="searchSubmissions()">
            <strong class="tile_header"> Search My Submissions </strong>
            <p>Search for applications you have submitted to review the application summary or upload
              documents if TennCare has not started working the application yet. You can also search for applications
              you have started but not finished.
            </p>
          </div>
        </div>
  </div>
<div *ngIf="peAccessCdArr.includes('LMC')">
  <h5 class="ml-3 section-header">Other Functions and Features</h5><hr>
  <div class="d-flex flex-column w-100">
    <div class="flex-fill card m-3 mt-3" *ngIf="peAccessCdArr.includes('LMC')">
        <div class="row align-items-center p-3">
          <div>
            <div><img class="col dot"  aria-label="newBorn" src="../../../assets/images/Icons/Welcome_Newborn.svg"></div>
          </div>
            <div class="col" (click)="linkCase()">
            <strong class="tile_header">Link a Member's Case</strong>
            <p>You can link a member's case to their TennCare Connect account if you have their Username or Email Address. 
              Link the Head of Household to view coverage, letters, upload documents, report changes, and submit renewals. </p>
          </div>
        </div>
    </div>
  </div>
</div>
  </div>
</section>