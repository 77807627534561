import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import Vs from '@app/services/validators/validators.service';

@Component({
  selector: "nh-confirm-ssn",
  templateUrl: './confirm-ssn.component.html',
  styleUrls: ['./confirm-ssn.component.scss']
})
export class ConfirmSsnModalComponent extends BaseModalDirective {
  public confirmssn: FormControl;
  @Input() ssnVal;

  ngOnInit() {
    this.confirmssn = new FormControl('',[Vs.required, Vs.ssn]);
  }

  ssnValidate() {
    const isSsnMatched = this.ssnVal === this.confirmssn.value;
    const exp = /^(?!000|999|666)\d{3}(?!00)\d{2}\d{4}$/;

    if(this.confirmssn.value.length === 9){
      if(!exp.test(this.confirmssn.value)){
        this.confirmssn.setErrors({INVALID_SSN_NUM: true});
      }else if(!isSsnMatched){
        this.confirmssn.setErrors({LINK_SSN_DIFFERENT: true});
      }else{
      this.confirmssn.setErrors(null);
      }
    }
  }

  isFormValid(): boolean {
    return ((this.confirmssn.status === "VALID"))
  }
}
