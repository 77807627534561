import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from "@app/gateway/services/api.service";
import { AuthService } from "@app/gateway/services/auth.service";
import { GoogleAnalyticsService } from "@app/services/analytics/analytics.service";
import { IdleService } from "@app/services/idle/idle.service";
import { ServiceConfigService } from "@app/services/service-config.service";
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
export interface PartnerLogin {
  userName?: string | null;
  code?: string;
  message?: string;
}
export const userTypeUpdated = new BehaviorSubject(null)
@Component({
  selector: "nh-at-a-glance",
  templateUrl: "./at-a-glance.component.html",
  styleUrls: ["./at-a-glance.component.scss"],
})

export class ProviderAtAGlanceComponent implements OnInit {
  peAccessCdArr: any[] = [];

  constructor(
    private apiService: ApiService,
    private idle: IdleService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private serverConfig: ServiceConfigService,
    private router: Router,
    public analytics: GoogleAnalyticsService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Providers');
  };
  validSession = false;
  entId;
  tcamId;
  mtlToken;
  ppUrl;

  getCookie(name) {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  ngOnInit() {
    // tslint:disable-next-line: no-debugger
    debugger;
    if (sessionStorage.getItem('backOrRefresh')) {
      this.router.navigateByUrl('/logout');
    } else {
      this.activatedRoute.queryParams.subscribe(res => {
        this.entId = res?.entityId;
        this.apiService.setMtlId(res.mtlToken);
        this.mtlToken = sessionStorage.getItem('mtlToken');
        this.apiService.removeQueryParam('mtlToken');
        //this.apiService.setEntityId(parseInt(res.entityId, 10))
      });

      this.tcamId = this.serverConfig.getServerConfig().TCAM_KEY;
      this.ppUrl = this.serverConfig.getServerConfig().partnerPortalUrl;
      const payload = { tcam: this.getCookie(this.tcamId), entityId: this.mtlToken };

      const backToPartnerSite = () => window.location.href = this.ppUrl;

      const contentHeader = new HttpHeaders({ "Content-Type": "application/json", "pageId": "TCPWC" });
      this.apiService.post(`publ/users/partnerLogin`, payload, { headers: contentHeader, observe: 'response' }).subscribe((res: any) => {
        if (res && res.body.isValidSession === 'true') {
          this.idle.start();
          sessionStorage.setItem('userType', res.body.userType);
          userTypeUpdated.next(res.body.userType);
          sessionStorage.setItem('userName', res.body.userName);
          const authToken = res.headers.get('x-auth-token');
          this.authService.setToken(authToken);
          this.validSession = true;
          if (res.body.peAccessCd.includes('SUP')) sessionStorage.setItem('SUP', 'true');
          if (res.body.peAccessCd.includes('KPU')) sessionStorage.setItem('KPU', 'true');
          this.peAccessCdArr = res.body.peAccessCd;
          this.analytics.setCurrentUser(res?.body?.userName);
        } else {
          this.validSession = false;
          backToPartnerSite();
        }
      }, () => {
        this.validSession = false;
        backToPartnerSite();
      })
    };
  }


};
