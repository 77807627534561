import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { BeforeStartComponent } from '../at-a-glance/before-start-application/before-start-application.component';
import {ApplicationService} from 'app/services/application/application.service';
import { StateService } from '@app/services/state/state.service';
import { toISO} from '@app/helpers/date.helpers';
import { StorageService } from '@app/services/storage/storage.service';
import { Title } from '@angular/platform-browser';
import { ModalService } from '@app/services/modal/modal.service';
@Component({
    selector: 'welcome-back',
    templateUrl: './welcome-back.component.html',
    styleUrls: ['./welcome-back.component.scss']
})

export class WelcomeBackComponent implements OnInit {
  constructor(
    private beforeStart: BeforeStartComponent, 
    private router: Router, 
    private app: ApplicationService, 
    private stateService: StateService, 
    protected storageService: StorageService,
    private titleService: Title,
    private modal: ModalService
  ) {
    this.titleService.setTitle('Welcome Back');
  }
  news = [];
  applicationNum;
  appType = '';
  setMessage;
  lastAccessed;
  user;
  headOfHouseholdName;
  isKepro;
  hohSSN;
  hohDob;
  hohGender;
  lastAccessedBy;
  appStartOn;
  appStartedBy;

  ngOnInit() {
    this.isKepro = sessionStorage.getItem('isKepro') === 'true';
    if (this.isKepro) {
      const payload = JSON.parse(sessionStorage.getItem('AfbAHSSearch'));
      this.app.getPendingApplications(null, null,'TCAWB', payload).subscribe((res: any) => {
        if (res?.AFB?.length > 0) {
          const hohDetails = res?.AFB[0]?.headOfHousehold;
          this.headOfHouseholdName = hohDetails?.headOfHousehold;
          this.applicationNum = res?.AFB[0]?.applicationNumber;
          this.hohSSN = hohDetails?.ssn;
          this.hohDob = this.getAge(hohDetails?.dob);
          this.hohGender = hohDetails?.gender;
          this.appStartOn = hohDetails?.startedOn;
          this.appStartedBy = hohDetails?.startedBy;
          this.lastAccessed = hohDetails?.lastAccessedOn;
          this.lastAccessedBy = hohDetails?.lastAccessedBy;
          if (this.applicationNum && this.storageService.getAppNumber() !== this.applicationNum) {
            sessionStorage.setItem('appNum', this.applicationNum);
            this.storageService.setAppNumber(this.applicationNum);
          }
        }
      });
    } else {
      this.app.getHeadOfHouseholdInfo(0, 'NEW').subscribe((res: any) => {
        this.applicationNum = res?.applicationNumber;
        this.headOfHouseholdName = res?.headOfHousehold;
        this.lastAccessed = toISO(res?.lastAccessedOn);
        if (this.applicationNum && this.storageService.getAppNumber() !== this.applicationNum) {
          sessionStorage.setItem('appNum', this.applicationNum);
          this.storageService.setAppNumber(this.applicationNum);
        }
      });
    }
  };

  select (value) {
    this.appType = value;
  }

  next () {
    this.stateService.continueApplication(true);
    sessionStorage.setItem('appType', 'AFB');
    if (this.appType && this.appType === 'continue') this.continueApp();
    else if (this.appType && this.appType === 'new') this.startNewApp();
    else {this.setMessage = 'Please select any one of the option to continue'}
  }

  startNewApp () {
    if (this.applicationNum && this.applicationNum !== '0' && this.applicationNum !== 0) {
      this.stateService.resetStates();
      this.app.deleteApp(this.applicationNum, 'TCWAB').subscribe(() => {
        sessionStorage.setItem('appNum', null);
        this.router.navigateByUrl('/application/' + 'NEW');
      })
    } else {
      this.router.navigateByUrl('/application/' + 'NEW');
    }
    if (sessionStorage.getItem('AfbAHSSearch')) {
      sessionStorage.removeItem('AfbAHSSearch');
    }
  }

  continueApp() {
    if (this.isKepro) {
      this.modal.confirmHohApp().subscribe(resp => {
        if (resp) {
          const payload = { "appType": "APPLY_BENEFITS" };
          this.app.confirmAppContinue(payload, this.applicationNum,'TCAWB' ).subscribe((res: any) => {
            console.log(res);
            sessionStorage.removeItem('AfbAHSSearch');
            sessionStorage.setItem('isNewApp', 'false');
            if (this.applicationNum && this.applicationNum !== '0' && this.applicationNum !== 0) {
              this.beforeStart.startApplication('AFB');
            } else {
              this.router.navigateByUrl('/application/' + 'NEW');
            }
          })
        }
      });
    } else {
      sessionStorage.setItem('isNewApp', 'false');
      if (this.applicationNum && this.applicationNum !== '0' && this.applicationNum !== 0) {
        this.beforeStart.startApplication('AFB');
      } else {
        this.router.navigateByUrl('/application/' + 'NEW');
      }
    }
  }

  onPreviousClick() {
    sessionStorage.removeItem('appType');
    if (this.isKepro) {
      this.router.navigateByUrl('api/prov/coverage-search');
    } else {
      this.router.navigateByUrl('/user-profile');
    }
  }

  getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
      age--;
    }
    return age;
  }
} 
