import { ApiService } from '@app/gateway/services/api.service'
import { Component, OnInit, DoCheck } from '@angular/core'
import { StateService } from '@app/services/state/state.service'
import { Router } from '@angular/router'
import { UserProfileComponent } from '../user-profile.component'
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpHeaders  } from '@angular/common/http';
//import Vs from '@app/services/validators/validators.service'

@Component({
  selector: 'nh-autoRenewal',
  templateUrl: './auto-renewal.component.html',
  styleUrls: ['./auto-renewal.component.scss']
})
export class AutoRenewalComponent implements OnInit, DoCheck {
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  defaultLanguage;
  translatedLang;
  updateSuccess = false;
  enableUpdate = false;
  caseSelected = false;
  caseNumbers = [];
  caseList = []
  items = []
  enableRenewEligibility = false;
  enableSelect = false;
  autoRenewalForm = new UntypedFormGroup({
    caseNumber: new UntypedFormControl(''),
    renewCoverageYesCheck: new UntypedFormControl(''),
    renewCoverageNoCheck: new UntypedFormControl(''),
    yearsRenewedCD: new UntypedFormControl(''),
  });
  individualId = sessionStorage.getItem('individualId');

  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public router: Router,
    public translateService: TranslateService,
    public userProfileComponent: UserProfileComponent,
    private titleService: Title
  ) {
    this.titleService.setTitle('Auto Renewal');
  }


  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  ngOnInit(): void {
    const url = `indi/autoRenewal/${this.individualId}`
    this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCATR'})}).subscribe(response => {
      if (response && response['autoRenewalCaseDetails'] && response['autoRenewalCaseDetails']['length']) {
        this.caseList = response['autoRenewalCaseDetails']

        if (this.caseList.length === 1) {
          this.autoRenewalForm.controls.caseNumber.patchValue(this.caseList[0].caseNum)
          this.caseSelected = true;
        } else {
          this.caseList.forEach(element => {
            this.caseNumbers.push(element.caseNum)
          })
        }
      } else {
        this.router.navigateByUrl('/user-profile/benefit-dashboard')
      }
      this.defaultLanguage = this.translateService.getDefaultLang();
      this.translatedLang = this.defaultLanguage;
      this.languageCheck(this.translatedLang)
    })
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(this.translatedLang)
    });
  }

  languageCheck(lang) {
    const url = 'publ/reference-tables?tables=MPFINISH';
    this.apiService.get(url, null , {headers: new HttpHeaders({'pageId': 'TCATR'})}).subscribe(resp => {
      this.items = []
      if (lang === 'en') {
        if(resp){
          resp['referenceGroup'][0].referenceData.forEach(englishValue => {
            this.items.push({ "code": englishValue.code, "value": englishValue.value })
          });
        }
      } else {
        if(resp){
        resp['referenceGroup'][0]?.referenceData.forEach(spanishValue => {
          this.items.push({ "code": spanishValue.code, "value": spanishValue.spValue })
        });
      }
      }
      if (this.autoRenewalForm?.controls?.yearsRenewedCD?.value) {
        this.items.forEach(item => {
          if (item.code === this.autoRenewalForm?.controls?.yearsRenewedCD.value.code) {
            this.autoRenewalForm.controls.yearsRenewedCD.value.value = item.value
          }
        })
      }
    });
  }

  onCaseSelect() {
    if (this.autoRenewalForm?.controls?.caseNumber?.value) {
      this.enableSelect = true;
      this.caseSelected = false;
      this.enableUpdate = false;
      this.enableRenewEligibility = false;
    } else {
      this.enableSelect = false;
      this.caseSelected = false;
      this.enableUpdate = false;
      this.enableRenewEligibility = false;
    }
    let obj;
    this.caseList.forEach((element, idx) => {
      if (element.caseNum === this.autoRenewalForm?.controls?.caseNumber?.value && element.updated === true) {
        obj = this.caseList[idx];
      }
    })
    if (obj?.updated === true) {
      this.updateSuccess = true;
      this.enableUpdate = false;
      if (obj.permissionToRenewSW === "Y") {
        this.autoRenewalForm?.controls?.renewCoverageYesCheck.patchValue(true);
        this.autoRenewalForm?.controls?.renewCoverageYesCheck.updateValueAndValidity();
      } else {
        this.autoRenewalForm?.controls?.renewCoverageNoCheck.patchValue(false);
        this.autoRenewalForm?.controls?.renewCoverageNoCheck.updateValueAndValidity();
        this.enableRenewEligibility = false
      }
      this.items.forEach(item => {
        if (Number(item.code) === obj.yearsRenewedCD) {
          this.autoRenewalForm.controls.yearsRenewedCD.patchValue(item);
          this.autoRenewalForm?.controls?.yearsRenewedCD.updateValueAndValidity();
        }
      })
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.disable();
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.updateValueAndValidity();
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.disable();
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.updateValueAndValidity();
      this.autoRenewalForm?.controls?.yearsRenewedCD.disable();
      this.autoRenewalForm?.controls?.yearsRenewedCD.updateValueAndValidity();
    } else {
      this.updateSuccess = false;
      this.enableRenewEligibility = false;
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.reset();
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.enable();
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.updateValueAndValidity();
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.reset();
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.enable();
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.updateValueAndValidity();
      this.autoRenewalForm?.controls?.yearsRenewedCD.reset();
      this.autoRenewalForm?.controls?.yearsRenewedCD.enable();
      this.autoRenewalForm?.controls?.yearsRenewedCD.updateValueAndValidity();
    }

  }

  onSelectedCase() {
    this.caseSelected = true;
    this.enableSelect = false;
    if (this.autoRenewalForm?.controls?.renewCoverageYesCheck?.value === true) {
      this.enableRenewEligibility = true;
      this.enableRenewEligibility = true;
    }
  }

  exitAutoRenewal() {
    this.router.navigateByUrl('user-profile/benefit-dashboard');
  }

  isRenewChecked(event, otherField) {
    this.updateSuccess = false;
    otherField.checked = false;
    const targetValue = event.target.value
    if (targetValue === 'Yes' && this.autoRenewalForm?.controls?.renewCoverageYesCheck?.value === true) {
      this.enableRenewEligibility = true;
      this.enableUpdate = false;
      this.autoRenewalForm.controls.renewCoverageNoCheck.patchValue(false)
    } else if (targetValue === 'No' && this.autoRenewalForm?.controls?.renewCoverageNoCheck?.value === true) {
      this.enableRenewEligibility = false;
      this.enableUpdate = true;
      this.autoRenewalForm.controls.renewCoverageYesCheck.patchValue(false)
      this.autoRenewalForm.controls.yearsRenewedCD.reset()
    } else {
      this.enableRenewEligibility = false;
      this.enableUpdate = false;
      this.autoRenewalForm.controls.yearsRenewedCD.reset()
    }

  }

  renewEligbilitySelect() {
    if (this.autoRenewalForm?.controls?.yearsRenewedCD?.value) {
      this.enableUpdate = true;
    } else {
      this.enableUpdate = false;
    }
  }

  updateAutoRenewal() {
    let selectedCase = {}
    if (this.autoRenewalForm?.controls?.renewCoverageNoCheck?.value === true) {
      this.updateSuccess = true;
      this.caseList.forEach((element, idx) => {
        if (element.caseNum === this.autoRenewalForm?.controls?.caseNumber?.value) {
          element.updated = true;
          element.permissionToRenewSW = "N",
            element.yearsRenewedCD = 0;
            selectedCase = this.caseList[idx]
        }
      })
    } else if (this.autoRenewalForm?.controls?.renewCoverageYesCheck?.value === true) {
      this.caseList.forEach((element, idx) => {
        if (element.caseNum === this.autoRenewalForm?.controls?.caseNumber?.value) {
          element.updated = true;
          element.permissionToRenewSW = "Y",
            element.yearsRenewedCD = Number(this.autoRenewalForm?.controls?.yearsRenewedCD?.value?.code)
            selectedCase = this.caseList[idx]
        }
      })
    }
    const payLoad = selectedCase;
    const url = `indi/autoRenewal/${this.individualId}`;
      const contentHeader = new HttpHeaders({ "Content-Type": "application/json", "pageId": "TCATR"});
      this.apiService.post(url, payLoad, { observe: 'response', headers: contentHeader}).subscribe(res => {
      this.updateSuccess = res['status'] === 200 ? true : false;
      this.enableUpdate = res['status'] === 200 ? false : true;
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.disable();
      this.autoRenewalForm?.controls?.renewCoverageNoCheck.updateValueAndValidity();
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.disable();
      this.autoRenewalForm?.controls?.renewCoverageYesCheck.updateValueAndValidity();
      this.autoRenewalForm?.controls?.yearsRenewedCD.disable();
      this.autoRenewalForm?.controls?.yearsRenewedCD.updateValueAndValidity();
    });
  }
}