import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms' 
import { ApiService } from '@app/gateway/services/api.service';

@Component({
  selector: "nh-reference-table",
  templateUrl: "./reference-table.component.html",
  styleUrls: ["./reference-table.component.scss"],
})

export class ReferenceTableComponent implements OnInit  { 
  rtForm: FormGroup;
  showCol: boolean
  rowData = [];
  rowIndex = 1;
  hasRows = false;
  hasNoData;

  constructor(private fb: FormBuilder, private apiService: ApiService) {
    this.rtForm = this.fb.group({
        tableId: '',
        tableName: '',
        columnDetails: this.fb.array([]) ,
    });
  }
 
  ngOnInit () {
  }

  get columnDetails() : FormArray {
    return this.rtForm.get("columnDetails") as FormArray
  }

  newCol(): FormGroup {
    const existingTable = this.rowData.length + this.columnDetails.length;
    const index = this.rowData ? existingTable : this.rowIndex++;
    const newIndex = index + 1;
    
    return this.fb.group({
        rowid: newIndex,
        code: '',
        enDescription: '',
        spDescription: ''
       })
    }

  search() {
    const table = this.rtForm.controls.tableId.value;
    const url = 'publ/reference-tables/retrieve?table=' + table;
    this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': ''})}).subscribe(res => {
      if (res['columnDetails'] !== null) {
        this.rtForm.patchValue({ tableName: res['tableName'] });
        this.rowData = res['columnDetails'];
        this.hasRows = true;
      } else {
        this.hasRows = false;
        this.hasNoData = true;
      }
    });
  }
 
  addColumns(show) {
    this.showCol = show;
    this.columnDetails.push(this.newCol());
  }

  clearSearch = () => {
    this.rowData = [];
    this.rtForm.reset();
    this.hasRows = false;
  };
 
  removeColumns(i: number) {
    this.rowIndex--;
    this.columnDetails.removeAt(i);
    this.showCol = false;
  }
 
  onSave() {
    const axn = this.rowData.length ? 'update' : 'save';
    const headers = new HttpHeaders({ action: axn });
    this.apiService.post('publ/reference-tables', this.rtForm.value, { headers }).subscribe(() => {
      return location.reload();
    });
  }
}
