import { isChangeReport, isRmbOnly } from './../../../../helpers/mode.helpers';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { ModalService } from '@app/services/modal/modal.service';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { minus3Months } from '@app/helpers/date.helpers';
import { formatDate } from '@angular/common';
import { formatTableData } from '@app/helpers/tables.helpers';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceConfigService } from '@app/services/service-config.service';
import Vs from "@app/services/validators/validators.service";
import { disableControls, disableResetControls, enableControls, enableResetControls } from '@app/helpers/reset-able.helpers';
import { isApplyForBenefits } from '@app/helpers/mode.helpers';
import { ApplicationService } from '@app/services/application/application.service';
@Component({
  templateUrl: './medicare.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicareComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  indi;
  head;
  isRmbRmc = false;
  isRmbOnly = false;
  otherSelectedIndividual = '';
  someoneEn = {
    name: {
      fullName: 'Someone outside of the home'
    },
    identifier: "1"
  };
  someoneSp = {
    name: {
      fullName: 'Alguien fuera de casa'
    },
    identifier: "1"
  };
  someone;
  individualQuestion;
  individualQuestionEn = "Who is paying for these bills? <div class=hint>If there is more than one bill, you can add more after you complete this page and click <b>Next</b>.</div>";
  individualQuestionSp = "¿Quién paga estas facturas? <div class=hint>Si hay más de una factura, puede agregar más después de completar esta página y hacer clic en <b>Siguiente</b>.</div>";
  individualQuestionEn_rmb = "Who paid these bills? <div class=hint>If there is more than one bill, you can add more after you complete this page and click <b>Next</b>.</div>";
  individualQuestionSp_rmb = "¿Quién pagó estas facturas? <div class=hint>Si hay más de una factura, puede agregar más después de completar esta página y hacer clic en <b>Siguiente</b>.</div>";
  medicalBillsHint;
  medicalBillsHintEn = "pays for more than one, you can add more after you complete this page and click <b>Next</b>.";
  medicalBillsHintSp = "pagó por más de uno, puede agregar más después de completar esta página y hacer clic en <b>Siguiente</b>."
  translatedLang;
  defaultLanguage;
  isNotAdded = false;
  isMedBillCarryFwSw = false;
  dateMoreThan3Months = false;
  apiContext = `easy-app/api`;
  kbReferralQuestion;
  kbReferralQuestionEn = "Does your household have medical bills, paid or unpaid, from the last three months? And, is the amount of these bills close to or more than your monthly income?";
  kbReferralQuestionSp = "¿Su hogar tiene facturas médicas, pagadas o no pagadas, de los últimos tres meses? Y, ¿la cantidad de estas facturas es casi o más que sus ingresos mensuales?";
  showKbQuestion = false;
  isAFB;
  KtFlag: boolean = false;
  otherHealthCareList;
  answeredBrstCervCancerSw;
  answeredEcfSw;
  answeredHcbsSw;
  answeredHospiceSw;
  answeredIntelDisbSw;
  answeredMedFacilitySw;
  answeredeMedicalBillsIndSw;
  answeredMedicareIndSw;
  answeredNursingHomeSw;
  answeredSsiBenefitsSw;
  finalListOfIndividuals = [];
  answeredKtBeckettSw;
  ktBeckettIndividuals;
  relationshipList;
  otherHealthCareListData;
  finalListIndvs;
  brstCervCancerSwChecked;
  ecfSwChecked;
  hcbsSwChecked;
  hospiceSwChecked;
  intelDisbSwChecked;
  medFacilitySwChecked;
  medicalBillsIndSwChecked;
  medicareIndSwChecked;
  nursingHomeSwChecked;
  ktBeckettSwChecked;
  ssiBenefitsSwChecked;
  spousalRelationOther;
  finalParentIndvsForLessThanEighteenOther;
  otherIndvsCheckedOther;
  spouseOtherSwitches = [];
  checkedIndividualsOtherSwitches = [];
  finalParentIndvsForLessThanEighteenOtherSwitches = [];
  ageOfIndividualsLessThanEighteen;
  finalListAll:any = [];
  finalListOfIndividualsKT = [];
  finalListIndvsKT;
  broSisRelation;
  finalBroSisIndvs;
  finalBroSisIndvsOtherSwitches = [];
  user = sessionStorage.getItem('userType');
  appType = sessionStorage.getItem('appType');
  isAFBorFC = (this.appType === 'AFB') || (this.user == 'RP' && (this.appType === 'AFB'));
  checkForDropdown: boolean = false;


  get medicalBillType() {
    return this.getControl('data.medicalBillType');
  }

  get medBillStillOweAmt() {
    return this.getControl('data.medBillStillOweAmt');
  }

  get personOutOfHouseholdName() {
    return this.getControl('data.personOutOfHouseholdName');
  }

  get careReceivingIndvNumber() {
    return this.getControl('data.careReceivingIndvNumber');
  }

  get unpaidAmt() {
    return this.getControl('data.medicalBillOweAmt');
  }

  get medicalBillUnpaidAmt() {
    return this.getControl('data.medicalBillUnpaidAmt');
  }

  get billPayingFrequencycd() {
    return this.getControl('data.billPayingFrequencycd');
  }

  get relationshipToPerson() {
    return this.getControl('data.relationPersonOutsideHH');
  }

  get careReceiveDate() {
    return this.getControl('data.careReceiveDate');
  }

  constructor(private titleService: Title, private modalService: ModalService, builder: FormBuilder, public translateService: TranslateService, 
    private http: HttpClient, private serverConfig: ServiceConfigService, private application: ApplicationService, private cd: ChangeDetectorRef) {
    super(builder);
    this.titleService.setTitle('Medicare Bills');
  }

  ngAfterContentInit() {

    this.checkForDropdown = (this.isAFBorFC && this.members.length > 1);
    
    //code for filtering the dropdowns for checked individuals on gatepost questions
    //only if AFB or FC and no. of people on the application is > 1
    if(this.isAFBorFC && this.members.length > 1){
      //request to get the gate-post questions data
      this.application.getSection(this.applicationNumber, 'AIBAO').subscribe(rtn => {
        if (rtn.records.length > 0) {
          const { records } = rtn;
          this.otherHealthCareListData = records[0].data;
          if (this.members.length > 1) {
            this.getGatePostQuestionsDetails(this.otherHealthCareListData);
            
            //request to get the relationship data
            this.application.getSection(this.applicationNumber, 'HHRLS').subscribe(rtn => {
              if (rtn.records.length > 0) {
                const { records } = rtn;
                this.relationshipList = records[0].data.relationshipList;

                if (this.members.length > 1) {
                  this.KtFlag = false;
                  this.getRelationshipsParStepParSpouseForOtherSwitches(this.relationshipList);
                  this.finalListAll = this.finalList();
                  this.cd.detectChanges();

                  //request to get applied indvs for KT
                  if(this.answeredKtBeckettSw){
                    this.application.getSection(this.applicationNumber, 'OTKBR').subscribe(rtn => {
                      if (rtn.records.length > 0) {
                        const { records } = rtn;
                        this.KtFlag = true;
                        this.ktBeckettSwChecked = records.map(data => JSON.parse(data.identifier?.individualId));
                        this.getRelationshipsParStepParSpouseForOtherSwitches(this.relationshipList);
                        
                        if(this.finalListAll && this.finalListAll.length > 0 ){
                          this.finalListAll = this.finalListAll.concat(this.finalListKT());

                          //remove duplicate individuals from the final array
                          this.finalListAll = this.finalListAll.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                              t.identifier === value.identifier
                            ))
                          );

                        }else{
                          this.finalListAll = this.finalListKT();
                        }
                      }
                    });
                  }; 

                };

              }
            });

          };
        }
      });
    }

    this.isRmbRmc = isRmbRmc(this.mode);
    this.isRmbOnly = isRmbOnly(this.mode);
    if (this.record && this.record['exportingStatus'] !== 'ADDED') {
      this.isNotAdded = true;
    } else {
      this.isNotAdded = false;
    }
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if(!this.isRmbOnly){
      if (this.defaultLanguage !== 'en') {
        this.individualQuestion = this.individualQuestionSp
        this.medicalBillsHint = this.medicalBillsHintSp
        this.someone = this.someoneSp
        this.kbReferralQuestion = this.kbReferralQuestionSp
      } else {
        this.individualQuestion = this.individualQuestionEn
        this.medicalBillsHint = this.medicalBillsHintEn
        this.someone = this.someoneEn
        this.kbReferralQuestion = this.kbReferralQuestionEn
      }
    }
     if(this.isRmbOnly){ 
      if (this.defaultLanguage !== 'en') {
      this.individualQuestion = this.individualQuestionSp_rmb
      this.medicalBillsHint = this.medicalBillsHintSp
      this.someone = this.someoneSp
      this.kbReferralQuestion = this.kbReferralQuestionSp
    } else {
      this.individualQuestion = this.individualQuestionEn_rmb
      this.medicalBillsHint = this.medicalBillsHintEn
      this.someone = this.someoneEn
      this.kbReferralQuestion = this.kbReferralQuestionEn
    }}
   
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
     if(!this.isRmbOnly){
      if (res.lang === 'en') {
        this.individualQuestion = this.individualQuestionEn
        this.medicalBillsHint = this.medicalBillsHintEn
        this.someone = this.someoneEn
        this.kbReferralQuestion = this.kbReferralQuestionEn
      } else {
        this.individualQuestion = this.individualQuestionSp
        this.medicalBillsHint = this.medicalBillsHintSp
        this.someone = this.someoneSp
        this.kbReferralQuestion = this.kbReferralQuestionSp
      }
     }
     else{
      if (res.lang === 'en') {
        this.individualQuestion = this.individualQuestionEn_rmb
        this.medicalBillsHint = this.medicalBillsHintEn
        this.someone = this.someoneEn
        this.kbReferralQuestion = this.kbReferralQuestionEn
      } else {
        this.individualQuestion = this.individualQuestionSp_rmb
        this.medicalBillsHint = this.medicalBillsHintSp
        this.someone = this.someoneSp
        this.kbReferralQuestion = this.kbReferralQuestionSp
      }
     }
      this.individualsFilter();
      this.getIndividualQuestion();
    });
    this.getIndividualQuestion();
    const head = this.individuals.filter(ind => ind.applicantRelationship === null);
    this.head = head[0];

    this.individualsFilter();
    const careReceivingIndvNumberVal = this.getValue('data.careReceivingIndvNumber');
    const personOutsideHome = this.getControl('data.personOutOfHouseholdName');
    const relationshipToPerson = this.getControl('data.relationPersonOutsideHH');
    const kbMedicalBillsSw = this.getControl('data.kbMedicalBillsSw');
    personOutsideHome.disable();
    relationshipToPerson.disable();
    this.showHide();
    if (kbMedicalBillsSw) {
      const medicalBillsControls = [this.medicalBillType, this.careReceivingIndvNumber, this.unpaidAmt, this.medicalBillUnpaidAmt, this.billPayingFrequencycd, this.careReceiveDate, this.relationshipToPerson, this.personOutOfHouseholdName];
      if (kbMedicalBillsSw.value?.code === 'N') {
        disableControls(medicalBillsControls);
      } else if (kbMedicalBillsSw.value?.code === 'Y') {
        enableControls(medicalBillsControls);
      }
    }
    if ((!careReceivingIndvNumberVal || careReceivingIndvNumberVal === "1") && this.getValue('data.personOutOfHouseholdName')) {
      const careReceivingIndvNumber = this.getControl('data.careReceivingIndvNumber');
      if (this.indi.length > 0) {
        careReceivingIndvNumber.patchValue("1")
        personOutsideHome.enable();
        relationshipToPerson.enable();
      }
    } else if (careReceivingIndvNumberVal) {
      const individual = this.individuals.filter(ind => ind.identifier === this.getValue('data.careReceivingIndvNumber'));
      this.otherSelectedIndividual = individual[0]?.name.fullName;
    }
    if (this.record && this.record.data?.['medBillCarryFwSw'] === 'Y') {
      this.isMedBillCarryFwSw = true;
      this.getControl('data.medBillStillOweSw').setValidators(Vs.required);
      this.getControl('data.medBillStillOweSw').updateValueAndValidity();
      this.hideNodataField();
    }
    else {
      this.isMedBillCarryFwSw = false;
    }
    this.dentalFieldHide();
    this.hideNodataField();
  }

  get languageUpdate(){
    return this.selectedIndividual?.name?.fullName || '' 
  }

  hideNodataField(){
    const transportation = this.getControl('data.milesPerAppointment');
    const relationshipToPerson = this.getControl('data.relationPersonOutsideHH');
    const personOutsideHome = this.getControl('data.personOutOfHouseholdName');
    if (personOutsideHome.value === null) {
      personOutsideHome.disable();
    }
    if (relationshipToPerson.value === null) {
      relationshipToPerson.disable();
    }
    if (transportation.value === null) {
      transportation.disable();
    }
  }

  dentalFieldHide() {
    const unpaidAmt = this.getControl('data.medicalBillOweAmt');
    const val = this.getValue('data.medicalBillType');
    if (val.code === 'DN') {
      unpaidAmt.disable();
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  getIndividualQuestion() {
    const appNum = sessionStorage.getItem('appNum');
    const kbMedicalBillsSw = this.getControl('data.kbMedicalBillsSw');
    this.isAFB = isApplyForBenefits(this.applicationType);
    if (this.isAFB) {
      this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/${appNum}/sections/AIBAO`, {headers: new HttpHeaders({'pageId': 'AIBAO'})}).subscribe(res => {
        if (res && res['records'].length > 0) {
          const data = res['records'][0].data;
          if (data?.kbReferralSw?.code === 'Y') {
            this.showKbQuestion = true;
            kbMedicalBillsSw.setValidators(Vs.required);
            kbMedicalBillsSw.updateValueAndValidity();
          } else {
            this.showKbQuestion = false;
            kbMedicalBillsSw.clearValidators();
            kbMedicalBillsSw.updateValueAndValidity();
          }
        }
      })
    }
  };

  individualsFilter() {
    if (isChangeReport(this.mode)) {
      this.indi = this.individuals.filter(indi => indi.someoneMovedIn === 'Y');
    } else {
      this.indi = [...this.individuals, ...[this.someone]];
    }
  };

  toggleAmountFields(event) {
    const careReceiveDate = this.getControl('data.careReceiveDate');
    if (careReceiveDate.valid) {
      const checkDate = minus3Months();
      this.dateMoreThan3Months = careReceiveDate.value.getTime() < checkDate.getTime();
    }

    if (event?.target?.name === 'careReceiveDate') {
      event.target.value = formatDate(this.getControl('data.careReceiveDate').value, 'MM/dd/yyyy', 'en');
    }
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  showModal() {
    this.modalService.openMedicalBills()
  }

  setIndiv() {
    this.otherSelectedIndividual = '';
    const personOutsideHome = this.getControl('data.personOutOfHouseholdName');
    const relationshipToPerson = this.getControl('data.relationPersonOutsideHH');
    if (this.getValue('data.careReceivingIndvNumber') === "1") {
      personOutsideHome.enable();
      relationshipToPerson.enable();
    } else {
      personOutsideHome.disable();
      relationshipToPerson.disable();
      const individual = this.individuals.filter(ind => ind.identifier === this.getValue('data.careReceivingIndvNumber'));
      this.otherSelectedIndividual = individual[0].name.fullName;
      
    }
    
  }

  showHide() {
    const unpaidAmt = this.getControl('data.medicalBillOweAmt');
    const transportation = this.getControl('data.milesPerAppointment');
    if(transportation?.value?.indexOf('.') > -1)
      transportation.setValue(transportation.value.substr(0, transportation.value.indexOf('.')));
    const careReceiveDate = this.getControl('data.careReceiveDate');
   unpaidAmt.enable();
    transportation.disable();
    if (careReceiveDate.valid) {
      const checkDate = minus3Months();
      this.dateMoreThan3Months = careReceiveDate.value.getTime() < checkDate.getTime();
    }
    const val = this.getValue('data.medicalBillType');
    if (val.code === 'DN') unpaidAmt.disable();
    if (val.code === 'TM') transportation.enable();
  }

  showOweBill(event: any) {
    const { value } = event.target;
    if (value === 'Yes') {
      this.medBillStillOweAmt.enable();
      this.medBillStillOweAmt.setValidators(Vs.required);
      this.medBillStillOweAmt.updateValueAndValidity();
    }
    else {
      this.medBillStillOweAmt.disable();
      this.medBillStillOweAmt.clearValidators();
      this.medBillStillOweAmt.updateValueAndValidity();
    }
    

  }

  validateMedBillStillOweAmt(event: any) {
    this.medicalBillUnpaidAmt.value;
    const valueA = event.target.value;
    const valueB = this.medicalBillUnpaidAmt.value
    if (valueA > valueB) {
      this.medBillStillOweAmt.setErrors({ INVALID_MEDBILL: true })
    }
    else if (this.medBillStillOweAmt.errors) {
      this.medBillStillOweAmt.setErrors({ ...this.medBillStillOweAmt.errors })
    }
    else {
      this.medBillStillOweAmt.setErrors(null)
    }

  }

  setValidations(event: any) {
    const medicalBillsControls = [this.medicalBillType, this.careReceivingIndvNumber, this.unpaidAmt, this.medicalBillUnpaidAmt, this.billPayingFrequencycd, this.careReceiveDate, this.relationshipToPerson, this.personOutOfHouseholdName];
    if (event.target.value === 'No') {
      disableResetControls(medicalBillsControls);
    } else if (event.target.value === 'Yes') {
      enableControls(medicalBillsControls);
      enableResetControls(medicalBillsControls)
    }
  }


 
  getRelationshipsParStepParSpouseForOtherSwitches(relationshipList) {

    //Individuals selected for all gate post except KT
    if(!this.KtFlag){
      const spoParStparArray = [this.medicalBillsIndSwChecked, this.hcbsSwChecked, this.ecfSwChecked, 
                      this.medicareIndSwChecked, this.hospiceSwChecked, this.intelDisbSwChecked, this.medFacilitySwChecked, this.nursingHomeSwChecked];
      const spoParStparNamesArray = ["medbills", "hcbs", "ecfsw", "medcare", "hospice", "intdis", "medfac", "nurhome"];

      for(let i = 0; i < spoParStparArray.length; i++){
        this.filteredRelationshipsParentStepPar(relationshipList,spoParStparArray[i], spoParStparNamesArray[i]);
      };

      this.spouseOtherSwitches = this.spouseOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.finalParentIndvsForLessThanEighteenOtherSwitches = this.finalParentIndvsForLessThanEighteenOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.checkedIndividualsOtherSwitches = this.checkedIndividualsOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.finalBroSisIndvsOtherSwitches = this.finalBroSisIndvsOtherSwitches.reduce((acc, val) => acc.concat(val), []);
    };


    //Individuals selected for KT Beckett
    if(this.KtFlag){
      const ktBeckettArray = [this.ktBeckettSwChecked];
      const ktBeckettNamesArray = ["kt"];

      for(let i = 0; i < ktBeckettArray.length; i++){
        this.filteredRelationshipsParentStepPar(relationshipList,ktBeckettArray[i], ktBeckettNamesArray[i]);
      };

      this.spouseOtherSwitches = this.spouseOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.finalParentIndvsForLessThanEighteenOtherSwitches = this.finalParentIndvsForLessThanEighteenOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.checkedIndividualsOtherSwitches = this.checkedIndividualsOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.finalBroSisIndvsOtherSwitches = this.finalBroSisIndvsOtherSwitches.reduce((acc, val) => acc.concat(val), []);
    };

  };


  filteredRelationshipsParentStepPar(relationshipList, switchName, codedName){
    //spouse, checked Indvs and ( parent, step-parent for individual under 18 ) for Medical Bills, HCBS, ECF
    //spouse and checked Indvs for Medicare, Med Facility, Nursing Home, Hospice, Disabilities
    //BCC is n/a
    //KT par and step-par under 18 for only the checked Indvs and not all the <18 indvs

    if(switchName.length > 0){
      //spouse relation
      if(codedName !== "kt"){
        this.spousalRelationOther = switchName.map((e)=>{
          let tempM = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                        ((element.relation.code === 'SPO') ))
          return tempM;
        });

        this.spousalRelationOther = (this.spousalRelationOther.map(item => 
                        ({"source" : item[0]?.sourceIndividual, "reference":item[0]?.referenceIndividual})).map(data => Object.values(data)));

        this.spousalRelationOther = (this.spousalRelationOther.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
      };


      //parent or step-parent relation for individuals checked under 18
      if(codedName === "medbills" || codedName === "hcbs" || codedName === "ecfsw" || (codedName === "kt" && this.ktBeckettSwChecked)){
        this.ageOfIndividualsLessThanEighteen = this.individuals.filter(item => item.age < 18).map(data => JSON.parse(data.identifier));

        let ageOfIndividualsLessThanEighteenChecked = switchName.map((e)=>{
          let tempC = this.ageOfIndividualsLessThanEighteen.filter(element=> (element === e))
          return tempC;
        });

        ageOfIndividualsLessThanEighteenChecked = (ageOfIndividualsLessThanEighteenChecked.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
        console.log(ageOfIndividualsLessThanEighteenChecked);

        let parentalRelationForLessThanEighteen = ageOfIndividualsLessThanEighteenChecked.map((e)=>{
            let temp = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                          ((element.relation.code === 'FAO') || (element.relation.code === 'MOO') ||
                                                            (element.relation.code === 'SFA') || (element.relation.code === 'SMO') ||
                                                            (element.relation.code === 'SDA') || (element.relation.code === 'SSO') ||
                                                            (element.relation.code === 'DAU') || (element.relation.code === 'SON') ))
            return temp;
        });

        parentalRelationForLessThanEighteen = parentalRelationForLessThanEighteen.reduce((acc, val) => acc.concat(val), []);

        let listParentIndvsForLessThanEighteen = (parentalRelationForLessThanEighteen.map(item => ({"source" : item.sourceIndividual, "reference":item.referenceIndividual})).map(data => Object.values(data)));
        listParentIndvsForLessThanEighteen = listParentIndvsForLessThanEighteen.reduce((acc, val) => acc.concat(val), []);

        if(codedName === "kt"){
          listParentIndvsForLessThanEighteen = listParentIndvsForLessThanEighteen.filter( x => !ageOfIndividualsLessThanEighteenChecked.includes(x) );
        }

        this.finalParentIndvsForLessThanEighteenOther = listParentIndvsForLessThanEighteen.filter((c, index) => {
          return listParentIndvsForLessThanEighteen.indexOf(c) === index;
        });
      };



      //bro, sis relation for individuals checked
      if(codedName === "medbills" || (codedName === "kt" && this.ktBeckettSwChecked)){

        this.broSisRelation = switchName.map((e)=>{
          let tempM = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                        ((element.relation.code === 'BRO') || (element.relation.code === 'SIS') ))
          return tempM;
        });

        this.broSisRelation = this.broSisRelation.reduce((acc, val) => acc.concat(val), []);

        let listBroSisRelation = (this.broSisRelation.map(item => ({"source" : item.sourceIndividual, "reference":item.referenceIndividual})).map(data => Object.values(data)));
        listBroSisRelation = listBroSisRelation.reduce((acc, val) => acc.concat(val), []);

        if(codedName === "kt"){
          const ageOfIndividualsLTEighteen = this.individuals.filter(item => item.age < 18).map(data => JSON.parse(data.identifier));

          let ageOfIndividualsLTEighteenChecked = switchName.map((e)=>{
            let tempC = ageOfIndividualsLTEighteen.filter(element=> (element === e))
            return tempC;
          });
          ageOfIndividualsLTEighteenChecked = (ageOfIndividualsLTEighteenChecked.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
          listBroSisRelation = listBroSisRelation.filter( x => !ageOfIndividualsLTEighteenChecked.includes(x) );
        }

        this.finalBroSisIndvs = listBroSisRelation.filter((c, index) => {
          return listBroSisRelation.indexOf(c) === index;
        });
      };

      //other individuals checked
      if(codedName !== "kt"){
        this.otherIndvsCheckedOther = switchName;
      }; 

    };


    if(switchName && switchName.length > 0){
      if(this.spousalRelationOther && this.spousalRelationOther.length > 0){
        this.spouseOtherSwitches.push(this.spousalRelationOther);
      };
      if(this.finalParentIndvsForLessThanEighteenOther && this.finalParentIndvsForLessThanEighteenOther.length > 0){
        this.finalParentIndvsForLessThanEighteenOtherSwitches.push(this.finalParentIndvsForLessThanEighteenOther);
      };
      if(this.finalBroSisIndvs && this.finalBroSisIndvs.length > 0){
        this.finalBroSisIndvsOtherSwitches.push(this.finalBroSisIndvs);
      };
      if(this.otherIndvsCheckedOther && this.otherIndvsCheckedOther.length > 0){
        this.checkedIndividualsOtherSwitches.push(this.otherIndvsCheckedOther);
      }
    };


  }



  getGatePostQuestionsDetails(otherHealthCareListData) {
    const otherHealthCareList = otherHealthCareListData?.otherBenefitsIndvProfileData;
    this.otherHealthCareList = otherHealthCareList;
    this.answeredYesToGatePostQuestions(otherHealthCareListData);
  }


  answeredYesToGatePostQuestions(otherBenefitsDataRecord){
    //list of individuals who checked for each switch in gatepost questions
    const otherBenefitsData = otherBenefitsDataRecord?.otherBenefitsIndvProfileData;
    this.ktBeckettSwChecked = otherBenefitsData.filter(data => data.ktBeckettSwChecked?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.brstCervCancerSwChecked = otherBenefitsData.filter(data => data.brstCervCancerSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.ecfSwChecked = otherBenefitsData.filter(data => data.ecfSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.hcbsSwChecked = otherBenefitsData.filter(data => data.hcbsSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.hospiceSwChecked = otherBenefitsData.filter(data => data.hospiceSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.intelDisbSwChecked = otherBenefitsData.filter(data => data.intelDisbSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medFacilitySwChecked = otherBenefitsData.filter(data => data.medFacilitySw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medicalBillsIndSwChecked = otherBenefitsData.filter(data => data.medicalBillsIndSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medicareIndSwChecked = otherBenefitsData.filter(data => data.medicareIndSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.nursingHomeSwChecked = otherBenefitsData.filter(data => data.nursingHomeSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.ssiBenefitsSwChecked = otherBenefitsData.filter(data => data.ssiBenefitsSw?.code === 'Y').map(element => JSON.parse(element?.indvId));


    //what gatepost questions are answered as yes
    this.answeredKtBeckettSw = otherBenefitsDataRecord?.kbReferralSw?.code === 'Y';
    let brstCervCancerSw = otherBenefitsData.map(item => item?.brstCervCancerSw?.code === 'Y');
    let ecfSw = otherBenefitsData.map(item => item?.ecfSw?.code === 'Y');
    let hcbsSw = otherBenefitsData.map(item => item?.hcbsSw?.code === 'Y');
    let hospiceSw = otherBenefitsData.map(item => item?.hospiceSw?.code === 'Y');
    let intelDisbSw = otherBenefitsData.map(item => item?.intelDisbSw?.code === 'Y');
    let medFacilitySw = otherBenefitsData.map(item => item?.medFacilitySw?.code === 'Y');
    let medicalBillsIndSw = otherBenefitsData.map(item => item?.medicalBillsIndSw?.code === 'Y');
    let medicareIndSw = otherBenefitsData.map(item => item?.medicareIndSw?.code === 'Y');
    let nursingHomeSw = otherBenefitsData.map(item => item?.nursingHomeSw?.code === 'Y');
    let ssiBenefitsSw = otherBenefitsData.map(item => item?.ssiBenefitsSw?.code === 'Y');

 
    this.answeredBrstCervCancerSw = brstCervCancerSw.some(data => data === true);
    this.answeredEcfSw = ecfSw.some(data => data === true);
    this.answeredHcbsSw = hcbsSw.some(data => data === true);
    this.answeredHospiceSw = hospiceSw.some(data => data === true);
    this.answeredIntelDisbSw = intelDisbSw.some(data => data === true);
    this.answeredMedFacilitySw = medFacilitySw.some(data => data === true);
    this.answeredeMedicalBillsIndSw = medicalBillsIndSw.some(data => data === true);
    this.answeredMedicareIndSw = medicareIndSw.some(data => data === true);
    this.answeredNursingHomeSw = nursingHomeSw.some(data => data === true);
    this.answeredSsiBenefitsSw = ssiBenefitsSw.some(data => data === true);
  }


  finalList(){

    this.finalListOfIndividuals = this.finalListOfIndividuals.concat(this.spouseOtherSwitches).concat(this.finalParentIndvsForLessThanEighteenOtherSwitches).concat(this.checkedIndividualsOtherSwitches).concat(this.finalBroSisIndvsOtherSwitches);

    const finalListIndvsData = this.finalListOfIndividuals.filter((c, index) => {
      return this.finalListOfIndividuals.indexOf(c) === index;
    });

    
    this.finalListIndvs = finalListIndvsData.map((e)=>{
      let temp = this.individuals.filter(element=> ((JSON.parse(element.identifier) === e)))
      return temp;
    });

    this.finalListIndvs = this.finalListIndvs.reduce((acc, val) => acc.concat(val), []);
    return this.finalListIndvs;

  }


  finalListKT(){

    this.finalListOfIndividualsKT = this.finalListOfIndividualsKT.concat(this.finalParentIndvsForLessThanEighteenOtherSwitches).concat(this.finalBroSisIndvsOtherSwitches);

    const finalListIndvsDataKT = this.finalListOfIndividualsKT.filter((c, index) => {
      return this.finalListOfIndividualsKT.indexOf(c) === index;
    });

    
    this.finalListIndvsKT = finalListIndvsDataKT.map((e)=>{
      let temp = this.individuals.filter(element=> ((JSON.parse(element.identifier) === e)))
      return temp;
    });

    this.finalListIndvsKT = this.finalListIndvsKT.reduce((acc, val) => acc.concat(val), []);
    return this.finalListIndvsKT;

  }

}