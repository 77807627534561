import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, HostListener, DoCheck } from "@angular/core";
import { ApiService } from "@app/gateway/services/api.service";
import { UserProfileService } from "@app/gateway/user-profile/user-profile.service";
import { ModalService } from "@app/services/modal/modal.service";
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders  } from '@angular/common/http';
@Component({
  selector: "nh-doc-upload",
  templateUrl: "./document-upload.component.html",
  styleUrls: ["./document-upload.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentUploadComponent implements OnInit, DoCheck {
  @Input() row;
  @Input() selectData;
  @Output() docUploadSuccess = new EventEmitter<any>();
  rows;
  rowsData = [];
  columnData = [{name : "Name", prop : "fullName"},
    {name : "Proof Type", prop : "verfDesc"}
  ]
  uploadDocumentFlag = false;
  data = [];
  documents = [{ docType: null, show: true, file: null, error: false }];
  docType = null;
  referenceData;
  options;
  code;
  tableCode = [];
  error = true;
  showAnother = false;
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  userId;
  columns;
  showCZPRmessage = false;
  displayLoading = false;
  docName: string;
  defaultLanguage;
  translatedLang;
  fromMyAppeals;
  vfdProofType;
  constructor(
    private apiService: ApiService,
    private userProfileService: UserProfileService,
    private modalService: ModalService,
    public translateService: TranslateService
  ) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  ngOnInit() {
    this.rows = [...[this.row]];
    this.userProfileService?.fromMyAppeals.subscribe((bool) => {
      this.fromMyAppeals = bool;
    })
    this.fromMyAppeals = JSON.parse(sessionStorage.getItem('myAppealsUploadProof'));
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.rowsData = [];
      this.options = [];
      if(this.translatedLang !== 'sp'){
        this.columnData = [{name : "Name", prop : "fullName"},
          {name : "Proof Type", prop : "verfDesc"}
        ]
      }else{
        this.columnData = [
          { name: "Nombre", prop: "fullName" },
          { name: "Tipo de comprobante", prop: "verfDesc" },
        ];
      }

      if(this.rows[0]['verfDesc'] === 'Documents for Appeals' || this.rows[0]['verfDesc'] === 'Documentos para Apelaciones'){
        if (this.translatedLang === 'en') {
          this.rows[0]['verfDesc'] = "Documents for Appeals";
        } else {
          this.rows[0]['verfDesc'] = "Documentos para Apelaciones"
        }
      }

      if (this.rows[0]['verfDesc'] === 'Appeal Proof Documents' || this.rows[0]['verfDesc'] === 'Documentos de comprobación para apelación') {
        if (this.translatedLang === 'en') {
          this.rows[0]['verfDesc'] = "Appeal Proof Documents";
        } else {
          this.rows[0]['verfDesc'] = "Documentos de comprobación para apelación"
        }
      }

      if(this.rows[0]['verfDesc'] === 'Proof Of Assisting Person'){
        if (this.translatedLang === 'en') {
          this.rows[0]['verfDesc'] = "Proof of Assisting Person";
        } else {
          this.rows[0]['verfDesc'] = "Comprobante de persona asistente."
        }
      }
      this.getColumns();      
    });  
    this.getColumns();  
  }

  getColumns(){ 
    if (this.row.refId) {
      this.columns = [
        { name: "Name", prop: "fullName" },
        { name: "Ref Id", prop: "refId" },
        { name: "Proof Type", prop: "verfDesc" },
      ];
      const url = `publ/reference-tables/retrieve?table=KBDOCUMENTTYPE`;
      this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCMUD'})}).subscribe((res) => {
        this.options = [...res['columnDetails']];
      });
    }else { 
      if(this.translatedLang !== 'sp'){
        this.columns = [
          { name: "Name", prop: "fullName" },
          { name: "Proof Type", prop: "proofType" },
          { name: "Due Date", prop: "verfDue" },
        ];
      }else{
        this.columns = [
          { name: "Name", prop: "fullName" },
          { name: "Proof Type", prop: "proofTypeEs" },
          { name: "Due Date", prop: "verfDue" },
        ];
      }
      if(this.row.verfDesc === "Documents for Appeals" || this.row.verfDesc === "Documentos para Apelaciones"){

        this.columns = [
          { name: "Name", prop: "fullName" },
          { name: "Proof Type", prop: "verfDesc" }
        ];

        this.code = [];
        this.code.push({ value: 'APPR' });
      }else if(this.row.verfCd !== 'AS') {
      this.code = this.selectData?.filter(
        (tableData) => tableData.code === this.row.verfCd
      );
      } else {
        this.code = [];
        this.code.push({ value: 'ASPR' })
      }

      if (this.row.verfDesc === "Appeal Proof Documents" || this.row.verfDesc === "Documentos de comprobación para apelación") {
        this.columns = [
          { name: "Name", prop: "fullName" },
          { name: "Proof Type", prop: "verfDesc" }
        ];
        this.code = [];
        this.code.push({ value: 'VFPR' });
      }

      if(this.translatedLang === 'sp'){
        this.columnData = [
          { name: "Nombre", prop: "fullName" },
          { name: "Tipo de comprobante", prop: "verfDesc" },
        ];
      }

      if(this.row.uploadAppealProofVfd === true && this.row.uploadAppealAiProof === false) {
        this.uploadDocumentFlag = true
        if (this.translatedLang === 'en') {
          this.vfdProofType = "Appeal Proof";
        } else {
          this.vfdProofType = "Comprobante de la Apelación"
        }
        let url = `publ/reference-tables/retrieve?table=VFPR`;
        this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCMUD'})}).subscribe((res) => {
            this.options = [...(this.options || []), ...res['columnDetails']];
            this.tableCode = [...(this.tableCode || []), res]; 
            const tableUploadData = {...this.row}
            tableUploadData.verfDesc = ""
            if (this.translatedLang === 'en') {
              tableUploadData.verfDesc = "Appeal Proof Documents";
            } else {
              tableUploadData.verfDesc = "Documentos de comprobación para apelación"
            }
            this.rowsData = [...this.rowsData, tableUploadData]
        });
         url = `publ/reference-tables/retrieve?table=APPR`;
        this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCMUD'})}).subscribe((res) => {
            this.options = [...(this.options || []), ...res['columnDetails']];
            this.tableCode = [...(this.tableCode || []), res];
            const tableUploadData = {...this.row}
            tableUploadData.verfDesc = ""
            if (this.translatedLang === 'en') {
              tableUploadData.verfDesc = "Documents for Appeals";
            } else {
              tableUploadData.verfDesc = "Documentos para Apelaciones"
            }
            this.rowsData = [...this.rowsData, tableUploadData]
        });
      }
      else if (this.row.uploadAppealAiProof === true && this.row.uploadAppealProofVfd === false) {
        this.uploadDocumentFlag = true
        const url = `publ/reference-tables/retrieve?table=APPR`;
        this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCMUD'})}).subscribe((res) => {
            this.options = [...(this.options || []), ...res['columnDetails']];
            this.documents[0].docType = this.options[0]
            this.tableCode = [...(this.tableCode || []), res];
            const tableUploadData = {...this.row}
            tableUploadData.verfDesc = ""
            if (this.translatedLang === 'en') {
              tableUploadData.verfDesc = "Documents for Appeals";
            } else {
              tableUploadData.verfDesc = "Documentos para Apelaciones"
            }
            this.vfdProofType = tableUploadData.verfDesc;
            this.rowsData = [tableUploadData]
        });
       
      } 
      else {
        const url = `publ/reference-tables/retrieve?table=${this.code[0].value}`;
        this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCMUD'})}).subscribe((res) => {
            this.options = [...res['columnDetails']];
        });
      }
    } 
 }

  selectedOption(i) {
    this.documents[i].error = !this.documents[i].docType;
    if (this.documents[i].docType !== null) {
      this.setCZPRmessage(i);
    }
    this.addDocbuttonVisibility();
  }

  setCZPRmessage(i) {
    const vals = [...this.documents];
    const proofType = vals[i].docType?.code;
    if (vals.length === 1) {
      this.showCZPRmessage = ["BC", "UPP", "US", "CC"].includes(proofType);
    } else {
      this.showCZPRmessage = false;
    }
  }

  addAnother() {
    this.documents.push({
      docType: null,
      show: true,
      file: null,
      error: false,
    });
    this.addDocbuttonVisibility();
  }

  close(i: any) {
    this.documents.splice(i, 1);
    this.addDocbuttonVisibility();
  };

  removeItem(i) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.documents[i].file = null;
        this.documents[i].show = true;
        this.addDocbuttonVisibility();
      }
    });
  }

  trackByIdx(index: number): any {
    return index;
  }

  getFileDetails(fileData, i) {
    if (fileData.res !== null) {
      this.documents[i].show = false;
      this.documents[i].file = { ...fileData.fileRes.file, ...{ res: fileData.res } };
      this.addDocbuttonVisibility();
      this.setCZPRmessage(i);
      //this.error = false;
    }
  }

  addDocbuttonVisibility() {
    this.error = this.documents.some(doc => doc.file == null || !doc.docType);
  }

  viewPdf(i) {
    const docId = this.documents[i].file.res.replace(/[{}]/g, "");
    this.userProfileService.getPrintDoc(docId).subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      let newVariable: any;
      newVariable = window.navigator;
      if (newVariable && newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveOrOpenBlob(file);
      } else {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    });
  }

  submitDocuments() {
    this.userProfileService.fromMyAppeals.next(false);
    const submitUrl = `indi/neededDocuments/submitDocuments`;
    const userType = sessionStorage.getItem("userType");
    const user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.userId = userType && userType === "RP" ? sessionStorage.getItem("userName") : user.userId;

    let uploadData;
    if (this.row.refId) {
      const types = this.documents.map((doc) => {
        return {
          documentSubType: {
            code: doc.docType.code,
            value: doc.docType.enDescription,
          },
          documentId: doc.file.res,
          indvId: this.row.clientId,
          documentType: { code: 'KB', value: 'KB Document'}
        };
      });
      uploadData = {
        refId: this.row.refId,
        caseNumber: this.row.caseNum,
        userId: this.userId,
        typeOfDocument: [...types]
      };
    } else {
      const types = this.documents.map((doc) => {
        let docType
        if(this.row.uploadAppealProofVfd === true || this.row.uploadAppealAiProof === true){
          const codeValue = this.tableCode.find(item =>(item.columnDetails.find(cd => (cd.code === doc.docType.code))))
          docType = {
            code: codeValue.tableId, value: codeValue.tableName
          }
        }
        else {
          docType = {
            code: this.code[0].value, value: this.row.verfDesc
          }
        }
        return {
          documentType: docType,
          documentSubType: {
            code: doc.docType.code,
            value: doc.docType.enDescription,
          },
          documentId: doc.file.res,
          indvId: this.row.clientId ? this.row.clientId : this.row.indvId,
        };
      });

      if (this.row.verfDesc === "Documents for Appeals" || this.row.verfDesc === "Documentos para Apelaciones" || this.row.verfDesc === "Appeal Proof Documents, Documents for Appeals"
        || this.row.verfDesc === "Documentos de comprobación para apelación, Documentos para Apelaciones" || this.row.uploadAppealAiProof === true || this.row.uploadAppealProofVfd === true) {        
          uploadData = {
          appealNumber:this.row.appealNum ? this.row.appealNum : this.row.appealNumber,
          caseNumber:  this.row.caseNum  ? this.row.caseNum : this.row.caseNumber,
          userId: this.userId,
          typeOfDocument: [...types],
        };
      }else{
        uploadData = {
          caseNumber: this.row.caseNum,
          userId: this.userId,
          typeOfDocument: [...types],
        }
      };

      if (this.row.verfDesc === "Appeal Proof Documents" || this.row.verfDesc === "Documentos de comprobación para apelación"
        || this.row.verfDesc === "Appeal Proof Documents, Documents for Appeals" || this.row.uploadAppealAiProof === true || this.row.uploadAppealProofVfd === true || this.row.verfDesc === "Documentos de comprobación para apelación, Documentos para Apelaciones") {
          uploadData = {
          appealNumber: this.row.appealNum ? this.row.appealNum : this.row.appealNumber,
          caseNumber: this.row.caseNum ? this.row.caseNum : this.row.caseNumber,
          userId: this.userId,
          typeOfDocument: [...types],
        };
      } else {
        uploadData = {
          caseNumber: this.row.caseNum,
          userId: this.userId,
          typeOfDocument: [...types],
        }
      };
    }
    const contentHeader = new HttpHeaders({'pageId':'TCMUD'})
    this.apiService.post(submitUrl, uploadData, {headers: contentHeader}).subscribe(() => {
      this.docUploadSuccess.emit({ success: true });
    });
  }

  getFileProgress(event) {
    if (event) {
      this.displayLoading = event.displayLoading;
      this.docName = event.fileName;
    } else {
      this.displayLoading = false;
      this.docName = null;
    }
  }
}