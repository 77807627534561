import { Component, Input } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';

import { propOr, keys } from 'ramda';

import { ErrorCode, ErrorCodes } from '@app/models/errors.model';
import { hasDateErrors } from '@app/helpers/validation.helpers';
import { toMiddleEndian } from '@app/helpers/date.helpers';

@Component({
  selector: 'nh-messages',
  templateUrl: './error-messages.component.html',
})
export class ErrorMessagesComponent {
  errorCodes: ErrorCode[] = keys(ErrorCodes);
  @Input() public field: NgControl;
  @Input() public label: string;
  @Input() public option: any;

  hasDateErrors() {
    return hasDateErrors(this.field);
  }

  errorMessage(errorCode: ErrorCode, config: ValidationErrors): string {
    const ERRORS = {
      minlength_count: `${typeof config === 'boolean' ? '' : typeof config !== 'string' ? config.requiredLength : ''}`,
      minlength: 'MIN_LENGTH',
      minlength_count_character: 'CHARACTER',
      maxlength_count: `${typeof config === 'boolean' ? '' : typeof config !== 'string' ? config.requiredLength : ''}`,
      maxlength: 'MAX_LENGTH',
      max: `Maximum value must be at least ${typeof config === 'boolean' ? '' : typeof config !== 'string' ? config.max : ''} or less.`,
      min: `Minimum value must be at least ${typeof config === 'boolean' ? '' : typeof config !== 'string' ? config.min : ''}.`,
      INVALID_START_DATE: `Start Date cannot be before ${this.option && this.option.name ? this.option.name + "'s" : ''} date of birth.`,
      INVALID_LANGUAGE: `Invalid entry ${this.label}. Please enter alphabetic characters only.`,
      INVALID_EXPENSE: `Invalid entry for ${this.label}. Please enter numeric characters only.`,
      INVALID_NAME: `Invalid entry for ${this.label === null ? this.option : this.label}. Please enter alphabetic characters, hyphens (-) , apostrophes ('), spaces, periods (.), or numbers only.`,
      INVALID_FIELD: `Invalid entry for ${this.label ? this.label : this.option ? this.option.label : ''}. Please enter alphabetic characters, hyphens (-) , apostrophes ('), spaces, periods (.), or numbers only.`,
      INVALID_ALPHANUMERIC_CHARACTER: `Invalid entry for ${this.label}. Please enter letters and numbers only`,
      INVALID_ORGANIZATION_NAME: `Invalid entry for ${this.label}. Please enter letters, numbers and spaces only`,
      INVALID_NUMERIC: `Invalid entry for ${this.label !== undefined ? this.label : this.option ? this.option.label : ''}. Please enter numeric characters only.`,
      INVALID_SAH_ALPHANUMERIC_CHARACTER:'INVALID_SAH_ALPHANUMERIC_CHARACTER',
      required: 'REQUIRED',
      INVALID_DATE: 'INVALID_DATE',
      INVALID_APPLICATION_DATE: 'INVALID_APPLICATION_DATE',
      INVALID_DATE_OF_BIRTH_FOR_APPLICATION: 'INVALID_DATE_OF_BIRTH_FOR_APPLICATION',
      INVALID_MIN_DATE: 'INVALID_MIN_DATE',
      INVALID_DATE_OF_SERVICE_MIN_DATE: "INVALID_DATE_OF_SERVICE_MIN_DATE",
      INVALID_MAX_DATE: 'INVALID_MAX_DATE',
      INVALID_FUTURE_DATE: 'INVALID_FUTURE_DATE',
      INVALID_DOB: 'INVALID_DOB',
      INVALID_POLICY_NUMBER: 'INVALID_POLICY_NUMBER',
      INVALID_CREDITCARD: 'INVALID_CREDITCARD',
      INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
      INVALID_PASSWORD: 'INVALID_PASSWORD',
      INVALID_HOUSE_NUMBER: 'INVALID_HOUSE_NUMBER',
      INVALID_UNIT_NUMBER: 'INVALID_UNIT_NUMBER',
      INVALID_OTHER_EXPENSE: `INVALID_OTHER_EXPENSE`,
      INVALID_TAX_NAME: `INVALID_TAX_NAME`,
      INVALID_INSURANCE_COMPANY_NAME: 'INVALID_INSURANCE_COMPANY_NAME',
      INVALID_NURSING_NAME: 'INVALID_NURSING_NAME',
      INVALID_DESCRIPTION: `INVALID_DESCRIPTION`,
      INVALID_NUMBER: `INVALID_NUMBER`,
      INVALID_PERCENTAGE_DECIMAL: `INVALID_PERCENTAGE_DECIMAL`,
      INVALID_STREET_ADDRESS: "INVALID_STREET_ADDRESS",
      INVALID_COMMENTS: 'INVALID_COMMENTS',
      UNMATCHED_FIELDS: 'UNMATCHED_FIELDS',
      DATE_DUE: "DATE_DUE",
      MAX_DATE_DUE: "MAX_DATE_DUE",
      PAST_DUE_DATE: "PAST_DUE_DATE",
      DATE_NOT_AFTER_MONTH: 'DATE_NOT_AFTER_MONTH',
      DATE_START_AFTER_END: 'DATE_START_AFTER_END',
      DATE_NOT_BEFORE_30_DAYS: 'DATE_NOT_BEFORE_30_DAYS',
      DATE_NOT_BEFORE_90_DAYS: 'DATE_NOT_BEFORE_90_DAYS',
      DATE_NOT_BEFORE_120_YEARS: `DATE_NOT_BEFORE_120_YEARS`,
      BABY_DATE_NOT_GREATER_THAN_1_YEAR: 'BABY_DATE_NOT_GREATER_THAN_1_YEAR',
      PERIOD_BIWEEKLY: 'PERIOD_BIWEEKLY',
      PERIOD_MONTHLY: 'PERIOD_MONTHLY',
      PERIOD_SEMIMONTHLY: 'PERIOD_SEMIMONTHLY',
      PERIOD_WEEKLY: 'PERIOD_WEEKLY',
      INVALID_ZIP_CODE: 'INVALID_ZIP_CODE',
      INVALID_CITY: `INVALID_CITY`,
      INVALID_MEDICARE_NUMBER: 'INVALID_MEDICARE_NUMBER',
      INVALID_VEHICLE_YEAR: `INVALID_VEHICLE_YEAR`,
      LESS_THAN: `LESS_THAN`,
      ONE_PLAN_REQUIRED: `ONE_PLAN_REQUIRED`,
      INVALID_DOCUMENT_DESCRIPTION: `INVALID_DOCUMENT_DESCRIPTION`,
      EQUITY_VALUE: `EQUITY_VALUE`,
      PHONE_NUMBER: `PHONE_NUMBER`,
      INVALID_I94: `INVALID_I94`,
      INVALID_ALIEN_NUMBER: `INVALID_ALIEN_NUMBER`,
      INVALID_SEVIS_ID: `INVALID_SEVIS_ID`,
      INVALID_CARD_NUMBER: `INVALID_CARD_NUMBER`,
      INVALID_CITIZENSHIP_CERTIFICATE: `INVALID_CITIZENSHIP_CERTIFICATE`,
      INVALID_NATURALIZATION_NUMBER: `INVALID_NATURALIZATION_NUMBER`,
      INVALID_CURRENCY: `INVALID_CURRENCY`,
      INVALID_HOURS: 'INVALID_HOURS',
      INVALID_SSN: `INVALID_SSN`,
      INVALID_PASSPORT_NUMBER: 'INVALID_PASSPORT_NUMBER',
      INVALID_PRECENTAGE: 'INVALID_PRECENTAGE',
      DATE_NOT_BEFORE_60_DAYS: 'DATE_NOT_BEFORE_60_DAYS',
      FIVE_MONTHS_MAX: 'FIVE_MONTHS_MAX',
      TWELVE_MONTHS_MAX: 'TWELVE_MONTHS_MAX',
      DATE_PAST_THREEMONTHS: `The date cannot be prior to ${!isNaN(Date.parse(config + '')) ? toMiddleEndian(config) : ''}`,
      INVALID_USER_NAME: 'INVALID_USER_NAME',
      INVALID_USER_NAME_EMAIL: 'INVALID_USER_NAME_EMAIL',
      INVALID_LENGTH: 'INVALID_LENGTH',
      INVALID_ANSWER: 'INVALID_ANSWER',
      DUPLICATE_SSN: 'DUPLICATE_SSN',
      PASSWORD_GUIDELINES: 'PASSWORD_GUIDELINES',
      PASSWORDS_DIFFERENT: 'PASSWORDS_DIFFERENT',
      ANSWER_DUPLICATE: 'ANSWER_DUPLICATE',
      EMAIL_MATCH: 'EMAIL_MATCH',
      PASSWORD_CONFIRM: 'PASSWORD_CONFIRM',
      ENTER_APP_NUM: `ENTER_APP_NUM`,
      INVALID_PERSON_ID: `INVALID_PERSON_ID`,
      INVALID_FIRST: `INVALID_FIRST`,
      INVALID_LAST: `INVALID_LAST`,
      INVALID_SSN_NUM: `INVALID_SSN_NUM`,
      INVALID_INDIV: `INVALID_INDIV`,
      POLICY_HOLDER_DOB: 'POLICY_HOLDER_DOB',
      INVALID_DATE_RANGE: 'INVALID_DATE_RANGE',
      SSN_CONFIRM: 'SSN_CONFIRM',
      SSN_DIFFERENT: 'SSN_DIFFERENT',
      LINK_SSN_DIFFERENT: 'LINK_SSN_DIFFERENT',
      INVALID_EMAIL:'INVALID_EMAIL',
      INVALID_SUBMITTEDBY: 'INVALID_SUBMITTEDBY',
      INVALID_MEDBILL: 'INVALID_MEDBILL',
      INVALID_JOB_START_DATE : 'INVALID_JOB_START_DATE'
    };
    return propOr('Error', errorCode, ERRORS);
  }
}