<div class="container custom-font">
  <div class="row justify-content-center align-items-center background-image">
    <div class="center-block">
      <div class="card center-aligned">
        <div class="card-body bg-signin">
          <nh-alert type="danger" [isOpen]="showError">
            <div class="row">
              <div class="icon">
                <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
              </div>
              <div class="message">
                <span [innerHTML]="showError | translate: {url: ppUrl}"></span>
              </div>
            </div>
          </nh-alert>
          <div class="text-center login-icon-style">
            <img alt="" src="../../../assets/images/⚛️ icons-login-person.svg">
          </div>
          <br />
          <div class="text-center login-header-style">
            <span>{{'TENNCARE_SIGNIN' | translate}}</span>
          </div>
          <br />
          <br />
          <form [formGroup]="SignInform">
            <div class="form-row justify-content-center align-items-center">
              <div class="col-12 col-md-5  col-xs-12">
                <nh-field [mandatory]=true [isRow]="true" label="{{'LOGIN_USERNAME_EMAIL' | translate}}"
                  class="label-section">
                  <input type="text" name="userName" formControlName="userName" aria-label="userName"
                    class="form-control" placeholder="{{'LOGIN_USERNAME_EMAIL' | translate}}" required>
                </nh-field>
              </div>
            </div>
            <div class="form-row justify-content-center align-items-center">
              <div class="col-12 col-md-5 col-xs-12">
                <nh-field [mandatory]=true [isRow]="true" label="{{'PASSWORD' | translate}}" class="label-section">
                  <div class="password-eye">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="password"
                      placeholder="{{'PASSWORD' | translate}}" required />
                    <span (click)="toggleFieldTextType()" class="eye-icon"><img alt=""
                        src="assets/images/Icons/eye-outline-password.svg"></span>
                  </div>
                </nh-field>
              </div>
            </div>
            <br />
            <div class="form-row justify-content-center align-items-center">
              <re-captcha *ngIf="isRecaptchaEnabled && loaderReady" (resolved)="resolved($event)"
                formControlName="recaptchaReactive" [siteKey]="siteKey">
              </re-captcha>
            </div>
          </form>
          <br />
          <div class="row">
            <div class="col-12 col-md-12 loginButton">
              <button id="signIn" (click)="signin()" [disabled]="SignInform.status != 'VALID'"
                class="btn btn-block btn-primary">{{'LOGIN' | translate}}</button>
            </div>
          </div>
          <br />
          <br />
          <div class="col-12">
            <ul id="menu">
              <li class="hover-red">
                <a [routerLink]="['/forgot-password']" class="btn btn-link hover-red">
                  <span>{{ 'FORGOT_PWD' | translate}}</span>
                </a>
              </li>
              <li class="hover-red">
                <a [routerLink]="['/forgot-user-name']" class="btn btn-link hover-red">
                  <span>{{'FORGOT_USERNAME' | translate}}</span>
                </a>
              </li>
              <li class="hover-red">
                <a [routerLink]="['/register-account']" class="btn btn-link hover-red">
                  <span>{{'CREATE_ACCOUNT' | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div> 
            <br />
            <hr>
          </div>
        <br />
          <div>
            <p class="warning">{{'SIGNIN_WARNING_HEADING' | translate}}</p>
            <div class="warning-message">{{'SIGNIN_WARNING' | translate}}</div> 
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>

</div>