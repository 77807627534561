import { Component, ChangeDetectionStrategy, AfterContentInit, OnDestroy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormArray, FormBuilder } from '@angular/forms';
import Vs from '@app/services/validators/validators.service';
import {  outsideHouseholdMembers } from '@app/helpers/models.helpers';
import { push, removeAt } from '@app/helpers/form-array.helpers';
import {ModalService} from '@app/services/modal/modal.service';
import { minus120Years } from '@app/helpers/date.helpers';
import { groupControls } from '@app/helpers/form-group.helpers';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ProcessService } from '@app/services/process.service';
import { Subscription } from 'rxjs';
import { equals } from 'ramda';
import { StateService } from '@app/services/state/state.service';
@Component({
  templateUrl: './tax-dependents-outside.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tax-dependents-outside.component.scss']
})

export class TaxDependentsOutsideComponent extends SectionFormDirective implements AfterContentInit, OnDestroy {
  today = new Date();
  minimumDate = minus120Years();
  mobile = window.screen.width < 768 ? true : false;
  defaultLanguage;
  translatedLang;
  isRmbRmc:boolean = false;
  duplicateTaxSub: Subscription;

  get taxDependentsOutside(): FormArray {
    return this.getControl('data.taxDependentsOutside') as FormArray;
  }
  constructor (private titleService: Title, private modalService: ModalService, builder: FormBuilder, public translateService: TranslateService, public processService: ProcessService, protected state: StateService ){
    super(builder);
    this.titleService.setTitle('Tax Dependents Outside Household');
    this.processService.isDuplicateTaxOutsideInside = false;
  }
  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    this.isRmbRmc = isRmbRmc(this.mode);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
    this.languageCheck(res.lang);
    });

    this.dataChange();
    this.duplicateTaxSub = this.processService.duplicateTaxOutsideInsideValidate.subscribe((action) => this.isDuplicateTaxDep(action));
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    if (typeof tableControl !== 'string') {
    const idx = tableControl;
    const taxDependentsOutsideList = this.getControl('data.taxDependentsOutside');
    const taxDependentOutsideCtrls = taxDependentsOutsideList['controls'];
    const relationControl = taxDependentOutsideCtrls[idx];
    tableControl = relationControl.controls['relation'];
    }
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  onResize (e) {
    this.mobile = e.target.innerWidth < 768 ? true : false;
  }

  hasTaxDependentsOutside () {
    const data = this.getControl('data');
    const hasTaxDependentOutside = data.get('hasTaxDependentOutside');
    hasTaxDependentOutside.value.code === 'Y' ? this.addRow() : this.clear();
    if(hasTaxDependentOutside.value.code === 'N'){
      (this.getControl('data.taxDependentsOutside') as FormArray).clear();
    }
    if(this.processService.isDuplicateTaxOutsideInside){
      this.processService.isDuplicateTaxOutsideInside = false;
    }

  }

  addRow() {
    push(outsideHouseholdMembers({ name: null, birthDate: null, relation: null }), this.taxDependentsOutside);
    const taxDependentsOutside = groupControls(this.getControl('data.taxDependentsOutside'));
    taxDependentsOutside.forEach(item => {
      item.controls["name"].setValidators([Vs.required, Vs.invalidName])
    })
  }

  removeAt(index) {
    this.modalService.confirmDelete().subscribe((res) => {
    if (res) {
      removeAt(index, this.taxDependentsOutside);
      if (this.taxDependentsOutside.value.length === 0) this.addRow();
    }})
  }

  clear() {
    const taxDependentsOutside = this.getControl('data.taxDependentsOutside') as FormArray;
    taxDependentsOutside.clear();
  }

  enableDeleteButton() {
    return this.taxDependentsOutside;
  }

  isInvalidDate(event, row) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else {
      event.target.value = formatDate(row.value.birthDate, 'MM/dd/yyyy', 'en');
    }
  }

  dataChange(){
    if(this.processService.isDuplicateTaxOutsideInside){
      this.processService.isDuplicateTaxOutsideInside = false;
    }
  }

  isDuplicateTaxDep(action){
    const isSaveExit = equals(action, 'fromSaveExit') ? true : false;
    const formData = this.formGroup.value.data;

    const taxDependentsOutsideIndvs =  JSON.parse(JSON.stringify(formData.taxDependentsOutside));
    if(isSaveExit && formData?.hasTaxDependentOutside?.code === 'N'){
      this.processService.isDuplicateTaxOutsideInside = true;
      isSaveExit ? this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit') : '';
    }else{

      Object.keys(taxDependentsOutsideIndvs).forEach((item) => {
        const checkDateGMT = (/\bGMT\b/).test(taxDependentsOutsideIndvs[item]['birthDate']);
        if(checkDateGMT){
          taxDependentsOutsideIndvs[item]['birthDate'] = this.getDate((taxDependentsOutsideIndvs[item]['birthDate']).toLocaleDateString());
        }else{
          const checkDateHasT = (/T/).test(taxDependentsOutsideIndvs[item]['birthDate']);
          if(checkDateHasT){
            taxDependentsOutsideIndvs[item]['birthDate'] = taxDependentsOutsideIndvs[item]['birthDate'].split("T")[0];
          }else{
            taxDependentsOutsideIndvs[item]['birthDate'] = this.getDate((taxDependentsOutsideIndvs[item]['birthDate']));
          }
        }
      });

      const indvsRows = [...this.members];
      let duplicateIndvRows = indvsRows.filter((el) => {
        return taxDependentsOutsideIndvs.some((f) => {
          return (f.name?.toLowerCase() === el.name.fullName?.toLowerCase()) && (f.birthDate === el.birthDate);
        });
      });

      duplicateIndvRows = duplicateIndvRows.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.name.fullName.toLowerCase() === value.name.fullName.toLowerCase() && t.birthDate === value.birthDate
      )));

      const duplicateIndvNames = duplicateIndvRows.map(indv => indv.name.fullName);

      if(duplicateIndvNames.length>0){
        this.modalService.openDuplicateTaxDepOrJointFiler(duplicateIndvNames, isSaveExit, 'taxdep').subscribe(response => {
          console.log(response);
          this.processService.isDuplicateTaxOutsideInside = true;
        });
      }else if(duplicateIndvNames.length<=0 && isSaveExit){
        this.processService.isDuplicateTaxOutsideInside = true;
        this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit');
      }else{
        this.processService.noDuplicateTaxInOutAfterModal = true;
        this.processService.isNoDuplicateTaxOutsideInsideValidate.next([true, this.formGroup.value]);
      };
    }
  }


  getDate(date){
    const checkSlash = (/\//).test(date);
    if(checkSlash){
      const dateParts = date.split("/");
      const formattedDate = dateParts[2] + "-" + dateParts[0].padStart(2,'0') + "-" + dateParts[1].padStart(2,'0');
      return formattedDate;
    }else{
      return date;
    }
  }

  ngOnDestroy() {
    this.duplicateTaxSub.unsubscribe();
  }
}
