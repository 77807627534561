import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceConfigService } from '@app/services/service-config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { prefixWith } from '@app/helpers/services.helpers';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private http: HttpClient, private serverConfig: ServiceConfigService) { }
  apiContext = `easy-app/api`;
  hideRenew = new BehaviorSubject<boolean>(false);
  enableChangeReport = new BehaviorSubject<boolean>(false);
  hideRenew$ = this.hideRenew.asObservable();
  searchResults = new BehaviorSubject<any>({});
  searchResults$ = this.searchResults.asObservable();
  disableChangeReport = new BehaviorSubject<boolean>(false);
  disableChangeReport$ = this.disableChangeReport.asObservable();
  getKbData = new BehaviorSubject<boolean>(false);
  getKbData$ = this.getKbData.asObservable();
  emailId$ = new BehaviorSubject<string>("");
  isReportChangedModalOpenedForFirstTime = false;
  showHideQFR = new BehaviorSubject<boolean>(false);
  showHideQFR$ = this.showHideQFR.asObservable();
  enableAppealOrHealthPlan = new BehaviorSubject<boolean>(false);
  showHideMyAppeals = new BehaviorSubject<boolean>(false);
  fromMyAppeals = new BehaviorSubject<boolean>(JSON.parse(sessionStorage.getItem('myAppealsUploadProof')));
  step1Info = new BehaviorSubject<boolean>(null);
  step1Information$ = this.step1Info.asObservable();
  step2Info = new BehaviorSubject<boolean>(null);
  step2Information$ = this.step2Info.asObservable();
  step3Info = new BehaviorSubject<boolean>(null);
  step3Information$ = this.step3Info.asObservable();
  step4Info = new BehaviorSubject<boolean>(null);
  step4Information$ = this.step4Info.asObservable();
  fomMobileApp = new BehaviorSubject<boolean>(false);
  fomMobileApp$ = this.fomMobileApp.asObservable();
  isHoh = new BehaviorSubject<boolean>(false);
  isHoh$ = this.isHoh.asObservable();
  reconDate = new BehaviorSubject<boolean>(false);
  reconDate$ = this.reconDate.asObservable();
  isCaseTerminated = new BehaviorSubject<boolean>(false);
  isCaseTerminated$ = this.reconDate.asObservable();
  dcsOrSSIMultipleCase = new BehaviorSubject<boolean>(false) 
  dcsOrSSIMultipleCase$ = this.dcsOrSSIMultipleCase.asObservable();
  sendStep1Info(bool) {
    this.step1Info.next(bool);
  }

  getTerminateCaseStatus(bool) {
    this.isCaseTerminated.next(bool);
  }

  sendStep2Info(bool) {
    this.step2Info.next(bool);
  }

  sendStep3Info(bool) {
    this.step3Info.next(bool);
  }
  sendStep4Info(bool) {
    this.step4Info.next(bool);
  }

  hideRenewal(bool) {
    this.hideRenew.next(bool);
  }

  fromMobileApptoTcc(bool) {
    this.fomMobileApp.next(bool)
  }

  disableRmc(value) {
    if (value === 'Y') {
      this.disableChangeReport.next(false);
    } else {
      this.disableChangeReport.next(true);
    }
  }

  isHeadofHousehold(bool) {
    this.isHoh.next(bool)
  }

  isDCSorSSIMultipleCase(bool){
    this.dcsOrSSIMultipleCase.next(bool);
  }

  getReconDate(bool) {
    this.reconDate.next(bool)
  }

  getKtBucketData(bool) {
    this.getKbData.next(bool)
  }

  gatewayURL() {
    return this.serverConfig.getServerConfig()['gatewayURL'];
  }

  getDocumentsWeNeed(indiv) {
    if(sessionStorage.uploadDoc && sessionStorage.userType === 'RP'){
      indiv = null;
      sessionStorage.uploadDoc = '';
    }      
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/docsWeNeed/indv/${indiv}`, {headers: new HttpHeaders({'pageId': 'TCMDT'})})
  }

  getBenefitDashboard(indivId) {
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/cmbSumm/indv/${indivId}`, {headers: new HttpHeaders({'pageId': 'TCCMB'})})
  }

  getLetters(indivNumber, type, begDate?, endDate?) {
    const contentHeaders = new HttpHeaders({'pageId': 'TCMLR'});
    if (begDate && endDate) {
        const bdate = new Date(begDate).toISOString().split('T')[0];
        const edate = new Date(endDate).toISOString().split('T')[0];
        return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/viewMyLetters/indv/${indivNumber}?letterType=${type}&begDate=${bdate}&endDate=${edate}`, {headers : contentHeaders})
    }
    if (begDate) {
        const bdate = new Date(begDate).toISOString().split('T')[0];
        return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/viewMyLetters/indv/${indivNumber}?letterType=${type}&begDate=${bdate}`, {headers : contentHeaders})
    }
    if (endDate) {
        const edate = new Date(endDate).toISOString().split('T')[0];
        return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/viewMyLetters/indv/${indivNumber}?letterType=${type}&endDate=${edate}`, {headers : contentHeaders})
    }
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/viewMyLetters/indv/${indivNumber}?letterType=${type}`, {headers : contentHeaders})
  }

  getCommunicationPreferenceData(indiv) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCCMP'});
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/users/getCommPreferences?indvId=${indiv}`, {headers : contentHeaders})
  }

  postCommunicationPreferenceData(data) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCCMP'});
    return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/users/updateCommPreferences`, data, {headers : contentHeaders})
  }

  getCaseDocuments(indivNumber) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCMDT'});
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/checkmybenefits/cmbDocSumm/indv/${indivNumber}`, {headers: contentHeaders});
  }

  getUploadedDocuments(indivId) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCMDT'});
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/checkmybenefits/uploadeddocs${prefixWith('/', indivId)}`, {headers: contentHeaders});
  }

  getPrintPdf(applicationNumber, pageId = '') {
    const contentHeader = new HttpHeaders({'pageId': pageId})
    const wpSwitch = sessionStorage.getItem('isKepro') === 'true' ? '&userType=kepro' : "";
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/viewpdf?applicationNumber=${applicationNumber}&applicationModeCode=N${wpSwitch}`, {
        'responseType': 'arraybuffer' as 'json',
        headers : contentHeader
    });
  }

  getAppealPrintPdf(applicationNumber, pageId = '', currentLang, item, appealsPrint) {
    currentLang = currentLang === "en" ? "EN" : "ES";
    const contentHeader = new HttpHeaders({'pageId': pageId})
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/viewpdf?applicationNumber=${applicationNumber}&applicationModeCode=N&lang${prefixWith('=', currentLang)}&indivId=${item?.indivId}&from=${appealsPrint}&SubmissionType=${item?.submissionType?.code}&AppealTypeSeqNum=${item?.aplSeqNum}`, {
        'responseType': 'arraybuffer' as 'json',
        headers : contentHeader
    });
  }

  getPrintDocPdf(payLoad) {
    return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/viewNotice`, payLoad, {
      'responseType': 'blob'
    });
  }

  getPrintDoc(docId) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCMDT'});
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/viewpdf?uploadDocId=${docId}`, {
        'responseType': 'arraybuffer' as 'json',
        headers: contentHeaders
    });
  }

  getEndCoverage(appNum) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCPEND'});
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/users/endMyCoverageDetails?appNum=${appNum}`, {headers: contentHeaders});
  }

  postEndCoverage(data) {
    return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/users/endMyCoverage`, { data });
  }

  postTutorial(data) {
    return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/publ/users/tutorial`, data, {headers: new HttpHeaders({'pageId': 'TCCDB'})} )
  }
/* Function Not called  */
  getTccAppData(url, data) {
    return this.http.post(`${this.gatewayURL()}${this.apiContext}${url}`, data)
  }

  getUploadedDocsByTNum(tNum) {
    const contentHeaders = new HttpHeaders({'pageId' : 'TCMDT'});
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}rest/api/indi/checkmybenefits/uploadeddocsByTNumber/${tNum}`, {headers: contentHeaders});
  }

  getSearchDetails(details) {
    this.searchResults.next(details);
  }


  getEmailId(): Observable<string> {
    return this.emailId$;
  }

  setEmailId(emailId: string): void {
    this.emailId$.next(emailId);
  }

}