export const casehead = {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    middleInitial: 'MIDDLE_NAME',
    suffix: 'SUFFIX'
}

export const languagePreference = {
    primaryLanguage: 'HOH_LANG_PREFERENCE',
    letterLanguage: 'LETTER_PREF_LANG'
}

export const contactPhones = {
    home: 'HOME_PHONE',
    message: 'WORK_PHONE',
    cell: 'CELL_PHONE',
    extension: 'EXTENSION'
}

export const physicalAddress = {
    address: 'PHYSICAL_ADDRESS',
    contactPhones: contactPhones,
    applicationSource: 'APPLICATION_SOURCE',
    changeRequestSource: 'CHANGEREQUEST_SOURCE',
    renewalSource: 'RENEWAL_SOURCE',
    bestTime: 'BEST_TIME_TO_CALL'
}

export const mailingAddrs = {
    addrAaAeAp: 'AA/AE/AP',
    addrApoFpo: 'APO/FPO',
    addressLine1: 'ADDRESS_LINE_1',
    addressLine2: 'ADDRESS_LINE_2',
    city: 'CITY',
    state: 'STATE',
    zip: 'ZIP_CODE'
}

export const mailingAddress = {
    address: 'MAILING_ADDRESS'
}
export const responsibilities = {
    applicantBehalf: 'APPLICANT_BEHALF',
    submitRenewal: 'SUBMIT_RENEWAL_FORM',
    letterOtherCommunication: 'LETTER_OTHER_COMMUNICATION',
    yourBehalf: 'YOUR_BEHALF'
}

export const assistingPerson = {
    relationship: 'RELATIONSHIP_TO_INDIV',
    otherRelationship: 'OTHER_RELATIONSHIP',
    helpDuration: "ASSIST_PERSON_HELP_DURATION1",
    name: casehead,
    organizationName: 'ORG_NAME_IF',
    idNumber: 'ID_NUMBER_IF',
    address: 'ASSISTING_ADDRESS',
    email: 'EMAIL_ADDRESS',
    bestTime: 'BEST_TIME_TO_CALL',
    contactPhones: contactPhones,
    responsibilities: responsibilities
}

export default {
    casehead: casehead,
    languagePreference: languagePreference,
    homeless: 'ARE_YOU_HOMELESS',
    sahPrgSw:'MAIL_THROUGH_SAFEATHOME',
    sahMailId:'SAFEATHOME_MAILID',
    sahCountyCd:'SAFEATHOME_COUNTY',
    physicalAddress: physicalAddress,
    mailingAddress: mailingAddress,
    assistingPerson: assistingPerson,
    removed: {
        assistingEndDate: 'REMOVE_INDIV'
    }

}
