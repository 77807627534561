import { environment } from '@env/environment';
import { Component, Output, EventEmitter, OnInit, OnDestroy, HostListener } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from '@app/gateway/services/auth.service';
import { ApiService } from '@app/gateway/services/api.service';
// import { HttpHeaders } from '@angular/common/http';
import { SigninService } from '@app/gateway/signin/signin.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
// import { ServiceConfigService } from '@app/services/service-config.service';
import { CreateAccountService } from '@app/gateway/services/createAccount.service';
import { JoyrideService } from 'ngx-joyride';
import { ChatbotComponent } from '../chatbot/chatbot.component';
import { isNil, and } from 'ramda';
import { ProcessService } from '@app/services/process.service';
import {StateService} from '@app/services/state/state.service';
import { UserProfileService } from "@app/gateway/user-profile/user-profile.service";
import { userTypeUpdated } from '@app/providers/at-a-glance/at-a-glance.component';
import { ModalService } from '@app/services/modal/modal.service';
import { HttpHeaders } from '@angular/common/http';

/* Kore ChatBot */
declare const $: any;
declare var destroy: any;
/* Kore ChatBot */

@Component({
  selector: 'nh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  region = environment.region;
  gatewayURL = environment.gatewayURL;
  @Output() toggle = new EventEmitter();
  @Output() signin = new EventEmitter();
  @Output() signout = new EventEmitter();
  userSub!: Subscription;
  userDetails: any;
  pPUserDetails;
  tcamId;
  entId;
  pPUserName;
  isLoggedOut = false;
  rtData;
  dropdownOpened = false;
  hovered = false;
  profileOptions = [
    { value: 'Manage My Account', link: '/user-profile/manage-account'},
    { value: 'Communication Preferences', link: '/user-profile/communication-preferences'},
  ];
  defaultLanguage;
  userType;
  showTutorialInMobileView = false;
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  indivId: any;
  isKepro: any;
  show = true;
  agentRequested = false;
  url:string;

  constructor(
    private state: StateService,
    private apiservice: ApiService,
    private router: Router,
    private singInService: SigninService,
    private translate: TranslateService,
    private authService: AuthService,
    // private apiService: ApiService,
    private createAccountService: CreateAccountService,
    private activatedRoute: ActivatedRoute,
    // private serverConfig: ServiceConfigService,
    public readonly joyrideService: JoyrideService,
    public processService: ProcessService,
    private userProfileService: UserProfileService,
    private modal: ModalService
    ) {
    this.getScreenSize();
    this.processService.getOnLogout().subscribe(res => {
      this.removeSignIn(res)
    });
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    this.singInService.accountData.subscribe(res => {
      this.indivId = res.individualId;
    });
    this.singInService.indivId.subscribe(res => {
      if (res !== "null" && !this.indivId) {
        this.indivId = res;
      }
    });
    userTypeUpdated.subscribe(type => {
      if(type){
        this.userType = type;
      }
    })
    this.isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    this.defaultLanguage = this.translate.getDefaultLang();
    let currentUser = null;
    if(sessionStorage.getItem('currentUser')) {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    this.userType = sessionStorage.getItem('userType');
    if (currentUser && currentUser.userId) {
      this.getUserDetails();
    }

    this.userSub = this.singInService.userLoggedIn.subscribe(res => {
      this.isLoggedOut = !res;
      if (res) {
        this.getUserDetails();
      }
    });

    this.activatedRoute.queryParams.subscribe(res => {
      this.entId = res.entityId;
    });

    // this.tcamId = this.serverConfig.getServerConfig().TCAM_KEY;
    // const payload = { tcam: this.getCookie(this.tcamId), entityId: this.entId };
    // const contentHeader = new HttpHeaders({ "Content-Type": "application/json" });
    // this.apiService.post(`publ/users/partnerLogin`, payload, { headers: contentHeader, observe: 'response' }).subscribe((res: any) => {
    //   if (res && res.body.isValidSession === 'true') {
    //     sessionStorage.setItem('userType', res.body.userType);
    //     sessionStorage.setItem('userName', res.body.userName);
    //     this.pPUserName = sessionStorage.getItem('userName');
    //     const authToken = res.headers.get('x-auth-token');
    //     this.authService.setToken(authToken);

    //     const userName = {
    //       'userName': this.pPUserName
    //     };
    //     if (!isNil(userName.userName)) {
    //       this.apiservice.post('indi/users/manageMyAccount', userName).subscribe((response) => {
    //         this.pPUserDetails = response;
    //       });
    //     }
    //   }
    // })
    this.isMobileView = this.screenWidth <= this.mobileWidth;
    const firstTimeUser = sessionStorage.getItem('firstTimeAccessSw');
    if (firstTimeUser && firstTimeUser === "Y" && this.isMobileView) {
      this.showTutorialInMobileView = true;
    }
  }

  fromPartnerPortal() {
    const userType = sessionStorage.getItem('userType');
    const userName = userType === 'RP' ? sessionStorage.getItem('userName') : null;
    return !isNil(userName);
  }

  mobileUser() {
    let mobileUser = sessionStorage.getItem('isMobileUser');
    this.url = this.router.url;
    if(this.url.includes('mobile-errorpage')){
      mobileUser = 'true';
    }
    return mobileUser === 'true' ? true : false;
  }

  navigateTo(link: string) {
    this.router.navigateByUrl(link);
    this.createAccountService.setIsStepperOn(false);
    this.dropdownOpened = false;
  }

  getUserDetails() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const userName = {
      'userName': currentUser && currentUser.userId
    }
    this.apiservice.post('indi/users/manageMyAccount', userName,{headers: new HttpHeaders({'pageId': 'TCMMA'})}).subscribe((res) => {
        this.userDetails = res;
    });
  }


  onOpen(event) {
    event.preventDefault();
    this.dropdownOpened = !this.dropdownOpened;
    this.hovered = false;
  }
  
  openFAQPopUp() {
    return this.modal.openMobileFAQ();
  }

  onSignout(event) {
    event.preventDefault();
    if (JSON.parse(sessionStorage.getItem('agentRequested'))) {
      this.agentRequested = true;
    }
    sessionStorage.clear();
    const tcam = this.getCookie("TCAM");
    document.cookie = "TCAM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    sessionStorage.setItem('tcam', tcam);
    this.dropdownOpened = false;
    this.userProfileService.hideRenewal(null);
    this.userProfileService.disableChangeReport.next(null);
    this.userProfileService.enableChangeReport.next(null);
    this.userProfileService.isHoh.next(null);
    this.userProfileService.showHideQFR.next(null);
    this.userProfileService.reconDate.next(null);
    this.userProfileService.isCaseTerminated.next(null);
    this.userProfileService.getKbData.next(null);
    this.userProfileService.dcsOrSSIMultipleCase.next(null);
    if (ChatbotComponent.chatbotInstance) {
      ChatbotComponent.chatbotInstance.destroy(this.agentRequested);
    }

    this.signout.emit();
  }

  setLanguage() {
    this.defaultLanguage = this.translate.getDefaultLang();
    if (this.defaultLanguage === 'en') {
      this.translate.setDefaultLang('sp');
      this.defaultLanguage = 'sp';
      sessionStorage.setItem('currentLang', 'sp');
    } else {
      this.translate.setDefaultLang('en');
      this.defaultLanguage = 'en'
      sessionStorage.setItem('currentLang', 'en');
    }
  }

  removeSignIn(res?) {
    if (res && res === 'false') {
      return this.show = false
    } else {
      return this.show = true;
    }
  };

  isLoggedIn() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const userName = sessionStorage.getItem('userName');
    const getCurrentUser = !isNil(currentUser) || !isNil(userName);

    const token = this.authService.tokenId;
    const hasToken = !isNil(token);
    return and(hasToken, getCurrentUser);
  }


  getCookie(name) {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }

    return null;
  }

  toggleProfile() {
    if(!this.mobileUser()){
      this.dropdownOpened = !this.dropdownOpened;
      this.hovered = false;
    } else {
      this.dropdownOpened = false;
      this.hovered = false;
    }
  }

  setLogoRedirectionUrl() {
    if(sessionStorage.getItem('appType') === 'APL') {
      const pageId = this.url.split('/').pop();
      return this.state.canDeactivateAppeal('TNIcon',pageId)
    } else {
      return this.isLoggedIn() && !this.fromPartnerPortal() && !this.mobileUser() ? this.router.navigateByUrl('/user-profile/benefit-dashboard') : ['/'];
    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
