<!-- Table Desktop -->
<ngx-datatable class="nh-table bootstrap d-none d-lg-block" columnMode="flex" rowHeight="auto" [headerHeight]="65" [footerHeight]="sectionId === 'HHSUR' || isFooterDisable ? 0 : 50" [limit]="limit" [rows]="sectionId === 'ARPDT'  ? appealRows : rows">
  <ngx-datatable-column [cellClass]="'my-auto'" *ngFor="let head of columns" [name]="head.name" [resizeable]="false" [flexGrow]="8">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div [innerHTML]="row | path: head.prop | loader: head.pipe:head.args | translate | sanitize"></div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="12" class="mr-3 pr-md-1 py-sm-1" *ngIf="sectionId !== 'HHSUR' && sectionId !== 'ARPDT'" name="">
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <div class="text-center">
        <button class="btn btn-sm btn-secondary edit-{{ rowIndex }}" [disabled]="sectionId === 'HHTJF' && enableEditReview && (row?.data?.taxFilingStatus !== 'Filer' && row?.data?.taxFilingStatus !== null)" (click)="onEdit(row)">{{'SUMMARY_EDIT' | translate }}</button>
          <div *ngIf="sectionId === 'HHIND' && row.data.headOfHousehold">
            <nh-popover [content]="headOfHouseHoldContent"></nh-popover>
          </div>
          <div *ngIf="canDelete(row)" class="pl-2 pt-1 hover-red">
            <button class="btn btn-link btn-lg hover-red delete-{{rowIndex}}" [disabled]="sectionId === 'HHTJF' && enableEditReview  && (row?.data?.taxFilingStatus !== null && (row?.data?.taxFilingStatus === 'In Household Dependent' || row?.data?.taxFilingStatus === 'Joint Filer' || row?.data?.taxFilingStatus === 'Out of Household Dependent'))" (click)="onDelete(row)">
              <span class="pr-2">{{'REMOVE' | translate}}</span>
              <nh-remove-svg></nh-remove-svg>
            </button>
          </div>
          <nh-popover *ngIf="sectionId === 'HHTJF' && row.data.taxFilingStatus !== 'Filer'"  [content]="taxPopUpOne + name[rowIndex] + taxPopUpTwo"></nh-popover>
        </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="12" class="mr-3 pr-md-1 py-sm-1" *ngIf="sectionId === 'ARPDT'" name="{{appealActionHeader | translate}}">
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <div *ngIf="!row.data.disableEdit" class="pt-1 hover-red">
        <button  class="btn btn-link btn-lg hover-red edit-{{ rowIndex }}" (click)="onEdit(row)"><span class="pr-2">{{'APPEAL_SUMMARY_EDIT' | translate }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2.5 19.5 19.49">
            <g id="_icons_create-outline" data-name=" icons create-outline">
              <path id="Combined_Shape-287" data-name="Combined Shape-287" class="cls-1"
                d="M2.63,19.49A2.62,2.62,0,0,1,0,16.91V5.62A2.63,2.63,0,0,1,2.58,3h7.9a.75.75,0,0,1,.75.75.74.74,0,0,1-.74.75H2.63A1.12,1.12,0,0,0,1.5,5.6V16.87A1.12,1.12,0,0,0,2.61,18H13.88A1.12,1.12,0,0,0,15,16.89V8.24a.76.76,0,0,1,.74-.76.75.75,0,0,1,.76.75v8.64a2.62,2.62,0,0,1-2.59,2.62Zm4.92-7.55a.17.17,0,0,1,0-.18l.39-1.17A.42.42,0,0,1,8,10.41L16.47,2a.43.43,0,0,1,.6,0h0l.47.47a.42.42,0,0,1,0,.59L9.09,11.49a.5.5,0,0,1-.19.1L7.74,12H7.68a.21.21,0,0,1-.13-.05ZM18.17,1.85l-.53-.53a.39.39,0,0,1,0-.53h0l.58-.58a.76.76,0,0,1,1.07,0,.75.75,0,0,1,0,1.06l-.56.57a.4.4,0,0,1-.54,0h0Z" />
            </g>
          </svg>
        </button>
      </div>
          <div *ngIf="canDelete(row)" class="pt-1 hover-red">
            <button class="btn btn-link btn-lg hover-red delete-{{rowIndex}}" (click)="onDeleteAplRow(row)">
              <span class="pr-2">{{'REMOVE' | translate}}</span>
              <nh-remove-svg></nh-remove-svg>
            </button>
          </div>
    </ng-template>
  </ngx-datatable-column>


  <ngx-datatable-column [flexGrow]="12" *ngIf="sectionId === 'HHSUR'" name="Are the people below still living in your household?" headerClass="text-right">
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <span class="summary-ctas" *ngIf="rowIndex > 0">
        <select class="custom-select" [(ngModel)]="options[rowIndex]" (change)="onChange()">
          <option></option>
          <option *ngFor="let item of items">{{item.value}}</option>
        </select>
      </span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<!-- Table Mobile -->
<div class="nh-table-mobile mt-3 pt-2 d-lg-none">
  <div *ngIf="sectionId !== 'ARPDT'">
    <div class="table-row mb-3 pb-3" *ngFor="let row of rows; index as i">
      <div *ngFor="let head of columns">
        <div class="mobile-table-header" [innerHtml]="head.name | sanitize"></div>
        <hr>
        <div class="mobile-table-data" [innerHTML]="row | path: head.prop | loader: head.pipe : head.args | sanitize">
        </div>
        <br>
      </div>
      <button class="btn btn-secondary btn-sm edit-{{ i }}"
        [disabled]="sectionId === 'HHTJF' && enableEditReview && (row?.data?.taxFilingStatus !== 'Filer' && row?.data?.taxFilingStatus !== null)"
        [ngClass]="sectionId === 'HHIND' && row.data.headOfHousehold ? 'w-90':'w-100'" (click)="onEdit(row)"
        #onFocus>{{'SUMMARY_EDIT' | translate }}</button>
      <nh-popover class="pl-2" *ngIf="sectionId === 'HHIND' && row.data.headOfHousehold"
        [content]="headOfHouseHoldContent"></nh-popover>
      <div *ngIf="canDelete(row)" class="pt-1 hover-red text-center">
        <button class="btn btn-link btn-lg hover-red delete-{{i}}"
          [disabled]="sectionId === 'HHTJF' && enableEditReview && row?.data?.taxFilingStatus !== null" (click)="onDelete(row)">
          <span class="pr-2">{{'REMOVE' | translate}}</span>
          <nh-remove-svg></nh-remove-svg>
        </button>
      </div>
      <nh-popover *ngIf="sectionId === 'HHTJF' && row.data.taxFilingStatus !== 'Filer'"
        [content]="taxPopUpOne + name[i] + taxPopUpTwo"></nh-popover>
    </div>
  </div>
  <div *ngIf="sectionId === 'ARPDT'">
    <div class="table-row mb-3 pb-3" *ngFor="let row of appealRows; index as i">
      <div *ngFor="let head of columns">
        <div class="mobile-table-header" [innerHtml]="head.name | sanitize"></div>
        <hr>
        <div class="mobile-table-data" [innerHTML]="row | path: head.prop | loader: head.pipe : head.args | sanitize">
        </div>
        <br>
      </div>

      <div class="mobile-table-header">{{appealActionHeader | translate}}</div>
      <hr>

      <div *ngIf="!row.data.disableEdit" class="pt-1 hover-red text-center">
        <button class="btn btn-link btn-lg hover-red edit-{{ i }}" (click)="onEdit(row)"><span
            class="pr-2">{{'APPEAL_SUMMARY_EDIT' | translate }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2.5 19.5 19.49">
            <g id="_icons_create-outline" data-name=" icons create-outline">
              <path id="Combined_Shape-287" data-name="Combined Shape-287" class="cls-1"
                d="M2.63,19.49A2.62,2.62,0,0,1,0,16.91V5.62A2.63,2.63,0,0,1,2.58,3h7.9a.75.75,0,0,1,.75.75.74.74,0,0,1-.74.75H2.63A1.12,1.12,0,0,0,1.5,5.6V16.87A1.12,1.12,0,0,0,2.61,18H13.88A1.12,1.12,0,0,0,15,16.89V8.24a.76.76,0,0,1,.74-.76.75.75,0,0,1,.76.75v8.64a2.62,2.62,0,0,1-2.59,2.62Zm4.92-7.55a.17.17,0,0,1,0-.18l.39-1.17A.42.42,0,0,1,8,10.41L16.47,2a.43.43,0,0,1,.6,0h0l.47.47a.42.42,0,0,1,0,.59L9.09,11.49a.5.5,0,0,1-.19.1L7.74,12H7.68a.21.21,0,0,1-.13-.05ZM18.17,1.85l-.53-.53a.39.39,0,0,1,0-.53h0l.58-.58a.76.76,0,0,1,1.07,0,.75.75,0,0,1,0,1.06l-.56.57a.4.4,0,0,1-.54,0h0Z" />
            </g>
          </svg>
        </button>
      </div>
      <div *ngIf="canDelete(row)" class="pt-1 hover-red text-center">
        <button class="btn btn-link btn-lg hover-red delete-{{i}}" (click)="onDeleteAplRow(row)">
          <span class="pr-2">{{'REMOVE' | translate}}</span>
          <nh-remove-svg></nh-remove-svg>
        </button>
      </div>
    </div>
  </div>
</div>
