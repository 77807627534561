import { Injectable } from '@angular/core';
import { Validators, AbstractControl, ValidationErrors } from '@angular/forms';

import { ErrorCodes } from '@app/models/errors.model';

import {
  creditCardValidator,
  emailValidator,
  passwordValidator,
  expenseValidator,
  individualNameValidator,
  otherExpenseValidator,
  nameValidator,
  companyNameValidator,
  nursingNameValidator,
  descriptionValidator,
  policyNumberValidator,
  safeAtHomeMailValidator,
  houseNumberValidator,
  unitValidator,
  numberValidator,
  splCharValidator,
  userNameEmail,
  streetAddressValidator,
  commentsValidator,
  zipCodeValidator,
  cityValidator,
  languageValidator,
  medicareNumberValidator,
  topLevelValidator,
  dateNotAfterMonthValidator,
  dueDateValidator,
  dateNotBefore30DaysValidator,
  dateNotBefore120YearsValidator,
  dateNotBefore90DaysValidator,
  unmatchedFieldsValidator,
  dateStartAfterEndValidator,
  wagesPeriodHourValidator,
  vehicleYearValidator,
  lessThanValidator,
  documentDescriptionValidator,
  phoneNumberValidator,
  i94Validator,
  alienValidator,
  sevisValidator,
  cardNumberValidator,
  alphanumericValidator,
  certificateValidator,
  naturalizationValidator,
  currencyValidator,
  hoursValidator,
  ssnValidator,
  personIdValidator,
  passportValidator,
  validDateValidator,
  decimalValidator,
  numericValidator,
  percentageValidator,
  dateNotBefore60DaysValidator,
  invalidFieldValidator,
  maxDueDateValidator,
  userNameLength,
  noSpaceInUsername,
  answerValidator,
  firstNameValidator,
  lastNameValidator,
  invalidFutureDate,
  babyDateNotGreaterThan1Year,
  organizationNameValidator,
  returnDateValidator,
  dateNotBefore12MonthsValidator,
  noInitialSpaceValidator,
  submittedByValidator,
  insuranceCompanyNameValidator,
  signatureNameValidator


} from '@app/helpers/validators.helpers';
import { isValid } from 'date-fns';

@Injectable()
export default class ValidatorsService extends Validators {
  static notBefore60ays: any;
  static creditCard(control: AbstractControl): ValidationErrors | null {
    return creditCardValidator(control);
  }

  static email(control: AbstractControl): ValidationErrors | null {
    return emailValidator(control);
  }

  static answer(control: AbstractControl): ValidationErrors | null {
    return answerValidator(control);
  }

  static password(control: AbstractControl): ValidationErrors | null {
    return passwordValidator(control);
  }

  static expense(control: AbstractControl): ValidationErrors | null {
    return expenseValidator(control);
  }

  static spl(control: AbstractControl): ValidationErrors | null {
    return splCharValidator(control);
  }

  static userNameEmail(control: AbstractControl): ValidationErrors | null {
    return userNameEmail(control);
  }

  static userLength(control: AbstractControl): ValidationErrors | null {
    return userNameLength(control);
  }

  static noSpaceinUsername(control: AbstractControl): ValidationErrors | null {
    return noSpaceInUsername(control);
  }

  static currency(control: AbstractControl): ValidationErrors | null {
    return currencyValidator(control);
  }

  static hours(control: AbstractControl): ValidationErrors | null {
    return hoursValidator(control);
  }

  static numeric(control: AbstractControl): ValidationErrors | null {
    return numericValidator(control);
  }

  static percentage(control: AbstractControl): ValidationErrors | null {
    return percentageValidator(control);
  }

  static individualName(control: AbstractControl): ValidationErrors | null {
    return individualNameValidator(control);
  }

  static signatureName(control: AbstractControl): ValidationErrors | null {
    return signatureNameValidator(control);
  }

  static otherExpense(control: AbstractControl): ValidationErrors | null {
    return otherExpenseValidator(control);
  }

  static invalidName(control: AbstractControl): ValidationErrors | null {
    return nameValidator(control);
  }

  static invalidInsuranceCompanyName(control: AbstractControl): ValidationErrors | null {
    return insuranceCompanyNameValidator(control);
  }

  static invalidCompanyName(control: AbstractControl): ValidationErrors | null {
    return companyNameValidator(control);
  }
  
  static invalidNursingName(control: AbstractControl): ValidationErrors | null {
    return nursingNameValidator(control);
  }

  static invalidDescription(control: AbstractControl): ValidationErrors | null {
    return descriptionValidator(control);
  }

  static policyNumber(control: AbstractControl): ValidationErrors | null {
    return policyNumberValidator(control);
  }

  static safeAtHomeMailID(control: AbstractControl): ValidationErrors | null {
    return safeAtHomeMailValidator(control);
  }

  static orgName(control: AbstractControl): ValidationErrors | null {
    return organizationNameValidator(control);
  }

  static houseNumber(control: AbstractControl): ValidationErrors | null {
    return houseNumberValidator(control);
  }

  static unit(control: AbstractControl): ValidationErrors | null {
    return unitValidator(control);
  }

  static number(control: AbstractControl): ValidationErrors | null {
    return numberValidator(control);
  }

  static decimal(control: AbstractControl): ValidationErrors | null {
    return decimalValidator(control);
  }
  static streetAddress(control: AbstractControl): ValidationErrors | null {
    return streetAddressValidator(control);
  }

  static comments(control: AbstractControl): ValidationErrors | null {
    return commentsValidator(control);
  }

  static submittedBy(control: AbstractControl): ValidationErrors | null {
    return submittedByValidator(control);
  }

  static notAfterMonth(control: AbstractControl): ValidationErrors | null {
    return dateNotAfterMonthValidator(control);
  }

  static due(control: AbstractControl): ValidationErrors | null {
    return dueDateValidator(control);
  }
  static maxDue(control: AbstractControl): ValidationErrors | null {
    return maxDueDateValidator(control);
  }
  static invalidReturnDate(control: AbstractControl): ValidationErrors | null {
    return returnDateValidator(control);
  }

  static maxFutureDate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && !isValid(value)) {
      return null;
    }
    return invalidFutureDate(control);
  }

  static notBefore30Days(control: AbstractControl): ValidationErrors | null {
    return dateNotBefore30DaysValidator(control);
  }

  static notBefore90Days(control: AbstractControl): ValidationErrors | null {
    return dateNotBefore90DaysValidator(control);
  }

  static dueDateNotBefore60Days(control: AbstractControl): ValidationErrors | null {
    return dateNotBefore60DaysValidator(control);
  }

  static pregnancyEndDateNotBefore5Months(control: AbstractControl): ValidationErrors | null {
    return dateNotBefore12MonthsValidator(control);
  }

  static notBefore120Years(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && !isValid(value)) {
      return null;
    }
    return dateNotBefore120YearsValidator(control);
  }

  static notGreaterThan1YearBabyDate(control: AbstractControl): ValidationErrors | null {

    return babyDateNotGreaterThan1Year(control);
  }

  static vehicleYear(control: AbstractControl): ValidationErrors | null {
    return vehicleYearValidator(control);
  }

  static zipCode(control: AbstractControl): ValidationErrors | null {
    return zipCodeValidator(control);
  }

  static city(control: AbstractControl): ValidationErrors | null {
    return cityValidator(control);
  }

  static language(control: AbstractControl): ValidationErrors | null {
    return languageValidator(control);
  }

  static space(control: AbstractControl): ValidationErrors | null {
    return noInitialSpaceValidator(control);
  }

  static medicareNumber(control: AbstractControl): ValidationErrors | null {
    return medicareNumberValidator(control);
  }

  static topLevel(control: AbstractControl): ValidationErrors | null {
    return topLevelValidator(control);
  }

  static unmatchedFields(firstControlName, secondControlName): ValidationErrors | null {
    return unmatchedFieldsValidator(firstControlName, secondControlName);
  }

  static startAfterEnd(firstControlName, secondControlName): ValidationErrors | null {
    return dateStartAfterEndValidator(firstControlName, secondControlName);
  }

  static wagesPeriodHour(wagesPeriod, hoursAverage): ValidationErrors | null {
    return wagesPeriodHourValidator(wagesPeriod, hoursAverage);
  }

  static invalidField(control: AbstractControl): ValidationErrors | null {
    return invalidFieldValidator(control);
  }

  static validDate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && isValid(value)) {
      return null;
    }
    return validDateValidator(control);
  }

  static lessThan(firstControlName, secondControlName, errorCode = ErrorCodes.LESS_THAN): ValidationErrors | null {
    return lessThanValidator(errorCode)(firstControlName, secondControlName);
  }

  static documentDescription(control: AbstractControl): ValidationErrors | null {
    return documentDescriptionValidator(control);
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    return phoneNumberValidator(control);
  }

  static i94(control: AbstractControl): ValidationErrors | null {
    return i94Validator(control);
  }

  static alphaNumeric(control: AbstractControl): ValidationErrors | null {
    return alphanumericValidator(control);
  }

  static alienNumber(control: AbstractControl): ValidationErrors | null {
    return alienValidator(control);
  }

  static sevisID(control: AbstractControl): ValidationErrors | null {
    return sevisValidator(control);
  }

  static ssn(control: AbstractControl): ValidationErrors | null {
    return ssnValidator(control);
  }

  static personId(control: AbstractControl): ValidationErrors | null {
    return personIdValidator(control);
  }

  static cardNumber(control: AbstractControl): ValidationErrors | null {
    return cardNumberValidator(control);
  }

  static certificateNumber(control: AbstractControl): ValidationErrors | null {
    return certificateValidator(control);
  }

  static naturalizationNumber(control: AbstractControl): ValidationErrors | null {
    return naturalizationValidator(control);
  }

  static passportNumber(control: AbstractControl): ValidationErrors | null {
    return passportValidator(control);
  }

  static firsName(control: AbstractControl): ValidationErrors | null {
    return firstNameValidator(control);
  }

  static lastName(control: AbstractControl): ValidationErrors | null {
    return lastNameValidator(control);
  }
}
