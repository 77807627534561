import { HouseholdPresumptiveComponent } from './household-presumptive.component';

import Vs from '@app/services/validators/validators.service';
import { isNil, evolve } from 'ramda';
import { trudy } from '@app/helpers/function.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { notOther, notOtherRelation } from '@app/helpers/models.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { nilControl } from '@app/helpers/form-control.helpers';
import { isSelf, sectionConfiguration } from '@app/helpers/models.helpers';
import { addressCreator, nhAddressCreator, nameCreator, phonesCreator } from '@app/helpers/models.helpers';
import {toDate, toISO} from "@app/helpers/date.helpers";
import { isNo } from '@app/helpers/tayn.helpers';

const formValidation = (builder, recordData) => {
  const household = lookIn(recordData);
  const ackSignSw = household(['assistingPerson','ackSignSw']);
  const assistingPersonSignName = household(['assistingPerson','assistingPersonSignName']);
  const applicantAckSignSw = household(['assistingPerson','applicantAckSignSw']);
  const applicantSignName = household(['assistingPerson','applicantSignName']);
  const primaryLanguage = household(['languagePreference', 'primaryLanguage']);
  const assistingOnBehalfCtrl = household(['ppAssistingOnBehalf']);
  const ppAddAssistingPersonToApp = household(['ppAddAssistingPersonToApp']);
  const homeless =  household(['homeless']);
  const sahPrgSw = household(['sahPrgSw']);
  const sahMailId = household(['sahMailId']);
  const sahCountyCd = household(['sahCountyCd']);
  const helpDuration = household(['assistingPerson', 'helpDuration']);
  const relationship = household(['assistingPerson', 'relationship']);
  const otherRelation = household(['assistingPerson', 'otherRelationship']);
  const email = household(['assistingPerson', 'email']);
  const organizationName = household(['assistingPerson', 'organizationName']);
  const idNumber = household(['assistingPerson', 'idNumber']);
  const bestTime = household(['assistingPerson', 'bestTime']);
  const addressFormatP = household(['physicalAddress', 'addressFormat']);
  const addressFormatA = household(['assistingPerson', 'addressFormat']);

  const noAssistingPerson = isNil(assistingOnBehalfCtrl) || (isNo(assistingOnBehalfCtrl) && isNo(ppAddAssistingPersonToApp));
  const helpers = builder.group({ addMailingInfo: ['', Vs.required] });

  const data = builder.group({
    dateOfApplication: [toDate(household(['dateOfApplication'])), [Vs.required]],
    healthPlan: household(['healthPlan']),
    casehead: builder.group(nameCreator(household(['casehead']))),
    languagePreference: builder.group({
      primaryLanguage: [primaryLanguage ? primaryLanguage : { code: '0', value: 'English' }],
      otherLanguageName: [{
          value: household(['languagePreference', 'otherLanguageName']),
          disabled: notOther(primaryLanguage),
        }, [Vs.required, Vs.maxLength(50), Vs.language]],
      letterLanguage: household(['languagePreference', 'letterLanguage'], [Vs.required]),
    }),
    homeless: [homeless, Vs.required],
    sahPrgSw: [sahPrgSw],
    sahMailId: [sahMailId, [Vs.safeAtHomeMailID, Vs.maxLength(50)]],
    sahCountyCd: [sahCountyCd],
    physicalAddress: builder.group({
      addressFormat: [nilControl(addressFormatP), Vs.required],
      address: builder.group(nhAddressCreator(household(['physicalAddress', 'address']))),
      contactPhones: builder.group({
        home: [household(['contactPhones', 'home']), Vs.phoneNumber],
        cell: [household([['contactPhones', 'cell']]), Vs.phoneNumber],
      }),
      bestTime: household(['physicalAddress', 'bestTime']),
    }),
    mailingAddress: builder.group({
      addressFormat: [household(['mailingAddress', 'addressFormat']), Vs.required],
      address: builder.group(addressCreator(household(['mailingAddress', 'address']))),
    }),
    ppAddAssistingPersonToApp: [ppAddAssistingPersonToApp, Vs.required],
    ppAssistingOnBehalf: [controlWith(assistingOnBehalfCtrl, !assistingOnBehalfCtrl), Vs.required],
    assistingPerson: builder.group({
      ackSignSw: [ackSignSw, Vs.required],
      assistingPersonSignName: [(assistingPersonSignName),[Vs.required, Vs.maxLength(30), Vs.signatureName]],
      applicantAckSignSw: [applicantAckSignSw, Vs.required],
      applicantSignName: [(applicantSignName), [Vs.required, Vs.maxLength(30), Vs.signatureName]],
      relationship: [controlWith(relationship, !relationship || isSelf(assistingOnBehalfCtrl)), Vs.required],
      otherRelationship: [controlWith(otherRelation, notOtherRelation(relationship)), [Vs.required, Vs.language]],
      helpDuration: [controlWith(helpDuration, !helpDuration || isSelf(assistingOnBehalfCtrl))],
      name: builder.group(nameCreator(household(['assistingPerson', 'name']))),
      organizationName: [controlWith(organizationName, noAssistingPerson), Vs.maxLength(256)],
      idNumber: [controlWith(idNumber, noAssistingPerson), Vs.maxLength(20)],
      addressFormat: [controlWith(addressFormatA, !addressFormatA), Vs.required],
      address: builder.group(addressCreator(household(['assistingPerson', 'address']))),
      email: [controlWith(email, !email), [Vs.email]],
      bestTime: [controlWith(bestTime, noAssistingPerson)],
      contactPhones: builder.group(phonesCreator(household(['assistingPerson', 'contactPhones']))),
      responsibilities: builder.group({
        applicantBehalf: household(['assistingPerson', 'responsibilities', 'applicantBehalf'], Vs.required),
        submitRenewal: household(['assistingPerson', 'responsibilities', 'submitRenewal'], Vs.required),
        letterOtherCommunication: household(['assistingPerson', 'responsibilities', 'letterOtherCommunication'], Vs.required),
        yourBehalf: household(['assistingPerson', 'responsibilities', 'yourBehalf'], Vs.required)
      })
    }),
    hiddenField: null
  })

  trudy(() => [
    disableGet('assistingPerson.name', data),
    disableGet('assistingPerson.address', data),
    disableGet('assistingPerson.contactPhones', data),
    disableGet('assistingPerson.responsibilities', data)
  ], noAssistingPerson);

  return { helpers, data };
};

const postObject = evolve({
  dateOfApplication: toISO,
});

export default sectionConfiguration(
  HouseholdPresumptiveComponent,
  formValidation,
  ['PRIMARYLANGUAGE', 'WRITTENLANGUAGE', 'MCONAME', 'MCONAME_UBW', 'YESNO', 'ADDRESSFORMATTYPES', 'MILITARYPOCODES',
  'APRNSOURCE', 'CONTACTTIME', 'MPASSTPER', 'AUTHREPTIME', 'AUTHREPRELATIONSHIP', 'NAMESUFFIX', 'STATE', 'COUNTY', 'STATEMILITARY'],
  [],
  {},
  {
    postObject: postObject,
    hasSummary: false,
    validateSubmit: true,
  }
);