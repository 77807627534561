import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationService } from '@app/services/application/application.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalDirective } from '../base-modal';


@Component({
  selector: 'nh-report-change-modal',
  templateUrl: './report-change-modal.component.html',
  styleUrls: ['./report-change-modal.component.scss']
})
export class ReportChangeModalComponent extends BaseModalDirective implements OnInit {
  readTCC = sessionStorage.getItem('ReadTCC') === 'true' ? true : false;
  
  constructor(
      protected modalRef: BsModalRef,
      private readonly appService: ApplicationService,
    protected builder: FormBuilder,
    private router: Router){
    super(modalRef, builder);
  }
  
  ngOnInit() {

  }

  updateMyInformation(): void{
    this.hide('');    
    this.appService.rmcPopUpUser({
      "userId": JSON.parse(sessionStorage.currentUser).userId  
    }).subscribe(() => {
       this.router.navigate(["/user-profile", "report-change"]);
      });
  }

  onCancel(): void{
    this.hide('');
  }
}